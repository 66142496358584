import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectClientEmployeeAccount} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";
import {SKILL_DOMAIN_ACTIVITY, selectSkill} from "store/skillsDevelopmentPlan/skill/skillSlice";

// formatage date
import moment from "moment";

const ClientEmployeeEditModal = ({ isOpen, toggle, editEmployee, clientEmployees }) => {

  const dispatch = useDispatch()

  const { clientEmployee } = useSelector(selectClientEmployeeAccount);
  const { skillActivities } = useSelector(selectSkill);

  const manager = clientEmployee.hasOwnProperty('managerId') && clientEmployee.managerId ? clientEmployees.find( employee => employee.id === clientEmployee.managerId) : null

  useEffect(() => {
    dispatch(SKILL_DOMAIN_ACTIVITY())
  }, [dispatch])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Modifier les informations du collaborateur ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <Row>
          <Col lg={12}>
            <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
          </Col>
        </Row>

        <AvForm onValidSubmit={(e, v) => editEmployee(e, v, clientEmployee.user.id, clientEmployee.id, clientEmployee.user.email)}>

          <Row form>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Prénom <span className="text-danger">*</span></h5>
              <AvField
                name="firstname"
                type="text"
                errorMessage="Veuillez renseigner un prénom"
                validate={{ required: { value: true } }}
                value={clientEmployee.hasOwnProperty('user') && clientEmployee.user.firstname ? clientEmployee.user.firstname : ""}
              />
            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Nom <span className="text-danger">*</span></h5>
              <AvField
                name="lastname"
                type="text"
                errorMessage="Veuillez renseigner un nom"
                validate={{ required: { value: true } }}
                value={clientEmployee.hasOwnProperty('user') && clientEmployee.user.lastname ? clientEmployee.user.lastname : ""}
              />
            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Poste <span className="text-danger">*</span></h5>
              <AvField
                name="job"
                type="text"
                errorMessage="Veuillez renseigner un poste"
                validate={{ required: { value: true } }}
                value={clientEmployee.job ? clientEmployee.job : ""}
              />
            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Pôle <span className="text-danger">*</span></h5>
              <AvField
                name="pole"
                type="text"
                errorMessage="Veuillez renseigner un pôle"
                validate={{ required: { value: true } }}
                value={clientEmployee.pole ? clientEmployee.pole : ""}
              />
            </Col>


            <Col className="col-12 mb-3">

              <h5 className="font-size-17 mt-5 mb-3">Service <span className="text-danger">*</span></h5>

              <AvField
                className="form-select"
                type="select"
                name="service"
                validate={{ required: { value: false } }}
                errorMessage="Veuillez renseigner un service"
              >
                <option key={`activity-${0}`} value={clientEmployee.service ? clientEmployee.service : ""}>{clientEmployee.service ? clientEmployee.service : ""}</option>
                {map(skillActivities, activity => (
                  <option key={`activity-${activity.id}`} value={activity.name}>{activity.name}</option>
                ))}
              </AvField>

            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Responsable hiérarchique</h5>
              <AvField
                className="form-select"
                type="select"
                name="managerId"
                validate={{ required: { value: false } }}
              >
                { clientEmployee.managerId
                  ? (
                    <>
                      <option key={`team-origin-${clientEmployee.managerId}`} value={clientEmployee.managerId}>{manager.user.firstname} {manager.user.lastname}</option>
                      <option value={null} key={"default"}>Aucun</option>
                    </>
                  )
                  : <option value={null} key={"default"}>Choisir ...</option>
                }


                {map(clientEmployees, employee => {
                  if (clientEmployee.id === employee.id) {
                     return null
                  } else {
                  return <option key={`team-${employee.id}`} value={employee.id}>{employee.user.firstname} {employee.user.lastname}</option>
                  }
                })}
              </AvField>
            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">E-mail <span className="text-danger">*</span></h5>
              <AvField
                name="email"
                type="email"
                errorMessage="Veuillez renseigner un email"
                validate={{ required: { value: true } }}
                value={clientEmployee.hasOwnProperty('user') && clientEmployee.user.email ? clientEmployee.user.email : ""}
              />
            </Col>

            <Col className="col-12 mb-5">
              <h5 className="font-size-17 mt-5 mb-3">Date d'arrivée dans le service <span className="text-danger">*</span></h5>
              <AvField
                name="arrivalDate"
                type="date"
                errorMessage="Veuillez renseigner une date valide"
                validate={{ required: { value: true } }}
                value={clientEmployee.arrivalDate ? moment.utc(clientEmployee.arrivalDate).format("yyyy-MM-DD") : ""}
              />
            </Col>

            <AvField type="hidden" name="employeeId" value={clientEmployee.id ? clientEmployee.id : null}></AvField>

          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
                <button type="submit" className="btn btn-success save-event">Modifier le collaborateur</button>
              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>

    </Modal>
  )
}

export default ClientEmployeeEditModal

ClientEmployeeEditModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
