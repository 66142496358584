import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    forgetSuccessMsg: null,
    forgetError: null,
}

const forgetpwdSlice = createSlice({
    name: "forgetpwd",
    initialState,

    reducers: {

        FORGET_PASSWORD (state) {
            return {...state, forgetSuccessMsg: null, forgetError: null}
        },

        RESET_PASSWORD (state) {
            return {...state, forgetSuccessMsg: null, forgetError: null}
        },

        FORGET_PASSWORD_SUCCESS (state, action) {
            return {...state, forgetSuccessMsg: action.payload}  // payload: message
        },

        FORGET_PASSWORD_ERROR (state, action) {
            return {...state, forgetError: action.payload}  // payload: message
        },

        RESET_ALERT (state) {
            return {...state, forgetSuccessMsg: null, forgetError: null}
        },
    }
})

const { reducer, actions } = forgetpwdSlice;

export const { FORGET_PASSWORD, RESET_PASSWORD, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_ERROR, RESET_ALERT } = actions;

export default reducer;

export const selectForgetpwd = (state) => state.forgetpwd;
