import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import {Row, Col, Container, CardBody, Card, UncontrolledAlert} from "reactstrap"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logo from "assets/images/logo-firmain.png"
import logolight from "assets/images/logo-firmain.png"

// REDUX
import {useDispatch, useSelector} from "react-redux";
import { selectForgetpwd, FORGET_PASSWORD, RESET_ALERT } from "store/auth/forgetpwd/forgetpwdSlice";

const ForgetPasswordPage = () => {

  const dispatch = useDispatch()
  const { forgetSuccessMsg, forgetError } = useSelector(selectForgetpwd);

  const [email, setEmail] = useState("")

  function handleValidSubmit() {
    const data = { email: email }
    dispatch(FORGET_PASSWORD(data))
  }

  useEffect(() => {
    dispatch(RESET_ALERT())
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, [dispatch]);

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block mt-3">
        <a href="https://firmain.fr" className="text-dark mx-4" target="_blank" rel="noreferrer">
          <i className="mdi mdi-home-variant h2"></i>
        </a>
      </div>

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">

            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>

                <a href="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </a>
                <Card>

                  <CardBody className="p-4">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Mot de passe oublié ?</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <p>
                        Saisissez l'adresse e-mail associée à votre compte.
                        Nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe.
                      </p>

                      {forgetError ? (
                        <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                          <i className="uil uil-exclamation-octagon me-2"></i> Une erreur s'est produite : "{forgetError}"
                        </UncontrolledAlert>
                      ) : null }

                      { forgetSuccessMsg ? (
                        <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                          <i className="uil uil-check me-2"></i> {forgetSuccessMsg}
                        </UncontrolledAlert>
                      ) : null }

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            value={email}
                            label="Email"
                            className="form-control"
                            placeholder="Saisir votre email"
                            type="email"
                            onChange={event => setEmail(event.target.value)}
                            errorMessage="Veuillez renseigner un email"
                            required
                          />
                        </div>

                        <Row className="row mb-0">
                          <Col className="col-12 text-center mt-3">
                            <button className="btn btn-success w-md waves-effect waves-light" type="submit">Envoyer lien de réinitialisation</button>
                          </Col>
                        </Row>

                        <div className="mt-4 text-center">
                          <p className="mb-0"><Link to="/login" className="fw-medium text-primary"> Retour page de connexion </Link></p>
                        </div>

                      </AvForm>

                    </div>

                  </CardBody>

                </Card>

                <div className="mt-5 text-center">
                  <p>Firmain</p>
                </div>

              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}
