import React from "react"
import { Link } from "react-router-dom"

const ClientAccountColumn = toggleModal => [

  {
    dataField: "entName",
    text: "Nom de l'organisation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    csvFormatter: (cellContent, row) => `${row.id}`,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "locality.region.name",
    text: "Région",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "entCommercialCoverage",
    text: "Domaine d'activité",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "contactLastname",
    text: "Représentant",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => (
      <>
        <p className="mb-0">{row.contactFirstname} {row.contactLastname}</p>
      </>
    ),
    csvFormatter: (cellContent, row) => `${row.contactFirstname} ${row.contactLastname}`,
    sortValue: (cell, row) => row.contactLastname,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Voir détails",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <div className="flex justify-content-center" style={{ minWidth: "130px"}}>
        <Link to={{ pathname: "details-organisation", state: row.id }} className="btn btn-link waves-effect px-2 text-success">
          <i className="uil uil-eye font-size-20"></i>
        </Link>

        <Link to={{ pathname: "modifier-organisation", state: row.id }} className="btn btn-link waves-effect px-2 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>

        <button
          type="button"
          className="btn btn-link waves-effect text-danger"
          onClick={() => toggleModal(row)}
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-trash-alt font-size-18"></i>
        </button>
    </div>
    ),
    csvExport: false
  }
]

export default ClientAccountColumn
