import React from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {isEmpty} from "lodash";

const AdvertEditModal = ({ isOpen, toggle, editAdvert, adverts }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-xl"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Modifier le bandeau publicitaire ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <AvForm onValidSubmit={(e, v) => editAdvert(e, v )}>

          <Row form>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Message</h5>
              <AvField
                name="message"
                type="text"
                validate={{ required: { value: false } }}
                value={!isEmpty(adverts) ? adverts[0].message : ''}
              />
            </Col>

            <Col className="col-12 mb-3">
              <h5 className="font-size-17 mt-5 mb-3">Lien</h5>
              <AvField
                name="link"
                type="text"
                validate={{ required: { value: false } }}
                value={!isEmpty(adverts) ? adverts[0].link : ''}
                helpMessage="ex: https://test.com"
              />
            </Col>

          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
                <button type="submit" className="btn btn-success save-event">Modifier le bandeau publicitaire</button>
              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>

    </Modal>
  )
}

export default AdvertEditModal

AdvertEditModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
