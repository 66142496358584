import React from "react"
import {Badge} from "reactstrap"
import { capitalize } from'lodash'

const CartographyTableCreateColumn = (toggleModal, toggleModalRequiredLevel, cartography) => {

  const employeeTotal = (cartography.hasOwnProperty('skills') && cartography.skills.length !== 0)
    ? cartography.skills[0].employeeSkills.length : null

  const employee = (cartography.hasOwnProperty('skills') && cartography.skills.length !== 0)
    ? cartography.skills[0].employeeSkills : null

  const columns = [

    {
      dataField: "pole",
      text: "Pôle",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      headerStyle: {
        color: '#597887',
        minWidth: '100px',
        cursor: 'pointer',
        fontSize: '15px'
      },
      classes: 'domaine-text',
      editable: false,
      sort: true,
      formatter: (cellContent, row) => <p className="mb-0 font-size-15">{row.pole}</p>,
      sortCaret: (order) => {
        if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
        else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
        else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
        return null;
      }
    },

    {
      dataField: "skillActivity.name",
      text: "Service",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      align: 'center',
      headerStyle: {
        color: '#597887',
        cursor: 'pointer',
        fontSize: '15px'
      },
      classes: 'domaine-text',
      editable: false,
      sort: true,
      formatter: (cellContent, row) => <p className="mb-0 font-size-15">{row.skillActivity.name}</p>,
      sortCaret: (order) => {
        if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
        else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
        else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
        return null;
      },
    },

    {
      dataField: "skillDomain.name",
      text: "Domaine de compétences",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      align: 'center',
      headerStyle: {
        color: '#597887',
        minWidth: '200px',
        cursor: 'pointer',
        fontSize: '15px'
      },
      classes: 'domaine-text',
      editable: false,
      sort: true,
      formatter: (cellContent, row) => <p className="mb-0 font-size-15">{row.skillDomain.name}</p>,
      sortCaret: (order) => {
        if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
        else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
        else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
        return null;
      },
    },

    {
      dataField: "task",
      text: "Tâches",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      headerStyle: {
        color: '#597887',
        cursor: 'pointer',
        fontSize: '15px'
      },
      classes: 'action-wrapper',
      sort: true,
      formatter: (cellContent, row) => {
        return <h6 className="font-size-15" style={{maxWidth: '280px'}}>{row.task}</h6>
      },
      sortCaret: (order) => {
        if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
        else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
        else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
        return null;
      },
    }
  ]

  const backgroundColor = (colorRisk) => {
    if (colorRisk === 'very-good') {
      return {backgroundColor: '#009644', borderRadius: '50px'}
    } else if (colorRisk === 'good') {
      return {backgroundColor: '#92D050', borderRadius: '50px'}
    } else if (colorRisk === 'medium') {
      return {backgroundColor: '#e5a65a', borderRadius: '50px'}
    } else if (colorRisk === 'bad') {
      return {backgroundColor: '#a93631', borderRadius: '50px'}
    } else if (colorRisk === 'not-concerned') {
      return {backgroundColor: '#a0a0a0', borderRadius: '50px'}
    }
  }

  for (let i = 0; i < employeeTotal; i++) {

    columns.push(
      {
        dataField: (employee[i].employee.id).toString(),
        text: `${capitalize(employee[i].employee.clientEmployee.user.firstname)} ${capitalize(employee[i].employee.clientEmployee.user.lastname)}`,
        headerStyle: {
          color: '#495057',
          fontSize: '15px'
        },
        headerFormatter: (cellContent, row) => {
          return (
            <span>
              {capitalize(employee[i].employee.clientEmployee.user.firstname)}&nbsp;{capitalize(employee[i].employee.clientEmployee.user.lastname)}
            </span>
          )
        },
        headerAlign: 'center',
        headerClasses: 'header-column-title',
        align: 'center',
        formatter: (cellContent, row) => {
          return (
            <>
              {row.employeeSkills[i].message === '' ? (
                <div
                  className="text-cartography-empty mb-0"
                  onClick={() => toggleModal(
                    row.employeeSkills[i].id,
                    employee[i].employee.clientEmployee.user.firstname,
                    employee[i].employee.clientEmployee.user.lastname,
                    row.employeeSkills[i].message,
                    row.employeeSkills[i].colorRisk,
                  )}
                  key={employee[i].id}
                >
                  <p className="empty-round" style={backgroundColor(row.employeeSkills[i].colorRisk)}></p>
                </div>
              ) : (
                <p
                  className="text-cartography mb-0"
                  onClick={() => toggleModal(
                    row.employeeSkills[i].id,
                    employee[i].employee.clientEmployee.user.firstname,
                    employee[i].employee.clientEmployee.user.lastname,
                    row.employeeSkills[i].message,
                    row.employeeSkills[i].colorRisk,
                  )}
                  key={employee[i].id}
                  style={backgroundColor(row.employeeSkills[i].colorRisk)}
                >
                  {row.employeeSkills[i].message}
                </p>
              ) }
            </>
          )}
      }
    )
  }

  columns.push(
    {
      dataField: "requiredLevel",
      text: "Niveau requis",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      headerStyle: {
        fontSize: '15px'
      },
      align: 'center',
      //hidden: true,
      type: 'number',
      formatter: (cellContent, row) => {
        return (
          <p className="mb-0 font-size-15" style={{cursor: 'pointer'}} onClick={() => toggleModalRequiredLevel(row)}>
            {row.requiredLevel}
          </p>
        )}
    },
    {
      dataField: "totalVeryGood",
      text: "expert (total)",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      classes: 'cell-hidden',
      hidden: true,
    },
    {
      dataField: "totalGood",
      text: "confirmé (total)",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      classes: 'cell-hidden',
      hidden: true,
    },
    {
      dataField: "totalMedium",
      text: "débutant (total)",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      align: 'center',
      classes: 'cell-hidden',
      hidden: true,
    },
    {
      dataField: "totalBad",
      text: "non-maîtrisé (total)",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      align: 'center',
      classes: 'cell-hidden',
      hidden: true,
    },
    {
      dataField: "risk",
      text: "Actions à mener",
      headerAlign: 'center',
      headerClasses: 'header-column-title',
      headerStyle: {
        color: '#597887',
        fontSize: '15px'
      },
      align: 'center',
      formatter: (cellContent, row) => {
        if (row.risk === 3) {
          return <Badge className="badge badge-cartography pill bg-risk-good font-size-14" pill>OK</Badge>
        } else if (row.risk === 2) {
          return <Badge className="badge badge-cartography pill bg-risk-medium font-size-14" pill>Action de formation dans l'année préconisée</Badge>
        } else if (row.risk === 1) {
          return <Badge className="badge badge-cartography pill bg-risk-bad font-size-14" pill>Action de formation immédiate préconisée</Badge>
        } else if (row.risk === 4) {
          return <Badge className="badge badge-cartography pill text-primary font-size-14" pill>Compétences inférieures au niveau requis</Badge>
        }
      },
    }
  )

  return columns
}


export default CartographyTableCreateColumn
