import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_ERROR } from "./forgetpwdSlice"
import { LOGIN_RESET_SUCCESS } from "store/auth/login/loginSlice"

// Include Both Helper File with needed methods
import { postForgetPwd, resetForgetPwd } from "helpers/request_helper"

// If user is send successfully send mail link then dispatch redux action's are directly from here.

function* forgetPassword({ payload: { email } }) {
    try {
        yield call(postForgetPwd,  { email: email })
        yield put(FORGET_PASSWORD_SUCCESS("Vérifier votre boîte mail, un lien de réinitialisation vous a été envoyé !"))
    } catch (error) {
        if (error.response.status === 500) {
            yield put(FORGET_PASSWORD_ERROR("Une erreur de serveur s'est produite !"))
        } else {
            yield put(FORGET_PASSWORD_ERROR(error))
        }
    }
}

function* resetPassword({ payload: { password, token, history } }) {
    try {
        yield call(resetForgetPwd,  {
            password: password,
            token: token
        })
        yield put(LOGIN_RESET_SUCCESS("Votre mot de passe a bien été réinitialisé"))
        history.push("/login")
    } catch (error) {
        if (error.response.status === 404) {
            yield put(FORGET_PASSWORD_ERROR("Vous n'avez pas l'autorisation d'effectuer cette opération"))
        } else {
            yield put(FORGET_PASSWORD_ERROR(error))
        }
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword)
    yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default function* ForgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)])
}

