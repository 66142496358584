import React from "react"
import { Container } from "reactstrap"

// Role Verification
import { verifRoleToken } from "helpers/role_helper"

// Components
import DashboardAdmin from "pages/Dashboard/DashboardAdmin";
import DashboardClient from "pages/Dashboard/DashboardClient";
import DashboardUser from "pages/Dashboard/DashboardUser";
import DashboardPrestataire from "pages/Dashboard/DashboardPrestataire";

const DashboardScene = () => {

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    { verifRoleToken('ROLE_ADMIN') && (
                      <DashboardAdmin />
                    )}

                    { verifRoleToken('ROLE_CLIENT') && (
                      <DashboardClient />
                    )}

                  { verifRoleToken('ROLE_USER') && (
                    <DashboardUser />
                  )}

                  { verifRoleToken('ROLE_PRESTATAIRE') && (
                    <DashboardPrestataire />
                  )}

                </Container>
            </div>
        </>
    )
}

export default DashboardScene
