import React from "react";
import PropTypes from 'prop-types';
import { FormGroup } from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { SHOW_RIGHT_SIDEBAR, CHANGE_LAYOUT, CHANGE_LAYOUT_WIDTH, CHANGE_SIDEBAR_TYPE, CHANGE_SIDEBAR_THEME, CHANGE_PRELOADER } from "store/layout/layoutSlice";
import {selectLayout} from "store/layout/layoutSlice";

const RightSidebar = props => {

  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);

  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
              <h5 className="m-0 me-2">Paramètres</h5>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  dispatch(SHOW_RIGHT_SIDEBAR(false))
                }}
                className="right-bar-toggle ms-auto"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Disposition du menu</span>
                <input
                  type="radio"
                  id="radioVertical"
                  name="radioFruit"
                  value="vertical"
                  checked={layout.layoutType === "vertical"}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(CHANGE_LAYOUT(e.target.value));
                    }
                  }}
                />
                <label htmlFor="radioVertical">Vertical</label>
                {"   "}
                <input
                  type="radio"
                  id="radioHorizontal"
                  name="radioFruit"
                  value="horizontal"
                  checked={layout.layoutType === "horizontal"}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(CHANGE_LAYOUT(e.target.value));
                    }
                  }}
                />
                <label htmlFor="radioHorizontal">Horizontal</label>
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Largeur des pages
                </span>
                <input
                  type="radio"
                  id="radioFluid"
                  name="radioWidth"
                  value="fluid"
                  checked={layout.layoutWidth === "fluid"}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(CHANGE_LAYOUT_WIDTH(e.target.value))
                    }
                  }}
                />
                <label htmlFor="radioFluid">Fluid</label>
                {"   "}
                <input
                  type="radio"
                  id="radioBoxed"
                  name="radioWidth"
                  value="boxed"
                  checked={layout.layoutWidth === "boxed"}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(CHANGE_LAYOUT_WIDTH(e.target.value))
                    }
                  }}
                />
                <label htmlFor="radioBoxed">Boxed</label>
              </div>

              <hr className="mt-1" />
              {layout.layoutType === "vertical" ? (
                <React.Fragment>
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Type de sidebar (menu){" "}
                    </span>
                    <input
                      type="radio"
                      id="sidebarDefault"
                      name="sidebarType"
                      value="default"
                      checked={layout.leftSideBarType === "default"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_TYPE(e.target.value));
                        }
                      }}
                    />
                    <label htmlFor="sidebarDefault">Default</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarCompact"
                      name="sidebarType"
                      value="compact"
                      checked={layout.leftSideBarType === "compact"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_TYPE(e.target.value))
                        }
                      }}
                    />
                    <label htmlFor="sidebarCompact">Compact</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarIcon"
                      name="sidebarType"
                      value="icon"
                      checked={layout.leftSideBarType === "icon"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_TYPE(e.target.value))
                        }
                      }}
                    />
                    <label htmlFor="sidebarIcon">Icon</label>
                  </div>

                  <hr className="mt-1" />

                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Couleur de la sidebar (menu)
                    </span>
                    <input
                      type="radio"
                      id="leftsidebarThemelight"
                      name="leftsidebarTheme"
                      value="light"
                      checked={layout.leftSideBarTheme === "light"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_THEME(e.target.value))
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemelight">Light</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemedark"
                      name="leftsidebarTheme"
                      value="dark"
                      checked={layout.leftSideBarTheme === "dark"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_THEME(e.target.value))
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemedark">Dark</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemecolored"
                      name="leftsidebarTheme"
                      value="colored"
                      checked={layout.leftSideBarTheme === "colored"}
                      onChange={e => {
                        if (e.target.checked) {
                          dispatch(CHANGE_SIDEBAR_THEME(e.target.value))
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemecolored">Colored</label>
                  </div>
                  <hr className="mt-1" />
                </React.Fragment>
              ) : null}

              <FormGroup>
                <span className="mb-2 d-block" id="radio-title">
                  Preloader
                </span>

                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input checkbox"
                    id="checkbox_1"
                    checked={layout.isPreloader}
                    onChange={() => {
                      dispatch(CHANGE_PRELOADER(!layout.isPreloader))
                    }}
                  />

                  <label className="form-check-label" htmlFor="checkbox_1">
                    Preloader (page de chargement)
                  </label>
                </div>
              </FormGroup>

            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};

export default RightSidebar;

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.func,
  topbarTheme: PropTypes.any
};
