import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  employee: {},
  success: "",
  error: "",
  isLoading: false
}

const employeeSlice = createSlice({
  name: "employee",
  initialState,

  reducers: {

    EMPLOYEE (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_EMPLOYEE (state, action) {
      state.employee = action.payload
    },

    ADD_DATA_EMPLOYEE (state, action) {
      state.employee = action.payload
    },

    ADD_EMPLOYEE_FROM_TEAM (state) {
      state.isLoading = true
    },

    UPDATE_EMPLOYEE (state) {
      state.isLoading = true
    },

    DELETE_EMPLOYEE (state) {
      state.isLoading = true
      state.employee = {}
    },

    EMPLOYEE_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    EMPLOYEE_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    }

  }
});

const { reducer, actions } = employeeSlice;

export const {
  EMPLOYEE,
  GET_EMPLOYEE,
  ADD_DATA_EMPLOYEE,
  ADD_EMPLOYEE_FROM_TEAM,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_ERROR
} = actions;

export default reducer;

export const selectEmployee = (state) => state.employee;
