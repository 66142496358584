import React from "react";

const ClientCatalogueFormationDetailColumn = () => [

  {
    dataField: "image.filePath",
    text: "",
    sort: true,
    formatter: (column, row) => {
      return <>
        {
          row.hasOwnProperty('image')
            ? (
              <img
                className="avatar-title rounded-circle bg-white"
                style={{ width: 35, height: 35 }}
                src={process.env.REACT_APP_URL + "/media/" + row.image.filePath}
                alt="Header Avatar"
              />
            ) : (
              <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-13`} style={{ width: 35, height: 35 }}>
                {row.intFirstname.charAt(0).toUpperCase() + row.intLastname.charAt(0).toUpperCase()}
              </span>
            )
        }
      </>
    },
  },

  {
    dataField: "intLastname",
    text: "Intervenant",
    sort: true,
    formatter: (column, row) => (
      <>
        <p className="mb-0">{row.intFirstname} {row.intLastname}</p>
      </>
    ),
  },

  {
    dataField: "qualification",
    text: "Qualification",
    sort: true
  },

];

export default ClientCatalogueFormationDetailColumn;
