import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  CLIENT_EMPLOYEES_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT,
  GET_CLIENT_EMPLOYEES_ACCOUNT,
  GET_CLIENT_EMPLOYEE_ACCOUNT,
  ADD_CLIENT_EMPLOYEE_ACCOUNT,
  ADD_NEW_CLIENT_EMPLOYEE_ACCOUNT,
  UPDATE_CLIENT_EMPLOYEE_ACCOUNT,
  UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER,
  ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT,
  DELETE_CLIENT_EMPLOYEE_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT_SUCCESS,
  CLIENT_EMPLOYEE_ACCOUNT_ERROR
} from "./clientEmployeeAccountSlice"

import {
  getClientEmployeesAccount,
  getClientEmployeeAccount,
  putClientEmployeeAccount,
  deleteClientEmployeeAccount,
  postClientEmployeeAccount,
  putEmailAccountSendMail,
  getProfile,
  putAccount
} from "helpers/request_helper"

import {CLIENT_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";
import {ACCOUNTS} from "store/account/account/accountSlice";
import {CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {GET_PROFILE} from "store/auth/profile/profileSlice";

function* viewClientEmployeesAccount() {
  try {
    const response = yield call(getClientEmployeesAccount)
    yield put(GET_CLIENT_EMPLOYEES_ACCOUNT(response['hydra:member']))
    yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error.message))
  }
}

function* viewClientEmployeeAccount({ payload: { clientEmployeeId } }) {
  try {
    const response = yield call(getClientEmployeeAccount, clientEmployeeId)
    yield put(GET_CLIENT_EMPLOYEE_ACCOUNT(response))
    yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error.message))
  }
}

function* addClientEmployeeAccount({ payload: {email, roles, password, status, firstname, lastname, clientEmployee, history, isAdmin, managerId } }) {
  try {

    const data = {
      email,
      password,
      firstname,
      lastname,
      roles,
      status,
      clientEmployee,
      isModifyPassword: false,
      managerId
    }

    const response = yield call(postClientEmployeeAccount, data)
    yield put(ADD_NEW_CLIENT_EMPLOYEE_ACCOUNT(response))

    if (isAdmin) {
      history.push({ pathname: "/gestion-collaborateurs", state: response.clientEmployee.client.id })
      yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS(`Ajout du user ${response.firstname} ${response.lastname} avec succès (un mail avec ses identifiants a été transmis)`))
    } else {
      history.push({ pathname: "/liste-collaborateurs" })
      yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS(`Ajout de l'utilisateur ${response.firstname} ${response.lastname} avec succès (un mail avec ses identifiants a été transmis)`))
    }
  } catch (error) {
    if (error.response.data.violations) {
      yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error.response.data.violations[0].message))
    } else if (error.response.data['hydra:description']) {
      yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error.response.data['hydra:description']))
    } else {
      yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error.message))
    }
  }
}

function* editClientEmployeeAccount({ payload: { job, service, pole, arrivalDate, email, firstname, lastname, clientEmployeeId, userId, clientId, cartographyId, originEmail, managerId } }) {

  try {
    yield call(putAccount, {
      email,
      firstname,
      lastname,
      userId,
    })

    yield call(putClientEmployeeAccount, {
      job,
      service,
      arrivalDate,
      pole,
      managerId,
      clientEmployeeId
    })
    yield put(CLIENT_ACCOUNT({ clientId: clientId }))
    if (cartographyId) yield put(CARTOGRAPHY({ cartographyId: cartographyId }))

    // si l'email a changé, on envoi un mail pour prévenir l'utilisateur
    if (originEmail !== email) yield call(putEmailAccountSendMail, { originEmail, email, firstname, lastname })

    yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS("Les informations de l'utilisateur ont bien été modifiées"))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error))
  }
}

function* archivedClientEmployeeAccount({ payload: { isArchived, clientEmployeeId, clientId } }) {
  try {
    yield call(putClientEmployeeAccount, { isArchived, clientEmployeeId })
    yield put(CLIENT_ACCOUNT({ clientId: clientId }))

    isArchived
      ? yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS("Le collaborateur a bien été archivé"))
      : yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS("Le collaborateur a bien été remis dans la liste"))

  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error))
  }
}

function* editClientEmployeeAccountByUser({ payload: { job, service, pole, arrivalDate, clientEmployeeId, userId } }) {

  try {
    yield call(putClientEmployeeAccount, {
      job,
      service,
      arrivalDate,
      pole,
      clientEmployeeId
    })

    const response = yield call(getProfile, userId)
    yield put(GET_PROFILE(response))

    yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS("Vos informations ont bien été modifiées"))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR(error))
  }
}

function* removeClientEmployeeAccount({ payload: { clientEmployeeId, clientId } }) {
  try {
    yield call(deleteClientEmployeeAccount, clientEmployeeId)
    yield put(CLIENT_ACCOUNT({ clientId: clientId }))
    yield put(ACCOUNTS())
    yield put(CLIENT_EMPLOYEE_ACCOUNT_SUCCESS(`Suppression du collaborateur avec succès !!`))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ACCOUNT_ERROR("Une erreur s'est produite !"))
  }
}

export function* watchProfile() {
  yield takeEvery(CLIENT_EMPLOYEES_ACCOUNT.type, viewClientEmployeesAccount)
  yield takeEvery(CLIENT_EMPLOYEE_ACCOUNT.type, viewClientEmployeeAccount)
  yield takeEvery(ADD_CLIENT_EMPLOYEE_ACCOUNT.type, addClientEmployeeAccount)
  yield takeEvery(UPDATE_CLIENT_EMPLOYEE_ACCOUNT.type, editClientEmployeeAccount)
  yield takeEvery(ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT.type, archivedClientEmployeeAccount)
  yield takeEvery(UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER.type, editClientEmployeeAccountByUser)
  yield takeEvery(DELETE_CLIENT_EMPLOYEE_ACCOUNT.type, removeClientEmployeeAccount)
}

export default function* ClientEmployeeAccountSaga() {
  yield all([fork(watchProfile)])
}
