import React, { useEffect, useState } from "react"
import {Col, Container, Row, Label} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import {Redirect, Link} from "react-router-dom"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Authentification
import {verifRoleRoute} from "helpers/role_helper";
import {verifToken} from "helpers/storage_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectAccount, ACCOUNTS, ADD_ACCOUNT, DELETE_ACCOUNT } from "store/account/account/accountSlice";
import {DELETE_CLIENT_EMPLOYEE_ACCOUNT} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import AccountColumn from "./AccountColumn"
import AccountModalRemove from "./AccountModalRemove"

const Account = ({ isRole, location, history }) => {

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()
  const account = useSelector(selectAccount);

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (userData) => {
    if (!showModal) dispatch(ADD_ACCOUNT(userData))
    setShowModal(!showModal);
  };

  const deleteUser = (id, role, clientEmployeeId, clientId) => {
    if (role[0] === 'ROLE_USER') {
      dispatch(DELETE_CLIENT_EMPLOYEE_ACCOUNT({ clientEmployeeId: clientEmployeeId,  clientId: clientId }))
    } else {
      dispatch(DELETE_ACCOUNT({ userId: id }))
    }
    setShowModal(!showModal);
  };

  const paginateOptions = {
    sizePerPage: 10,
    totalSize: account.users.length,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }

  useEffect(() => {
    dispatch(ACCOUNTS(history))
  }, [dispatch, history])

  if (verifRoleRoute(isRole) && verifToken()) {
    return (
      <React.Fragment>

        <AccountModalRemove isOpen={showModal} toggle={toggleModal} deleteUser={deleteUser}/>

        <div className="page-content my-5">
          <Container fluid>

            <TitleBanner title="Liste des comptes" />

            <Row>
              <Col sm={12}>
                <div className="mt-3 mb-4">

                  <Link to="/ajout-super-administrateur" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter un compte super administrateur
                  </Link>

                  <Link to="/ajout-organisation" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter un administrateur
                  </Link>

                  <Link to="/ajout-user" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter un utilisateur
                  </Link>

                  <Link to="/ajout-prestataire" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter un prestataire
                  </Link>

                </div>
              </Col>
            </Row>

            <SuccessErrorAlert error={account.error} success={account.successForm} />

            <Row>
              <Col lg="12">
                <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                  {({paginationProps, paginationTableProps}) => (
                    <ToolkitProvider
                      keyField="id"
                      data={account.users}
                      columns={AccountColumn(toggleModal)}
                      bootstrap4
                      search
                      exportCSV={{fileName: `liste des comptes utilisateur (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv`}}
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row>

                            <Col sm="12" md="6">
                              <Label>
                                <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                  Exporter le tableau en CSV
                                </ExportCSVButton>
                              </Label>
                            </Col>

                            <Col sm="12" md="6">
                              <Label className="float-end">{" "}
                                <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                              </Label>
                            </Col>

                          </Row>

                          <div className="table-responsive mb-4">

                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                              keyField="id"
                              headerWrapperClasses={"bg-transparent"}
                              {...toolkitProps.baseProps}
                              //onTableChange={handleTableChange}
                              {...paginationTableProps}
                            />
                            <div className="float-end">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </div>

                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }
}

export default Account
