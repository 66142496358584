import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  GET_ACCOUNTS,
  GET_ACCOUNT,
  ACCOUNTS,
  ACCOUNT,
  ADMIN_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_PASSWORD_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_SUCCESS,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_DATA_SUCCESS,
  ADD_ACCOUNT_PASSWORD_SUCCESS,
  ACCOUNT_ERROR
} from "./accountSlice"

import { getAccounts, getAccount, postAdminAccount, putAccount, putPasswordAccount, deleteAccount } from "helpers/request_helper"

function* viewAccounts() {
  try {
    const response = yield call(getAccounts)
    yield put(GET_ACCOUNTS(response['hydra:member']))
    yield put(ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(ACCOUNT_ERROR(error.message))
  }
}

function* viewAccount({ payload: { userId } }) {
  try {
    const response = yield call(getAccount, userId)
    yield put(GET_ACCOUNT(response))
    yield put(ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(ACCOUNT_ERROR(error.message))
  }
}

function* addNewAdminAccount({ payload: { email, password, firstname, lastname, status, history } }) {
  try {
    const response = yield call(postAdminAccount, {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname,
      roles: ['ROLE_ADMIN'],
      status: status,
      isModifyPassword: false
    })
    yield put(ADD_ACCOUNT(response))
    history.push("/gestion-comptes")
    yield put(ADD_ACCOUNT_SUCCESS(`Ajout de l'administrateur ${response.firstname} ${response.lastname} avec succès (un mail avec ses identifiants a été transmis)`))
  } catch (error) {
    if (error.response.data.violations) {
      yield put(ACCOUNT_ERROR(error.response.data.violations[0].message))
    } else if (error.response.data['hydra:description']) {
      yield put(ACCOUNT_ERROR(error.response.data['hydra:description']))
    } else {
      yield put(ACCOUNT_ERROR(error.message))
    }
  }
}

function* editAccount({ payload: { userId, email, firstname, lastname, status } }) {
  try {
    const response = yield call(putAccount, {
      userId: userId,
      email: email,
      firstname: firstname,
      lastname: lastname,
      status: status
    })
    yield put(GET_ACCOUNT(response))
    yield put(ADD_ACCOUNT_DATA_SUCCESS('Le compte a bien été modifié'))
  } catch (error) {
    yield put(ACCOUNT_ERROR(error))
  }
}


function* editPasswordAccount({ payload: { userId, password } }) {
  try {
    const response = yield call(putPasswordAccount, {
      userId: userId,
      password: password
    })
    yield put(GET_ACCOUNT(response))
    yield put(ADD_ACCOUNT_PASSWORD_SUCCESS('Le mot de passe a bien été modifié'))
  } catch (error) {
    yield put(ACCOUNT_ERROR(error))
  }
}

function* removeAccount({ payload: { userId } }) {
  try {
    yield call(deleteAccount, userId)
    const response = yield call(getAccounts)
    yield put(GET_ACCOUNTS(response['hydra:member']))
    yield put(ADD_ACCOUNT_SUCCESS(`Suppression de l'utilisateur avec succès !!`))
  } catch (error) {
    if (error.response.status === 500) {
      yield put(ACCOUNT_ERROR('Un compte associé à une organisation ne peut pas être supprimé !!'))
    } else {
      yield put(ACCOUNT_ERROR(error.message))
    }
  }
}


export function* watchProfile() {
  yield takeEvery(ACCOUNTS.type, viewAccounts)
  yield takeEvery(ACCOUNT.type, viewAccount)
  yield takeEvery(ADMIN_ACCOUNT.type, addNewAdminAccount)
  yield takeEvery(UPDATE_ACCOUNT.type, editAccount)
  yield takeEvery(UPDATE_PASSWORD_ACCOUNT.type, editPasswordAccount)
  yield takeEvery(DELETE_ACCOUNT.type, removeAccount)
}

export default function* AccountSaga() {
  yield all([fork(watchProfile)])
}
