import React, {useEffect, useState} from "react"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import {Container, Row, Col, CardBody, Card, Button, UncontrolledAlert} from "reactstrap"

// import images
import logo from "assets/images/logo-dark.png"
import logolight from "assets/images/logo-light.png"

// Redux
import {useDispatch, useSelector} from "react-redux"
import {selectLogin, LOCK_SCREEN_USER} from "store/auth/login/loginSlice";

const LockScreen = props => {

  const dispatch = useDispatch();

  const { error } = useSelector(selectLogin);

  const [passwordShown, setPasswordShown] = useState(false);

  const firstname = localStorage.getItem('firstname').toUpperCase();
  const lastname = localStorage.getItem('lastname').toUpperCase();
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  const handleValidSubmit = (event, values) => {
    const data = {
      email: (localStorage.getItem("username")),
      password: values.password,
      history: props.history
    }
    dispatch(LOCK_SCREEN_USER(data))
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    localStorage.removeItem("accessJWT")
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block mt-3">
        <a href="https://firmain.fr" className="text-dark mx-4" target="_blank" rel="noreferrer">
          <i className="mdi mdi-home-variant h2"></i>
        </a>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>

              <Card>
                <CardBody className="p-4">

                  <div className="text-center mt-2">
                    <h5 className="text-primary">Ecran verrouillé</h5>
                    <p className="text-muted">Saisir votre mot de passe pour revenir à l'application!</p>
                  </div>

                  <div className="p-2 mt-4">

                    <div className="user-thumb text-center d-flex flex-column align-items-center mb-4">

                      {/* Avatar circle */}
                      <div className="avatar-sm me-0">
                        <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-18`}>
                            {nameIcon}
                        </span>
                      </div>

                      <h5 className="font-size-15 mt-3">{(localStorage.getItem("username"))}</h5>
                    </div>

                    {error ? (
                      <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                        <i className="uil uil-exclamation-octagon me-2"></i> {error}
                      </UncontrolledAlert>
                    ) : null }

                    <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                    >

                      <div className="form-group mb-4 mx-4">

                        <div className="d-flex align-items-center">

                          <div style={{ width: '300px' }}>
                            <AvField
                              name="password"
                              value=""
                              type={passwordShown ? "text" : "password"}
                              required
                              placeholder="Saisir un mot de passe"
                              errorMessage="Veuillez saisir un mot de passe"
                            />
                          </div>

                          <div style={{ cursor: 'pointer' }} onClick={togglePasswordVisiblity}>
                            <i className="uil uil-eye font-size-22 mx-3 text-primary"></i>
                          </div>

                        </div>
                      </div>

                      <div className="mb-3 text-center">
                        <Button color="success" className="w-sm waves-effect waves-light" type="submit">Déverrouiller</Button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">Ce n'est pas vous ? <Link to="/login" className="fw-medium text-primary"> Page de connexion </Link></p>
                      </div>

                    </AvForm>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Firmain</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default LockScreen
