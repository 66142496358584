import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectClientEmployeeAccount} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

const ClientEmployeeRemoveModal = ({ isOpen, toggle, deleteEmployee}) => {

  const { clientEmployee } = useSelector(selectClientEmployeeAccount);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Supprimer un collaborateur ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        { clientEmployee.hasOwnProperty('user') &&
        <p>Confirmez-vous la suppression du collaborateur "{clientEmployee.user.firstname} {clientEmployee.user.lastname}" ?</p>
        }
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => deleteEmployee(clientEmployee.id)}>Confirmer la suppression</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default ClientEmployeeRemoveModal

ClientEmployeeRemoveModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
