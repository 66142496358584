import React, {useEffect, useState} from "react"
import {Col, Container, Row, Label} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import {Redirect, Link} from "react-router-dom"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectPrestataire, PRESTATAIRES, DELETE_PRESTATAIRE, ADD_NEW_PRESTATAIRE } from "store/prestataire/prestataireSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import PrestataireColumn from "./PrestataireColumn"
import PrestataireModalRemove from "./PrestataireModalRemove";

const PrestataireAll = ({ isRole, location }) => {

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()
  const { prestataires, error, successForm } = useSelector(selectPrestataire);

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (prestataireData) => {
    if (!showModal) dispatch(ADD_NEW_PRESTATAIRE(prestataireData))
    setShowModal(!showModal);
  };

  const deletePrestataire = prestataireId => {
    dispatch(DELETE_PRESTATAIRE({prestataireId: prestataireId}))
    setShowModal(!showModal);
  };

  const paginateOptions = {
    sizePerPage: 10,
    totalSize: prestataires.length,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }

  useEffect(() => {
    dispatch(PRESTATAIRES())
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <PrestataireModalRemove isOpen={showModal} toggle={toggleModal} deletePrestataire={deletePrestataire} />

        <div className="page-content my-5">
          <Container fluid>

            <TitleBanner title="Liste des prestataires" />
            <SuccessErrorAlert error={error} success={successForm} />


            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">
                  <Link to="/ajout-prestataire" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter un prestataire
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                  {({paginationProps, paginationTableProps}) => (
                    <ToolkitProvider
                      keyField="id"
                      data={prestataires}
                      columns={PrestataireColumn(toggleModal)}
                      bootstrap4
                      search
                      exportCSV={ { fileName: `liste des prestataires (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row>

                            <Col sm="12" md="6">
                              <Label>
                                <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                  Exporter le tableau en CSV
                                </ExportCSVButton>
                              </Label>
                            </Col>

                            <Col sm="12" md="6">
                              <Label className="float-end">{" "}
                                <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                              </Label>
                            </Col>

                          </Row>

                          <div className="table-responsive mb-4">

                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                              keyField="id"
                              headerWrapperClasses={"bg-transparent"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                            <div className="float-end">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </div>

                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default PrestataireAll
