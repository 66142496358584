import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"

import LoginSaga from "./auth/login/saga"
import ForgetPasswordSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"

import AccountSaga from "./account/account/saga"
import ClientAccountSaga from "./account/clientAccount/saga"
import ClientEmployeeAccountSaga from "./account/clientEmployeeAccount/saga"
import PrestataireAccountSaga from "./account/prestataireAccount/saga"

import ClientEmployeeSaga from "./clientEmployee/saga"

import InterventionSaga from "./intervention/saga"
import PrestataireSaga from "./prestataire/saga"
import FormationSaga from "./formation/saga"

import EmployeeSaga from "./skillsDevelopmentPlan/employee/saga"
import SkillSaga from "./skillsDevelopmentPlan/skill/saga"
import CartographySaga from "./skillsDevelopmentPlan/cartography/saga"
import TrainingPlanSaga from "./skillsDevelopmentPlan/trainingPlan/saga"

import GlobalDataSaga from "./globalData/saga"

import NotificationSaga from "./notification/saga"

import AdvertSaga from "./advert/saga"

import FormationInternalSaga from "./formationInternal/saga"

export default function* rootSaga() {
    yield all([
        //public
        fork(LoginSaga),
        ForgetPasswordSaga(),
        ProfileSaga(),
        AccountSaga(),
        ClientAccountSaga(),
        ClientEmployeeAccountSaga(),
        PrestataireAccountSaga(),
        ClientEmployeeSaga(),
        EmployeeSaga(),
        SkillSaga(),
        InterventionSaga(),
        PrestataireSaga(),
        FormationSaga(),
        GlobalDataSaga(),
        CartographySaga(),
        TrainingPlanSaga(),
        NotificationSaga(),
        AdvertSaga(),
        FormationInternalSaga(),
        fork(LayoutSaga)
    ])
}
