export const countries = [
  "France",
  "Albanie",
  "Algérie",
  "Allemagne",
  "États-Unis",
  "Andorre",
  "Angola",
  "Antigua-et-Barbuda",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Belgique",
  "Belize",
  "Bénin",
  "Bhoutan",
  "Biélorussie",
  "Birmanie",
  "Guinée-Bissau",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Royaume-Uni",
  "Brunéi",
  "Bulgarie",
  "Burkina",
  "Burundi",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Cap-Vert",
  "Centrafrique",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Congo-Kinshasa",
  "Congo-Brazzaville",
  "Corée du Nord",
  "Corée du Sud",
  "Îles Cook",
  "Costa Rica",
  "Croatie",
  "Cuba",
  "Danemark",
  "Djibouti",
  "République dominicaine",
  "Dominique",
  "Égypte",
  "Émirats arabes unis",
  "Guinée équatoriale",
  "Équateur",
  "Érythrée",
  "Espagne",
  "Timor-Leste",
  "Estonie",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Ghana",
  "Grenade",
  "Guatemala",
  "Guinée",
  "Guyana",
  "Haïti",
  "Grèce",
  "Honduras",
  "Hongrie",
  "Inde",
  "Indonésie",
  "Iraq",
  "Iran",
  "Irlande",
  "Islande",
  "Israël",
  "Italie",
  "Côte d'Ivoire",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Saint-Christophe-et-Niévès",
  "Koweït",
  "Laos",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Libéria",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Macédoine",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Madagascar",
  "Mali",
  "Malte",
  "Maroc",
  "Îles Marshall",
  "Maurice",
  "Mauritanie",
  "Mexique",
  "Micronésie",
  "Moldovie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Mozambique",
  "Namibie",
  "Nauru",
  "Pays-Bas",
  "Nouvelle-Zélande",
  "Népal",
  "Nicaragua",
  "Nigéria",
  "Niger",
  "Niue",
  "Norvège",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Palestine",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pérou",
  "Philippines",
  "Pologne",
  "Portugal",
  "Qatar",
  "Roumanie",
  "Russie",
  "Rwanda",
  "Sainte-Lucie",
  "Saint-Marin",
  "Saint-Vincent-et-les Grenadines",
  "Îles Salomon",
  "Salvador",
  "Samoa",
  "Sao Tomé-et-Principe",
  "Arabie saoudite",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Sri Lanka",
  "Afrique du Sud",
  "Soudan du Sud",
  "Suède",
  "Suisse",
  "Suriname",
  "Swaziland",
  "Syrie",
  "Tadjikistan",
  "Tanzanie",
  "Tchad",
  "Tchéquie",
  "Thaïlande",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turkménistan",
  "Turquie",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Viêt Nam",
  "Yémen",
  "Zambie",
  "Zimbabwe",
]
