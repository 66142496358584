import React from "react";
import { Card, Col, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";

const ClientEmployeeArchiveCard = ({employee, archivedEmployee}) => {

  const firstname = employee.user.firstname;
  const lastname = employee.user.lastname;
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <CardBody>
            <Media className="d-flex">

              {/* Avatar circle */}
              <div className="avatar-sm me-4">
                <span className={`avatar-title rounded-circle bg-soft-secondary primary text-primary font-size-16`}>
                  {nameIcon}
                </span>
              </div>


              <Media body className="flex-1 align-self-center">

                <div className="pb-1 mb-0">

                  <h5 className="text-truncate font-size-17 mb-0">
                    <Link to="#" className="text-dark">{employee.user.firstname} {employee.user.lastname}</Link>
                  </h5>

                  {
                    employee.user.email
                      ? <p className="text-muted font-size-16">{employee.user.email}</p>
                      : <p className="font-size-15 text-danger">E-mail non-renseigné</p>
                  }

                </div>
              </Media>
            </Media>

            <Media className="d-flex mt-1">
              <Media body className="flex-1 align-self-center">

                <p className="mb-1 font-size-16 text-dark">Poste : {employee.job}</p>
                <p className="text-muted mb-1 font-size-16">Pôle : {employee.pole}</p>
                <p className="text-muted font-size-16">Service : {employee.service}</p>

                <div>
                  <button
                    type="button"
                    className="btn btn-link waves-effect px-3 text-danger"
                    onClick={ () => archivedEmployee(employee) }
                    data-toggle="modal"
                    data-target="modal-delete"
                  >
                    <i className="uil uil-archive-alt font-size-18"></i> <span className="font-size-16">Enlever l'archivage</span>
                  </button>

                </div>
              </Media>
            </Media>

          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ClientEmployeeArchiveCard;

