import React, {useState} from "react"
import PropTypes from "prop-types"
import {Col, Input, Label, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";

// REDUX
import {useSelector} from "react-redux";
import {selectFormationInternal} from "store/formationInternal/formationInternalSlice";


const ClientFormationPlanSelectFormationInternalModal = ({ isOpen, toggle, selectnewFormationInternal }) => {

  const { formationInternal } = useSelector(selectFormationInternal);

  const [customDate, setCustomDate] = useState(false)
  const [customPlace, setCustomPlace] = useState('')
  const [customDateStart, setCustomDateStart] = useState('')
  const [customDateEnd, setCustomDateEnd] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      size="lg"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Choisir une formation interne ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <Row>
          <Col lg={12}>
            <p>Vous avez sélectionné la formation interne : <span className="color-primary">{formationInternal.title}</span>.</p>
          </Col>
        </Row>

        <AvForm onValidSubmit={() => selectnewFormationInternal(formationInternal.id, formationInternal.price, customDate, customPlace, customDateStart, customDateEnd)}>

          <Row form>

            <Col className="col-12 mb-4">
              <Label className="form-check-label mx-2" htmlFor="allday-input">Ajouter des dates sur mesure</Label>
              <Input type="checkbox"
                     className="form-check-input"
                     id="allday-input"
                     onChange={() => customDate ? setCustomDate(false) : setCustomDate(true)}
                     checked={customDate}
              />
            </Col>

            {customDate && (
              <>
                <Col className="col-12 mb-4">
                  <h5 className="font-size-17 mt-4 mb-3">Lieu</h5>
                  <AvField
                    name="customPlace"
                    type="text"
                    errorMessage="Invalid name"
                    validate={{ required: { value: false } }}
                    value=""
                    onChange={event => setCustomPlace(event.target.value)}
                  />
                </Col>

                <Col className="col-12 mb-4">
                  <h5 className="font-size-17 mt-4 mb-3">Date de début</h5>
                  <AvField
                    name="customDateStart"
                    type="datetime-local"
                    errorMessage="Veuillez renseigner une date de début"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    validate={{ required: { value: false } }}
                    onChange={event => setCustomDateStart(event.target.value)}
                  />
                </Col>

                <Col className="col-12 mb-4">
                  <h5 className="font-size-17 mt-4 mb-3">Date de fin</h5>
                  <AvField
                    name="customDateEnd"
                    type="datetime-local"
                    errorMessage="Veuillez renseigner une date de fin"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    validate={{ required: { value: false } }}
                    onChange={event => setCustomDateEnd(event.target.value)}
                  />
                </Col>

              </>
            )}
          </Row>


          <Row>
            <Col>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-success save-event">Choisir la formation</button>
                <button type="button" className="btn btn-primary me-2 mx-3" onClick={toggle}>Annuler</button>
              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>

    </Modal>
  )
}

export default ClientFormationPlanSelectFormationInternalModal

ClientFormationPlanSelectFormationInternalModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
