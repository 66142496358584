import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {isEmpty, map} from "lodash";

// Login Redux States

import {
  TRAINING_PLANS,
  TRAINING_PLAN,
  TRAINING_PLANS_CARTOGRAPHY,
  TRAINING_PLAN_ACTION,
  TRAINING_PLANS_ANALYTICS,
  GET_TRAINING_PLANS,
  GET_TRAINING_PLAN,
  GET_TRAINING_PLANS_CARTOGRAPHY,
  GET_TRAINING_PLAN_ACTION,
  ADD_TRAINING_PLAN,
  ADD_TRAINING_PLAN_ACTION,
  ADD_NEW_TRAINING_PLAN,
  UPDATE_TRAINING_PLAN_ACTION,
  UPDATE_TRAINING_PLAN_VALIDATE,
  DELETE_TRAINING_PLAN_ACTION,
  TRAINING_PLAN_SUCCESS,
  TRAINING_PLAN_ERROR,
  TRAINING_PLAN_ACTION_FILTER_FORMATION,
  GET_TRAINING_PLAN_ACTION_FILTER_FORMATION,
  GET_TRAINING_PLAN_ACTION_FILTER_FORMATION_INTERNAL,
  ADD_PAYMENT_FOUNDING_TYPES,
  PAYMENT_FOUNDING_TYPES,
  TRAINING_PLAN_ACTION_DEVIS_FORMATION,
  TRAINING_PLAN_ACTION_RESUME_REQUEST,
  GET_AVERAGE_FORMATIONS_FINISHED_WITH_CERTIFICATE,
  GET_TRAINING_PLANS_ANALYTICS, GET_SKILLS_WORKED,
  GET_FORMATION_ACCESS_LEVEL,
  GET_FORMATIONS_COMMITTED_IN_THE_SIX_MOUTH,
  GET_COST_FORMATIONS_FINISHED_LAST_SIX_MOUTH,
  TRAINING_PLAN_BY_CLIENT
} from "./trainingPlanSlice"

import {
  getTrainingPlans,
  getTrainingPlan,
  postTrainingPlan,
  getCartography,
  postTrainingPlanAction,
  getTrainingPlanAction,
  putTrainingPlanAction,
  deleteTrainingPlanAction,
  putTrainingPlanValidate,
  putIntervention,
  getPaymentFoundingTypes,
  getFormations,
  getFormationInternalsFilterByClient,
  postFormationDevis,
  postFormationResume,
  postFormationInternalResume,
  getClientAccount
} from "helpers/request_helper"

import {GET_CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {ADD_NOTIFICATION} from "store/notification/notificationSlice";
import { GET_CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";
import moment from "moment/moment";

function* viewTrainingPlans() {
  try {
    const response = yield call(getTrainingPlans)
    yield put(GET_TRAINING_PLANS(response['hydra:member']))
    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlan({ payload: { trainingPlanId } }) {
  try {
    const response = yield call(getTrainingPlan, trainingPlanId)
    yield put(GET_TRAINING_PLAN(response))
    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlanAction({ payload: { trainingPlanActionId } }) {
  try {
    const response = yield call(getTrainingPlanAction, trainingPlanActionId)
    yield put(GET_TRAINING_PLAN_ACTION(response))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* addTrainingPlan({payload: { createdAt, validate, cartography, cartographyId, trainingPlanStatus, title, adminRole, history }}) {

  try {
    const data = {
      title: title,
      createdAt: createdAt,
      validate: validate,
      cartography: cartography,
      trainingPlanStatus: trainingPlanStatus
    }

    const response = yield call(postTrainingPlan, data)
    yield put(ADD_NEW_TRAINING_PLAN(response))

    /*  ============ création de demande de formation dynamiquement ============= */

    const cartographyData = yield call(getCartography, cartographyId)

    let employeesWithFormation = [];

    {map(cartographyData.skills, (skill, key) => {

      // récupération des skills qui sont moyen ou mauvais
      if (skill.risk === 2 || skill.risk === 1) {
        {map(skill.employeeSkills, (employeeSkill) => {

          // récupération des employées qui ont un risque moyen ou mauvais
          if (employeeSkill.colorRisk === 'bad' || employeeSkill.colorRisk === 'medium') {
            employeesWithFormation.push({skillDomainId: skill.skillDomain.id, skillActivityId: skill.skillActivity.id, skillTask: skill.task, employeeSkill: employeeSkill})
          }
        })}
      }
    })}

    for (let i = 0; i < employeesWithFormation.length; i++) {

      const data = {
        employee: `/api/employees/${employeesWithFormation[i].employeeSkill.employee.id}`,
        employeeSkill: `/api/employee_skills/${employeesWithFormation[i].employeeSkill.id}`,
        trainingPlan: `/api/training_plans/${response.id}`,
        certificateReceived: false,
        finished: false,
        isValidate: false,
        devisRequestSent: false,
        skillDomain: `/api/skill_domains/${employeesWithFormation[i].skillDomainId}`,
        skillActivity: `/api/skill_activities/${employeesWithFormation[i].skillActivityId}`,
        isCustomTrainingDate: false
      }

      yield call(postTrainingPlanAction, data)
    }

    /*  ========================== */

    yield put(TRAINING_PLAN_SUCCESS(""))

    if (adminRole) {
      history.push({ pathname: `/plan-developpement-des-competences`, state: response.cartography.id})
    } else {
      history.push({ pathname: `/mon-plan-developpement-competences`, state: response.cartography.id})
    }
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlansCartography({ payload: { cartographyId } }) {
  try {

    const response = yield call(getCartography, cartographyId)
    yield put(GET_CARTOGRAPHY(response))
    yield put(GET_TRAINING_PLANS_CARTOGRAPHY(response.trainingPlans))

    const pdc = yield call(getTrainingPlan, response.trainingPlans[0].id)
    yield put(GET_TRAINING_PLAN(pdc))

    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* addTrainingPlanAction({payload: {
  employee, trainingPlan, trainingPlanId, cartographyId, certificateReceived, finished, isValidate, devisRequestSent, isCustomTrainingDate
}}) {

  try {
    const data = {
      employee: employee,
      trainingPlan: trainingPlan,
      certificateReceived: certificateReceived,
      finished: finished,
      isValidate: isValidate,
      devisRequestSent: devisRequestSent,
      isCustomTrainingDate: isCustomTrainingDate,
    }

    yield call(postTrainingPlanAction, data)
    yield put(TRAINING_PLANS_CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(TRAINING_PLAN({trainingPlanId}))
    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* editTrainingPlanAction({ payload: {
  trainingDateDesired, skillDomain, trainingPlace, trainingStart, trainingEnd, trainingCostTotal, trainingCostHour,
  paymentFundingType, paymentFinanced, finished, certificateReceived, certificateExpiration, formation, trainingPlanActionId,
  isCustomTrainingDate, devisRequestSent, trainingPlanId, taskCustom, formationInternal
} }) {
  try {
    yield call(putTrainingPlanAction, {
      trainingDateDesired: trainingDateDesired,
      skillDomain: skillDomain,
      trainingPlace: trainingPlace,
      trainingStart: trainingStart,
      trainingEnd: trainingEnd,
      trainingCostTotal: trainingCostTotal,
      trainingCostHour: trainingCostHour,
      paymentFundingType: paymentFundingType,
      paymentFinanced: paymentFinanced,
      finished: finished,
      certificateReceived: certificateReceived,
      certificateExpiration: certificateExpiration,
      formation: formation,
      trainingPlanActionId: trainingPlanActionId,
      isCustomTrainingDate: isCustomTrainingDate,
      devisRequestSent: devisRequestSent,
      taskCustom: taskCustom,
      formationInternal: formationInternal
    })
    yield put(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanActionId}))
    if (trainingPlanId) yield put(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    yield put(TRAINING_PLAN_SUCCESS("Les informations ont bien été modifiées"))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error))
  }
}

function* removeTrainingPlanAction({ payload: { actionId, cartographyId, trainingPlanId } }) {
  try {
    yield call(deleteTrainingPlanAction, actionId)
    yield put(TRAINING_PLANS_CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(TRAINING_PLAN({trainingPlanId}))
    yield put(TRAINING_PLAN_SUCCESS(`Suppression de la demande avec succès !!`))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* editTrainingPlanValidate({ payload: { validate, isValidate, trainingPlanId, interventionId, adminRole, history, clientAccountId, cartographyId, clientId } }) {
  try {
    yield call(putTrainingPlanValidate, {
      validate: validate,
      trainingPlanId: trainingPlanId
    })

    // demande de formation en validé
    const trainingPlanData = yield call(getTrainingPlan, trainingPlanId)

    for (let i = 0; i < trainingPlanData.trainingPlanActions.length; i++) {

      yield call(putTrainingPlanAction, {
        isValidate: isValidate,
        trainingPlanActionId: trainingPlanData.trainingPlanActions[i].id
      })
    }

    yield call(putIntervention, {
      id: interventionId,
      interventionStatus: validate ? "/api/intervention_statuses/5" : "/api/intervention_statuses/4"
    })

    /*
    yield put(ADD_NOTIFICATION({
      userId: clientAccountId,
      title: "Validation d'un plan de formation",
      message: `Le diagnostiqueur a validé votre plan de formation. Une validation de votre part est demandé !`,
      link: "/mon-plan-developpement-competences",
      linkState: cartographyId,
      allAdmin: false,
      notificationRead: false
    }))
    */

    const response = yield call(getTrainingPlan, trainingPlanId)
    yield put(GET_TRAINING_PLAN(response))

    yield put(TRAINING_PLAN_SUCCESS(''))

    if (adminRole) {
      history.push({ pathname: "/details-organisation", state: clientId })
    }

  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewPaymentFoundingTypes() {
  try {
    const response = yield call(getPaymentFoundingTypes)
    yield put(ADD_PAYMENT_FOUNDING_TYPES(response['hydra:member']))
    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlanActionFilterFormation({ payload: { trainingPlanActionId, clientId } }) {
  try {
    const response = yield call(getTrainingPlanAction, trainingPlanActionId)
    const responseAllFormations = yield call(getFormations)
    const responseAllFormationInternals = yield call(getFormationInternalsFilterByClient, clientId)

    yield put(GET_TRAINING_PLAN_ACTION(response))

    // filtrage des formations avec un domaine de compétence spécifique
    let formationsFilter = []

    {map(responseAllFormations['hydra:member'], (formation, key) => {
      if (formation.skillDomain.id === response.skillDomain.id && response.trainingPlan.cartography.intervention.client.locality.region.id === formation.prestataire.locality.region.id) {
        formationsFilter.push(formation)
      }
    })}

    yield put(GET_TRAINING_PLAN_ACTION_FILTER_FORMATION(formationsFilter))

    // filtrage des formations avec un domaine de compétence spécifique
    let formationInternalsFilter = []

    {map(responseAllFormationInternals, (formationInternal, key) => {
      if (formationInternal.skillDomain.id === response.skillDomain.id) {
        formationInternalsFilter.push(formationInternal)
      }
    })}

    yield put(GET_TRAINING_PLAN_ACTION_FILTER_FORMATION_INTERNAL(formationInternalsFilter))



    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* addTrainingPlanActionDevisFormation({payload: {
  trainingPlanAction, trainingPlanActionId, cartographyId,
  clientName, prestataireAccountId, formationTitle, prestataireEmail
}}) {

  try {
    const data = {
      trainingPlanAction: trainingPlanAction
    }
    yield call(postFormationDevis, data)

    yield call(putTrainingPlanAction, {
      devisRequestSent: true,
      trainingPlanActionId: trainingPlanActionId
    })

    yield put(ADD_NOTIFICATION({
      userId: prestataireAccountId,
      title: "Demande de devis de formation",
      message: `Vous avez reçu par email (${prestataireEmail}) une demande de devis de formation de l'organisation ${clientName} pour la formation ${formationTitle}`,
      link: "",
      allAdmin: false,
      notificationRead: false
    }))

    yield put(TRAINING_PLANS_CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* addTrainingPlanActionResumeRequest({payload: {trainingPlanActionId, clientEmployeeUser, internal}}) {

  const trainingPlanActionData = yield call(getTrainingPlanAction, trainingPlanActionId)

  try {
    const data = {
      formationDescription: trainingPlanActionData,
      clientEmployeeUser: clientEmployeeUser
    }

    internal
      ? yield call(postFormationInternalResume, data)
      : yield call(postFormationResume, data)

    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlansAnalytics({ payload: { clientId } }) {
  try {

    // ======= récupération de tous les plans de formations d'un client ================

    const client = yield call(getClientAccount, clientId)

    const calls = yield [];

    yield client.interventions.forEach(intervention => {
      if (intervention.hasOwnProperty('cartography') && !isEmpty(intervention.cartography.trainingPlans)) {
        const trainingPlanId = intervention.cartography.trainingPlans[0].id
        calls.push(call(getTrainingPlan, trainingPlanId))
      }
    });

    const trainingPlans = yield all(calls);

    let formationsFinished = 0
    let formationsFinishedWithCertificate = 0
    let durationTotalFormations = 0
    let formationsPlanned = 0

    map(trainingPlans, trainingPlan => {
      const formationsFinishedTemp = trainingPlan.trainingPlanActions.filter( item => item.hasOwnProperty('formation') && item.finished)
      const formationsFinishedWithCertificateTemp = formationsFinishedTemp.filter(item => item.formation.cpfEligible)

      formationsFinished += formationsFinishedTemp.length
      formationsFinishedWithCertificate += formationsFinishedWithCertificateTemp.length
    })

    yield put(GET_AVERAGE_FORMATIONS_FINISHED_WITH_CERTIFICATE(Math.ceil((formationsFinishedWithCertificate * 100) / formationsFinished)))

    // ======== volume, durée moyenne des formations et nombre de formations prévues et réalisées ================

    map(trainingPlans, trainingPlan => {
      const formationsFinishedTemp = trainingPlan.trainingPlanActions.filter( item => item.finished === true)
      durationTotalFormations += formationsFinishedTemp.reduce((acc, next) => acc + (next.formation.durationUnit === 'jours' ? Number(next.formation.duration) * 7 : Number(next.formation.duration)), 0)

      formationsPlanned += trainingPlan.trainingPlanActions.filter( item => item.formation && moment(item.trainingStart).isAfter()).length
    })

    let averageDurationFormations = durationTotalFormations / formationsFinished

    yield put(GET_TRAINING_PLANS_ANALYTICS({
      totalFormationsFinished: formationsFinished,
      averageDuration: averageDurationFormations.toFixed(1),
      totalDurationFormationFinished: durationTotalFormations,
      formationsFinished: formationsFinished,
      formationsPlannedAndFinished: [formationsPlanned, formationsFinished]
    }))

    // ======== compétences travaillées ================

    let skillsWorked = { series: [], labels: [] }
    let skillsWorkedData = { series: [], labels: [] }

    map(trainingPlans, trainingPlan => {
      const formationsFinishedTemp = trainingPlan.trainingPlanActions.filter( item => item.finished === true)

      // on récupère tous les domaines de compétences présents
      formationsFinishedTemp.map( skill => skillsWorked.labels.push(skill.skillDomain.name))
    })

    // on enlève les domaines de compétences en doublons
    let skillsWorkedWithLabelSorted = { series: [], labels: [...new Set(skillsWorked.labels)] }

    // on compte le nombre de formations réalisées par domaines de compétences travaillés
    skillsWorkedWithLabelSorted.labels.map( skillDomain => {

      let formationsFinishedFilterByDomain = 0

      map(trainingPlans, trainingPlan => {
        const formationsFinishedTemp = trainingPlan.trainingPlanActions.filter( item => item.finished === true)

        formationsFinishedFilterByDomain += formationsFinishedTemp.filter( formation => formation.skillDomain.name === skillDomain).length
      })

      skillsWorkedWithLabelSorted.series.push(formationsFinishedFilterByDomain)
    })

    // on garde que les 4 domaines de compétences les plus travaillées
    const skillsWorkedTemp = []

    skillsWorkedWithLabelSorted.labels.map( (skill, idx) => {
      skillsWorkedTemp.push({ skill: skill, nbr: skillsWorkedWithLabelSorted.series[idx] })
    })

    const skillsWorkedTempSort =  skillsWorkedTemp.sort((a, b) => b.nbr - a.nbr)

    skillsWorkedTempSort.map( (data, idx) => {
      if (idx < 4) {
        skillsWorkedData.series.push(data.nbr)
        skillsWorkedData.labels.push(data.skill)
      }
    })

    yield put(GET_SKILLS_WORKED(skillsWorkedData))

    // ======== niveau d'accès à la formation ================

    let formationsFinishedLessThanOneYear = []

    // formations réalisées et finies depuis moins de 1 an

    map(trainingPlans, trainingPlan => {
      const formationsFinishedTemp = trainingPlan.trainingPlanActions.filter( item => item.finished === true)

      formationsFinishedTemp.forEach( formation => {
        if (moment(formation.trainingEnd).isBetween(moment().subtract(1, 'years'), moment())) {
          formationsFinishedLessThanOneYear.push(formation)
        }
      })
    })

    // nombre de personnes ayant réalisé et fini une formation depuis moins de 1 an

      let totalEmployeeWithFormationsFinishedLessThanOneYear = [...new Set(formationsFinishedLessThanOneYear.map( formation => formation.employee.id))].length

    yield put(GET_FORMATION_ACCESS_LEVEL({
      totalEmployeeWithFormationsFinishedLessThanOneYear: totalEmployeeWithFormationsFinishedLessThanOneYear,
      totalEmployeeWithFormationsFinishedNotLessThanOneYear: client.clientEmployees.length - totalEmployeeWithFormationsFinishedLessThanOneYear,
      totalEmployees: client.clientEmployees.length
    }))

    // ======== diagrammes à barre du haut - nombre de formations engagées ================

    // Récupérer les formations engagées dans les 6 prochains mois

    let formationsCommittedInTheSixMouth = []

    map(trainingPlans, trainingPlan => {

      trainingPlan.trainingPlanActions.forEach( item => {
        if(item.formation && moment(item.trainingStart).isBetween(moment(), moment().add(6, 'months'))) {
          formationsCommittedInTheSixMouth.push(item)
        }
      })
    })

    let monthsLastSixMouth = []
    let nbrFormationsCommittedByMouth = []

    // // créer le tableau avec les 6 derniers mois

    for (let i = 0; i < 6; i++) {
      monthsLastSixMouth.push(moment().add(i, 'months').format("MMM"))
    }

    // trier le tableau formationsCommittedInTheSixMouth et compter le nombre de formation par mois

    monthsLastSixMouth.map( month => {
      const formations = formationsCommittedInTheSixMouth.filter( formation => moment(formation.trainingStart).format('MMM') === month )
      nbrFormationsCommittedByMouth.push(formations.length)
    })

    yield put(GET_FORMATIONS_COMMITTED_IN_THE_SIX_MOUTH( {data: nbrFormationsCommittedByMouth, categories: monthsLastSixMouth}))

    // ======== diagrammes à barre - cout des formations ================

    let monthsAfterSixMouth = []
    let costFormationsFinishedByMouth = []

    // récupéré les formations finies sur les 6 derniers mois

    let formationsFinishedLastSixMouth = []

    map(trainingPlans, trainingPlan => {

      trainingPlan.trainingPlanActions.forEach( item => {
        if(item.formation && moment(item.trainingEnd).isBetween(moment().subtract(6, 'months'), moment() )) {
          formationsFinishedLastSixMouth.push(item)
        }
      })
    })

    // créer le tableau avec les 6 prochains mois

    for (let i = 5; i >= 0; i--) {
      monthsAfterSixMouth.push(moment().subtract(i, 'months').format("MMM"))
    }

    // trier le tableau formationsFinishedLastSixMouth et compter le nombre de formation par mois

    monthsAfterSixMouth.map( month => {
      const formations = formationsFinishedLastSixMouth.filter( formation => moment(formation.trainingEnd).format('MMM') === month )
      const totalCostByMonth = formations.reduce( (acc, next) => acc + (next.formation.priceHT + next.formation.priceHT * 0.1), 0)
      costFormationsFinishedByMouth.push(totalCostByMonth)
    })

    yield put(GET_COST_FORMATIONS_FINISHED_LAST_SIX_MOUTH({data: costFormationsFinishedByMouth, categories:  monthsAfterSixMouth}))

    // =======================================================

    yield put(GET_TRAINING_PLANS(trainingPlans))

    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

function* viewTrainingPlanByClient({ payload: { clientId } }) {
  try {
    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    const interventionsSort = client.interventions.filter( intervention => {
      if (intervention.hasOwnProperty('cartography') && intervention.cartography.trainingPlans[0] && intervention.cartography.validate) {
        return intervention
      }
    })

    const cartography = yield call(getCartography, interventionsSort[interventionsSort.length - 1].cartography.id)
    yield put(GET_CARTOGRAPHY(cartography))

    const clientResponse = yield call(getClientAccount, cartography.intervention.client.id)
    yield put(GET_CLIENT_ACCOUNT(clientResponse))

    yield put(TRAINING_PLAN_SUCCESS(""))
  } catch (error) {
    yield put(TRAINING_PLAN_ERROR(error.message))
  }
}

export function* watchProfile() {
  yield takeEvery(TRAINING_PLANS.type, viewTrainingPlans)
  yield takeEvery(TRAINING_PLAN.type, viewTrainingPlan)
  yield takeEvery(ADD_TRAINING_PLAN.type, addTrainingPlan)
  yield takeEvery(TRAINING_PLANS_CARTOGRAPHY.type, viewTrainingPlansCartography)
  yield takeEvery(ADD_TRAINING_PLAN_ACTION.type, addTrainingPlanAction)
  yield takeEvery(TRAINING_PLAN_ACTION.type, viewTrainingPlanAction)
  yield takeEvery(DELETE_TRAINING_PLAN_ACTION.type, removeTrainingPlanAction)
  yield takeEvery(UPDATE_TRAINING_PLAN_ACTION.type, editTrainingPlanAction)
  yield takeEvery(UPDATE_TRAINING_PLAN_VALIDATE.type, editTrainingPlanValidate)
  yield takeEvery(PAYMENT_FOUNDING_TYPES.type, viewPaymentFoundingTypes)
  yield takeEvery(TRAINING_PLAN_ACTION_FILTER_FORMATION.type, viewTrainingPlanActionFilterFormation)
  yield takeEvery(TRAINING_PLAN_ACTION_DEVIS_FORMATION.type, addTrainingPlanActionDevisFormation)
  yield takeEvery(TRAINING_PLAN_ACTION_RESUME_REQUEST.type, addTrainingPlanActionResumeRequest)
  yield takeEvery(TRAINING_PLANS_ANALYTICS.type, viewTrainingPlansAnalytics)
  yield takeEvery(TRAINING_PLAN_BY_CLIENT.type, viewTrainingPlanByClient)
}

export default function* TrainingPlanSaga() {
  yield all([fork(watchProfile)])
}
