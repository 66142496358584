import React, {useEffect, useState} from "react"
import {Redirect} from "react-router-dom"
import {Col, Container, Row, Card, CardBody} from "reactstrap"
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectTrainingPlan, UPDATE_TRAINING_PLAN_ACTION, TRAINING_PLAN_ACTION_FILTER_FORMATION} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {FORMATION, selectFormation} from "store/formation/formationSlice";
import {FORMATION_INTERNAL} from "store/formationInternal/formationInternalSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import ClientFormationPlanSelectFormationModal from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanSelectFormationModal";
import ClientFormationPlanSelectFormationCard from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanSelectFormationCard";
import ClientFormationPlanSelectFormationInternalCard from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanSelectFormationInternalCard";
import ClientFormationPlanSelectFormationInternalModal from "../SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanSelectFormationInternalModal";


const ClientFormationSelectFormation = ({isRole, location}) => {

  const trainingPlanActionId = location.state

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()
  const {trainingPlanAction, trainingPlanActionFilterFormation, trainingPlanActionFilterFormationInternal, error, success} = useSelector(selectTrainingPlan);
  const {formation} = useSelector(selectFormation);

  /* ========== Choisir une formation =========== */

  const [showModalSelectFormation, setShowModalSelectFormation] = useState(false);
  const [showModalSelectFormationInternal, setShowModalSelectFormationInternal] = useState(false);

  const toggleModalSelectFormation = (formationId) => {
    if (!showModalSelectFormation) dispatch(FORMATION({formationId: formationId}))
    setShowModalSelectFormation(!showModalSelectFormation);
  };

  const toggleModalSelectFormationInternal = (formationInternalId) => {
    if (!showModalSelectFormationInternal) dispatch(FORMATION_INTERNAL({formationInternalId: formationInternalId}))
    setShowModalSelectFormationInternal(!showModalSelectFormationInternal);
  };

  const selectnewFormationInternal = (formationInternalId, price, customDate, customPlace, customDateStart, customDateEnd) => {

    const data = {
      trainingPlace: customPlace ? customPlace : null,
      trainingStart: customDateStart ? customDateStart : null,
      trainingEnd: customDateEnd ? customDateEnd : null,
      isCustomTrainingDate: customDate ? true : false,
      formation: null,
      formationInternal: `/api/formation_internals/${formationInternalId}`,
      trainingCostTotal: price,
      trainingPlanActionId: trainingPlanAction.id,
      devisRequestSent: false,
    }

    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))

    setShowModalSelectFormationInternal(!showModalSelectFormationInternal);
  }

  const selectnewFormation = (formationId, customDate, sessionDateId, customPlace, customDateStart, customDateEnd) => {

    let data

    if (customDate) {
      data = {
        trainingPlace: customPlace ? customPlace : null,
        trainingStart: customDateStart ? customDateStart : null,
        trainingEnd: customDateEnd ? customDateEnd : null,
        formation: `/api/formations/${formationId}`,
        trainingCostTotal: formation.priceHT,
        trainingPlanActionId: trainingPlanAction.id,
        isCustomTrainingDate: true,
        devisRequestSent: false
      }
    } else {

      const session = sessionDateId !== 0 ? formation.formationDates.filter(date => date.id.toString() === sessionDateId.toString()) : null

      data = {
        trainingPlace: session ? session[0].place : null,
        trainingStart: session ? session[0].formationStart : null,
        trainingEnd: session ? session[0].formationEnd : null,
        formation: `/api/formations/${formationId}`,
        trainingCostTotal: Number(formation.priceHT + formation.priceHT * 0.1),
        trainingPlanActionId: trainingPlanAction.id,
        isCustomTrainingDate: false,
        devisRequestSent: false
      }
    }

    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))

    setShowModalSelectFormation(!showModalSelectFormation);
  }


  /* ============================================ */

  useEffect(() => {
    dispatch(TRAINING_PLAN_ACTION_FILTER_FORMATION({trainingPlanActionId: trainingPlanActionId, clientId: clientId}))
  }, [dispatch, trainingPlanActionId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientFormationPlanSelectFormationModal isOpen={showModalSelectFormation} toggle={toggleModalSelectFormation} selectnewFormation={selectnewFormation} />
        <ClientFormationPlanSelectFormationInternalModal isOpen={showModalSelectFormationInternal} toggle={toggleModalSelectFormationInternal} selectnewFormationInternal={selectnewFormationInternal} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link={{pathname: `/mon-plan-developpement-competences`}} title="Retour au plan de développement des compétences" />
            <TitleBanner title="Choisir une formation" />
            <SuccessErrorAlert error={error} success={success} />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">

                    <h5 className="font-size-18 color-secondary mb-4 mt-3">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Proposition de formation interne avec le domaine "{trainingPlanAction.hasOwnProperty('skillDomain') && trainingPlanAction.skillDomain.name}"
                    </h5>

                    <Row>

                      {map(trainingPlanActionFilterFormationInternal, (formationInternal, index) => (
                        <Col lg={4} key={`_formation_${formationInternal.id}`}>
                          <ClientFormationPlanSelectFormationInternalCard formationInternal={formationInternal} toggleModalSelectFormationInternal={toggleModalSelectFormationInternal} trainingPlanActionId={trainingPlanActionId} />
                        </Col>
                      ))}

                      {trainingPlanActionFilterFormationInternal.length === 0 && <p className="mt-2 mb-5">Aucune Formation interne trouvée</p>}

                    </Row>

                    <h5 className="font-size-18 color-secondary mb-4 mt-3">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Proposition de formation externe avec le domaine "{trainingPlanAction.hasOwnProperty('skillDomain') && trainingPlanAction.skillDomain.name}"
                    </h5>

                    <Row>

                      {map(trainingPlanActionFilterFormation, (formation, index) => (
                        (formation.activeFormation) && (
                          <Col lg={4} key={`_formation_${formation.id}`}>
                            <ClientFormationPlanSelectFormationCard formation={formation} toggleModalSelectFormation={toggleModalSelectFormation} trainingPlanActionId={trainingPlanActionId} />
                          </Col>
                        )
                      ))}

                      {trainingPlanActionFilterFormation.length === 0 && <p className="mt-2 mb-5">Aucune Formation trouvée</p>}

                    </Row>

                  </CardBody>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default ClientFormationSelectFormation
