import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";

import {withRouter} from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectLayout } from "store/layout/layoutSlice";
import { CHANGE_SIDEBAR_TYPE, CHANGE_SIDEBAR_THEME, CHANGE_LAYOUT_WIDTH, CHANGE_TOPBAR_THEME } from "store/layout/layoutSlice";

const Layout = props => {

    const isMobile = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))

    const dispatch = useDispatch();
    const layout = useSelector(selectLayout);

    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    useEffect(() => {
        if (layout.isPreloader === true) {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";

            setTimeout(function () {
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }, 2500);
        } else {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);

        let currentage = capitalizeFirstLetter(props.location.pathname);
        document.title = currentage + " | Firmain";

        if (layout.leftSideBarTheme) {
            dispatch(CHANGE_SIDEBAR_THEME(layout.leftSideBarTheme))
        }

        if (layout.layoutWidth) {
            dispatch(CHANGE_LAYOUT_WIDTH(layout.layoutWidth))
        }

        if (layout.leftSideBarType) {
            dispatch(CHANGE_SIDEBAR_TYPE(layout.leftSideBarType))
        }
        if (layout.topbarTheme) {
            dispatch(CHANGE_TOPBAR_THEME(layout.topbarTheme))
        }
    }, [dispatch, layout.isPreloader, layout.layoutWidth, layout.leftSideBarTheme, layout.leftSideBarType, layout.topbarTheme, props.location.pathname])

    const toggleMenuCallback = () => {
        if (layout.leftSideBarType === "default") {
            dispatch(CHANGE_SIDEBAR_TYPE("condensed", layout.isMobile))
        } else if (layout.leftSideBarType === "condensed") {
            dispatch(CHANGE_SIDEBAR_TYPE("default", layout.isMobile))
        }
    };

    return (
        <React.Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="uil-shutter-alt spin-icon"></i>
                    </div>
                </div>
            </div>
            <div id="layout-wrapper">
                <Header toggleMenuCallback={toggleMenuCallback}/>
                <Sidebar
                    theme={layout.leftSideBarTheme}
                    type={layout.leftSideBarType}
                    isMobile={isMobile}
                />
                <div className="main-content">{props.children}</div>
                <Footer/>
            </div>
            {layout.showRightSidebar ? <Rightbar/> : null}
        </React.Fragment>
    );
}

export default withRouter(Layout);

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any
};
