import React from "react"
import {Row, Col, Card, CardBody, Media} from "reactstrap"

const TitleBanner = ({ title }) => {
  return (
    <Row>
      <Col lg="12">

        <Card className="mt-3 mb-5">
          <CardBody className="bg-gradient-primary">
            <Media className="d-flex">

              <Media body className="flex-1 align-self-center">
                <div className="text-muted">
                  <h5 className="mb-3 text-white">{title}</h5>
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TitleBanner
