import React from "react"
import {Col, Container, Row} from "reactstrap"
import {Redirect} from "react-router-dom"


// Authentification
import {verifRoleRoute} from "helpers/role_helper";

const UserSeFormerAutrement = ({isRole, location}) => {

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-5">
              <Col md="12">
                <p>Cette fonctionnalité est en cours de développement. Merci pour votre patience.</p>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default UserSeFormerAutrement
