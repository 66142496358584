import React, {useEffect, useState} from "react"
import {Redirect} from "react-router-dom"
import {Col, Container, Row, Card, CardBody} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectCartography, CARTOGRAPHY } from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import { selectTrainingPlan, ADD_TRAINING_PLAN } from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const FormationPlanNew = ({ isRole, location, history }) => {

  // récupération de l'id passé dans la route
  const routeId = location.state

  const dispatch = useDispatch()
  const { cartography } = useSelector(selectCartography);
  const { success } = useSelector(selectTrainingPlan);

  const [createdAt, setCreatedAt] = useState("")
  const [title, setTitle] = useState("")


  function handleValidFormationPlanSubmit() {

    const data = {
      title: title,
      createdAt: createdAt,
      validate: false,
      cartography:`/api/cartographies/${routeId}`,
      cartographyId: routeId,
      trainingPlanStatus: "/api/training_plan_statuses/1",
      adminRole: true,
      history: history
    }
    dispatch(ADD_TRAINING_PLAN(data))
  }

  useEffect(() => {
    dispatch(CARTOGRAPHY({ cartographyId: routeId }))
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>


        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn
              link={{ pathname: "/details-organisation", state: cartography.hasOwnProperty('intervention') && cartography.intervention.client.id ? cartography.intervention.client.id : 0 }}
              title={`Retour aux informations de l'organisation : ${cartography.hasOwnProperty('intervention') && cartography.intervention.client.entName}`}
            />
            <TitleBanner title="Plan de développement des compétences" />
            <SuccessErrorAlert success={success} />


            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <h5 className="font-size-18 color-secondary mt-3 mb-5">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Création d'un plan de développement des compétences pour l'organisation : "{cartography.hasOwnProperty('intervention') && cartography.intervention.client.entName}"
                    </h5>

                    <AvForm onValidSubmit={(e, v) => handleValidFormationPlanSubmit(e, v)}>

                      <Row form>

                        <Col className="col-4 mb-3">
                          <div className="form-group mb-4">
                            <AvField
                              name="title"
                              label="Titre du plan de développement des compétences"
                              value=""
                              className="form-control"
                              placeholder=""
                              type="text"
                              onChange={event => setTitle(event.target.value)}
                            />
                          </div>
                        </Col>

                        <Col className="col-4 mb-3">
                          <AvField
                            name="createdAt"
                            label="Date de création"
                            type="Date"
                            errorMessage="Veuillez renseigner une date"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            validate={{ required: { value: true } }}
                            onChange={event => setCreatedAt(event.target.value)}
                          />
                        </Col>

                      </Row>

                      <button type="submit" className="btn btn-success save-event mt-3">Créer un plan de développement des compétences</button>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }
}

export default FormationPlanNew
