import React from 'react'
import {Card, CardBody, CardImg, CardText, Col, Row} from "reactstrap";

const AdvertCard = ({ img, message, link, isTransparent }) => {
  return (
    <Card style={ isTransparent ? { backgroundColor: 'transparent', boxShadow: 'none' } : {}}>
      <Row className="g-0 align-items-center">
        <Col md={2} className="text-center">
          <CardImg
            className="img-fluid"
            src={img}
            alt="illustration de la publicité"
            style={{ width: '120px'}}
          />
        </Col>
        <Col md={10}>
          <CardBody>

            <CardText>{message}</CardText>

            <CardText>
              <small className="text-muted">
                <a href={link} target="_blank" rel="noreferrer">Voir plus ...</a>
              </small>
            </CardText>

          </CardBody>
        </Col>
      </Row>
    </Card>
  )
}

export default AdvertCard
