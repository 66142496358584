import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  EMPLOYEE,
  GET_EMPLOYEE,
  ADD_EMPLOYEE_FROM_TEAM,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_ERROR
} from "./employeeSlice"

import { getEmployee, postEmployee, putEmployee, deleteEmployee, postEmployeeSkill, deleteEmployeeSkill } from "helpers/request_helper"
import {CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

function* viewEmployee({ payload: { employeeId } }) {
  try {
    const response = yield call(getEmployee, employeeId)
    yield put(GET_EMPLOYEE(response))
    yield put(EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(EMPLOYEE_ERROR(error.message))
  }
}

function* addEmployeeFromTeam({payload: { clientEmployeesId, cartography, cartographyId, skillsId }}) {

  try {
    for (let i = 0; i < clientEmployeesId.length; i++) {

      const employeeData = {
        clientEmployee: `/api/client_employees/${clientEmployeesId[i].value}`,
        cartography: cartography
      }

      const response = yield call(postEmployee, employeeData)

      for (let i = 0; i < skillsId.length; i++) {
        yield call(postEmployeeSkill, {
          employee: `/api/employees/${response.id}`,
          skill: `/api/skills/${skillsId[i]}`,
          message: '',
          notation: 0,
          colorRisk: 'default',
          isUpgrade: false
        })
      }
    }

    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(EMPLOYEE_ERROR(error.message))
  }
}

function* editEmployee({ payload: { firstname, lastname, job, service, arrivalDate, employeeId, cartographyId } }) {
  try {
    yield call(putEmployee, {
      firstname: firstname,
      lastname: lastname,
      job: job,
      service: service,
      arrivalDate: arrivalDate,
      employeeId: employeeId
    })
    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(EMPLOYEE_SUCCESS("Les informations du salarié ont bien été modifiées"))
  } catch (error) {
    yield put(EMPLOYEE_ERROR(error))
  }
}

function* removeEmployee({ payload: { employeeId, cartographyId } }) {
  try {
    const response = yield call(getEmployee, employeeId)

    for (let i = 0; i < response.employeeSkills.length; i++) {
      yield call(deleteEmployeeSkill, response.employeeSkills[i].id)
    }

    yield call(deleteEmployee, employeeId)
    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(EMPLOYEE_SUCCESS(`Suppression du salarié avec succès !!`))
  } catch (error) {
    yield put(EMPLOYEE_ERROR(error.message))
  }
}


export function* watchProfile() {
  yield takeEvery(EMPLOYEE.type, viewEmployee)
  yield takeEvery(ADD_EMPLOYEE_FROM_TEAM.type, addEmployeeFromTeam)
  yield takeEvery(UPDATE_EMPLOYEE.type, editEmployee)
  yield takeEvery(DELETE_EMPLOYEE.type, removeEmployee)
}

export default function* EmployeeSaga() {
  yield all([fork(watchProfile)])
}
