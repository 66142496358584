import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const Contact = () => {

    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <h4 className="text-uppercase mt-4">Page contact</h4>
                                <div className="mt-5">
                                    <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Retour au tableau de bord</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Contact
