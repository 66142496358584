import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectFormation, ADD_FORMATION, FORMATION_ERROR } from "store/formation/formationSlice";
import {selectSkill, SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const PrestataireFormationAdd = ({history, isRole, location}) => {

  const prestataireId = Number(localStorage.getItem('prestataireId'))

  const dispatch = useDispatch()
  const { error } = useSelector(selectFormation);
  const { skillDomains } = useSelector(selectSkill);

  const [title, setTitle] = useState("")
  const [skillDomainId, setSkillDomainId] = useState("")
  const [targetAudience, setTargetAudience] = useState("")
  const [educationalMethods, setEducationalMethods] = useState("")
  const [educationalTechnics, setEducationalTechnics] = useState("")
  const [duration, setDuration] = useState(0)
  const [durationUnit, setDurationUnit] = useState("heures")
  const [modalities, setModalities] = useState("")
  const [sanction, setSanction] = useState("")
  const [evaluation, setEvaluation] = useState("")
  const [necessaryMaterial, setNecessaryMaterial] = useState("")
  const [accessDelay, setAccessDelay] = useState("")
  const [disabledAccessibility, setDisabledAccessibility] = useState(true)
  const [satisfactionRate, setSatisfactionRate] = useState(0)
  const [successRate, setSuccessRate] = useState(0)
  const [market, setMarket] = useState("")
  const [equivalences, setEquivalences] = useState("")
  const [priceHT, setPriceHT] = useState(0)
  const [cpfEligible, setCpfEligible] = useState(false)
  const [cpfCode, setCpfCode] = useState("")
  const [cpfDesignation, setCpfDesignation] = useState("")
  const [description, setDescription] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [objectifs, setObjectifs] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [prerequisite, setPrerequisite] = useState("") // si problème, remplacer \n par &#13;&#10;
  const [programme, setProgramme] = useState("")
  const [benefices, setBenefices] = useState("")    // si problème, remplacer \n par &#13;&#10;

  function handleValidSubmitFormation() {
    const data = {
      title: title,
      description: description,
      targetAudience: targetAudience,
      duration: Number(duration),
      durationUnit: durationUnit,
      priceHT: priceHT ? Number(priceHT) : undefined,
      priceTTC: Number(priceHT) + Number(priceHT * 0.2),
      accessDelay: accessDelay,
      disabledAccessibility: disabledAccessibility,
      modalities: modalities,
      evaluation: evaluation,
      necessaryMaterial: necessaryMaterial,
      satisfactionRate: Number(satisfactionRate),
      successRate: Number(successRate),
      equivalences: equivalences,
      cpfEligible: cpfEligible,
      cpfCode: cpfEligible ? cpfCode : null,
      cpfDesignation: cpfEligible ? cpfDesignation : null,
      objectifs: objectifs,
      prerequisite: prerequisite,
      programme: programme,
      educationalMethods: educationalMethods,
      educationalTechnics: educationalTechnics,
      market: market,
      sanction: sanction,
      benefices: benefices,
      activeFormation: false,
      formationStatus: "/api/formation_statuses/3",
      formationTrainingStatus: "/api/formation_training_statuses/1",
      skillDomain: `/api/skill_domains/${skillDomainId}`,
      prestataire: `/api/prestataires/${prestataireId}`,
      role: "prestataire",
      history: history
    }
    dispatch(ADD_FORMATION(data))
  }

  function handleInvalidSubmitFormation() {
    dispatch(FORMATION_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(SKILL_DOMAIN_ACTIVITY())
    dispatch(FORMATION_ERROR(""))
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/prestataire-formations" title="Retour à la liste des formations" />
            <TitleBanner title="Ajout d'une formation" />
            <SuccessErrorAlert error={error} />


            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitFormation(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitFormation(e, v)}
                    >
                      <Row>
                        <Col xl="12">

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Titre de la formation <span className="text-danger">*</span></h5>
                            <AvField
                              name="title"
                              value=""
                              className="form-control"
                              placeholder=""
                              type="text"
                              errorMessage="Le titre de la formation est requis"
                              onChange={event => setTitle(event.target.value)}
                              required
                            />
                          </div>

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Public visé par la formation <span className="text-danger">*</span></h5>
                            <div className="form-group mb-4">
                              <AvField
                                name="targetAudience"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                errorMessage="Le public visé est requis"
                                onChange={event => setTargetAudience(event.target.value)}
                                required
                              />
                            </div>
                          </div>

                          <div className="px-4 py-4 d-flex justify-content-between text-center">

                            <div>
                              <h5 className="font-size-17 mt-5">Durée de la formation</h5>
                              <div className="mb-4">
                                <AvField
                                  name="duration"
                                  value=""
                                  placeholder=""
                                  type="number"
                                  onChange={event => setDuration(event.target.value)}
                                />
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="durationUnit"
                                  errorMessage="Une unité de temps est requise"
                                  onChange={event => setDurationUnit(event.target.value)}
                                >
                                  <option key="heures">heures</option>
                                  <option key="jours">jours</option>
                                </AvField>
                              </div>
                            </div>

                            <div>
                              <h5 className="font-size-17 mt-5 mx-5">Prix de vente (HT)</h5>
                              <div className="d-flex mx-4">
                                <AvField
                                  name="priceHT"
                                  value=""
                                  placeholder=""
                                  type="number"
                                  onChange={event => setPriceHT(event.target.value)}
                                />
                                <p className="mx-2">€</p>
                              </div>
                            </div>

                            <div className="mx-5">
                              <h5 className="font-size-17 mt-5 mb-3">Délais d’accès</h5>
                              <AvField
                                name="accessDelay"
                                value=""
                                placeholder=""
                                type="string"
                                onChange={event => setAccessDelay(event.target.value)}
                              />
                            </div>

                            <div className="mx-5">
                              <h5 className="font-size-17 mt-5 mb-3">Accessibilité handicap</h5>
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="status-input"
                                     onChange={() => disabledAccessibility ? setDisabledAccessibility(false) : setDisabledAccessibility(true)}
                                     checked={disabledAccessibility}
                              />
                            </div>

                          </div>

                          <h5 className="font-size-17 mt-4 mb-3">Description de la formation</h5>

                          <AvField
                            name="description"
                            value=""
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            validate={{
                              required: {value: false},
                              minLength: {value: 0},
                              maxLength: {value: 800}
                            }}
                            onChange={event => setDescription(event.target.value)}
                          />
                          <p className="font-size-15 color-grey mx-2 mt-2">(la description est limitée à 800 caractères)</p>


                          <h5 className="font-size-17 mt-4 mb-3">Objectifs</h5>

                          <AvField
                            name="objectifs"
                            value=""
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setObjectifs(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-5 mb-3">Prérequis de la formation</h5>

                          <AvField
                            name="prerequisite"
                            value=""
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setPrerequisite(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-5 mb-3">Programme</h5>

                          <ReactQuill theme="snow" value={programme} onChange={setProgramme}/>

                          <h5 className="font-size-17 mt-5 mb-4">Pédagogie</h5>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_moy_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Moyens pédagogiques</h5>
                                <AvField
                                  name="educationalMethods"
                                  value=""
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalMethods(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="_moy_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Méthodes pédagogiques</h5>
                                <AvField
                                  name="educationalTechnics"
                                  value=""
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalTechnics(event.target.value)}
                                />
                              </div>
                            </li>

                          </ul>

                          <h5 className="font-size-17 mt-5 mb-4">Bénéfices</h5>

                          <ul className="activity-feed mb-0 ps-2">

                            <li className="feed-item" key="sanction">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Sanction de la formation</h5>
                                <AvField
                                  name="sanction"
                                  value=""
                                  placeholder=""
                                  type="string"
                                  onChange={event => setSanction(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="market">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Débouchés de la formation</h5>
                                <AvField
                                  name="market"
                                  value=""
                                  placeholder=""
                                  type="string"
                                  onChange={event => setMarket(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="benefice">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Bénéfices</h5>

                                <AvField
                                  name="benefices"
                                  value=""
                                  className="form-control"
                                  placeholder=""
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setBenefices(event.target.value)}
                                />

                              </div>
                            </li>

                          </ul>

                          <div className="mt-3 px-4 py-4">

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17">Modalités de la formation : </h5>
                              <AvField
                                name="modalities"
                                value=""
                                className="mx-3"
                                placeholder=""
                                type="string"
                                onChange={event => setModalities(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17">Modalités d’évaluations : </h5>
                              <AvField
                                name="evaluation"
                                value=""
                                placeholder=""
                                className="mx-3"
                                type="string"
                                onChange={event => setEvaluation(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex  align-items-center">
                              <h5 className="font-size-17">Matériel nécessaire pour réaliser la formation : </h5>
                              <AvField
                                name="necessaryMaterial"
                                value=""
                                className="mx-3"
                                placeholder=""
                                type="string"
                                onChange={event => setNecessaryMaterial(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17 mb-0">Taux de satisfaction de la formation (en %) : </h5>
                              <AvField
                                name="satisfactionRate"
                                value={satisfactionRate}
                                placeholder=""
                                className="mx-3"
                                type="number"
                                validate={{
                                  min: {value: 0, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'},
                                  max: {value: 100, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'}
                                }}
                                onChange={event => setSatisfactionRate(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17">Taux de réussite à la formation (en %) : </h5>
                              <AvField
                                name="successRate"
                                value={successRate}
                                placeholder=""
                                className="mx-3"
                                type="number"
                                validate={{
                                  min: {value: 0, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'},
                                  max: {value: 100, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'}
                                }}
                                onChange={event => setSuccessRate(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17">Equivalences de la formation : </h5>
                              <AvField
                                name="equivalences"
                                value={equivalences}
                                placeholder=""
                                className="mx-3"
                                type="string"
                                onChange={event => setEquivalences(event.target.value)}
                              />
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                              <h5 className="font-size-17 mb-0">Eligible au CPF ?</h5>
                              <Input type="checkbox"
                                     className="form-check-input mx-3"
                                     id="status-input"
                                     onChange={() => cpfEligible ? setCpfEligible(false) : setCpfEligible(true)}
                                     checked={cpfEligible}
                              />
                            </div>

                            { cpfEligible ? (
                              <>
                                <div className="mb-5 d-flex align-items-center">
                                  <h5 className="font-size-17">Code CPF de la formation : </h5>
                                  <AvField
                                    name="cpfCode"
                                    value={cpfCode}
                                    placeholder=""
                                    className="mx-3"
                                    type="string"
                                    onChange={event => setCpfCode(event.target.value)}
                                  />
                                </div>

                                <div className="mb-5 d-flex align-items-center">
                                  <h5 className="font-size-17">Désignation CPF de la formation : </h5>
                                  <AvField
                                    name="cpfDesignation"
                                    value={cpfDesignation}
                                    placeholder=""
                                    className="mx-3"
                                    type="string"
                                    onChange={event => setCpfDesignation(event.target.value)}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                              </>
                            )}

                            <Row>
                              <Col lg={8}>

                                <Card outline color="primary" className="border">
                                  <CardBody className="mx-2">

                                    <div className="form-group mb-4">
                                      <h5 className="font-size-17 mt-3 mb-3">Domaine de compétence de la formation <span className="text-danger">*</span></h5>
                                      <AvField
                                        className="form-select"
                                        type="select"
                                        name="skillDomainId"
                                        onChange={event => setSkillDomainId(event.target.value)}
                                        errorMessage="Veuillez choisir un domaine de formation"
                                        required
                                      >
                                        <option key="default" value="">Choisir</option>
                                        {map(skillDomains, (domain, key) => (
                                          <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                                        ))}
                                      </AvField>
                                      <p className="mt-3">Choisir un domaine de formation dans le menu déroulant</p>
                                    </div>

                                  </CardBody>
                                </Card>

                              </Col>
                            </Row>

                          </div>
                        </Col>
                      </Row>

                      <SuccessErrorAlert error={error} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Ajouter</Button>
                        </div>

                        <Link to="/gestion-formations" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des formations
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(PrestataireFormationAdd)
