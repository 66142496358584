import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  prestataires: [],
  prestataire: {},
  prestatairesFilter: [],
  intervenant: {},
  intervenantFormation: {},
  success: "",
  successForm: "",
  successAvatar: "",
  error: "",
  errorAvatar: "",
  isLoading: false
}

const prestataireSlice = createSlice({
  name: "prestataire",
  initialState,

  reducers: {

    PRESTATAIRES (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    PRESTATAIRE (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    PRESTATAIRES_FILTER (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    INTERVENANT (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    GET_PRESTATAIRES (state, action) {
      state.prestataires = action.payload
    },

    GET_PRESTATAIRE (state, action) {
      state.prestataire = action.payload
    },

    GET_PRESTATAIRES_FILTER (state, action) {
      state.prestatairesFilter = action.payload
    },

    GET_INTERVENANT (state, action) {
      state.intervenant = action.payload
    },

    ADD_NEW_PRESTATAIRE (state, action) {
      state.prestataire = action.payload
    },

    ADD_NEW_INTERVENANT (state, action) {
      state.intervenant = action.payload
    },

    UPDATE_PRESTATAIRE (state) {
      state.isLoading = true
    },

    DELETE_PRESTATAIRE (state) {
      state.isLoading = true
      state.prestataire = {}
    },

    ADD_INTERVENANT (state) {
      state.isLoading = true
    },

    UPDATE_INTERVENANT (state) {
      state.isLoading = true
    },

    DELETE_INTERVENANT (state) {
      state.isLoading = true
      state.intervenant = {}
    },

    PRESTATAIRE_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorAvatar = ""
      state.success = action.payload
    },

    ADD_FORMATION_INTERVENANT (state, action) {
      state.intervenantFormation = action.payload
    },

    UPDATE_FORMATION_INTERVENANT (state) {
      state.isLoading = true
    },

    ADD_PRESTATAIRE_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorAvatar = ""
      state.successForm = action.payload
    },

    ADD_INTERVENANT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorAvatar = ""
      state.successForm = action.payload
    },

    PRESTATAIRE_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    /* ============= AVATAR ============== */

    ADD_AVATAR_INTERVENANT (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    UPDATE_AVATAR_INTERVENANT (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    DELETE_AVATAR_INTERVENANT (state) {
      return { ...state, isLoading: true, success: "", successForm: "", successAvatar: "" }
    },

    INTERVENANT_AVATAR_SUCCESS (state, action) {
      state.isLoading = false
      state.successAvatar = action.payload
    },

    INTERVENANT_AVATAR_ERROR (state, action) {
      state.errorAvatar = action.payload  // payload: errorAvatar
    },

  }
});

const { reducer, actions } = prestataireSlice;

export const {
  PRESTATAIRES,
  PRESTATAIRE,
  PRESTATAIRES_FILTER,
  INTERVENANT,
  GET_PRESTATAIRES,
  GET_PRESTATAIRE,
  GET_PRESTATAIRES_FILTER,
  GET_INTERVENANT,
  ADD_NEW_PRESTATAIRE,
  ADD_NEW_INTERVENANT,
  UPDATE_PRESTATAIRE,
  DELETE_PRESTATAIRE,
  ADD_INTERVENANT,
  UPDATE_INTERVENANT,
  DELETE_INTERVENANT,
  ADD_FORMATION_INTERVENANT,
  UPDATE_FORMATION_INTERVENANT,
  ADD_PRESTATAIRE_SUCCESS,
  ADD_INTERVENANT_SUCCESS,
  PRESTATAIRE_SUCCESS,
  PRESTATAIRE_ERROR,
  DELETE_AVATAR_INTERVENANT,
  ADD_AVATAR_INTERVENANT,
  UPDATE_AVATAR_INTERVENANT,
  INTERVENANT_AVATAR_SUCCESS,
  INTERVENANT_AVATAR_ERROR,
} = actions;

export default reducer;

export const selectPrestataire = (state) => state.prestataire;
