import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import errorImg from "assets/images/500-error.png"

const PageNotPermission = () => {

    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div>
                                    <Row className="justify-content-center">
                                        <Col sm={4}>
                                            <div className="error-img">
                                                <img src={errorImg} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className="text-uppercase mt-4">Not Permission</h4>
                                <p className="text-muted">Vous n'avez pas la permission de voir cette page</p>
                                <div className="mt-5">
                                    <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Retour au tableau de bord</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PageNotPermission
