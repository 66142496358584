import React, {useEffect} from "react"
import {Redirect, withRouter} from "react-router-dom"
import {Col, Container, Row} from "reactstrap"
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_ACCOUNT_INTERVENTION} from "store/account/clientAccount/clientAccountSlice";
import {selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

// Components
import ClientFormationCard from "./ClientFormationCard";

const ClientFormation = ({isRole, location}) => {

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()
  const { trainingPlan } = useSelector(selectTrainingPlan);

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT_INTERVENTION({ clientId: clientId }))
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-5">
              <Col md="12">

                <h5 className="font-size-18 color-secondary mb-4 mt-5"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des formations choisies</h5>

                { (trainingPlan && trainingPlan.validate) ? (
                  <Row>
                    { map(trainingPlan.trainingPlanActions, (trainingPlanAction, key) => (
                      (trainingPlanAction.hasOwnProperty('formation') && (
                        <Col lg={4} key={"_formation_" + key}>
                          <ClientFormationCard
                            color="primary"
                            formation={trainingPlanAction.formation}
                            trainingPlanAction={trainingPlanAction}
                            employee={trainingPlanAction.employee}
                          />
                        </Col>
                      ))
                    ))}
                  </Row>
                ) : (
                  <p>A venir</p>
                )}

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientFormation)
