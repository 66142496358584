import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectAccount} from "store/account/account/accountSlice";

const AccountModalRemove = ({ isOpen, toggle, deleteUser}) => {

  const account = useSelector(selectAccount)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Supprimer un utilisateur ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Confirmez-vous la suppression de {account.user.firstname} {account.user.lastname} ?</p>
        <p>Email: {account.user.email}</p>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => deleteUser(
            account.user.id,
            account.user.roles,
            account.user.hasOwnProperty('clientEmployee') && account.user.clientEmployee.id,
            account.user.hasOwnProperty('clientEmployee') && account.user.clientEmployee.client.id
          )}
        >Confirmer la suppression</button>

        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default AccountModalRemove

AccountModalRemove.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
