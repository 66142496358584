import React, {useEffect, useState} from "react"
import {Redirect, Link} from "react-router-dom"
import {Col, Container, Row, Card, CardBody, Button, Input} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectTrainingPlan, TRAINING_PLAN_ACTION, UPDATE_TRAINING_PLAN_ACTION, PAYMENT_FOUNDING_TYPES} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {FORMATIONS} from "store/formation/formationSlice";
import {selectSkill, SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import ClientFormationPlanRemoveFormationModal from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanRemoveFormationModal";

// formatage date
import moment from "moment";

const ClientFormationPlanEdit = ({isRole, location}) => {

  // récupération de l'id passé dans la route
  const routeId = location.state

  const dispatch = useDispatch()
  const {trainingPlanAction, trainingPlans, paymentFoundingTypes, error, success} = useSelector(selectTrainingPlan);
  const {skillDomains} = useSelector(selectSkill);

  const [trainingDateDesired, setTrainingDateDesired] = useState(trainingPlanAction.trainingDateDesired)
  const [skillDomainId, setSkillDomainId] = useState(trainingPlanAction.hasOwnProperty('skillDomain') ? trainingPlanAction.skillDomain.id : '')
  const [taskCustom, setTaskCustom] = useState(trainingPlanAction.hasOwnProperty('taskCustom') ? trainingPlanAction.taskCustom : '')
  const [trainingCostHour, setTrainingCostHour] = useState(trainingPlanAction.hasOwnProperty('trainingCostHour') ? trainingPlanAction.trainingCostHour : '')
  const [trainingCostTotal, setTrainingCostTotal] = useState(trainingPlanAction.hasOwnProperty('trainingCostTotal') ? trainingPlanAction.trainingCostTotal : '')
  const [paymentFoundingTypeId, setPaymentFoundingTypeId] = useState(trainingPlanAction.hasOwnProperty('paymentFundingType') ? trainingPlanAction.paymentFundingType.id : '')
  const [paymentFinanced, setPaymentFinanced] = useState(trainingPlanAction.hasOwnProperty('paymentFinanced') ? trainingPlanAction.paymentFinanced : '')
  const [finished, setFinished] = useState(true)
  const [certificateReceived, setCertificateReceived] = useState(true)
  const [certificateExpiration, setCertificateExpiration] = useState(trainingPlanAction.hasOwnProperty('certificateExpiration') ? trainingPlanAction.certificateExpiration : '')

  /* ========== Enlever une formation =========== */

  const [showModalRemoveFormation, setShowModalRemoveFormation] = useState(false);

  const toggleModalRemoveFormation = () => {
    setShowModalRemoveFormation(!showModalRemoveFormation);
  };

  const removeFormation = () => {

    const data = {
      trainingPlace: null,
      trainingStart: null,
      trainingEnd: null,
      formation: null,
      formationInternal: null,
      trainingCostTotal: null,
      trainingPlanActionId: trainingPlanAction.id
    }
    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))
    dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: routeId}))

    setShowModalRemoveFormation(!showModalRemoveFormation);
  }

  /* ============================================ */

  function handleValidSubmit() {

    const data = {
      trainingDateDesired: trainingDateDesired ? trainingDateDesired : undefined,
      skillDomain: skillDomainId ? `/api/skill_domains/${skillDomainId}` : undefined,
      trainingCostHour: trainingCostHour ? parseFloat(trainingCostHour) : undefined,
      trainingCostTotal: trainingCostTotal ? parseFloat(trainingCostTotal) : undefined,
      paymentFundingType: paymentFoundingTypeId ? `/api/payment_founding_types/${paymentFoundingTypeId}` : undefined,
      paymentFinanced: paymentFinanced ? parseFloat(paymentFinanced) : undefined,
      certificateReceived: certificateReceived ? certificateReceived : false,
      finished: finished ? finished : false,
      certificateExpiration: certificateExpiration ? certificateExpiration : undefined,
      trainingPlanActionId: trainingPlanAction.id,
      taskCustom: taskCustom ? taskCustom : undefined,
    }

    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))
  }

  useEffect(() => {
    dispatch(SKILL_DOMAIN_ACTIVITY())
    dispatch(PAYMENT_FOUNDING_TYPES())
    dispatch(FORMATIONS())
    dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: routeId}))
    setCertificateReceived(trainingPlanAction.certificateReceived)
    setFinished(trainingPlanAction.finished)
  }, [dispatch, routeId, trainingPlanAction.certificateReceived, trainingPlanAction.finished])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientFormationPlanRemoveFormationModal isOpen={showModalRemoveFormation} toggle={toggleModalRemoveFormation} removeFormation={removeFormation}/>

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/mon-plan-developpement-competences" title="Retour au plan de développement des compétences" />
            <TitleBanner title="Modification d'une demande de formation" />
            <SuccessErrorAlert error={error} success={success} />


            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">

                    {(trainingPlanAction.formation && !trainingPlans[0].validate) && (

                      <Row className="align-items-center">

                        <Col lg={6}>
                           <p className="mb-0">La formation actuellement choisie est : <span className="color-primary">{trainingPlanAction.formation.title}</span></p>
                        </Col>

                        <Col lg={4}>
                          <Button
                            color="danger"
                            className="btn font-16 btn-block"
                            onClick={toggleModalRemoveFormation}
                            id="btn-remove-formation"
                          >
                            Retirer la formation
                          </Button>
                        </Col>
                      </Row>
                    )}

                    { trainingPlanAction.formationInternal && (
                      <Row className="align-items-center">

                        <Col lg={6}>
                          <p className="mb-0">La formation actuellement choisie est : <span className="color-primary">{trainingPlanAction.formationInternal.title}</span></p>
                        </Col>

                        <Col lg={4}>
                          <Button
                            color="danger"
                            className="btn font-16 btn-block"
                            onClick={toggleModalRemoveFormation}
                            id="btn-remove-formation"
                          >
                            Retirer la formation
                          </Button>
                        </Col>
                      </Row>
                    )}

                    <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>

                      <Row>

                        <Col md={12}>

                          {(!trainingPlanAction.hasOwnProperty('formation')) && (

                            <div className="mx-5 mb-5">
                              <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Demande de formation</h5>

                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Date de formation souhaitée</h5>
                                  <AvField
                                    name="trainingDateDesired"
                                    type="date"
                                    errorMessage="Date invalide"
                                    validate={{required: {value: false}}}
                                    value={trainingPlanAction.trainingDateDesired ? moment.utc(trainingPlanAction.trainingDateDesired).format("yyyy-MM-DD") : ""}
                                    onChange={event => setTrainingDateDesired(event.target.value)}
                                  />
                                </div>
                              </Col>

                              <Col className="col-6 mb-4">
                                <h5 className="font-size-17 mt-5 mb-3">Compétence</h5>
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="skillDomainId"
                                  validate={{required: {value: false}}}
                                  onChange={event => setSkillDomainId(event.target.value)}
                                  errorMessage="Veuillez choisir une compétence"
                                  helpMessage="Choisir une compétence dans le menu déroulant"
                                  >
                                  {trainingPlanAction.hasOwnProperty('skillDomain') ? (
                                    <option key={`domain-${trainingPlanAction.skillDomain.id}`} value={trainingPlanAction.skillDomain.id}>{trainingPlanAction.skillDomain.name}</option>
                                  ) : (
                                    <option key="default" value="">Choisir</option>
                                  )}
                                  {map(skillDomains, (domain, key) => (
                                    <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                                  ))}
                                </AvField>
                              </Col>

                              { trainingPlanAction.employeeSkill
                                ? (
                                  <p className="mt-5 mb-0 text-primary">La tâche ne peut pas être modifiée car elle provient de la cartographie</p>
                                ) : (
                                  <Col className="col-6 mb-4">
                                    <h5 className="font-size-17 mt-5 mb-3">Tâche</h5>
                                    <AvField
                                      name="taskCustom"
                                      value={trainingPlanAction.taskCustom}
                                      className="form-control"
                                      placeholder=""
                                      type="text"
                                      onChange={event => setTaskCustom(event.target.value)}
                                    />
                                  </Col>
                                )
                              }

                            </div>

                          )}

                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Fin de la formation</h5>

                            <div className="form-group mb-5 d-flex align-items-center">
                              <h5 className="font-size-17 mx-3 mb-0 mt-1">Formation réalisée ?</h5>
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="finished-input"
                                     onChange={event => setFinished(event.target.checked)}
                                     checked={finished}
                              />
                            </div>

                            <div className="form-group mb-4  d-flex align-items-center">
                              <h5 className="font-size-17 mx-3 mb-0 mt-1">Attestation reçue ?</h5>
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="certification-input"
                                     onChange={event => setCertificateReceived(event.target.checked)}
                                     checked={certificateReceived}
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Echéance (certificat/habilitation)</h5>
                              <AvField
                                name="certificateExpiration"
                                type="date"
                                errorMessage="Date invalide"
                                validate={{required: {value: false}}}
                                value={trainingPlanAction.certificateExpiration ? moment.utc(trainingPlanAction.certificateExpiration).format("yyyy-MM-DD") : ""}
                                onChange={event => setCertificateExpiration(event.target.value)}
                              />
                            </div>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Financement de la formation</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Coût pédagogique (en euros)</h5>
                              <AvField
                                name="trainingCostHour"
                                value={trainingPlanAction.trainingCostTotal}
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={event => setTrainingCostTotal(event.target.value)}
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Coût horaire (en euros)</h5>
                              <AvField
                                name="trainingCostHour"
                                value={trainingPlanAction.trainingCostHour}
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={event => setTrainingCostHour(event.target.value)}
                              />
                            </div>

                            <div className="form-group mb-4">

                              <h5 className="font-size-17 mt-5 mb-3">Type de financement</h5>

                              <AvField
                                className="form-select"
                                type="select"
                                name="paymentFoundingTypeId"
                                validate={{required: {value: false}}}
                                onChange={event => setPaymentFoundingTypeId(event.target.value)}
                                errorMessage="Veuillez choisir un type de financement"
                                helpMessage="Choisir un type dans le menu déroulant">
                                >
                                {trainingPlanAction.hasOwnProperty('paymentFundingType') ? (
                                  <option key={`founding-type-${trainingPlanAction.paymentFundingType.id}`} value={trainingPlanAction.paymentFundingType.id}>{trainingPlanAction.paymentFundingType.name}</option>
                                ) : (
                                  <option key="default" value="">Choisir</option>
                                )}
                                {map(paymentFoundingTypes, (type, key) => (
                                  <option key={`founding-type-${type.id}`} value={type.id}>{type.name}</option>
                                ))}
                              </AvField>

                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Montant financé (en euros)</h5>
                              <AvField
                                name="paymentFinanced"
                                value={trainingPlanAction.paymentFinanced}
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={event => setPaymentFinanced(event.target.value)}
                              />
                            </div>

                          </div>
                        </Col>

                      </Row>

                      <SuccessErrorAlert error={error} success={success} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Modifier les informations</Button>
                        </div>

                        <Link
                          to="/mon-plan-developpement-competences"
                          className="btn btn-primary waves-effect waves-light mx-2">
                          <i className="uil uil-corner-up-right-alt me-1"></i>Retour au plan de développement des compétences
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default ClientFormationPlanEdit
