import React, {useEffect} from "react"
import {Container} from "reactstrap"
import {Redirect} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectCartography, CARTOGRAPHY } from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import CartographyResumePdf from "components/Common/Pdf/CartographyResumePdf";

const MyCartographyPdf = ({ isRole, location }) => {

  const { cartographyId, cartographyWidth } = location.state

  const dispatch = useDispatch()
  const { cartography, success, error } = useSelector(selectCartography);

  useEffect(() => {
    dispatch(CARTOGRAPHY({ cartographyId }))
  }, [dispatch, cartographyId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link={{pathname:`/cartographie`, state: cartography.id}} title="Retour à la cartographie" />
            <SuccessErrorAlert error={error} success={success} />

            <CartographyResumePdf cartographyId={cartographyId} cartographyWidth={cartographyWidth} />

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default MyCartographyPdf
