import React, {useEffect, useRef, useState} from "react"
import {Col, Container, Row, Label} from "reactstrap"
import {Redirect} from "react-router-dom"

// Editable
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// REDUX
import {useDispatch, useSelector} from "react-redux";

import {
  selectTrainingPlan,
  TRAINING_PLANS_CARTOGRAPHY,
  TRAINING_PLAN,
  TRAINING_PLAN_ACTION,
  ADD_TRAINING_PLAN_ACTION,
  DELETE_TRAINING_PLAN_ACTION,
  UPDATE_TRAINING_PLAN_VALIDATE,
  UPDATE_TRAINING_PLAN_ACTION
} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

import {selectCartography } from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import FormationPlanColumn from "pages/Administrator/SkillsDevelopmentPlan/FormationPlan/FormationPlanColumn";
import FormationPlanReadColumn from "pages/Administrator/SkillsDevelopmentPlan/FormationPlan/FormationPlanReadColumn";
import FormationPlanAddActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanAddActionModal";
import FormationPlanRemoveActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanRemoveActionModal";
import FormationPlanValidateModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanValidateModal";
import FormationPlanDevalidateModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDevalidateModal";
import FormationPlanDomainSelectActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDomainSelectActionModal";
import FormationPlanDetailFormationModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDetailFormationModal";
import FormationPlanDetailFormationInternalModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDetailFormationInternalModal";

const FormationPlan = ({isRole, location, history}) => {

  const cartographyId = location.state

  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()

  /* ========== Btn Scroll =========================== */
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  /* ================================================= */

  const { trainingPlan, success, error } = useSelector(selectTrainingPlan);
  const { cartography } = useSelector(selectCartography);

  const [showModalActionAdd, setShowModalActionAdd] = useState(false)
  const [showModalActionDelete, setShowModalActionDelete] = useState(false)
  const [showModalActionDomainSelect, setShowModalActionDomainSelect] = useState(false)
  const [showModalValidate, setShowModalValidate] = useState(false)
  const [showModalDevalidate, setShowModalDevalidate] = useState(false)
  const [showModalDetailFormation, setShowModalDetailFormation] = useState(false)
  const [showModalDetailFormationInternal, setShowModalDetailFormationInternal] = useState(false)

  const toggleModalActionAdd = (trainingPlanId) => {
    if (!showModalActionAdd) dispatch(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    setShowModalActionAdd(!showModalActionAdd);
  };

  const toggleModalActionDelete = (actionData) => {
    if (!showModalActionDelete) dispatch(TRAINING_PLAN_ACTION({ trainingPlanActionId: actionData.id }))
    setShowModalActionDelete(!showModalActionDelete);
  };

  const toggleModalActionDomainSelect = (actionData) => {
    if (!showModalActionDomainSelect) {
      dispatch(SKILL_DOMAIN_ACTIVITY())
      dispatch(TRAINING_PLAN_ACTION({ trainingPlanActionId: actionData.id }))
    }
    setShowModalActionDomainSelect(!showModalActionDomainSelect);
  };

  const toggleModalValidate = (trainingPlanId) => {
    if (!showModalValidate) dispatch(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    setShowModalValidate(!showModalValidate);
  };

  const toggleModalDevalidate = (trainingPlanId) => {
    if (!showModalDevalidate) dispatch(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    setShowModalDevalidate(!showModalDevalidate);
  };

  /* =========== Descriptif des détails de la formation ============ */

  const toggleModalDetailFormation = (trainingPlanAction) => {
    if (!showModalDetailFormation) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalDetailFormation(!showModalDetailFormation);
  };

  const detailFormation = () => {
    setShowModalDetailFormation(!showModalDetailFormation);
  }

  const toggleModalDetailFormationInternal = (trainingPlanAction) => {
    if (!showModalDetailFormationInternal) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalDetailFormationInternal(!showModalDetailFormationInternal);
  };

  const detailFormationInternal = () => {
    setShowModalDetailFormationInternal(!showModalDetailFormationInternal);
  }

  /* ========================================= */

  const addAction = (employeeId, trainingPlanId) => {

    const data = {
      employee: `/api/employees/${employeeId}`,
      trainingPlan: `/api/training_plans/${trainingPlanId}`,
      certificateReceived: false,
      finished: false,
      isValidate: false,
      devisRequestSent: false,
      cartographyId: cartographyId,
      isCustomTrainingDate: false,
      trainingPlanId: trainingPlanId
    }

    dispatch(ADD_TRAINING_PLAN_ACTION(data))
    setShowModalActionAdd(!showModalActionAdd);
  };

  const deleteAction = (id, trainingPlanId) => {
    dispatch(DELETE_TRAINING_PLAN_ACTION({ actionId: id, cartographyId: cartographyId, trainingPlanId: trainingPlanId }))
    setShowModalActionDelete(!showModalActionDelete);
  };

  const domainSelectAction = (skillDomainId, trainingPlanActionId) => {
    const data = {
      skillDomain: skillDomainId ? `/api/skill_domains/${skillDomainId}` : undefined,
      trainingPlanActionId: trainingPlanActionId,
      trainingPlanId: trainingPlan.id
    }
    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))
    setShowModalActionDomainSelect(!showModalActionDomainSelect);
  };


  const validateTrainingPlan = () => {

    dispatch(UPDATE_TRAINING_PLAN_VALIDATE({
      validate: true,
      adminRole: true,
      trainingPlanId: trainingPlan.id,
      interventionId: cartography.intervention.id,
      clientId: cartography.intervention.client.id,
      history: history
    }))

    setShowModalValidate(!showModalValidate);
  };

  const devalidateTrainingPlan = () => {
    dispatch(UPDATE_TRAINING_PLAN_VALIDATE({
      validate: false,
      adminRole: true,
      trainingPlanId: trainingPlan.id,
      interventionId: cartography.intervention.id,
      clientId: cartography.intervention.client.id,
      history: history
    }))

    setShowModalDevalidate(!showModalDevalidate);
  };

  useEffect(() => {
    dispatch(TRAINING_PLANS_CARTOGRAPHY({ cartographyId: cartographyId }))
  }, [dispatch, cartographyId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <FormationPlanAddActionModal isOpen={showModalActionAdd} toggle={toggleModalActionAdd} addAction={addAction} cartography={cartography}/>
        <FormationPlanRemoveActionModal isOpen={showModalActionDelete} toggle={toggleModalActionDelete} deleteAction={deleteAction} />
        <FormationPlanDomainSelectActionModal isOpen={showModalActionDomainSelect} toggle={toggleModalActionDomainSelect} domainSelectAction={domainSelectAction} />
        <FormationPlanDetailFormationModal isOpen={showModalDetailFormation} toggle={toggleModalDetailFormation} detailFormation={detailFormation} />
        <FormationPlanDetailFormationInternalModal isOpen={showModalDetailFormationInternal} toggle={toggleModalDetailFormationInternal} detailFormationInternal={detailFormationInternal} />

        <FormationPlanValidateModal isOpen={showModalValidate} toggle={toggleModalValidate} validateTrainingPlan={validateTrainingPlan} />
        <FormationPlanDevalidateModal isOpen={showModalDevalidate} toggle={toggleModalDevalidate} devalidateTrainingPlan={devalidateTrainingPlan} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn
              link={{ pathname: "/details-organisation", state: cartography.hasOwnProperty('intervention') && cartography.intervention.client.id }}
              title="Retour aux détails de l'intervention"
            />
            <TitleBanner title={`Plan de développement des compétences de l'organisation "${cartography.hasOwnProperty('intervention') && cartography.intervention.client.entName}"`} />
            <SuccessErrorAlert error={error} success={success} />


            <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Plan de développement des compétences</h5>

            <p className="mb-4">Date de création : {moment(trainingPlan.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

            { trainingPlan.validate
              ? (
                <>
                  <Row>
                    <Col sm={12}>
                        <p className="text-danger mb-4">Le plan de développement des compétences a été validé par l'organisation</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <button  onClick={() => toggleModalDevalidate(trainingPlan.id)} type="button" className="btn btn-danger waves-effect waves-light mx-2 mb-5">
                        <i className="uil uil-corner-up-right-alt me-1"></i>Enlever la validation de l'organisation
                      </button>
                    </Col>
                  </Row>

                  <Row className="mb-5">
                    <Col md="12 position-relative">

                      {trainingPlan && (

                        <ToolkitProvider
                          keyField="id"
                          data={trainingPlan.trainingPlanActions || []}
                          columns={FormationPlanReadColumn(
                            toggleModalDetailFormation,
                            toggleModalDetailFormationInternal,
                            cartographyId,
                            cartography.intervention?.client.id
                          )}
                          bootstrap4
                          exportCSV={ { fileName: `Plan de developpement des competences - (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy")}).csv` } }
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row>

                                <Col sm="12" md="6">
                                  <Label>
                                    <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3">
                                      Exporter le tableau en CSV
                                    </ExportCSVButton>
                                  </Label>
                                </Col>

                              </Row>

                              <div className="table-responsive mb-4" ref={ref}>

                                <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  hover={true}
                                  classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                  rowClasses={ (row, rowIndex) => {
                                    let classes = null;
                                    if (row.isValidate) classes = 'bg-formation-valid';
                                    return classes;
                                  } }
                                  {...toolkitProps.baseProps}
                                  //onTableChange={handleTableChange}

                                />
                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>

                      )}

                    </Col>
                  </Row>
                </>
              ) : (
                <>
                <button
                  type="button"
                  className="btn btn-success waves-effect waves-light mb-4"
                  onClick={() => toggleModalActionAdd(trainingPlan.id)}
                  data-toggle="modal"
                  data-target="modal-add"
                >
                  <i className="uil uil-corner-up-right-alt me-2"></i> Ajouter une demande de formation
                </button>

                  <Row className="mb-3">
                    <Col md="12">

                      {trainingPlan && (
                        <>
                          <div className="table-responsive mb-4" ref={ref}>

                            <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                            <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                            <BootstrapTable
                              keyField="id"
                              data={trainingPlan.trainingPlanActions || []}
                              columns={FormationPlanColumn(
                                toggleModalActionDelete,
                                toggleModalActionDomainSelect,
                                toggleModalDetailFormation,
                                toggleModalDetailFormationInternal,
                                cartography.intervention?.client.id
                              )}
                              responsive
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                            />
                          </div>
                        </>
                      )}

                    </Col>
                  </Row>

                  <button
                    type="button"
                    className="btn btn-success waves-effect waves-light mb-5"
                    onClick={() => toggleModalActionAdd(trainingPlan.id)}
                    data-toggle="modal"
                    data-target="modal-add"
                  >
                    <i className="uil uil-corner-up-right-alt me-2"></i> Ajouter une demande de formation
                  </button>
                </>
              )}

            { !trainingPlan.validate && (
              <>
                <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Validation du plan de développement des compétences</h5>

                <Row>
                  <Col sm={12}>

                    <button onClick={() => toggleModalValidate(trainingPlan.id)} type="button" className="btn btn-success waves-effect waves-light mx-2 mb-5">
                      <i className="uil uil-corner-up-right-alt me-1"></i>Valider le plan de développement des compétences
                    </button>

                  </Col>
                </Row>
              </>
            )}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default FormationPlan
