import React from "react";
import {Button, Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import {map} from "lodash";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ClientFormationPlanSelectFormationCard = ({ formation, toggleModalSelectFormation, trainingPlanActionId }) => {

  return (
    <Card outline color="secondary" className="border">

      <CardHeader className="bg-transparent">
        <h5 className="my-0 text-primary"><i className="uil uil-graduation-cap mx-2"></i>{formation.title}</h5>
      </CardHeader>

      <CardBody className="py-2">

        <CardTitle>

          <p className="font-size-15 color-text mb-4">
            {formation.description ? formation.description : 'Pas de description'}
          </p>

          {formation.hasOwnProperty('formationDates') && formation.formationDates.length !== 0 ? (
            <>
              {map(formation.formationDates, (formationDate, index) => (

                <p className="font-size-15 text-muted mb-3" key={`_formation_date_${index}`}>
                  Du <span className="color-table-secondary mx-1">{moment.utc(formationDate.formationStart).locale('fr', localization).format("DD MMMM yyyy à HH:mm")}</span>
                  {" "}au{" "}
                  <span className="color-table-secondary mx-1">{moment.utc(formationDate.formationEnd).locale('fr', localization).format("DD MMMM yyyy à HH:mm")}</span>
                  {" "}à {formationDate.place}
                </p>
              ))}
            </>
          ) : (
            <p className="font-size-15 color-text mb-4">Pas de date actuellement</p>
          )}

          <Link to={{ pathname: "/ma-formation-details", state: {formationId: formation.id, url: 'formationChoice', trainingPlanActionId: trainingPlanActionId }}} className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3">
            <i className="uil uil-graduation-cap mx-2 font-size-18"></i>Détails de la formation
          </Link>

          <Button
            color="warning"
            className="btn btn-warning waves-effect waves-light mx-2 py-2"
            onClick={() => toggleModalSelectFormation(formation.id)}
            id="btn-select-formation"
          >
            Choisir
          </Button>

        </CardTitle>
      </CardBody>
    </Card>
  );
};

export default ClientFormationPlanSelectFormationCard;

