import React, {useEffect, useState} from "react"
import {Redirect, withRouter} from "react-router-dom"
import {Card, CardBody, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// import charts
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectFormationInternal, FORMATION_INTERNAL} from "store/formationInternal/formationInternalSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import ClientFormationInternalDetailColumn from "../../../Client/FormationInternal/ClientFormationInternalDetailColumn";


const FormationInternalChoiceDetail = ({isRole, location}) => {

  const { formationInternalId, trainingPlanActionId, cartographyId, clientId } = location.state

  const dispatch = useDispatch()

  const { formationInternal } = useSelector(selectFormationInternal);

  const [activeTab, setActiveTab] = useState("1");

  const formationtoggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(FORMATION_INTERNAL({formationInternalId}))
  }, [dispatch, formationInternalId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            { (location.state.url === 'planFormation') ? (
              <GoBackBtn link={{ pathname: "/plan-developpement-des-competences", state: cartographyId }} title="Retour au PDC" />
            ) : (
              <GoBackBtn link={{ pathname: "/choisir-formation", state: {trainingPlanActionId: trainingPlanActionId, clientId: clientId} }} title="Retour à la liste des formations" />
            )}

            <Row>
              <Col xl="4">
                <Card className="card h-100">

                  {/*{formation.hasOwnProperty("prestataire") && (*/}

                  <CardBody>

                    <div className="text-center mt-4">
                      <h5 className="mb-3 color-primary font-size-17">Intervenants pressentis de la formation :</h5>


                      <Row>
                        <Col lg={12}>
                          <Card className="mb-0">
                            <CardBody className="mt-0">

                              <ToolkitProvider
                                keyField="id"
                                data={formationInternal.clientEmployees || []}
                                columns={ClientFormationInternalDetailColumn()}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <React.Fragment>

                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        remote
                                        hover={true}
                                        bordered={false}
                                        striped={false}
                                        classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                        {...toolkitProps.baseProps}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                    </div>

                  </CardBody>
                  {/*)}*/}
                </Card>
              </Col>

              <Col xl="8">

                <h5 className="font-size-24 color-primary mb-5 text-center">{formationInternal?.title}</h5>

                <div className=" text-center">
                  <h5 className="font-size-17 mt-5 mb-3">Pourquoi la formation ?</h5>
                  <p className="text-muted">{formationInternal?.context}</p>
                </div>

                <div className=" text-center">
                  <h5 className="font-size-17 mt-4 mb-3">Description de la formation : </h5>
                  <p className="text-muted">{formationInternal?.description}</p>
                </div>

                <div className=" text-center">
                  <h5 className="font-size-17 mt-5 mb-3">Public visé par la formation</h5>
                  <p className="text-muted">{formationInternal?.targetAudience}</p>
                </div>


                <div className="px-4 py-4 d-flex justify-content-between text-center">

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Durée de la formation</h5>
                    <p className="text-muted">{formationInternal?.duration}  {formationInternal?.durationUnit}</p>
                  </div>

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Coût de la formation</h5>
                    { formationInternal?.price ? (
                      <p className="text-muted">{Number(formationInternal.price).toFixed(2)} €</p>
                    ) : (
                      <p className="text-muted">Non précisé</p>
                    )}
                  </div>

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Statut</h5>
                    <p className="text-muted">{formationInternal?.internal ? 'Interne' : 'Externe'}</p>
                  </div>

                  <div className="mx-5">
                    <h5 className="font-size-17 mt-4 mb-3">Accessibilité handicap</h5>
                    <p className="text-muted">
                      {formationInternal?.disabledAccessibility ? (
                        <i className="uil uil-check font-size-22 color-success"></i>
                      ) : (
                        <i className="uil uil-times font-size-22 color-danger"></i>
                      )}
                    </p>
                  </div>

                </div>


                <Card className="mb-0">

                  {/* =========== DEBUT ONGLETS ================== */}

                  <Nav tabs className="nav-tabs-custom nav-justified">

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "1"})}
                        onClick={() => formationtoggle("1") }
                      >
                        <i className="uil uil-crosshair font-size-20"></i>
                        <span className="d-none d-sm-block">Objectifs</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "2"})}
                        onClick={ () => formationtoggle("2") }
                      >
                        <i className="uil uil-file-check-alt font-size-20"></i>
                        <span className="d-none d-sm-block">Prérequis</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "3"})}
                        onClick={ () => formationtoggle("3") }
                      >
                        <i className="uil uil-book-reader font-size-20"></i>
                        <span className="d-none d-sm-block">Programme</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "4"})}
                        onClick={ () => formationtoggle("4") }
                      >
                        <i className="uil uil-graduation-cap font-size-20"></i>
                        <span className="d-none d-sm-block">Pédagogie</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  {/* =========== FIN ONGLETS ================== */}

                  <TabContent activeTab={activeTab} className="p-4">

                    {/* =========== TAB 1 ================== */}

                    <TabPane tabId="1">
                      <div>

                        <div>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_obj_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Objectifs stratégiques</h5>
                                <p className="text-muted">{formationInternal?.objectifStrategic}</p>
                              </div>
                            </li>

                            <li className="feed-item" key="_obj_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Objectifs opérationnels</h5>
                                <p className="text-muted">{formationInternal?.objectifOperational}</p>
                              </div>
                            </li>

                            <li className="feed-item" key="_obj_3">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Objectifs pédagogiques</h5>
                                <p className="text-muted">{formationInternal?.objectifEducational}</p>
                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 2 ================== */}

                    <TabPane tabId="2">
                      <div>

                        <div>
                          <ul className="activity-feed mb-0 ps-2">

                            <li className="feed-item" key="_pre_1">
                              <div className="feed-item-list">

                                <h5 className="font-size-17 my-3">Prérequis de la formation</h5>

                                {formationInternal.hasOwnProperty('prerequisites') && (
                                  <ul>
                                    {formationInternal?.prerequisites.split('\n').map((item, idx) => <li key={idx}>{item}</li>)}
                                  </ul>
                                )}

                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 3 ================== */}

                    <TabPane tabId="3">
                      <div>

                        <div>

                          { formationInternal?.programme
                            ? (
                              <>
                                {/*<h5 className="font-size-17 my-3">Contenu de la formation</h5>*/}
                                <div className="mt-3" dangerouslySetInnerHTML={{__html: formationInternal?.programme}}></div>
                              </>
                            ) : (
                              <p>Aucun</p>
                            )}

                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 4 ================== */}

                    <TabPane tabId="4">
                      <div>

                        <div>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_moy_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Moyens pédagogiques</h5>
                                <p className="text-muted">{formationInternal?.educationalMethods}</p>
                              </div>
                            </li>

                            <li className="feed-item" key="_moy_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Méthodes pédagogiques</h5>
                                <p className="text-muted">{formationInternal?.educationalTechnics}</p>
                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </TabPane>


                  </TabContent>
                </Card>

                <div className="mt-3 px-4 py-4">

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Domaine de compétence de la formation : </h5>
                    <p className="text-muted mx-3 mb-0">{formationInternal.hasOwnProperty('skillDomain') && formationInternal.skillDomain.name}</p>
                  </div>

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Sanction de la formation :</h5>
                    <p className="text-muted mx-3 mb-0">{formationInternal?.sanction}</p>
                  </div>

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Modalités de la formation : </h5>
                    <p className="text-muted mx-3 mb-0">{formationInternal?.modalities}</p>
                  </div>

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Matériel nécessaire pour réaliser la formation : </h5>
                    <p className="text-muted mx-3 mb-0">{formationInternal?.necessaryMaterial}</p>
                  </div>

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Référent de la formation : </h5>
                    <p className="text-muted mx-3 mb-0">{formationInternal?.referentFirstname} {formationInternal?.referentLastname} ({formationInternal?.formationQualification})</p>
                  </div>

                </div>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(FormationInternalChoiceDetail)
