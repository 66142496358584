import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Row, UncontrolledAlert} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Data
import { countries } from "helpers/data_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount, CLIENT_ACCOUNT, UPDATE_CLIENT_ACCOUNT, CLIENT_ACCOUNT_ERROR } from "store/account/clientAccount/clientAccountSlice";
import {selectGlobalDatas, FORM_DATAS} from "store/globalData/globalDataSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import TitleBanner from "components/Common/TitleBanner";

const ClientAccountEdit = ({isRole, location}) => {

  const dispatch = useDispatch()
  const { client, error, successForm } = useSelector(selectClientAccount);
  const { localities, societyTypes } = useSelector(selectGlobalDatas);

  const [email, setEmail] = useState(client.email)
  const [phone, setPhone] = useState(client.phone)
  const [clientFunction, setClientFunction] = useState(client.clientFunction)
  const [entName, setEntName] = useState(client.entName)
  const [entAddress, setEntAddress] = useState(client.entAddress)
  const [localityId, setLocalityId] = useState(client.hasOwnProperty('locality') ? client.locality.id : "")
  const [entZipCode, setEntZipCode] = useState(client.entZipCode)
  const [entCity, setEntCity] = useState(client.entCity)
  const [entCodeNaf, setEntCodeNaf] = useState(client.entCodeNaf)
  const [entCodeSiret, setEntCodeSiret] = useState(client.entCodeSiret)
  const [entCodeTva, setEntCodeTva] = useState(client.entCodeTva)
  const [entCountry, setEntCountry] = useState(client.entCountry)
  const [entCommercialCoverage, setEntCommercialCoverage] = useState(client.entCommercialCoverage)
  const [societyStatus, setSocietyStatus] = useState(client.hasOwnProperty('societyType') ? client.societyType.id : "")
  const [contactFirstname, setContactFirstname] = useState(client.contactFirstname)
  const [contactLastname, setContactLastname] = useState(client.contactLastname)
  const [entEffectif, setEntEffectif] = useState(client.entEffectif)

  // récupération de l'id passé dans la route
  const routeId = location.state

  function handleValidSubmitClient(e, value) {

    const data = {
      clientId: routeId,
      clientFunction: clientFunction,
      phone: phone,
      entName: entName,
      entAddress: entAddress,
      locality: localityId ? `/api/localities/${localityId}` : undefined,
      societyType: societyStatus ? `/api/society_types/${societyStatus}` : undefined,
      entZipCode: entZipCode,
      entCity: entCity,
      entCodeNaf: entCodeNaf,
      entCodeSiret: entCodeSiret,
      entCodeTva: entCodeTva,
      entCountry: entCountry,
      entCommercialCoverage: entCommercialCoverage,
      entEffectif: entEffectif,
      contactFirstname: contactFirstname,
      contactLastname: contactLastname,
      email: email
    }
    dispatch(UPDATE_CLIENT_ACCOUNT(data))
  }

  function handleInvalidSubmitAdmin() {
    dispatch(CLIENT_ACCOUNT_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(FORM_DATAS())
    dispatch(CLIENT_ACCOUNT({ clientId: routeId }))
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-organisations" title="Retour à la liste des organisations" />
            <TitleBanner title="Modification d'une organisation" />

            <Row>
              <Col lg={8}>

                {error ? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-exclamation-octagon me-2"></i>{error}
                  </UncontrolledAlert>
                ) : null}

                { successForm ? (
                  <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-check me-2"></i> {successForm}
                  </UncontrolledAlert>
                ) : null }

              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <p className="text-danger mt-4">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5 pt-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitClient(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitAdmin(e, v)}
                    >

                      <Row>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations personnelles</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="firstname"
                                value={client.contactFirstname}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="lastname"
                                value={client.contactLastname}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mt-5 mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Fonction <span className="text-danger">*</span></h5>
                              <AvField
                                name="audFunction"
                                value={client.clientFunction}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setClientFunction(event.target.value)}
                                errorMessage="Veuillez renseigner une fonction"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="email"
                                value={client.email}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Téléphone <span className="text-danger">*</span></h5>
                              <AvField
                                name="phone"
                                value={client.phone}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setPhone(event.target.value)}
                                errorMessage="Veuillez renseigner un téléphone"
                                required
                              />
                            </div>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations administratives</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entName"
                                value={client.entName}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntName(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Adresse de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entAddress"
                                value={client.entAddress}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntAddress(event.target.value)}
                                errorMessage="Veuillez renseigner une adresse"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Département <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="locality"
                                onChange={event => setLocalityId(event.target.value)}
                                errorMessage="Veuillez renseigner un département"
                                helpMessage="Choisir un département dans le menu déroulant"
                              >
                                {client.hasOwnProperty('locality') && (
                                  <>
                                    <option>{client.locality.departmentCode} - {client.locality.departmentName}</option>
                                    {localities.map( locality => (
                                      <option key={locality.id} value={locality.id}>
                                        {locality.departmentCode} - {locality.departmentName}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code postal <span className="text-danger">*</span></h5>
                              <AvField
                                name="zipCode"
                                value={client.entZipCode}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntZipCode(event.target.value)}
                                errorMessage="Veuillez renseigner un code postal"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Ville <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCity"
                                value={client.entCity}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCity(event.target.value)}
                                errorMessage="Veuillez renseigner une ville"
                                required
                              />
                            </div>

                            <div className="form-group mb-5">
                              <h5 className="font-size-17 mt-5 mb-3">Pays <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="entCountry"
                                onChange={event => setEntCountry(event.target.value)}
                                errorMessage="Veuillez choisir un pays"
                                helpMessage="Choisir un pays dans le menu déroulant"
                              >
                                <option>{client.entCountry}</option>
                                {countries.map((country, idx) => <option key={idx}>{country}</option>)}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Domaine d'activité <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCommercialCoverage"
                                value={client.entCommercialCoverage}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCommercialCoverage(event.target.value)}
                                errorMessage="Veuillez renseigner un domaine d'activité"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Statut juridique de la société <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="societyType"
                                onChange={event => setSocietyStatus(event.target.value)}
                                errorMessage="Veuillez choisir un statut juridique"
                                helpMessage="Choisir un statut de société dans le menu déroulant"
                              >
                                {client.hasOwnProperty('societyType') && (
                                  <>
                                    <option>{client.societyType.name}</option>
                                    {societyTypes.map( type => (
                                      <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                  </>
                                )}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code NAF <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeNaf"
                                value={client.entCodeNaf}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeNaf(event.target.value)}
                                errorMessage="Veuillez renseigner un code NAF"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">SIRET <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeSiret"
                                value={client.entCodeSiret}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeSiret(event.target.value)}
                                errorMessage="Veuillez renseigner un code SIRET"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code TVA <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeTva"
                                value={client.entCodeTva}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeTva(event.target.value)}
                                errorMessage="Veuillez renseigner un code TVA"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Effectif de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entEffectif"
                                value={client.entEffectif}
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={event => setEntEffectif(event.target.value)}
                                errorMessage="Veuillez renseigner un effectif"
                                required
                              />
                            </div>

                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={8}>

                          {error ? (
                            <UncontrolledAlert color="danger" className="alert-dismissible fade show mt-5 mb-0" role="alert">
                              <i className="uil uil-exclamation-octagon me-2"></i>{error}
                            </UncontrolledAlert>
                          ) : null}

                          { successForm ? (
                            <UncontrolledAlert color="success" className="alert-dismissible fade show mt-5 mb-0" role="alert">
                              <i className="uil uil-check me-2"></i> {successForm}
                            </UncontrolledAlert>
                          ) : null }

                        </Col>
                      </Row>

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Modifier les informations</Button>
                        </div>

                        <Link to="/gestion-organisations" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des organisations
                        </Link>

                      </div>


                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientAccountEdit)
