import React from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"

const FormationPlanRemoveFormationModal = ({ isOpen, toggle, removeFormation }) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Voulez-vous vraiment retirer la formation du plan de développement des compétences ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <Row>
          <Col lg={12}>

            <p>Une fois l'opération effectuée, le choix d'une nouvelle formation sera à nouveau disponible depuis le tableau du plan de développement des compétences.</p>

          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mt-3">
              <button type="button" className="btn btn-danger mx-2" onClick={removeFormation}>Retirer la formation</button>
              <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
            </div>
          </Col>
        </Row>

      </ModalBody>

    </Modal>
  )
}

export default FormationPlanRemoveFormationModal

FormationPlanRemoveFormationModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
