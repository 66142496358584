import React, {useEffect, useState} from "react"
import {Redirect} from "react-router-dom"
import {Col, Container, Row, Card, CardBody} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectIntervention, INTERVENTION } from "store/intervention/interventionSlice";
import { selectCartography, ADD_CARTOGRAPHY } from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const CartographyCreate = ({ isRole, location, history }) => {

  // récupération de l'id passé dans la route
  const routeId = location.state

  const dispatch = useDispatch()
  const { intervention } = useSelector(selectIntervention);
  const { error, success } = useSelector(selectCartography);

  const [createdAt, setCreatedAt] = useState("")


  function handleValidCartographySubmit() {

    const data = {
      createdAt: createdAt,
      validate: false,
      intervention:`/api/interventions/${routeId}`,
      cartographyStatus: "/api/cartography_statuses/1",
      interventionId: routeId,
      history: history
    }
    dispatch(ADD_CARTOGRAPHY(data))
  }

  useEffect(() => {
    if (!routeId && history.push('gestion-plan-developpement-competences'))
    dispatch(INTERVENTION({ interventionId: routeId }))
  }, [dispatch, routeId, history])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>


        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn
              link={{ pathname: "/details-plan-developpement-competences", state: routeId }}
              title={`Retour aux informations de l'organisation : ${intervention.client && intervention.client.entName}`}
            />
            <TitleBanner title="Plan de développement des compétences" />
            <SuccessErrorAlert error={error} success={success} />


            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <h5 className="font-size-18 color-secondary mt-3 mb-5">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Création d'une cartographie pour l'organisation : <span className="text-primary">{intervention.client && intervention.client.entName}</span>
                    </h5>

                    <AvForm onValidSubmit={(e, v) => handleValidCartographySubmit(e, v)}>

                      <Row form>
                        <Col className="col-4 mb-3">
                          <AvField
                            name="createdAt"
                            label="Date de création"
                            type="Date"
                            errorMessage="Veuillez renseigner une date"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            validate={{ required: { value: true } }}
                            onChange={event => setCreatedAt(event.target.value)}
                          />
                        </Col>
                      </Row>

                      <button type="submit" className="btn btn-success save-event mt-3">Créer</button>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }
}

export default CartographyCreate
