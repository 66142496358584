import React from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";

// REDUX
import {selectClientEmployee} from "store/clientEmployee/clientEmployeeSlice";


const UserFicheMissionSkillUpgradeModal = ({ isOpen, toggle, skillUpgrade }) => {

  const { modal } = useSelector(selectClientEmployee);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <ModalHeader toggle={toggle}>
        <p className="modal-title mt-0">Mettre à jour l'évaluation de compétence ?</p>
        <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>

      <ModalBody>

        <AvForm onValidSubmit={skillUpgrade}>

          <Row form>

            <Col className="col-12 mb-3">
              <AvField
                name="messageUpgrade"
                label="Commentaire (facultatif)"
                type="text"
                value={modal.messageUpgrade ? modal.messageUpgrade : ""}
              />
            </Col>

            <Col className="col-6 mb-3">
              <AvField
                className="form-select"
                type="select"
                name="colorRiskUpgrade"
                label="Niveau de maîtrise"
                validate={{ required: { value: true } }}
                value={modal.colorRiskUpgrade ? modal.colorRiskUpgrade : "default"}
              >
                <option value="default">Aucun</option>
                <option value="not-concerned">Non concerné</option>
                <option value="bad">Non maîtrisé</option>
                <option value="medium">Débutant</option>
                <option value="good">Confirmé</option>
                <option value="very-good">Expert</option>
              </AvField>
            </Col>

            <AvField type="hidden" name="employeeSkillId" value={modal.employeeSkillId ? modal.employeeSkillId : null}></AvField>

          </Row>

          <Row>
            <Col>
              <div className="text-end">

                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>

                <button type="submit" className="btn btn-success save-event">Modifier</button>

              </div>
            </Col>
          </Row>
        </AvForm>

      </ModalBody>
    </Modal>
  )
}

export default UserFicheMissionSkillUpgradeModal

UserFicheMissionSkillUpgradeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
