import React, {useEffect, useState} from "react"
import {Col, Container, Row, Label} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import {Redirect, Link} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Redux
import {useDispatch, useSelector} from "react-redux";

import {
  selectFormationInternal,
  ADD_NEW_FORMATION_INTERNAL,
  FORMATION_INTERNALS,
  DELETE_FORMATION_INTERNAL
} from "store/formationInternal/formationInternalSlice";

// Components
//import SuccessErrorAlert from "components/Common/SuccessErrorAlert";


import FormationInternalColumn from "./FormationInternalColumn";
import FormationInternalModalRemove from "./FormationInternalModalRemove";


const FormationInternalAll = ({ isRole, location }) => {

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()

  const { formationInternals } = useSelector(selectFormationInternal);

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (formationData) => {
    if (!showModal) dispatch(ADD_NEW_FORMATION_INTERNAL(formationData))
    setShowModal(!showModal);
  };

  const deleteInternalFormation = id => {
    dispatch(DELETE_FORMATION_INTERNAL({ formationInternalId: id, role: "admin" }))
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(FORMATION_INTERNALS())
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <FormationInternalModalRemove isOpen={showModal} toggle={toggleModal} deleteInternalFormation={deleteInternalFormation} />

        <div className="page-content my-5">
          <Container fluid>

            <h5 className="font-size-22 color-secondary text-center mb-5">Liste des formations internes</h5>

            {/*<SuccessErrorAlert error={error} success={success} />*/}

            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">
                  <Link to="/ajout-formation-interne" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter une formation interne
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">

                <PaginationProvider pagination={paginationFactory({
                  sizePerPage: 10,
                  totalSize: formationInternals.length,
                  custom: true,
                  nextPageText: 'Suivant',
                  prePageText: 'Précédent',
                })}>

                  {({paginationProps, paginationTableProps}) => (
                    <ToolkitProvider
                      keyField="id"
                      data={formationInternals}
                      columns={FormationInternalColumn(toggleModal)}
                      bootstrap4
                      search
                      exportCSV={ { fileName: `liste des formations internes (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row>

                            <Col sm="12" md="6">
                              <Label>
                                <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                  Exporter le tableau en CSV
                                </ExportCSVButton>
                              </Label>
                            </Col>

                            <Col sm="12" md="6">
                              <Label className="float-end">{" "}
                                <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                              </Label>
                            </Col>

                          </Row>

                          <div className="table-responsive mb-4">

                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                              keyField="id"
                              headerWrapperClasses={"bg-transparent"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                            <div className="float-end">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </div>

                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default FormationInternalAll
