import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, CardTitle, Col, Container, Form, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectPrestataire, INTERVENANT, UPDATE_INTERVENANT, PRESTATAIRE_ERROR, ADD_AVATAR_INTERVENANT, UPDATE_AVATAR_INTERVENANT, DELETE_AVATAR_INTERVENANT} from "store/prestataire/prestataireSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import IntervenantDeleteAvatarModal from "./IntervenantDeleteAvatarModal";

const IntervenantEdit = ({isRole, location}) => {

  // récupération de l'id passé dans la route
  const intervenantId = location.state

  const dispatch = useDispatch()
  const {intervenant, successForm, successAvatar, error} = useSelector(selectPrestataire);

  const [showModalAvatarRemove, setShowModalAvatarRemove] = useState(false)

  const [intFirstname, setIntFirstname] = useState("")
  const [intLastname, setIntLastname] = useState("")
  const [qualification, setQualification] = useState(intervenant.qualification)
  const [intPhone, setIntPhone] = useState(intervenant.intPhone)
  const [intEmail, setIntEmail] = useState(intervenant.intEmail)

  const nameIcon = intFirstname && intLastname ? intFirstname.charAt(0).toUpperCase() + intLastname.charAt(0).toUpperCase() : ''

  const [selectedFiles, setselectedFiles] = useState([])

  const toggleModalAvatarRemove = () => {
    setShowModalAvatarRemove(!showModalAvatarRemove);
  };

  const deleteAvatarImage = () => {

    if (intervenant.hasOwnProperty('image')) {
      dispatch(DELETE_AVATAR_INTERVENANT({imageId: intervenant.image.id, intervenantId: intervenantId}))
      setselectedFiles([])
    }

    setShowModalAvatarRemove(!showModalAvatarRemove);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleValidSubmitIntervenant() {

    const data = {
      intFirstname: intFirstname,
      intLastname: intLastname,
      qualification: qualification,
      intPhone: intPhone,
      intEmail: intEmail,
      intervenantId: intervenantId
    }
    dispatch(UPDATE_INTERVENANT(data))
  }

  function handleInvalidSubmitIntervenant() {
    dispatch(PRESTATAIRE_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  const handleAddAvatar = () => {

    if (selectedFiles.length === 1) {
      if (intervenant.hasOwnProperty('image')) {
        dispatch(DELETE_AVATAR_INTERVENANT({imageId: intervenant.image.id, intervenantId: intervenantId}))

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(UPDATE_AVATAR_INTERVENANT({file: formData, intervenantId: intervenantId}))

      } else {

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(ADD_AVATAR_INTERVENANT({file: formData, intervenantId: intervenantId}))
      }
    }

    setselectedFiles([])
  }

  useEffect(() => {
    dispatch(INTERVENANT({intervenantId: intervenantId}))
    setIntFirstname(intervenant.intFirstname)
    setIntLastname(intervenant.intLastname)
    setQualification(intervenant.qualification)
    setIntPhone(intervenant.intPhone)
    setIntEmail(intervenant.intEmail)
    setselectedFiles([])
  }, [dispatch, intervenantId, intervenant.intFirstname, intervenant.intLastname, intervenant.qualification, intervenant.intPhone, intervenant.intEmail])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <IntervenantDeleteAvatarModal isOpen={showModalAvatarRemove} toggle={toggleModalAvatarRemove} deleteAvatarImage={deleteAvatarImage}/>

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link={{pathname: "/gestion-intervenants", state: intervenant.hasOwnProperty('prestataire') && intervenant.prestataire.id}} title="Retour au prestataire" />
            <TitleBanner title="Modification d'un intervenant" />
            <SuccessErrorAlert error={error} success={successForm} />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">

                    <Row>
                      <Col md={6}>


                        <AvForm
                          onValidSubmit={(e, v) => handleValidSubmitIntervenant(e, v)}
                          onInvalidSubmit={(e, v) => handleInvalidSubmitIntervenant(e, v)}
                        >

                          <Row>

                            <Col md={12}>
                              <div className="mx-5">

                                <h5 className="mb-5 mt-5 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations de l'intervenant</h5>

                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Prénom de l'intervenant <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="intFirstname"
                                    value={intFirstname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setIntFirstname(event.target.value)}
                                    errorMessage="Veuillez renseigner un prénom"
                                    required
                                  />
                                </div>

                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Nom de l'intervenant <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="intLastname"
                                    value={intLastname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setIntLastname(event.target.value)}
                                    errorMessage="Veuillez renseigner un nom"
                                    required
                                  />
                                </div>

                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Qualification de l'intervenant <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="qualification"
                                    value={qualification}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setQualification(event.target.value)}
                                    errorMessage="Veuillez renseigner une qualification"
                                    required
                                  />
                                </div>

                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Email de l'intervenant <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="intEmail"
                                    value={intEmail}
                                    className="form-control"
                                    placeholder=""
                                    type="email"
                                    onChange={event => setIntEmail(event.target.value)}
                                    errorMessage="Veuillez renseigner un email"
                                    required
                                  />
                                </div>

                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Téléphone de l'intervenant <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="intPhone"
                                    value={intPhone}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setIntPhone(event.target.value)}
                                    errorMessage="Veuillez renseigner un téléphone"
                                    required
                                  />
                                </div>

                              </div>
                            </Col>

                          </Row>

                          <SuccessErrorAlert error={error} success={successForm} />

                          <div className="d-flex align-items-center mt-5 mb-3">

                            <div className="mx-5">
                              <Button type="submit" color="success" className="w-md">Modifier</Button>
                            </div>

                            <Link to={{pathname: "/gestion-intervenants", state: intervenant.hasOwnProperty('prestataire') && intervenant.prestataire.id}} className="btn btn-primary waves-effect' +
                                ' waves-light">
                              Retour au prestataire
                            </Link>

                          </div>

                        </AvForm>


                      </Col>

                      <Col md={6}>

                        <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier l'avatar</h5>

                        <SuccessErrorAlert success={successAvatar} />

                        <CardTitle className="h4">Profil de l'intervenant</CardTitle>

                        <div className="avatar-sm me-4 my-3">

                          {intervenant.hasOwnProperty('image') ? (
                            <img
                              className="avatar-title rounded-circle bg-white"
                              src={process.env.REACT_APP_URL + "/media/" + intervenant.image.filePath}
                              alt="Header Avatar"
                            />
                          ) : (
                            <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-15`}>
                                      {nameIcon}
                                    </span>
                          )}

                        </div>

                        <p className="card-title-desc">
                          {intervenant.hasOwnProperty('image') ? 'Choisissez une nouvelle image de profil' : 'Choisissez une image de profil'}
                        </p>

                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                            maxFiles={1}
                            accept="image/*"
                          >
                            {({getRootProps, getInputProps}) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                  </div>
                                  <h5 className="mx-3">Glisser une image ici ou cliquer pour télécharger</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link to="#" className="text-muted font-weight-bold">
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>

                          <div className="text-center d-flex justify-content-center mt-4">
                            <button
                              type="button"
                              className="btn btn-success waves-effect waves-light mx-2"
                              onClick={handleAddAvatar}
                            >
                              {intervenant.hasOwnProperty('image') ? "Modifier l'image de profil" : "Enregistrer"}
                            </button>

                            {intervenant.hasOwnProperty('image') && (
                              <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light mx-2"
                                onClick={toggleModalAvatarRemove}
                              >
                                Supprimer l'image de profil
                              </button>
                            )}
                          </div>

                        </Form>

                      </Col>

                    </Row>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(IntervenantEdit)
