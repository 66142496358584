import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  ADVERTS,
  ADVERT,
  GET_ADVERTS,
  GET_ADVERT,
  ADD_ADVERT,
  UPDATE_ADVERT,
  DELETE_ADVERT,
  ADD_ADVERT_IMAGE,
  DELETE_ADVERT_IMAGE,
  ADVERT_SUCCESS,
  ADVERT_ERROR,
  ADVERT_IMAGE_SUCCESS,
  ADVERT_IMAGE_ERROR
} from "./advertSlice";

import { getAdverts, getAdvert, postAdvert, putAdvert, deleteAdvert } from "helpers/request_helper"
import {deleteMediaObject, editAdvertImage, postMediaObject} from "../../helpers/request_helper";

function* viewAdverts() {
  try {
    const response = yield call(getAdverts)
    yield put(GET_ADVERTS(response['hydra:member']))
    yield put(ADVERT_SUCCESS(""))
  } catch (error) {
    yield put(ADVERT_ERROR(error.message))
  }
}

function* viewAdvert({payload: { advertId }}) {
  try {
    const response = yield call(getAdvert, advertId);
    yield put(GET_ADVERT(response))
    yield put(  ADVERT_SUCCESS(response));
  } catch (error) {
    yield put(  ADVERT_ERROR(error.message));
  }
}

function* addNewAdvert({ payload: { message, link } }) {
  try {
    const response = yield call(postAdvert, {
      message,
      link,
      isActive: false
    })
    yield put(ADD_ADVERT(response))

    yield put(ADVERT_SUCCESS('La publicité a été ajoutée'))
  } catch (error) {
    yield put(ADVERT_ERROR(error))
  }
}

function* editAdvert({ payload: { advertId, message, link, isActive } }) {
  try {
    yield call(putAdvert, { advertId, message, link, isActive})

    yield put(ADVERTS())
    yield put(ADVERT_SUCCESS('La publicité a bien été modifiée'))
  } catch (error) {
    yield put(ADVERT_ERROR(error))
  }
}


function* removeAdvert({ payload: { advertId } }) {
  try {
    yield call(deleteAdvert, advertId)
    yield put(ADVERT_SUCCESS(`Suppression de la publicité avec succès !!`))
  } catch (error) {
    yield put(ADVERT_ERROR("Une erreur s'est produite !"))
  }
}

function* addAdvertImage({ payload: { file, advertId } }) {
  try {
    const image = yield call(postMediaObject, file)

    yield call(editAdvertImage, { image: `/api/media_objects/${image.id}`, advertId })
    yield put(ADVERTS())
    yield put(ADVERT_IMAGE_SUCCESS('Votre image a bien été modifiée'))
  } catch (error) {
    yield put(ADVERT_IMAGE_ERROR(error))
  }
}

function* deleteAdvertImage({ payload: { imageId, advertId } }) {
  try {
    yield call(editAdvertImage, { image: null, advertId })
    yield put(ADVERTS())
    yield call(deleteMediaObject, imageId)
    yield put(ADVERT_IMAGE_SUCCESS('Votre image a bien été supprimée'))
  } catch (error) {
    yield put(ADVERT_IMAGE_ERROR(error))
  }
}


export function* watchProfile() {
  yield takeEvery(ADVERTS.type, viewAdverts)
  yield takeEvery(ADVERT.type, viewAdvert)
  yield takeEvery(ADD_ADVERT.type, addNewAdvert)
  yield takeEvery(UPDATE_ADVERT.type, editAdvert)
  yield takeEvery(DELETE_ADVERT.type, removeAdvert)
  yield takeEvery(ADD_ADVERT_IMAGE.type, addAdvertImage)
  yield takeEvery(DELETE_ADVERT_IMAGE.type, deleteAdvertImage)
}

export default function* AdvertSaga() {
  yield all([fork(watchProfile)])
}
