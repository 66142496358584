import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    layoutType: "vertical",
    layoutWidth: "fluid",
    leftSideBarTheme: "dark",
    leftSideBarType: "default",
    topbarTheme: "colored",  // light
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
}

const layoutSlice = createSlice({
    name: "layout",
    initialState,

    reducers: {

        /* ========== LAYOUT ========= */

        CHANGE_LAYOUT (state, action) {
            state.layoutType = action.payload  // payload: layout
        },
        CHANGE_LAYOUT_WIDTH (state, action) {
            state.layoutWidth = action.payload  // payload: width
        },
        CHANGE_SIDEBAR_THEME (state, action) {
            state.leftSideBarTheme = action.payload  // payload: theme
        },

        CHANGE_SIDEBAR_TYPE (state, action) {
            state.leftSideBarType = action.payload.sidebarType  // payload: { sidebarType, isMobile }
        },

        CHANGE_TOPBAR_THEME (state, action) {
            state.topbarTheme = action.payload  // payload: topbarTheme
        },

        SHOW_SIDEBAR (state, action) {
            state.showSidebar = action.payload  // payload: isopen
        },

        TOGGLE_LEFT_MENU (state, action) {
            state.leftMenu = action.payload  //  payload: isopen
        },

        /* ========== RIGHT SIDEBAR ========= */

        SHOW_RIGHT_SIDEBAR (state, action) {
            state.showRightSidebar = action.payload  // payload: isopen
        },

        /* ========== PRELOADER ========= */

        CHANGE_PRELOADER (state, action) {
            state.isPreloader = action.payload  // payload: layout
        }
}
});

const { reducer, actions } = layoutSlice;

export const {
    CHANGE_LAYOUT,
    CHANGE_PRELOADER,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME,
    SHOW_RIGHT_SIDEBAR,
    SHOW_SIDEBAR,
    TOGGLE_LEFT_MENU
} = actions;

export default reducer;

export const selectLayout = (state) => state.layout;
