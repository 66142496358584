import React, {useState} from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"

// Redux
import {useSelector} from "react-redux"
import {selectFormation} from "store/formation/formationSlice"

const PrestataireFormationModalCreateDate = ({ isOpen, toggle, createFormationDate}) => {

  const { formation } = useSelector(selectFormation)

  const [formationStart, setFormationStart] = useState('')
  const [formationEnd, setFormationEnd] = useState('')
  const [place, setPlace] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <ModalBody>

        <h5 className="modal-title mt-0 text-center mb-5">Ajouter une date de formation</h5>

        <AvForm onValidSubmit={() => createFormationDate(formation.id, formationStart, formationEnd, place)}>

          <Row form>

            <Col className="col-12 mb-4">
              <h5 className="font-size-17 mt-4 mb-3">Date de début</h5>
              <AvField
                name="formationStart"
                type="datetime-local"
                errorMessage="Veuillez renseigner une date de début"
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                validate={{ required: { value: true } }}
                onChange={event => setFormationStart(event.target.value)}
              />
            </Col>

            <Col className="col-12 mb-4">
              <h5 className="font-size-17 mt-4 mb-3">Date de fin</h5>
              <AvField
                name="formationEnd"
                type="datetime-local"
                errorMessage="Veuillez renseigner une date de fin"
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                validate={{ required: { value: true } }}
                onChange={event => setFormationEnd(event.target.value)}
              />
            </Col>


            <Col className="col-12 mb-4">
              <h5 className="font-size-17 mt-4 mb-3">Lieu</h5>
              <AvField
                name="place"
                value=""
                className="form-control"
                placeholder=""
                type="text"
                validate={{ required: { value: true } }}
                onChange={event => setPlace(event.target.value)}
                errorMessage="Veuillez renseigner un lieu"
              />
            </Col>

          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
                <button type="submit" className="btn btn-success save-event">Ajouter une date</button>
              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>
    </Modal>
  )
}

export default PrestataireFormationModalCreateDate

PrestataireFormationModalCreateDate.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
