import React from "react"
import { Link } from "react-router-dom"
import {Badge} from "reactstrap";

const FormationColumn = toggleModal => [

  {
    dataField: "title",
    text: "Nom de la formation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <Link to={{ pathname: "/details-formation", state: {formationId: row.id } }}>
          <p className="mb-0 color-primary">{cellContent}</p>
        </Link>
      )
    },
    csvFormatter: (cellContent, row) => `${row.title}`,

    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "prestataires",
    text: "Prestataire",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.hasOwnProperty("prestataire")) {
        return (
          <Link to={{ pathname: "/details-prestataire", state: row.prestataire.id }} key={row.prestataire.id}>
            <p className="mb-0 color-primary">{row.prestataire.name}</p>
          </Link>
        )
      } else {
        return "Pas de prestataire"
      }
    },
    csvFormatter: (cellContent, row) => {
        return `${row.prestataire.name}`
    },
    sortValue: (cell, row) => {
      if (row.hasOwnProperty('prestataire')) {
        return row.prestataire.name
      } else {
        return
      }
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "formationStatus",
    text: "Statut",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.formationStatus.id === 1) {
        return <Badge className="badge bg-pill bg-soft-success" color="success" pill>{row.formationStatus.name}</Badge>
      } else if (row.formationStatus.id === 2) {
        return <Badge className="badge bg-pill bg-soft-warning" color="warning" pill>{row.formationStatus.name}</Badge>
      } else {
        return <Badge className="badge bg-pill bg-soft-danger" color="danger" pill>{row.formationStatus.name}</Badge>
      }
    },
    csvFormatter: (cellContent, row) => {
      return (row.activeFormation) ? "Actif" : "Suspendu"
    },
    sortValue: (cell, row) => {
      if (row.formationStatus.id === 1 || row.formationStatus.id === 2 || row.formationStatus.id === 3 ) {
        return row.formationStatus.name
      } else {
        return
      }
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <div className="flex justify-content-center" style={{ minWidth: "130px"}}>
        <Link to={{ pathname: "/details-formation", state: {formationId: row.id, url: 'formationGestion' }}} className="btn btn-link waves-effect px-2 text-success">
          <i className="uil uil-eye font-size-20"></i>
        </Link>

        <Link to={{ pathname: "modifier-formation", state: row.id }} className="btn btn-link waves-effect px-2 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>

        <button
          type="button"
          className="btn btn-link waves-effect text-danger"
          onClick={() => toggleModal(row)}
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-trash-alt font-size-18"></i>
        </button>
      </div>
    ),
    csvExport: false
  }

]

export default FormationColumn
