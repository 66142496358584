import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, CardTitle, Col, Container, Form, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectPrestataire, ADD_INTERVENANT, PRESTATAIRE_ERROR } from "store/prestataire/prestataireSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const PrestataireIntervenantAdd = ({history, isRole, location}) => {

  // récupération de l'id passé dans la route
  const prestataireId = location.state

  const dispatch = useDispatch()
  const { error } = useSelector(selectPrestataire);

  const [intFirstname, setIntFirstname] = useState("")
  const [intLastname, setIntLastname] = useState("")
  const [qualification, setQualification] = useState("")
  const [intPhone, setIntPhone] = useState("")
  const [intEmail, setIntEmail] = useState("")

  const [selectedFiles, setselectedFiles] = useState([])

  const removeAvatarImage = () => {
    setselectedFiles([])
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleValidSubmitIntervenant() {

    const data = {
      intFirstname: intFirstname,
      intLastname: intLastname,
      qualification: qualification,
      intPhone: intPhone,
      intEmail: intEmail,
      prestataire: `/api/prestataires/${prestataireId}`,
      prestataireId: prestataireId,
      imageData: selectedFiles,
      role: "prestataire",
      history: history
    }
    dispatch(ADD_INTERVENANT(data))
  }

  function handleInvalidSubmitIntervenant() {
    dispatch(PRESTATAIRE_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    setselectedFiles([])
  }, [])


  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/prestataire-intervenants" title="Retour à la gestion des intervenants" />
            <TitleBanner title="Ajout d'un intervenant" />
            <SuccessErrorAlert error={error} />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitIntervenant(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitIntervenant(e, v)}
                    >

                      <Row>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-4 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations de l'intervenant</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom de l'intervenant <span className="text-danger">*</span></h5>
                              <AvField
                                name="intFirstname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setIntFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom de l'intervenant <span className="text-danger">*</span></h5>
                              <AvField
                                name="intLastname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setIntLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Qualification de l'intervenant <span className="text-danger">*</span></h5>
                              <AvField
                                name="qualification"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setQualification(event.target.value)}
                                errorMessage="Veuillez renseigner une qualification"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email de l'intervenant <span className="text-danger">*</span></h5>
                              <AvField
                                name="intEmail"
                                value={intEmail}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setIntEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Téléphone de l'intervenant <span className="text-danger">*</span></h5>
                              <AvField
                                name="intPhone"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setIntPhone(event.target.value)}
                                errorMessage="Veuillez renseigner un téléphone"
                                required
                              />
                            </div>

                          </div>
                        </Col>

                        <Col md={6}>

                          <h5 className="mb-4 mt-4 mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier l'avatar</h5>

                          <CardTitle className="h4">Choisir une image de profil pour l'intervenant</CardTitle>

                          <p className="card-title-desc mt-3">(L'image de profil peut être ajoutée ou mise à jour ultérieurement depuis le menu édition d'un intervenant)</p>

                          <Form>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                              maxFiles={1}
                              accept="image/*"
                            >
                              {({getRootProps, getInputProps}) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted uil uil-cloud-upload"/>
                                    </div>
                                    <h5 className="mx-3">Glisser une image ici ou cliquer pour télécharger</h5>
                                  </div>
                                </div>
                              )}
                            </Dropzone>

                            <div className="dropzone-previews mt-3" id="file-previews">
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          {selectedFiles.length !== 0 && (
                                            <button
                                              type="button"
                                              className="btn btn-danger waves-effect waves-light mx-2"
                                              onClick={removeAvatarImage}
                                            >
                                              <i className="uil uil-trash-alt font-size-18"></i>
                                            </button>
                                          )}
                                        </Col>
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>

                          </Form>

                        </Col>

                      </Row>

                      <SuccessErrorAlert error={error} />

                      <div className="d-flex align-items-center mt-3 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Ajouter</Button>
                        </div>

                        <Link to="/prestataire-intervenants" className="btn btn-primary waves-effect waves-light">
                          Retour à la gestion des intervenants
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(PrestataireIntervenantAdd)
