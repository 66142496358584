import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap"
import {Link, Redirect} from "react-router-dom"
import {map, capitalize} from "lodash";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount, CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import {AvForm} from "availity-reactstrap-validation";

const ClientFicheMission = ({isRole, location}) => {

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()
  const { client, success, error } = useSelector(selectClientAccount);

  const clientEmployeesSortByName = employees => {
    if (employees) {
      const arrayEmployees = [...employees]

      function SortArray(x, y){
        return x.user.lastname.localeCompare(y.user.lastname);
      }

      return arrayEmployees.sort(SortArray);
    }
  }

  /* ============ start Searchbar Filter =================  */

  const handleSearchSubmit = () => {}

  const [query, setQuery] = useState('');

  const renderEmployeeBySearch = (employee, queryText) => {

    const formattedQuery = queryText.toUpperCase();

    const firstnameUpper = employee.user.firstname.toUpperCase();
    const lastnameUpper = employee.user.lastname.toUpperCase();
    const emailUpper = employee.user.email.toUpperCase();
    const poleUpper = employee.pole.toUpperCase();
    const serviceUpper = employee.service.toUpperCase();
    const jobUpper = employee.job.toUpperCase();

    if (
      firstnameUpper.includes(formattedQuery) ||
      lastnameUpper.includes(formattedQuery) ||
      emailUpper.includes(formattedQuery) ||
      poleUpper.includes(formattedQuery) ||
      serviceUpper.includes(formattedQuery) ||
      jobUpper.includes(formattedQuery)
    ) {
      return (
        <div className="mt-3 mb-1" key={employee.id}>
          <Link to={{pathname: "/fiches-mission-detail", state: employee.id}} className="btn btn-grey-light waves-effect waves-light w-100 d-flex justify-content-between">
            <span className="align-items-start">{capitalize(employee.user.firstname)} {capitalize(employee.user.lastname)}</span>
            <i className="uil uil-eye font-size-20 text-success"></i>
          </Link>
        </div>
      )
    }
  }

  /* ============ end Searchbar Filter =================  */

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT({ clientId: clientId }))
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <SuccessErrorAlert error={error} success={success} />

            <h5 className="font-size-22 color-secondary text-center mb-5">Fiches de mission</h5>

            <Row className="justify-content-end">
              <Col md={4}>
                <AvForm onValidSubmit={(e, v) => handleSearchSubmit(e, v)}>
                  <Row>
                    <Col className="col-12 mb-2">
                      <div className="form-inline mb-3">
                        <div className="search-box ml-2">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control rounded border-0"
                              name="search"
                              onChange={ queryText => {
                                setQuery(queryText.target.value)
                              }}
                              placeholder="Rechercher ..."
                            />
                            <i className="mdi mdi-magnify search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={12}>

                  {map(clientEmployeesSortByName(client.clientEmployees), employee => (
                    renderEmployeeBySearch(employee, query)
                  ))}

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default ClientFicheMission
