import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectCartography} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const CartographyArchivedModal = ({ isOpen, toggle, archivedCartography}) => {

  const { cartography } = useSelector(selectCartography);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmez-vous la suppression de la cartographie du {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")} ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        { cartography.hasOwnProperty('intervention') &&
          <p className="text-danger">Attention, Si un PDC est rattaché à cette cartographie, celui-ci sera également supprimé ! </p>
        }
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => archivedCartography(cartography.intervention.id)}>Confirmer la suppression</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default CartographyArchivedModal

CartographyArchivedModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
