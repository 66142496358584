import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectCartography} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const CartographyRemoveArchiveModal = ({ isOpen, toggle, removeArchiveCartography}) => {

  const { cartography } = useSelector(selectCartography);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Enlever la suppression de la cartographie ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        { cartography.hasOwnProperty('intervention') && (
          <>
            <p>Cette cartographie a été supprimée par l'organisation.</p>
            <p>Confirmez-vous la remise en place de la cartographie du {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")} ? </p>
          </>
        )}
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => removeArchiveCartography(cartography.intervention.id)}>Confirmer</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default CartographyRemoveArchiveModal

CartographyRemoveArchiveModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
