import React, {useEffect, useRef, useState} from "react"
import {Col, Container, Row, Card, Nav, NavItem, NavLink, TabContent, TabPane, Label} from "reactstrap"
import {Redirect, Link} from "react-router-dom"
import classnames from "classnames";
import DotLoader from 'react-spinners/DotLoader'

import "./datatable.scss"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {
  selectCartography,
  CARTOGRAPHY,
  UPDATE_CARTOGRAPHY,
  ADD_CARTOGRAPHY_MODAL_DATA,
  UPDATE_CARTOGRAPHY_VALIDATE,
  UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE,
  DELETE_CARTOGRAPHY
} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import { selectClientAccount } from "store/account/clientAccount/clientAccountSlice";
import {UPDATE_SKILL_REQUIRED_LEVEL} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import CartographyTableCreateColumn from 'pages/SkillsDevelopmentPlan/Cartography/CartographyTableColumn/CartographyTableCreateColumn'
import CartographyTableReadColumn from 'pages/SkillsDevelopmentPlan/Cartography/CartographyTableColumn/CartographyTableReadColumn'
import CartographyModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyModal"
import CartographyValidateModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyValidateModal";
import CartographyDevalidateModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyDevalidateModal";
import CartographyDeleteModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyDeleteModal";
import CartographyRequiredLevelModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyRequiredLevelModal";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';
import {isEmpty} from "lodash";

const Cartography = ({isRole, location, history}) => {

  const { SearchBar } = Search;

  const dispatch = useDispatch()

  const [cartographyWidth, setCartographyWidth] = useState(0)

  /* ========== Btn Scroll =========================== */
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  /* ================================================= */

  const { cartography, success, isLoading } = useSelector(selectCartography);
  const clientAccount = useSelector(selectClientAccount);

  const [showModal, setShowModal] = useState(false)
  const [showModalValidate, setShowModalValidate] = useState(false)
  const [showModalDevalidate, setShowModalDevalidate] = useState(false)
  const [showModalDeleteCartography, setShowModalDeleteCartography] = useState(false)
  const [showModalRequiredLevel, setShowModalRequiredLevel] = useState(false)

  const [activeTab, setActiveTab] = useState("1");

  const clientToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // récupération de l'id passé dans la route
  const routeId = location.state

  const toggleModal = (employeeActionId, employeeFirstname, employeeLastname, message, colorRisk) => {

    if (!showModal) dispatch(ADD_CARTOGRAPHY_MODAL_DATA({
      employeeActionId: employeeActionId,
      employeeFirstname: employeeFirstname,
      employeeLastname: employeeLastname,
      message: message,
      colorRisk: colorRisk
    }))

    setShowModal(!showModal);
  };

  const toggleModalValidate = () => {
    setShowModalValidate(!showModalValidate);
  };

  const toggleModalDevalidate = () => {
    setShowModalDevalidate(!showModalDevalidate);
  };

  const toggleModalDeleteCartography = () => {
    setShowModalDeleteCartography(!showModalDeleteCartography);
  };

  const toggleModalRequiredLevel = (skillData) => {
    if (!showModal) dispatch(ADD_CARTOGRAPHY_MODAL_DATA(skillData))
    setShowModalRequiredLevel(!showModalRequiredLevel);
  };

  const validateCartography = () => {

    if (cartography.hasOwnProperty('trainingPlans') && !isEmpty(cartography.trainingPlans)) {

      // Si le PDC existe déjà, mise à jour des lignes de demande de formation
      dispatch(UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE({
        validate: true,
        cartographyId: cartography.id,
        interventionId: cartography.intervention.id,
        history: history,
        clientName: cartography.intervention.client.entName,
        trainingPlanId: cartography.trainingPlans[0].id,
        trainingPlanActions: cartography.trainingPlans[0].trainingPlanActions,
        clientId: cartography.intervention.client.id,
        adminRole: true
      }))

    } else {
      dispatch(UPDATE_CARTOGRAPHY_VALIDATE({
        validate: true,
        cartographyId: cartography.id,
        interventionId: cartography.intervention.id,
        history: history,
        clientId: cartography.intervention.client.id,
        adminRole: true,
      }))
    }

    setShowModalValidate(!showModalValidate);
  };

  const devalidateCartography = () => {

    dispatch(UPDATE_CARTOGRAPHY_VALIDATE({
      validate: false,
      cartographyId: cartography.id,
      interventionId: cartography.intervention.id,
      adminRole: true,
      clientId: cartography.intervention.client.id,
      clientAccountId: cartography.intervention.client.user.id,
      history: history
    }))

    setShowModalDevalidate(!showModalDevalidate);
  };

  const updateCartography = (event, data) => {

    dispatch(UPDATE_CARTOGRAPHY({
      colorRisk: data.colorRisk,
      message: data.message,
      employeeActionId: data.employeeActionId,
      cartographyId: cartography.id,
    }))
    setShowModal(!showModal);
  };

  const deleteCartography = () => {
    dispatch(DELETE_CARTOGRAPHY({ cartographyId: cartography.id, clientId: cartography.intervention.client.id }))

    setShowModalDeleteCartography(!showModalDeleteCartography);
  }

  const updateRequiredLevel = (event, data) => {
    dispatch(UPDATE_SKILL_REQUIRED_LEVEL({
      requiredLevel: parseInt(data.requiredLevel),
      skillId: data.skillId,
      cartographyId: cartography.id,
    }))

    setShowModalRequiredLevel(!showModalRequiredLevel);
  };

  const paginateOptions = {
    sizePerPage: 8,
    totalSize: cartography.skills ? cartography.skills.length : 0,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }

  useEffect(() => {
    dispatch(CARTOGRAPHY({ cartographyId: routeId }))
    if (ref.current) setCartographyWidth(ref.current.scrollWidth)
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        {!cartography.validate && <CartographyModal isOpen={showModal} toggle={toggleModal} updateCartography={updateCartography} />}
        <CartographyValidateModal isOpen={showModalValidate} toggle={toggleModalValidate} validateCartography={validateCartography} />
        <CartographyDevalidateModal isOpen={showModalDevalidate} toggle={toggleModalDevalidate} devalidateCartography={devalidateCartography} />
        <CartographyDeleteModal isOpen={showModalDeleteCartography} toggle={toggleModalDeleteCartography} deleteCartography={deleteCartography} />
        <CartographyRequiredLevelModal isOpen={showModalRequiredLevel} toggle={toggleModalRequiredLevel} updateRequiredLevel={updateRequiredLevel} />

        <div className="page-content my-5">
          <Container fluid>

            { cartography.hasOwnProperty('intervention') &&
            <GoBackBtn link={{ pathname: "/details-organisation", state: cartography.intervention.client.id }} title="Retour aux détails de l'intervention" />
            }

            <TitleBanner title="Cartographie" />
            <SuccessErrorAlert success={success} />


            <Row className="mb-5">
              <Col md="12">

                <Card className="mb-0">

                  {/* =========== DEBUT ONGLETS ================== */}

                  <Nav tabs className="nav-tabs-custom nav-justified">

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "1"})}
                        onClick={() => clientToggle("1") }
                      >
                        <span className="d-none d-sm-block">Organisation</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "2"})}
                        onClick={ () => clientToggle("2") }
                      >
                        <span className="d-none d-sm-block">Contact</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "3"})}
                        onClick={ () => clientToggle("3") }
                      >
                        <span className="d-none d-sm-block">Adresse</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "4"})}
                        onClick={ () => clientToggle("4") }
                      >
                        <span className="d-none d-sm-block">Informations complémentaires</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  {/* =========== FIN ONGLETS ================== */}

                  <TabContent activeTab={activeTab} className="p-4">

                    {/* =========== TAB 1 ================== */}

                    <TabPane tabId="1">
                      <div>

                        <div className="text-center">
                          <h5 className="mt-3 mb-1">{clientAccount.client.entName}</h5>
                          <p className="text-muted">{clientAccount.client.entCommercialCoverage}</p>

                          {clientAccount.client.hasOwnProperty('locality') && (
                            <p className="text-muted">
                              {clientAccount.client.locality.region.name} ({clientAccount.client.locality.departmentName})
                            </p>
                          )}
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 2 ================== */}

                    <TabPane tabId="2">
                      <div className="text-center mt-4">

                        <div>
                          <p className="mb-1">Représentant :</p>
                          <h5 className="font-size-17">{clientAccount.client.contactFirstname} {clientAccount.client.contactLastname}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Fonction :</p>
                          <h5 className="font-size-17">{clientAccount.client.clientFunction}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Téléphone :</p>
                          <h5 className="font-size-17">{clientAccount.client.phone}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Email :</p>
                          <h5 className="font-size-17">{clientAccount.client.email}</h5>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 3 ================== */}

                    <TabPane tabId="3">
                      <div>

                        <div className="text-center mt-3 mx-3">
                          <p className="mb-1">Adresse :</p>
                          <h5 className="font-size-17">{clientAccount.client.entAddress} {clientAccount.client.entZipCode} {clientAccount.client.entCity}</h5>
                        </div>

                        <div className="text-center mt-3 mx-3">
                          <p className="mb-1">Pays :</p>
                          <h5 className="font-size-17">{clientAccount.client.entCountry}</h5>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 4 ================== */}

                    <TabPane tabId="4">
                      <div className="text-center">

                        <div className="mt-3 mx-3">
                          <p className="mb-1">Effectif :</p>
                          <h5 className="font-size-17">{clientAccount.client.entEffectif}</h5>
                        </div>

                        {clientAccount.client.hasOwnProperty('societyType') && (
                          <div className="mt-3 mx-3">
                            <p className="mb-1">Statut de l'entreprise :</p>
                            <h5 className="font-size-17">{clientAccount.client.societyType.name}</h5>
                          </div>
                        )}

                        <div className="mt-3 mx-3">
                          <p className="mb-1">Code NAF :</p>
                          <h5 className="font-size-17">{clientAccount.client.entCodeNaf}</h5>
                        </div>

                        <div className="mt-3 mx-3">
                          <p className="mb-1">Code SIRET :</p>
                          <h5 className="font-size-17">{clientAccount.client.entCodeSiret}</h5>
                        </div>

                        <div className="mt-3 mx-3">
                          <p className="mb-1">Code TVA :</p>
                          <h5 className="font-size-17">{clientAccount.client.entCodeTva}</h5>
                        </div>

                      </div>
                    </TabPane>

                  </TabContent>
                </Card>

              </Col>
            </Row>

            {isLoading ? (
              <Row className="justify-content-center">
                <DotLoader size={40} color="#597887" />
              </Row>
            ) : (
              <>
                { cartography.validate
                  ? (
                    <>

                      {/* Mode lecture non modifiable (après validation) */}

                      <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Cartographie</h5>

                      <p className="mb-4">Date de création: {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

                      <Row>
                        <Col sm={12}>
                          <p className="text-danger">La cartographie a été validé par l'organisation et ne peut plus être modifiée</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <button  onClick={() => toggleModalDevalidate()} className="btn btn-danger waves-effect waves-light mx-2 mb-5">
                            <i className="uil uil-corner-up-right-alt me-1"></i>Enlever la validation de l'organisation
                          </button>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12} className="mt-2 mb-4">
                          <span className="text-primary">Légende :</span>
                          <span className="badge bg-risk-very-good mx-2">Expert</span>
                          <span className="badge bg-risk-good mx-2">Confirmé</span>
                          <span className="badge bg-risk-medium mx-2">Débutant</span>
                          <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                          <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md="12 position-relative">

                          <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                            {({paginationProps, paginationTableProps}) => (
                              <ToolkitProvider
                                keyField="id"
                                data={cartography.skills || []}
                                columns={CartographyTableReadColumn(cartography)}
                                search
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <React.Fragment>

                                    <Row>
                                      <Col sm="12" md="6">
                                        { (cartography.validate) && (
                                          <Link to={{pathname:`/cartographie-export-pdf`, state: {cartographyId: cartography.id, cartographyWidth: cartographyWidth } }} className="btn btn-primary waves-effect waves-light me-1
    ">
                                            Exporter en PDF <i className="uil uil-download-alt ms-2"></i>
                                          </Link>
                                        )}
                                      </Col>

                                      <Col sm="12" md="6">
                                        <Label className="float-end">{" "}
                                          <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                        </Label>
                                      </Col>
                                    </Row>

                                    <div className="table-responsive mb-4" ref={ref}>

                                      <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                      <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        hover={true}
                                        classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                        headerWrapperClasses={"bg-transparent"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                      <div className="float-end">
                                        <PaginationListStandalone {...paginationProps} />
                                      </div>
                                    </div>

                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </>

                  ) : (
                    <>
                      {/* Mode création modifiable (non validé) */}

                      <h5 className="font-size-18 color-secondary mb-3"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Edition de la cartographie</h5>

                      <p className="mb-4">Date de création: {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

                      <Row>
                        <Col sm={12}>
                          <Link to={{ pathname: "/configuration-cartographie", state: routeId }} className="btn btn-primary waves-effect waves-light mx-2 mb-5">
                            <i className="uil uil-corner-up-right-alt me-1"></i>Mise à jour des compétences et des collaborateurs
                          </Link>
                        </Col>
                      </Row>

                      { !isEmpty(cartography.skills) ? (
                        <>
                          <Row>
                            <Col lg={12} className="mt-0 mb-5">
                              <span className="text-primary">Légende :</span>
                              <span className="badge bg-risk-very-good mx-2">Expert</span>
                              <span className="badge bg-risk-good mx-2">Confirmé</span>
                              <span className="badge bg-risk-medium mx-2">Débutant</span>
                              <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                              <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12 position-relative">
                              <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                                {({paginationProps, paginationTableProps}) => (
                                  <ToolkitProvider
                                    keyField="id"
                                    data={cartography.skills || []}
                                    columns={CartographyTableCreateColumn(toggleModal, toggleModalRequiredLevel, cartography)}
                                    bootstrap4
                                    search
                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row>
                                          <Col>
                                            <Label className="float-end">{" "}
                                              <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                            </Label>
                                          </Col>
                                        </Row>

                                        <div className="table-responsive mb-4" ref={ref}>

                                          <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                          <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            hover={true}
                                            classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                            headerWrapperClasses={"bg-transparent"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                          <div className="float-end">
                                            <PaginationListStandalone {...paginationProps} />
                                          </div>
                                        </div>

                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                              )}
                            </PaginationProvider>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div className="mb-5">
                          <p>La cartographie est vide.</p>
                          <p>Veuillez ajouter des compétences à la cartographie.</p>
                        </div>
                      )}
                    </>
                  )}

                { cartography.validate
                  ? (
                    <></>
                  ) : (
                    <>
                      { !isEmpty(cartography.skills) ? (
                        <>
                          <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Validation de la cartographie</h5>

                          <Row>
                            <Col sm={12}>

                              <button  onClick={() => toggleModalValidate()} type="button" className="btn btn-success waves-effect waves-light mx-2 mb-5">
                                <i className="uil uil-corner-up-right-alt me-1"></i>Valider la cartographie de l'organisation
                              </button>

                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row>
                          <Col sm={12}>
                            <button  onClick={() => toggleModalDeleteCartography()} className="btn btn-danger waves-effect waves-light mx-2">
                              <i className="uil uil-corner-up-right-alt me-1"></i>Supprimer la cartographie
                            </button>
                          </Col>
                        </Row>
                      )}
                    </>
                  )
                }
              </>
            )}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default Cartography
