import React, {useState} from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { map } from "lodash";

// Redux
import {useSelector} from "react-redux";
import {selectPrestataire} from "store/prestataire/prestataireSlice";

const PrestataireIntervenantGestionFormationModalAdd = ({ isOpen, toggle, addFormationIntervenant}) => {

  const { prestataire } = useSelector(selectPrestataire);
  const [intervenantSelect, setIntervenantSelect] = useState("")

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Ajouter un intervenant à la formation?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Choisir un intervenant dans la liste déroulante :</p>

        <select
          //type="select"
          name="intervenantSelect"
          className="form-control"
          onChange={event => setIntervenantSelect(event.target.value)}
          //helpMessage="Choisir un département dans le menu déroulant"
        >
          <option key="null">Choisir ...</option>

          {map(prestataire.prestataireIntervenants, (intervenant) => (
            <option key={intervenant.id} value={intervenant.id}>
              {intervenant.intFirstname} {intervenant.intLastname}
            </option>
          ))}

        </select>

      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={() => addFormationIntervenant(intervenantSelect)}>Ajouter l'intervenant</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default PrestataireIntervenantGestionFormationModalAdd

PrestataireIntervenantGestionFormationModalAdd.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
