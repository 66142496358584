import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  formations: [],
  formation: {},
  formationDate: {},
  formationStatus: [],
  success: "",
  successForm: "",
  error: "",
  isLoading: false
}

const formationSlice = createSlice({
  name: "formation",
  initialState,

  reducers: {

    FORMATIONS (state) {
      return { ...state, isLoading: true, successForm: "" }
    },

    FORMATION (state) {
      return { ...state, isLoading: true, success: "", successForm: "" }
    },

    GET_FORMATIONS (state, action) {
      state.formations = action.payload
    },

    GET_FORMATION (state, action) {
      state.formation = action.payload
    },

    ADD_FORMATION (state) {
      state.isLoading = true
    },

    ADD_NEW_FORMATION (state, action) {
      state.formation = action.payload
    },

    ADD_FORMATION_PRESTATAIRE_LINK (state) {
      state.isLoading = true
    },

    UPDATE_FORMATION (state) {
      state.isLoading = true
    },

    DELETE_FORMATION (state) {
      state.isLoading = true
      state.formation = {}
    },

    DELETE_FORMATION_PRESTATAIRE_LINK (state) {
      return { ...state }
    },

    FORMATION_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADD_FORMATION_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.successForm = action.payload
    },

    FORMATION_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    /* ========== Formation Status ================= */

    FORMATION_STATUS (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_FORMATION_STATUS (state, action) {
      state.formationStatus = action.payload
    },

    /* ========== Formation Date ================= */

    FORMATION_DATE (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_FORMATION_DATE (state, action) {
      state.formationDate = action.payload
    },

    ADD_FORMATION_DATE (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    DELETE_FORMATION_DATE (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    /* ========== Formation Request By User ================= */

    FORMATION_DEVIS_REQUEST_BY_USER (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    FORMATION_TRAINING_REQUEST_BY_USER (state) {
      return { ...state, isLoading: true, success: ""  }
    },
  }
});

const { reducer, actions } = formationSlice;

export const {
  FORMATIONS,
  FORMATION,
  GET_FORMATIONS,
  GET_FORMATION,
  ADD_FORMATION,
  ADD_NEW_FORMATION,
  ADD_FORMATION_PRESTATAIRE_LINK,
  UPDATE_FORMATION,
  DELETE_FORMATION,
  DELETE_FORMATION_PRESTATAIRE_LINK,
  ADD_FORMATION_SUCCESS,
  FORMATION_SUCCESS,
  FORMATION_ERROR,
  FORMATION_STATUS,
  GET_FORMATION_STATUS,
  FORMATION_DATE,
  GET_FORMATION_DATE,
  ADD_FORMATION_DATE,
  DELETE_FORMATION_DATE,
  FORMATION_DEVIS_REQUEST_BY_USER,
  FORMATION_TRAINING_REQUEST_BY_USER
} = actions;

export default reducer;

export const selectFormation = (state) => state.formation;
