import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Col, Container, Row} from "reactstrap"
import {AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount, CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";

import {
  selectClientEmployeeAccount,
  GET_CLIENT_EMPLOYEE_ACCOUNT,
  DELETE_CLIENT_EMPLOYEE_ACCOUNT,
  UPDATE_CLIENT_EMPLOYEE_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR,
  ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT
} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import GoBackBtn from "components/Common/GoBackBtn";

import ClientEmployeeCard from "components/Common/Card/ClientEmployeeCard";

import ClientEmployeeEditModal from "components/Common/Modal/ClientEmployeeConfiguration/ClientEmployeeEditModal";
import ClientEmployeeRemoveModal from "components/Common/Modal/ClientEmployeeConfiguration/ClientEmployeeRemoveModal";
import ClientEmployeeArchivedModal from "components/Common/Modal/ClientEmployeeConfiguration/ClientEmployeeArchivedModal";

const ClientEmployeeGestion = ({isRole, location}) => {

  const dispatch = useDispatch()
  const { client, error } = useSelector(selectClientAccount);
  const { success } = useSelector(selectClientEmployeeAccount);

  // récupération de l'id passé dans la route
  const clientId = location.state

  const [showModalEmployeeRemove, setShowModalEmployeeRemove] = useState(false)
  const [showModalEmployeeEdit, setShowModalEmployeeEdit] = useState(false)
  const [showModalEmployeeArchived, setShowModalEmployeeArchived] = useState(false)

  const toggleModalEmployeeRemove = employeeData => {
    if (!showModalEmployeeRemove) dispatch(GET_CLIENT_EMPLOYEE_ACCOUNT(employeeData))
    setShowModalEmployeeRemove(!showModalEmployeeRemove);
  };

  const toggleModalEmployeeEdit = employeeData => {
    if (!showModalEmployeeEdit) dispatch(GET_CLIENT_EMPLOYEE_ACCOUNT(employeeData))
    setShowModalEmployeeEdit(!showModalEmployeeEdit);
  };

  const toggleModalEmployeeArchived = employeeData => {
    if (!showModalEmployeeArchived) dispatch(GET_CLIENT_EMPLOYEE_ACCOUNT(employeeData))
    setShowModalEmployeeArchived(!showModalEmployeeArchived);
  };

  const deleteEmployee = employeeId => {
    dispatch(DELETE_CLIENT_EMPLOYEE_ACCOUNT({ clientEmployeeId: employeeId,  clientId: clientId }))
    setShowModalEmployeeRemove(!showModalEmployeeRemove);
  };

  const editEmployee = (e, v, userId, clientEmployeeId, originEmail) => {

    const data = {
      job: v.job,
      service: v.service ? v.service : undefined,
      arrivalDate: v.arrivalDate,
      pole: v.pole,
      userId,
      clientEmployeeId,
      clientId,
      firstname: v.firstname,
      lastname: v.lastname,
      email: v.email,
      originEmail,
      managerId: v.managerId ? (v.managerId !== 'Choisir ...' && v.managerId !== 'Aucun' ? Number(v.managerId) : null) : undefined,
    }

    dispatch(UPDATE_CLIENT_EMPLOYEE_ACCOUNT(data))

    setShowModalEmployeeEdit(!showModalEmployeeEdit);
  };

  const archivedEmployee = clientEmployeeId => {

    const data = {
      clientEmployeeId,
      clientId,
      isArchived: true,
    }

    dispatch(ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT(data))
    setShowModalEmployeeArchived(!showModalEmployeeArchived);
  };

  /* ============ start Searchbar Filter =================  */

  const handleSearchSubmit = () => {}

  const [query, setQuery] = useState('');

  const renderFormationBySearch = (employee, queryText) => {

    const formattedQuery = queryText.toUpperCase();

    const firstnameUpper = employee.user.firstname.toUpperCase();
    const lastnameUpper = employee.user.lastname.toUpperCase();
    const emailUpper = employee.user.email.toUpperCase();
    const poleUpper = employee.pole.toUpperCase();
    const serviceUpper = employee.service.toUpperCase();
    const jobUpper = employee.job.toUpperCase();

    if (
      firstnameUpper.includes(formattedQuery) ||
      lastnameUpper.includes(formattedQuery) ||
      emailUpper.includes(formattedQuery) ||
      poleUpper.includes(formattedQuery) ||
      serviceUpper.includes(formattedQuery) ||
      jobUpper.includes(formattedQuery)
    ) {
      return (
        <ClientEmployeeCard
          employee={employee}
          toggleModalEmployeeRemove={toggleModalEmployeeRemove}
          toggleModalEmployeeEdit={toggleModalEmployeeEdit}
          toggleModalEmployeeArchived={toggleModalEmployeeArchived}
          key={'formation-' + employee.id}
        />
      )
    }
  }

  /* ============ end Searchbar Filter =================  */

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT({ clientId: clientId }))
    dispatch( CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR())
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientEmployeeEditModal isOpen={showModalEmployeeEdit} toggle={toggleModalEmployeeEdit} editEmployee={editEmployee} clientEmployees={client.clientEmployees} />
        <ClientEmployeeRemoveModal isOpen={showModalEmployeeRemove} toggle={toggleModalEmployeeRemove} deleteEmployee={deleteEmployee} />
        <ClientEmployeeArchivedModal isOpen={showModalEmployeeArchived} toggle={toggleModalEmployeeArchived} archivedEmployee={archivedEmployee} />

        <div className="page-content my-5">
          <Container fluid>

            <SuccessErrorAlert error={error} success={success} />

            <GoBackBtn link={{ pathname: "/details-organisation", state: clientId }} title="Retour aux détails de l'organisation" />

            <h5 className="font-size-22 color-secondary text-center mb-5">Liste des collaborateurs de l'organisation</h5>

            <Row className="mb-0">

              <Col md={4}>
                <AvForm onValidSubmit={(e, v) => handleSearchSubmit(e, v)}>
                  <Row>
                    <Col className="mb-5">
                      <div className="form-inline mb-3">
                        <div className="search-box ml-2">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control rounded border-0"
                              name="search"
                              onChange={ queryText => {
                                setQuery(queryText.target.value)
                              }}
                              placeholder="Rechercher par mot-clé ..."
                            />
                            <i className="mdi mdi-magnify search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>

              <Col md={8}>
                <div className="d-flex">
                  <Link to={{ pathname: "/ajout-organisation-user", state: client }} className="btn btn-success waves-effect waves-light">
                    <i className="uil uil-corner-up-right-alt me-2"></i>Ajouter un nouveau collaborateur
                  </Link>

                  <Link to={{ pathname: "/gestion-collaborateurs-archive", state: clientId }} className="btn btn-primary waves-effect waves-light mx-2">
                    Voir les collaborateurs archivés
                  </Link>
                </div>
              </Col>
            </Row>

            <p className="mb-4">
              <i className="uil uil-exclamation-octagon me-2 font-size-22 color-warning"></i>
              Si le collaborateur est déjà présent dans une cartographie, sa suppression n'est pas possible et il doit être archivé.
            </p>

            <Row>
              {map(client.clientEmployees, employee => {
                if (!employee.isArchived) {
                  return renderFormationBySearch(employee, query)
                }
              })}
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientEmployeeGestion)
