import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  cartographies: [],
  cartography: {},
  currentCartographyId: 0,
  modal: {},
  success: "",
  error: "",
  isLoading: false
}

const cartographySlice = createSlice({
  name: "cartography",
  initialState,

  reducers: {

    CARTOGRAPHIES (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    CARTOGRAPHY (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    CARTOGRAPHY_BY_CLIENT (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_CARTOGRAPHIES (state, action) {
      state.cartographies = action.payload
    },

    CURRENT_CARTOGRAPHY_ID (state, action) {
      state.currentCartographyId = action.payload
    },

    GET_CARTOGRAPHY (state, action) {
      state.cartography = action.payload
    },

    ADD_CARTOGRAPHY (state) {
      state.isLoading = true
    },

    ADD_CARTOGRAPHY_WITH_INTERVENTION (state) {
      state.isLoading = true
    },

    ADD_NEW_CARTOGRAPHY (state, action) {
      state.cartography = action.payload
    },

    UPDATE_CARTOGRAPHY (state) {
      state.isLoading = true
    },

    UPDATE_CARTOGRAPHY_VALIDATE (state) {
      state.isLoading = true
    },

    UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE (state) {
      state.isLoading = true
    },

    ADD_CARTOGRAPHY_MODAL_DATA (state, action) {
      state.modal = action.payload
    },

    DELETE_CARTOGRAPHY (state) {
      state.isLoading = true
    },

    CARTOGRAPHY_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    CARTOGRAPHY_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    }

  }
});

const { reducer, actions } = cartographySlice;

export const {
  CARTOGRAPHIES,
  CARTOGRAPHY,
  CARTOGRAPHY_BY_CLIENT,
  GET_CARTOGRAPHIES,
  GET_CARTOGRAPHY,
  ADD_CARTOGRAPHY,
  ADD_CARTOGRAPHY_WITH_INTERVENTION,
  ADD_NEW_CARTOGRAPHY,
  UPDATE_CARTOGRAPHY,
  UPDATE_CARTOGRAPHY_VALIDATE,
  UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE,
  ADD_CARTOGRAPHY_MODAL_DATA,
  DELETE_CARTOGRAPHY,
  CARTOGRAPHY_SUCCESS,
  CARTOGRAPHY_ERROR,
  CURRENT_CARTOGRAPHY_ID
} = actions;

export default reducer;

export const selectCartography = (state) => state.cartography;
