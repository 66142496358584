import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectFormationInternal, FORMATION_INTERNAL, UPDATE_FORMATION_INTERNAL, FORMATION_INTERNAL_ERROR} from "store/formationInternal/formationInternalSlice";
import {selectSkill, SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import {map} from "lodash";

const FormationInternalEdit = ({history, isRole, location}) => {

  // récupération de l'id passé dans la route
  const formationInternalId = location.state

  const dispatch = useDispatch()

  const {formationInternal, error, successForm} = useSelector(selectFormationInternal);
  const {skillDomains} = useSelector(selectSkill);

  const [title, setTitle] = useState("")
  const [context, setContext] = useState("")
  const [targetAudience, setTargetAudience] = useState("")
  const [duration, setDuration] = useState(0)
  const [durationUnit, setDurationUnit] = useState("heures")
  const [description, setDescription] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [objectifStrategic, setObjectifStrategic] = useState("")
  const [objectifOperational, setObjectifOperational] = useState("")
  const [objectifEducational, setObjectifEducational] = useState("")
  const [prerequisites, setPrerequisites] = useState("")
  const [programme, setProgramme] = useState("")
  const [sanction, setSanction] = useState("")
  const [price, setPrice] = useState(0)
  const [educationalMethods, setEducationalMethods] = useState("")
  const [educationalTechnics, setEducationalTechnics] = useState("")
  const [modalities, setModalities] = useState("")
  const [necessaryMaterial, setNecessaryMaterial] = useState("")
  const [internal, setInternal] = useState('interne')
  const [disabledAccessibility, setDisabledAccessibility] = useState(true)
  const [referentFirstname, setReferentFirstname] = useState("")
  const [referentLastname, setReferentLastname] = useState("")
  const [formationQualification, setFormationQualification] = useState("")
  const [skillDomainId, setSkillDomainId] = useState(formationInternal.hasOwnProperty('skillDomain') ? formationInternal.skillDomain.id : '')

  function handleValidSubmitFormation() {

    const data = {
      title,
      context,
      targetAudience,
      duration: Number(duration),
      durationUnit,
      description,
      objectifStrategic,
      objectifOperational,
      objectifEducational,
      prerequisites,
      programme,
      educationalTechnics,
      educationalMethods,
      modalities,
      necessaryMaterial,
      internal: internal === "interne" ? true : false,
      referentFirstname,
      referentLastname,
      formationQualification,
      price: Number(price),
      sanction,
      disabledAccessibility,
      skillDomain: skillDomainId ? `/api/skill_domains/${skillDomainId}` : undefined,
      role: "client",
      history: history,
      formationInternalId
    }
    dispatch(UPDATE_FORMATION_INTERNAL(data))
  }

  function handleInvalidSubmitFormation() {
    dispatch(FORMATION_INTERNAL_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(SKILL_DOMAIN_ACTIVITY())
    dispatch(FORMATION_INTERNAL({formationInternalId: formationInternalId}))

    setTitle(formationInternal.title)
    setContext(formationInternal.context)
    setTargetAudience(formationInternal.targetAudience)
    setDuration(formationInternal.duration)
    setDurationUnit(formationInternal.durationUnit)
    setDescription(formationInternal.description)
    setObjectifStrategic(formationInternal.objectifStrategic)
    setObjectifOperational(formationInternal.objectifOperational)
    setObjectifEducational(formationInternal.objectifEducational)
    setPrerequisites(formationInternal.prerequisites)
    setProgramme(formationInternal.programme)
    setEducationalTechnics(formationInternal.educationalTechnics)
    setEducationalMethods(formationInternal.educationalMethods)
    setModalities(formationInternal.modalities)
    setNecessaryMaterial(formationInternal.necessaryMaterial)
    setInternal(formationInternal.internal ? 'interne': 'externe')
    setReferentFirstname(formationInternal.referentFirstname)
    setReferentLastname(formationInternal.referentLastname)
    setFormationQualification(formationInternal.formationQualification)
    setPrice(formationInternal.price)
    setSanction(formationInternal.sanction)
    setDisabledAccessibility(formationInternal.disabledAccessibility)
  }, [
    dispatch,
    formationInternalId,
    formationInternal.title,
    formationInternal.context,
    formationInternal.targetAudience,
    formationInternal.duration,
    formationInternal.durationUnit,
    formationInternal.description,
    formationInternal.objectifStrategic,
    formationInternal.objectifOperational,
    formationInternal.objectifEducational,
    formationInternal.prerequisites,
    formationInternal.programme,
    formationInternal.educationalTechnics,
    formationInternal.educationalMethods,
    formationInternal.modalities,
    formationInternal.necessaryMaterial,
    formationInternal.internal,
    formationInternal.referentFirstname,
    formationInternal.referentLastname,
    formationInternal.formationQualification,
    formationInternal.price,
    formationInternal.sanction,
    formationInternal.disabledAccessibility
  ])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <h5 className="font-size-22 color-secondary text-center mb-5">Modification d'une formation interne</h5>

            <SuccessErrorAlert error={error} success={successForm} />

            <GoBackBtn link="/gestion-formations-internes" title="Retour à la liste des formations internes" />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitFormation(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitFormation(e, v)}
                    >
                      <Row>
                        <Col xl="12">

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Titre du parcours de formation <span className="text-danger">*</span></h5>
                            <AvField
                              name="title"
                              value={formationInternal.title}
                              className="form-control"
                              placeholder=""
                              type="text"
                              errorMessage="Le titre de la formation est requis"
                              onChange={event => setTitle(event.target.value)}
                              required
                            />
                          </div>



                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Pourquoi la formation ?</h5>
                            <AvField
                              name="context"
                              value={formationInternal.context}
                              className="form-control"
                              placeholder=""
                              type="text"
                              errorMessage="Le titre de la formation est requis"
                              onChange={event => setContext(event.target.value)}
                            />
                            <p className="font-size-15 color-grey mx-2 mt-2">Expliquer dans quel cadre a lieu cette formation</p>
                          </div>

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Public visé par la formation</h5>
                            <div className="form-group mb-4">
                              <AvField
                                name="targetAudience"
                                value={formationInternal.targetAudience}
                                className="form-control"
                                placeholder=""
                                type="text"
                                errorMessage="Le public visé est requis"
                                onChange={event => setTargetAudience(event.target.value)}
                              />
                            </div>
                          </div>

                          <div className="px-4 py-4 d-flex justify-content-between text-center">

                            <div>
                              <h5 className="font-size-17 mt-5">Durée de la formation</h5>
                              <div className="mb-4">
                                <AvField
                                  name="duration"
                                  value={formationInternal.duration}
                                  placeholder=""
                                  type="number"
                                  onChange={event => setDuration(event.target.value)}
                                />

                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="durationUnit"
                                  errorMessage="Une unité de temps est requise"
                                  onChange={event => setDurationUnit(event.target.value)}
                                >
                                  <option>{formationInternal.durationUnit}</option>
                                  <option key="heures">heures</option>
                                  <option key="jours">jours</option>
                                </AvField>

                              </div>
                            </div>

                            <div>
                              <h5 className="font-size-17 mt-5 mx-5">Coût de la formation</h5>
                              <div className="d-flex mx-4">
                                <AvField
                                  name="price"
                                  value={formationInternal.price}
                                  placeholder=""
                                  type="number"
                                  onChange={event => setPrice(event.target.value)}
                                />
                                <p className="mx-2">€</p>
                              </div>
                            </div>

                            <div className="mx-5">
                              <h5 className="font-size-17 mt-5 mb-3">Accessibilité handicap</h5>
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="status-input"
                                     onChange={() => disabledAccessibility ? setDisabledAccessibility(false) : setDisabledAccessibility(true)}
                                     checked={disabledAccessibility}
                              />
                            </div>

                          </div>

                          <h5 className="font-size-17 mt-4 mb-3">Description de la formation</h5>

                          <AvField
                            name="description"
                            value={formationInternal.description}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            validate={{
                              required: {value: false},
                              minLength: {value: 0},
                              maxLength: {value: 800}
                            }}
                            onChange={event => setDescription(event.target.value)}
                          />
                          <p className="font-size-15 color-grey mx-2 mt-2">Description brève de la formation dans les grands axes afin d’apporter une présentation de celle-ci (la description est limitée à 800 caractères)</p>


                          <h5 className="font-size-17 mt-4 mb-3">Objectifs stratégiques</h5>

                          <AvField
                            name="objectifStrategic"
                            value={formationInternal.objectifStrategic}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setObjectifStrategic(event.target.value)}
                          />
                          <p className="font-size-15 color-grey mx-2 mt-2">Pourquoi la formation ? -- Ecrire en compétence : verbes d’action + sujet </p>


                          <h5 className="font-size-17 mt-4 mb-3">Objectifs opérationnels</h5>

                          <AvField
                            name="objectifOperational"
                            value={formationInternal.objectifOperational}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setObjectifOperational(event.target.value)}
                          />
                          <p className="font-size-15 color-grey mx-2 mt-2">Quels sont les changements attendus ? -- Ecrire en compétence : verbes d’action + sujet (exemple : Créer une nouvel culture managériale)</p>

                          <h5 className="font-size-17 mt-4 mb-3">Objectifs pédagogiques</h5>

                          <AvField
                            name="objectifEducational"
                            value={formationInternal.objectifEducational}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setObjectifEducational(event.target.value)}
                          />

                          <p className="font-size-15 color-grey mx-2 mt-2">Ce que sera capable de faire le bénéficiaire au terme de la formation en termes de savoirs / savoir-faire /savoir être -- Ecrire en compétence : verbes d’action + sujet </p>

                          <h5 className="font-size-17 mt-5 mb-3">Prérequis de la formation</h5>

                          <AvField
                            name="prerequisites"
                            value={formationInternal.prerequisites}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setPrerequisites(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-5 mb-3">Programme</h5>

                          {programme && (
                            <ReactQuill theme="snow" value={programme} onChange={setProgramme}/>
                          )}

                          <p className="font-size-15 color-grey mx-2 mt-2">Détail des étapes du parcours pédagogique en prenant en compte les temps de formation, de situation de travail, les séquences réflexives, etc.</p>


                          <h5 className="font-size-17 mt-5 mb-4">Pédagogie</h5>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_moy_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Moyens pédagogiques</h5>
                                <AvField
                                  name="educationalMethods"
                                  value={formationInternal.educationalMethods}
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalMethods(event.target.value)}
                                />

                                <p className="font-size-15 color-grey mx-2 mt-2">Outils et matériels utilisés pour mener à bien l’action de formation</p>

                              </div>
                            </li>

                            <li className="feed-item" key="_moy_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Méthodes pédagogiques</h5>
                                <AvField
                                  name="educationalTechnics"
                                  value={formationInternal.educationalTechnics}
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalTechnics(event.target.value)}
                                />
                              </div>

                              <p className="font-size-15 color-grey mx-2 mt-2">Activités, ateliers, démarches et documents utilisés pour réaliser l’action de formation ou à transmettre au bénéficiaire</p>

                            </li>

                          </ul>

                          <div className="px-4 py-0">

                            <div className="mb-5">

                              <h5 className="font-size-17 mb-3">Modalités de la formation : </h5>

                              <AvField
                                name="modalities"
                                value={formationInternal.modalities}
                                className=""
                                placeholder=""
                                type="string"
                                onChange={event => setModalities(event.target.value)}
                                helpMessage="Présentiel, distanciel, synchrone, asynchrone …"
                              />

                            </div>

                            <div className="mb-5">

                              <h5 className="font-size-17 mb-3">Sanction de la formation : </h5>

                              <AvField
                                name="sanction"
                                value={formationInternal.sanction}
                                className=""
                                placeholder=""
                                type="string"
                                onChange={event => setSanction(event.target.value)}
                              />

                            </div>

                            <div className="mb-5">
                              <h5 className="font-size-17 mb-3">Matériel nécessaire pour réaliser la formation : </h5>
                              <AvField
                                name="necessaryMaterial"
                                value={formationInternal.necessaryMaterial}
                                className=""
                                placeholder=""
                                type="string"
                                onChange={event => setNecessaryMaterial(event.target.value)}
                                helpMessage="Matériel dont aura besoin le bénéficiaire pour suivre la formation"
                              />

                            </div>


                            <div className="mb-5 d-flex align-items-center">

                              <h5 className="font-size-17 mb-0">Type de formation :</h5>

                              <AvField
                                className="form-select mx-3"
                                type="select"
                                name="status-input"
                                errorMessage="Une unité de temps est requise"
                                onChange={event => setInternal(event.target.value)}
                              >
                                <option>{internal}</option>
                                <option key="interne">interne</option>
                                <option key="externe">externe</option>
                              </AvField>
                            </div>

                            <div className="form-group mb-4">

                              <h5 className="font-size-17 mt-3 mb-3">Domaine de compétence de la formation <span className="text-danger">*</span></h5>

                              <AvField
                                className="form-select"
                                type="select"
                                name="skillDomainId"
                                validate={{required: {value: false}}}
                                onChange={event => setSkillDomainId(event.target.value)}
                                errorMessage="Veuillez choisir une compétence"
                                >
                                {formationInternal.hasOwnProperty('skillDomain') ? (
                                  <option key={`domain-${formationInternal.skillDomain.id}`} value={formationInternal.skillDomain.id}>{formationInternal.skillDomain.name}</option>
                                ) : (
                                  <option key="default" value="">Choisir</option>
                                )}
                                {map(skillDomains, (domain, key) => (
                                  <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                                ))}
                              </AvField>
                              <p className="font-size-15 color-grey mx-2 mt-2">Choisir un domaine de formation dans le menu déroulant</p>
                            </div>

                            <h5 className="mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Référent de la formation</h5>

                            <div className="px-4 d-flex justify-content-between align-items-baseline text-center">

                              <div className=" text-center">
                                <h5 className="font-size-17 mt-4 mb-3">Prénom</h5>
                                <AvField
                                  name="referentFirstname"
                                  value={formationInternal.referentFirstname}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={event => setReferentFirstname(event.target.value)}
                                />
                              </div>

                              <div className=" text-center">
                                <h5 className="font-size-17 mt-4 mb-3">Nom</h5>
                                <AvField
                                  name="referentLastname"
                                  value={formationInternal.referentLastname}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={event => setReferentLastname(event.target.value)}
                                />
                              </div>

                              <div className=" text-center">
                                <h5 className="font-size-17 mt-4 mb-3">Qualification</h5>
                                <AvField
                                  name="formationQualification"
                                  value={formationInternal.formationQualification}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={event => setFormationQualification(event.target.value)}
                                />

                                <p className="font-size-15 color-grey mx-2 mt-2">Expérience</p>
                              </div>

                            </div>

                          </div>
                        </Col>
                      </Row>

                      <SuccessErrorAlert error={error} success={successForm} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Modifier la formation interne</Button>
                        </div>

                        <Link to="/gestion-formations-internes" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des formations internes
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(FormationInternalEdit)
