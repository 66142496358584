import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  formationInternals: [],
  formationInternal: {},
  intervenantFormationInternal: {},
  success: "",
  successForm: "",
  error: "",
  isLoading: false
}

const formationInternalSlice = createSlice({
  name: "formationInternal",
  initialState,

  reducers: {

    FORMATION_INTERNALS (state) {
      return { ...state, isLoading: true, successForm: "" }
    },

    FORMATION_INTERNALS_FILTER_BY_CLIENT (state) {
      return { ...state, isLoading: true, successForm: "" }
    },

    FORMATION_INTERNAL (state) {
      return { ...state, isLoading: true, success: "", successForm: "" }
    },

    GET_FORMATION_INTERNALS (state, action) {
      state.formationInternals = action.payload
    },

    GET_FORMATION_INTERNALS_FILTER_BY_CLIENT (state, action) {
      state.formationInternals = action.payload
    },

    GET_FORMATION_INTERNAL (state, action) {
      state.formationInternal = action.payload
    },

    ADD_FORMATION_INTERNAL (state) {
      state.isLoading = true
    },

    ADD_NEW_FORMATION_INTERNAL (state, action) {
      state.formationInternal = action.payload
    },

    UPDATE_FORMATION_INTERNAL (state) {
      state.isLoading = true
    },

    DELETE_FORMATION_INTERNAL (state) {
      state.isLoading = true
      state.formationInternal = {}
    },

    FORMATION_INTERNAL_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADD_FORMATION_INTERNAL_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.successForm = action.payload
    },

    FORMATION_INTERNAL_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    // INTERVENANTS
    ADD_FORMATION_INTERNAL_INTERVENANT (state, action) {
      state.intervenantFormationInternal = action.payload
    },

    UPDATE_FORMATION_INTERNAL_INTERVENANT (state) {
      state.isLoading = true
    },
  }
});

const { reducer, actions } = formationInternalSlice;

export const {
  FORMATION_INTERNALS,
  FORMATION_INTERNAL,
  GET_FORMATION_INTERNALS,
  GET_FORMATION_INTERNAL,
  ADD_FORMATION_INTERNAL,
  ADD_NEW_FORMATION_INTERNAL,
  FORMATION_INTERNALS_FILTER_BY_CLIENT,
  GET_FORMATION_INTERNALS_FILTER_BY_CLIENT,
  UPDATE_FORMATION_INTERNAL,
  DELETE_FORMATION_INTERNAL,
  FORMATION_INTERNAL_SUCCESS,
  ADD_FORMATION_INTERNAL_SUCCESS,
  FORMATION_INTERNAL_ERROR,
  ADD_FORMATION_INTERNAL_INTERVENANT,
  UPDATE_FORMATION_INTERNAL_INTERVENANT
} = actions;

export default reducer;

export const selectFormationInternal = (state) => state.formationInternal;
