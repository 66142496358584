import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    user: {},
    success: "",
    successAvatar: "",
    error: "",
    errorAvatar: "",
    isLoading: false,
}

const profileSlice = createSlice({
    name: "profile",
    initialState,

    reducers: {

        GET_USER_PROFILE (state) {
            return {...state}
        },

        GET_PROFILE (state, action) {
            state.isLoading = true
            state.user = action.payload  // payload: { user }
        },

        EDIT_USER_PROFILE (state) {
            return {...state}
        },

        EDIT_PASSWORD_PROFILE (state) {
            return {...state}
        },

        ADD_AVATAR_PROFILE (state) {
            return {...state}
        },

        DELETE_AVATAR_PROFILE (state) {
            return {...state}
        },

        PROFILE_SUCCESS (state, action) {
            state.isLoading = false
            state.success = action.payload
        },

        PROFILE_AVATAR_SUCCESS (state, action) {
            state.isLoading = false
            state.successAvatar = action.payload
        },

        PROFILE_ERROR (state, action) {
            state.error = action.payload  // payload: error
        },

        PROFILE_AVATAR_ERROR (state, action) {
            state.errorAvatar = action.payload  // payload: errorAvatar
        },
    }
})

const { reducer, actions } = profileSlice;

export const {
    GET_USER_PROFILE,
    GET_PROFILE,
    EDIT_USER_PROFILE,
    EDIT_PASSWORD_PROFILE,
    DELETE_AVATAR_PROFILE,
    ADD_AVATAR_PROFILE,
    PROFILE_AVATAR_SUCCESS,
    PROFILE_AVATAR_ERROR,
    PROFILE_SUCCESS,
    PROFILE_ERROR
} = actions;

export default reducer;

export const selectProfile = (state) => state.profile;
