import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Input, Label, Button, Card, CardBody, FormGroup, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";
import { generatePassword } from "helpers/text_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectAccount, ADMIN_ACCOUNT, ACCOUNT_ERROR, RESET_ALERT_MESSAGE} from "store/account/account/accountSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const AccountAdminAdd = ({history, isRole, location}) => {

  const dispatch = useDispatch()
  const account = useSelector(selectAccount);

  const [email, setEmail] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [checked, setChecked] = useState(true);

  const GeneratePasswordSecure = () => {
    const passwordSecure = generatePassword()
    setPassword(passwordSecure)
    setConfirmPassword(passwordSecure)
  }

  function handleValidSubmitAdmin() {

    if (password !== confirmPassword) {
      dispatch(ACCOUNT_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else {

      const data = {
        email: email,
        password: password,
        firstname: firstname,
        lastname: lastname,
        status: checked,
        history: history
      }
      dispatch(ADMIN_ACCOUNT(data))
    }
  }

  function handleInvalidSubmitAdmin() {
    dispatch(ACCOUNT_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(RESET_ALERT_MESSAGE())
    // génération d'un mot de passe sécurisé
    GeneratePasswordSecure()
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-comptes" title="Retour à la liste des comptes" />
            <TitleBanner title="Ajout d'un nouveau compte super administrateur" />
            <SuccessErrorAlert error={account.error} success="" />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitAdmin(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitAdmin(e, v)}
                    >

                      <Row>
                        <Col lg={6}>
                          <div className="mt-4">

                            <h5 className="mb-4 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations</h5>

                            <Row>

                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Prénom <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="firstname"
                                    value={firstname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setFirstname(event.target.value)}
                                    errorMessage="Veuillez renseigner un prénom"
                                    required
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Nom <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="lastname"
                                    value={lastname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setLastname(event.target.value)}
                                    errorMessage="Veuillez renseigner un nom"
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="form-group mb-5">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="email"
                                value={email}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <Card outline color="primary" className="border">
                              <CardBody className="mx-2">

                                <h5 className="mb-4 mt-2 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Mot de passe de connexion</h5>

                                <p><i className="uil uil-question-circle font-size-16 text-info me-2"></i> Un mot de passe sécurisé est généré automatiquement par défaut</p>

                                <Button
                                  color="dark"
                                  outline
                                  className="waves-effect waves-light mb-3 mt-3"
                                  onClick={ () => GeneratePasswordSecure()}
                                >
                                  Générer un nouveau mot de passe ?
                                </Button>

                                <div className="form-group">
                                  <h5 className="font-size-17 mt-4 mb-4">Mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="password"
                                    value={password}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setPassword(event.target.value)}
                                    errorMessage="Veuillez renseigner un mot de passe"
                                    required
                                  />
                                </div>

                                <div className="form-group mt-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Confirmer le mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    errorMessage="Veuillez confirmer le mot de passe"
                                    required
                                  />
                                </div>

                              </CardBody>
                            </Card>

                            <FormGroup>
                              <div className="form-check mt-4">
                                <Input type="checkbox"
                                       className="form-check-input"
                                       id="status-input"
                                       onChange={() => checked ? setChecked(false) : setChecked(true)}
                                       checked={checked}
                                />
                                <Label className="form-check-label" htmlFor="status-input">Statut actif</Label>
                              </div>
                            </FormGroup>
                          </div>
                        </Col>

                      </Row>


                      <div className="mt-5 mb-3">

                        <SuccessErrorAlert error={account.error} success="" />

                        <div className="d-flex align-items-center mt-5 mb-3">

                          <div className="mx-1">
                            <Button type="submit" color="success" className="w-md">Ajouter</Button>
                          </div>

                          <Link to="/gestion-comptes" className="btn btn-primary waves-effect waves-light mx-3">
                            Retour à la liste des comptes
                          </Link>

                        </div>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(AccountAdminAdd)
