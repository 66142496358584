import React, { useEffect, useState } from "react"
import {Col, Container, Row, Label, UncontrolledAlert} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import {Redirect, Link} from "react-router-dom"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectClientAccount, CLIENTS_ACCOUNT, ADD_CLIENT_ACCOUNT, DELETE_CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import ClientAccountColumn from "./ClientAccountColumn"
import ClientAccountModalRemove from "./ClientAccountModalRemove";

const ClientAccount = ({ isRole, location }) => {

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()
  const { clients, error, successForm } = useSelector(selectClientAccount);

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (clientData) => {
    if (!showModal) dispatch(ADD_CLIENT_ACCOUNT(clientData))
    setShowModal(!showModal);
  };

  const deleteClient = (id) => {
    dispatch(DELETE_CLIENT_ACCOUNT({ clientId: id }))
    setShowModal(!showModal);
  };

  const paginateOptions = {
    sizePerPage: 10,
    totalSize: clients.length,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }

  useEffect(() => {
    dispatch(CLIENTS_ACCOUNT())
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientAccountModalRemove isOpen={showModal} toggle={toggleModal} deleteClient={deleteClient} />

        <div className="page-content my-5">
          <Container fluid>

            <TitleBanner title="Liste des organisations" />

            <Row>
              <Col sm="12" md="8">
                {error ? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-exclamation-octagon me-2"></i> Une erreur s'est produite : "{error}"
                  </UncontrolledAlert>
                ) : null }

                { successForm ? (
                  <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-check me-2"></i> {successForm}
                  </UncontrolledAlert>
                ) : null }

              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">

                  <Link to="/ajout-organisation" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter une organisation
                  </Link>

                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                  {({paginationProps, paginationTableProps}) => (
                    <ToolkitProvider
                      keyField="id"
                      data={clients}
                      columns={ClientAccountColumn(toggleModal)}
                      bootstrap4
                      search
                      exportCSV={ { fileName: `liste des entreprises (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                    >
                      {toolkitProps => (
                        <React.Fragment>

                            <Row>

                              <Col sm="12" md="6">
                                <Label>
                                  <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                    Exporter le tableau en CSV
                                  </ExportCSVButton>
                                </Label>
                              </Col>

                              <Col sm="12" md="6">
                                <Label className="float-end">{" "}
                                  <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                </Label>
                              </Col>

                            </Row>

                            <div className="table-responsive mb-4">

                              <BootstrapTable
                                responsive
                                bordered={false}
                                striped={false}
                                hover={true}
                                classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                keyField="id"
                                headerWrapperClasses={"bg-transparent"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <div className="float-end">
                                <PaginationListStandalone {...paginationProps} />
                              </div>
                            </div>

                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default ClientAccount
