import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

const FormationPlanRemoveActionModal = ({ isOpen, toggle, deleteAction}) => {

  const { trainingPlan, trainingPlanAction } = useSelector(selectTrainingPlan)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Supprimer une demande ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Confirmez-vous la suppression de la demande de formation de "
          {trainingPlanAction.hasOwnProperty("employee") && trainingPlanAction.employee.clientEmployee.user.firstname}
          {" "}
          {trainingPlanAction.hasOwnProperty("employee") && trainingPlanAction.employee.clientEmployee.user.lastname}
          " ?</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => deleteAction(trainingPlanAction.id, trainingPlan.id)}>Confirmer la suppression</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default FormationPlanRemoveActionModal

FormationPlanRemoveActionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
