import React, {useEffect, useState} from "react"
import {Col, Container, Row, Label} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import {Redirect, Link} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {DELETE_FORMATION, ADD_NEW_FORMATION } from "store/formation/formationSlice";
import {selectPrestataire, PRESTATAIRE} from "store/prestataire/prestataireSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import PrestataireFormationModalRemove from "./PrestataireFormationModalRemove";
import PrestataireFormationColumn from "./PrestataireFormationColumn";


const PrestataireFormationAll = ({ isRole, location }) => {

  const prestataireId = Number(localStorage.getItem('prestataireId'))

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()
  const { prestataire, error, success } = useSelector(selectPrestataire);

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (formationData) => {
    if (!showModal) dispatch(ADD_NEW_FORMATION(formationData))
    setShowModal(!showModal);
  };

  const deleteFormation = id => {
    dispatch(DELETE_FORMATION({formationId: id, role: "prestataire", prestataireId: prestataireId}))
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(PRESTATAIRE({prestataireId: prestataireId}))
  }, [dispatch, prestataireId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <PrestataireFormationModalRemove isOpen={showModal} toggle={toggleModal} deleteFormation={deleteFormation} />

        <div className="page-content my-5">
          <Container fluid>

            <TitleBanner title="Liste des formations" />
            <SuccessErrorAlert error={error} success={success} />



            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">
                  <Link to="/prestataire-formation-ajout" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter une formation
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">

                {prestataire.hasOwnProperty('formations') && (
                  <PaginationProvider pagination={paginationFactory({
                    sizePerPage: 10,
                    totalSize: prestataire.formations.length,
                    custom: true,
                    nextPageText: 'Suivant',
                    prePageText: 'Précédent',
                  })}>

                    {({paginationProps, paginationTableProps}) => (
                      <ToolkitProvider
                        keyField="id"
                        data={prestataire.formations}
                        columns={PrestataireFormationColumn(toggleModal)}
                        bootstrap4
                        search
                        exportCSV={ { fileName: `liste des formations (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row>

                              <Col sm="12" md="6">
                                <Label>
                                  <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                    Exporter le tableau en CSV
                                  </ExportCSVButton>
                                </Label>
                              </Col>

                              <Col sm="12" md="6">
                                <Label className="float-end">{" "}
                                  <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                </Label>
                              </Col>

                            </Row>

                            <div className="table-responsive mb-4">

                              <BootstrapTable
                                responsive
                                bordered={false}
                                striped={false}
                                hover={true}
                                classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                keyField="id"
                                headerWrapperClasses={"bg-transparent"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <div className="float-end">
                                <PaginationListStandalone {...paginationProps} />
                              </div>
                            </div>

                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default PrestataireFormationAll
