import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Data
import { countries } from "helpers/data_helper"
import { generatePassword } from "helpers/text_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectClientAccount, ADD_ACCOUNT, CLIENT_ACCOUNT_ERROR} from "store/account/clientAccount/clientAccountSlice";
import {selectGlobalDatas, FORM_DATAS} from "store/globalData/globalDataSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const ClientAccountAdd = ({history, isRole, location}) => {

  const dispatch = useDispatch()
  const { error } = useSelector(selectClientAccount);
  const { localities, societyTypes } = useSelector(selectGlobalDatas);

  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [clientFunction, setClientFunction] = useState("")
  const [entName, setEntName] = useState("")
  const [entAddress, setEntAddress] = useState("")
  const [localityId, setLocalityId] = useState("")
  const [entZipCode, setEntZipCode] = useState("")
  const [entCity, setEntCity] = useState("")
  const [entCodeNaf, setEntCodeNaf] = useState("")
  const [entCodeSiret, setEntCodeSiret] = useState("")
  const [entCodeTva, setEntCodeTva] = useState("")
  const [entCountry, setEntCountry] = useState("")
  const [entCommercialCoverage, setEntCommercialCoverage] = useState("")
  const [societyStatus, setSocietyStatus] = useState("")
  const [contactFirstname, setContactFirstname] = useState("")
  const [contactLastname, setContactLastname] = useState("")
  const [entEffectif, setEntEffectif] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const GeneratePasswordSecure = () => {
    const passwordSecure = generatePassword()
    setPassword(passwordSecure)
    setConfirmPassword(passwordSecure)
  }

  function handleValidSubmitAdmin() {

    if (password !== confirmPassword) {
      dispatch(CLIENT_ACCOUNT_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else if (societyStatus === "Choisir ...") {
      dispatch(CLIENT_ACCOUNT_ERROR("Veuillez renseigner un statut juridique de société !!"))
    } else if (localityId === "Choisir ...") {
      dispatch(CLIENT_ACCOUNT_ERROR("Veuillez renseigner un département !!"))
    } else {

      const data = {
        email: email,
        password: password,
        firstname: contactFirstname,
        lastname: contactLastname,
        roles: ['ROLE_CLIENT'],
        status: true,
        client: {
          clientFunction: clientFunction,
          phone: phone,
          entName: entName,
          entAddress: entAddress,
          locality: `/api/localities/${localityId}`,
          entZipCode: entZipCode,
          entCity: entCity,
          entCodeNaf: entCodeNaf,
          entCodeSiret: entCodeSiret,
          entCodeTva: entCodeTva,
          entCountry: entCountry,
          entCommercialCoverage: entCommercialCoverage,
          societyType: `/api/society_types/${societyStatus}`,
          entEffectif: entEffectif,
          contactFirstname: contactFirstname,
          contactLastname: contactLastname,
          email: email,
        },
        history: history
      }
      dispatch(ADD_ACCOUNT(data))
    }
  }

  function handleInvalidSubmitAdmin() {
    dispatch(CLIENT_ACCOUNT_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(FORM_DATAS())

    // génération d'un mot de passe sécurisé
    GeneratePasswordSecure()

  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-organisations" title="Retour à la liste des organisations" />
            <TitleBanner title="Ajout d'une organisation" />
            <SuccessErrorAlert error={error} />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5 pt-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitAdmin(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitAdmin(e, v)}
                    >

                      <Row>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations personnelles</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="firstname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                validate={{
                                  minLength: {value: 2},
                                  maxLength: {value: 255}
                                }}
                                onChange={event => setContactFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom (entre 2 et 255 caractères)"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="lastname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Fonction <span className="text-danger">*</span></h5>
                              <AvField
                                name="audFunction"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setClientFunction(event.target.value)}
                                errorMessage="Veuillez renseigner une fonction"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="email"
                                value={email}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Téléphone <span className="text-danger">*</span></h5>
                              <AvField
                                name="phone"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setPhone(event.target.value)}
                                errorMessage="Veuillez renseigner un téléphone"
                                required
                              />
                            </div>

                            <Card outline color="primary" className="border">
                              <CardBody className="mx-2">

                                <h5 className="mb-4 mt-2 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Mot de passe de connexion</h5>

                                <p><i className="uil uil-question-circle font-size-16 text-info me-2"></i> Un mot de passe sécurisé est généré automatiquement par défaut</p>

                                <Button
                                  color="dark"
                                  outline
                                  className="waves-effect waves-light mb-4 mt-3"
                                  onClick={ () => GeneratePasswordSecure()}
                                >
                                  Générer un nouveau mot de passe ?
                                </Button>

                                <div className="form-group">
                                  <h5 className="font-size-17 mt-4 mb-4">Mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="password"
                                    value={password}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setPassword(event.target.value)}
                                    errorMessage="Veuillez renseigner un mot de passe"
                                    required
                                  />
                                </div>

                                <div className="form-group mt-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Confirmer le mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    errorMessage="Veuillez confirmer le mot de passe"
                                    required
                                  />
                                </div>

                              </CardBody>
                            </Card>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations administratives</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entName"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntName(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Adresse de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entAddress"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntAddress(event.target.value)}
                                errorMessage="Veuillez renseigner une adresse"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Département <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="locality"
                                onChange={event => setLocalityId(event.target.value)}
                                errorMessage="Veuillez choisir un département"
                                helpMessage="Choisir un département dans le menu déroulant"
                              >
                                <option key="null">Choisir ...</option>
                                {localities.map( locality => (
                                  <option key={locality.id} value={locality.id}>
                                    {locality.departmentCode} - {locality.departmentName}
                                  </option>
                                ))}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code postal <span className="text-danger">*</span></h5>
                              <AvField
                                name="zipCode"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntZipCode(event.target.value)}
                                errorMessage="Veuillez renseigner un code postal"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Ville <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCity"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCity(event.target.value)}
                                errorMessage="Veuillez renseigner une ville"
                                required
                              />
                            </div>

                            <div className="form-group mb-5">
                              <h5 className="font-size-17 mt-5 mb-3">Pays <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="entCountry"
                                onChange={event => setEntCountry(event.target.value)}
                                errorMessage="Veuillez choisir un pays"
                                helpMessage="Choisir un pays dans le menu déroulant">
                                {countries.map((country, idx) => <option key={idx}>{country}</option>)}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Domaine d'activité <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCommercialCoverage"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCommercialCoverage(event.target.value)}
                                errorMessage="Veuillez renseigner un domaine d'activité"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Statut juridique de la société <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="societyType"
                                onChange={event => setSocietyStatus(event.target.value)}
                                errorMessage="Veuillez choisir un statut juridique"
                                helpMessage="Choisir un statut de société dans le menu déroulant"
                              >
                                <option key="null">Choisir ...</option>
                                {societyTypes.map( type => (
                                  <option key={type.id} value={type.id}>{type.name}</option>
                                ))}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code NAF <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeNaf"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeNaf(event.target.value)}
                                errorMessage="Veuillez renseigner un code NAF"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">SIRET <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeSiret"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeSiret(event.target.value)}
                                errorMessage="Veuillez renseigner un SIRET"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code TVA <span className="text-danger">*</span></h5>
                              <AvField
                                name="entCodeTva"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setEntCodeTva(event.target.value)}
                                errorMessage="Veuillez renseigner un code TVA"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Effectif de l'organisation <span className="text-danger">*</span></h5>
                              <AvField
                                name="entEffectif"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={event => setEntEffectif(event.target.value)}
                                errorMessage="Veuillez renseigner un nombre"
                                required
                              />
                            </div>


                          </div>
                        </Col>
                      </Row>

                      <SuccessErrorAlert error={error} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Ajouter</Button>
                        </div>

                        <Link to="/gestion-organisations" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des organisations
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientAccountAdd)
