import React from "react"
import {Badge} from "reactstrap";

const UserFicheMissionDetailColumn = (toggleModalSkillUpgrade) => [

  {
    dataField: "skillDomain",
    text: "Domaine de compétences",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>{column}</p>
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "skillTask",
    text: "Tâches",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>{column}</p>
    }
  },

  {
    dataField: "upgradeStatus",
    text: "Mon évaluation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      if (!row.upgradeStatus) {
        return (
          <div className="flex justify-content-between">
            { row.colorRiskUpgrade === 'very-good' && <Badge className="badge badge-cartography pill bg-risk-very-good" pill>&ensp;{row.messageUpgrade}&ensp;</Badge>}
            { row.colorRiskUpgrade === 'good' && <Badge className="badge badge-cartography pill bg-risk-good" pill>&ensp;{row.messageUpgrade}&ensp;</Badge>}
            { row.colorRiskUpgrade === 'medium' && <Badge className="badge badge-cartography pill bg-risk-medium" pill>&ensp;{row.messageUpgrade}&ensp;</Badge>}
            { row.colorRiskUpgrade === 'bad' && <Badge className="badge badge-cartography pill bg-risk-bad" pill>&ensp;{row.messageUpgrade}&ensp;</Badge>}
            { row.colorRiskUpgrade === 'not-concerned' && <Badge className="badge badge-cartography pill bg-risk-not-concerned" pill>&ensp;{row.messageUpgrade}&ensp;</Badge>}

            <button
              type="button"
              className="btn btn-link waves-effect px-3 text-primary"
              onClick={() => toggleModalSkillUpgrade(
                row.messageUpgrade,
                row.colorRiskUpgrade,
                row.employeeSkillId,
                row.cartography.cartography.id
              )}
              data-toggle="modal"
              data-target="modal-delete"
            >
              <i className="uil uil-pen font-size-18"></i>
            </button>
          </div>
        )
      } else if (row.upgradeStatus === 'accord') {
        return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>Mise à niveau acceptée</p>
      } else if (row.upgradeStatus === 'refus') {
        return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>Mise à niveau refusée</p>
      } else if (row.upgradeStatus === 'attente') {
        return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>Mise à niveau en attente</p>
      }

    }
  },

  {
    dataField: "risk",
    text: "L’évaluation de mes compétences par l’entreprise",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    editable: false,
    formatter: (cellContent, row) => {
      if (row.risk === 'very-good') {
        return <Badge className="badge badge-cartography pill bg-risk-very-good" pill>&ensp;{row.message}&ensp;</Badge>
      } else if (row.risk === 'good') {
        return <Badge className="badge badge-cartography pill bg-risk-good" pill>&ensp;{row.message}&ensp;</Badge>
      } else if (row.risk === 'medium') {
        return <Badge className="badge badge-cartography pill bg-risk-medium" pill>&ensp;{row.message}&ensp;</Badge>
      } else if (row.risk === 'bad') {
        return <Badge className="badge badge-cartography pill bg-risk-bad" pill>&ensp;{row.message}&ensp;</Badge>
      } else if (row.risk === 'not-concerned') {
        return <Badge className="badge badge-cartography pill bg-risk-not-concerned" pill>&ensp;{row.message}&ensp;</Badge>
      } else if (row.risk === 'default') {
        return <Badge className="badge badge-cartography pill text-primary" pill>Non renseigné</Badge>
      }
    },
  },

  {
    dataField: "objectif",
    text: "Objectifs",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      return (
        <>
          { row.colorRiskObjectif === 'very-good' && <Badge className="badge badge-cartography pill bg-risk-very-good" pill>&ensp;</Badge>}
          { row.colorRiskObjectif === 'good' && <Badge className="badge badge-cartography pill bg-risk-good" pill>&ensp;</Badge>}
          { row.colorRiskObjectif === 'medium' && <Badge className="badge badge-cartography pill bg-risk-medium" pill>&ensp;</Badge>}
          { row.colorRiskObjectif === 'bad' && <Badge className="badge badge-cartography pill bg-risk-bad" pill>&ensp;</Badge>}
          { row.colorRiskObjectif === 'not-concerned' && <Badge className="badge badge-cartography pill bg-risk-not-concerned" pill>&ensp;</Badge>}

          <p className="mb-0 mt-2" style={{minWidth: '80px'}}>{row.objectif}</p>
        </>
      )
    }
  },
]

export default UserFicheMissionDetailColumn
