import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  FORM_DATAS,
  GET_LOCALITIES,
  GET_SOCIETY_TYPES,
  GLOBAL_DATA_SUCCESS,
  GLOBAL_DATA_ERROR
} from "./globalDataSlice"

import { getLocalities, getSocietyTypes } from "helpers/request_helper"

function* viewFormDatas() {
  try {
    const localities = yield call(getLocalities)
    yield put(GET_LOCALITIES(localities['hydra:member']))
    const societyTypes = yield call(getSocietyTypes)
    yield put(GET_SOCIETY_TYPES(societyTypes['hydra:member']))
    yield put(GLOBAL_DATA_SUCCESS(""))
  } catch (error) {
    yield put(GLOBAL_DATA_ERROR(error.message))
  }
}

export function* watchProfile() {
  yield takeEvery(FORM_DATAS.type, viewFormDatas)
}

export default function* GlobalDataSaga() {
  yield all([fork(watchProfile)])
}
