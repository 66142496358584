import React, {useState} from "react"
import PropTypes from "prop-types"
import {Input, Modal} from "reactstrap"
import {map} from "lodash";

// Redux
import {useSelector} from "react-redux";
import {selectSkill} from "store/skillsDevelopmentPlan/skill/skillSlice";
import {selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

const FormationPlanDomainSelectActionModal = ({ isOpen, toggle, domainSelectAction}) => {

  const {skillDomains} = useSelector(selectSkill);

  const [skillDomainId, setSkillDomainId] = useState('')
  const { trainingPlanAction } = useSelector(selectTrainingPlan)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Choisir une compétence</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

          <Input
            className="form-select"
            type="select"
            name="skillDomainId"
            validate={{required: {value: false}}}
            onChange={event => setSkillDomainId(event.target.value)}
            >
            <option key="default" value="">Choisir</option>
            {map(skillDomains, (domain, key) => (
              <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
            ))}
          </Input>

      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={() => domainSelectAction(skillDomainId, trainingPlanAction.id)}>Valider la compétence</button>
        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default FormationPlanDomainSelectActionModal

FormationPlanDomainSelectActionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
