import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

const CartographyDeleteModal = ({ isOpen, toggle, deleteCartography}) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Supprimer la cartographie?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Actuellement, la cartographie ne contient aucune données.</p>
        <p>Confirmez-vous la suppression de la cartographie ?</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={() => deleteCartography()}>Supprimer</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default CartographyDeleteModal

CartographyDeleteModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
