import React from "react"
import PropTypes from "prop-types"
import {Badge, Col, Modal, Row} from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ModalRow = ({ title, content }) => (
  <div className="modal-body-row">
    <span className="modal-body-row__title">{title} : </span>
    <span className="modal-body-row__content">{content}</span>
  </div>
)

const FormationPlanDetailFormationInternalModal = ({ isOpen, toggle}) => {

  const { trainingPlanAction } = useSelector(selectTrainingPlan)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-xl"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Détail de la formation interne</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

        <ModalRow
          title="Intitulé de la formation"
          content={trainingPlanAction.hasOwnProperty('formationInternal') ? trainingPlanAction.formationInternal.title : 'Pas de formation'}
        />

        <div className="modal-body-row mx-0 mt-2 d-flex">
          <span className="modal-body-row__title">Echéance (certificat/habilitation) : </span>
          <span className="mx-2 font-size-14">
                {
                  trainingPlanAction.certificateExpiration
                    ? moment.utc(trainingPlanAction.certificateExpiration).locale('fr', localization).format("DD/MM/yyyy")
                    : 'Aucune date renseignée'
                }
              </span>
        </div>

        <Row>
          <Col sm="12" md="6">

            <ModalRow
              title="Prestataire"
              content="Formation interne"
            />

            <ModalRow
              title="Début de la formation"
              content={trainingPlanAction.trainingStart ? `Le ${moment.utc(trainingPlanAction.trainingStart).locale('fr', localization).format("DD/MM/yyyy [à] HH[h]mm")}` : 'Non renseigné'}
            />

            <ModalRow
              title="Coût pédagogique"
              content={trainingPlanAction.trainingCostTotal ? `${trainingPlanAction.trainingCostTotal} €` : ''}
            />

            <ModalRow
              title="Type de financement"
              content={trainingPlanAction.hasOwnProperty('paymentFundingType') && trainingPlanAction.paymentFundingType.name ? `${trainingPlanAction.paymentFundingType.name}` : 'Non renseigné'}
            />


            <div className="modal-body-row mx-5 mt-4 d-flex">
              <span className="modal-body-row__title">Formation réalisée : </span>
              <span className="mx-2">
                {
                  trainingPlanAction.finished
                    ? <Badge className="badge bg-pill bg-soft-success" color="dark" pill>Oui</Badge>
                    : <Badge className="badge bg-pill bg-soft-warning" color="dark" pill>Non</Badge>
                }
              </span>
            </div>

          </Col>

          <Col sm="12" md="6">

            <ModalRow
              title="Lieu"
              content={trainingPlanAction.trainingPlace ? trainingPlanAction.trainingPlace : 'Non renseigné'}
            />

            <ModalRow
              title="Fin de la formation"
              content={trainingPlanAction.trainingEnd ? `Le ${moment.utc(trainingPlanAction.trainingEnd).locale('fr', localization).format("DD/MM/yyyy [à] HH[h]mm")}` : 'Non renseigné'}
            />

            <ModalRow
              title="Coût horaire"
              content={trainingPlanAction.trainingCostHour ? `${trainingPlanAction.trainingCostHour} €` : 'Non renseigné'}
            />

            <ModalRow
              title="Montant financé"
              content={trainingPlanAction.paymentFinanced ? `${trainingPlanAction.paymentFinanced} €` : 'Non renseigné'}
            />

            <div className="modal-body-row mx-5 mt-4">
              <span className="modal-body-row__title">Attestation reçue : </span>
              <span className="mx-2">
                {
                  trainingPlanAction.certificateReceived
                    ? <Badge className="badge bg-pill bg-soft-success" color="dark" pill>Oui</Badge>
                    : <Badge className="badge bg-pill bg-soft-warning" color="dark" pill>Non</Badge>
                }
              </span>
            </div>

          </Col>
        </Row>

      </div>

    </Modal>
  )
}

export default FormationPlanDetailFormationInternalModal

FormationPlanDetailFormationInternalModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
