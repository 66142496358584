import React from "react"
import PropTypes from "prop-types"
import {Modal} from "reactstrap"

// REDUX
import {useSelector} from "react-redux";
import {selectFormation} from "store/formation/formationSlice";

const UserCatalogueFormationTrainingRequestModal = ({ isOpen, toggle, trainingRequest }) => {

  const { formation } = useSelector(selectFormation);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Demander cette formation à mon entreprise ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Vous êtes intéressé par la formation : {formation.title}</p>
        <p>Lors de l'envoi de la demande, un mail sera envoyé à votre entreprise.</p>
      </div>

      <div className="modal-footer">

        <button
          type="button"
          className="btn btn-success"
          onClick={() => trainingRequest()}
        >
          Envoyer la demande
        </button>

        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default UserCatalogueFormationTrainingRequestModal

UserCatalogueFormationTrainingRequestModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
