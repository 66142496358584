import axios from "axios"

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({ baseURL: API_URL })

/* ==================== REFRESH TOKEN  ============================================ */

function getLocalAccessToken() {
  const accessToken = localStorage.getItem("accessJWT");
  return accessToken;
}

function getLocalRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

axiosApi.interceptors.request.use(

  (config) => {

    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let PENDING_REQUESTS = 0

axiosApi.interceptors.response.use(

  response => response,

  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Lors de l'expiration du token
      if (err.response.status === 401 && PENDING_REQUESTS < 15) {

        PENDING_REQUESTS++

        try {
          // on fait une requête pour vérification du refresh token
          const response = await refreshToken();
          // on récupère en réponse de la requête le nouveau token généré et on met à jour le local storage
          // (token identique à l'ancien mais avec une nouvelle date d'expiration)
          const { token } = response.data;
          localStorage.setItem("accessJWT", token);
          // on relance une vérification du nouveau token
          axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          PENDING_REQUESTS = 0

          return axiosApi(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
)

function refreshToken() {
  return axiosApi.post("/token/refresh", {
    refresh_token: getLocalRefreshToken(),
  });
}

/* ================================================================ */

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
