import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import {Collapse} from "reactstrap"
import {Link, NavLink, withRouter} from "react-router-dom"
import classname from "classnames"

// Redux
import {useSelector} from "react-redux";
import {selectLayout} from "store/layout/layoutSlice";

// Role Verification
import {verifRoleToken} from "helpers/role_helper"

const Navbar = props => {

  const layout = useSelector(selectLayout);

  const [account, setAccount] = useState(false)
  const [transaction, setTransaction] = useState(false)
  const [organisation, setOrganisation] = useState(false)
  const [prestataire, setPrestataire] = useState(false)
  const [formation, setFormation] = useState(false)
  const [formationInternal, setFormationInternal] = useState(false)
  const [team, setTeam] = useState(false)
  const [skill, setSkill] = useState(false)

  useEffect(() => {

    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")

    for (let i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={layout.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">

                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard">
                    <i className="uil-home-alt"></i>
                    {" "}Tableau de bord
                  </NavLink>
                </li>

                {verifRoleToken('ROLE_ADMIN') && (
                  <>
                    <li className="nav-item dropdown">
                      <NavLink
                        to="/gestion-comptes"
                        onClick={e => {
                          //e.preventDefault()
                          setAccount(!account)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="uil-users-alt"></i>
                        Comptes <div className="arrow-down text-dark"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: account})}>

                        <div className="dropdown">
                          <Link to="/gestion-comptes" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des utilisateurs</Link>
                          <Link to="/ajout-super-administrateur" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter un super administrateur</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/gestion-organisations"
                        onClick={e => {
                          //e.preventDefault()
                          setOrganisation(!organisation)
                        }}
                      >
                        <i className="uil-house-user"></i>
                        Organisations <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: organisation})}>

                        <div className="dropdown">
                          <Link to="/gestion-organisations" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des organisations</Link>
                          <Link to="/ajout-organisation" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter une organisation</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/gestion-transactions"
                        onClick={e => {
                          //e.preventDefault()
                          setTransaction(!transaction)
                        }}
                      >
                        <i className="uil-shopping-cart"></i>
                        Transactions <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: transaction})}>

                        <div className="dropdown">
                          <Link to="/gestion-transactions" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des transactions</Link>
                          <Link to="/ajout-transaction" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter une transaction</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/gestion-prestataires"
                        onClick={e => {
                          //e.preventDefault()
                          setPrestataire(!prestataire)
                        }}
                      >
                        <i className="uil-channel"></i>
                        Prestataires <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: prestataire})}>

                        <div className="dropdown">
                          <Link to="/gestion-prestataires" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des prestataires</Link>
                          <Link to="/ajout-prestataire" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter un prestataire</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/gestion-formations"
                        onClick={e => {
                          //e.preventDefault()
                          setFormation(!formation)
                        }}
                      >
                        <i className="uil-graduation-cap"></i>
                        Catalogue de formation <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: formation})}>

                        <div className="dropdown">
                          <Link to="/gestion-formations" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des formations</Link>
                          <Link to="/ajout-formation" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter une formation</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/gestion-formations-internes"
                        onClick={e => {
                          //e.preventDefault()
                          setFormationInternal(!formationInternal)
                        }}
                      >
                        <i className="uil-graduation-cap"></i>
                        Formations internes <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: formationInternal})}>

                        <div className="dropdown">
                          <Link to="/gestion-formations-internes" className="dropdown-item dropdown-toggle arrow-none color-text">Catalogue de formations internes</Link>
                          <Link to="/ajout-formation-interne" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter une formation interne</Link>
                        </div>

                      </div>
                    </li>
                  </>
                )}

                {verifRoleToken('ROLE_CLIENT') && (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/mon-espace">
                        <i className="uil-book"></i>
                        {" "}Mon espace
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        to="/liste-collaborateurs"
                        onClick={e => {
                          //e.preventDefault()
                          setTeam(!team)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="uil-users-alt"></i>
                        Mon équipe <div className="arrow-down text-dark"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: team})}>

                        <div className="dropdown">
                          <Link to="/fiches-mission" className="dropdown-item dropdown-toggle arrow-none color-text">Fiche de mission</Link>
                          <Link to="/liste-collaborateurs" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des collaborateurs</Link>
                          <Link to="/liste-poles" className="dropdown-item dropdown-toggle arrow-none color-text">Liste des pôles</Link>
                          {/*<Link to="/organigramme" className="dropdown-item dropdown-toggle arrow-none color-text">Organigramme</Link>*/}
                        </div>

                      </div>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/ma-cartographie">
                        <i className="uil-map"></i>
                        {" "}Cartographie
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/mon-plan-developpement-competences">
                        <i className="uil-briefcase"></i>
                        {" "}PDC
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/mon-catalogue-de-formation">
                        <i className="uil-books"></i>
                        {" "}Catalogue de formation
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/mes-formations-internes"
                        onClick={e => {
                          //e.preventDefault()
                          setFormationInternal(!formationInternal)
                        }}
                      >
                        <i className="uil-graduation-cap"></i>
                        Formations internes <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: formationInternal})}>

                        <div className="dropdown">
                          <Link to="/mes-formations-internes" className="dropdown-item dropdown-toggle arrow-none color-text">Catalogue de formations internes</Link>
                          <Link to="/mes-formations-internes-ajout" className="dropdown-item dropdown-toggle arrow-none color-text">Ajouter une formation interne</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/formation-autrement">
                        <i className="uil-graduation-cap"></i>
                        {" "}Se former autrement
                      </NavLink>
                    </li>
                  </>
                )}

                {verifRoleToken('ROLE_USER') && (
                  <>
                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="/ma-fiche-de-mission"
                        onClick={e => {
                          //e.preventDefault()
                          setSkill(!skill)
                        }}
                      >
                        <i className="uil-briefcase"></i>
                        Mes compétences <div className="arrow-down"></div>
                      </NavLink>

                      <div className={classname("dropdown-menu", {show: skill})}>

                        <div className="dropdown">
                          <Link to="/ma-fiche-de-mission" className="dropdown-item dropdown-toggle arrow-none color-text">Fiche de mission</Link>
                          <Link to="/mon-plan-de-charge" className="dropdown-item dropdown-toggle arrow-none color-text">Plan de charge</Link>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/catalogue-de-formation">
                        <i className="uil-books"></i>
                        {" "}Catalogue de formation
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/se-former-autrement">
                        <i className="uil-graduation-cap"></i>
                        {" "}Se former autrement
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/mon-espace-personnel">
                        <i className="uil-user-square"></i>
                        {" "}Mon espace personnel
                      </NavLink>
                    </li>
                  </>
                )}


                {verifRoleToken('ROLE_PRESTATAIRE') && (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/prestataire-informations">
                        <i className="uil-user-square"></i>
                        {" "}Mes informations
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/prestataire-intervenants">
                        <i className="uil-users-alt"></i>
                        {" "}Gestion des intervenants
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/prestataire-formations">
                        <i className="uil-graduation-cap"></i>
                        {" "}Mes formations
                      </NavLink>
                    </li>
                  </>
                )}

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(Navbar)
