import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Badge, Button, Card, CardBody, Col, Container, Row, UncontrolledAlert} from "reactstrap"
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount, CLIENT_ACCOUNT_INTERVENTION } from "store/account/clientAccount/clientAccountSlice";
import {ADD_INTERVENTION, DELETE_INTERVENTION, ARCHIVED_INTERVENTION} from "store/intervention/interventionSlice";
import {CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// Components
import CartographyRemoveArchiveModal from "components/Common/Modal/Cartography/CartographyRemoveArchiveModal";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ClientAccountDetail = ({isRole, location}) => {

  const dispatch = useDispatch()
  const { client, successForm, error } = useSelector(selectClientAccount);

  // récupération de l'id passé dans la route
  const clientId = location.state

  const [showModalCartographyArchived, setShowModalCartographyArchived] = useState(false)

  const toggleModalCartographyArchived = (cartographyId) => {
    dispatch(CARTOGRAPHY({ cartographyId }))
    setShowModalCartographyArchived(!showModalCartographyArchived);
  };

  const createInterventionDiagnostic = () => {
    dispatch(ADD_INTERVENTION({ clientId: clientId }))
  }

  const deleteIntervention = (interventionId) => {
    dispatch(DELETE_INTERVENTION({ clientId: clientId, interventionId: interventionId }))
  }

  const removeArchiveCartography = interventionId => {

    const data = {
      interventionId,
      isArchived: false,
      clientId
    }

    dispatch(ARCHIVED_INTERVENTION(data))
    setShowModalCartographyArchived(!showModalCartographyArchived);
  };

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT_INTERVENTION({ clientId: clientId }))
  }, [dispatch, clientId])

  const trainingPlanDisplay = (intervention) => {
    if (
      intervention.cartography &&
      intervention.cartography.validate &&
      intervention.cartography.trainingPlans.length !== 0
    ) {
      return (
        <>

          <div className="d-flex align-items-center">
            <p className="mb-0">Date de création :</p>
            <p className="mb-0 mx-2">{moment(intervention.cartography.trainingPlans[0].createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>
          </div>

          <div className="mt-3 d-flex align-items-center">

            <p className="mb-1">Statut du plan de développement des compétences : </p>

            {
              intervention.cartography.trainingPlans[0].validate
                ?  (<Badge className="badge bg-pill bg-soft-success mx-3" color="dark" pill>Validé</Badge>)
                :  (<Badge className="badge bg-pill bg-soft-danger mx-3" color="dark" pill>En création et non validé</Badge>)
            }
          </div>

          { intervention.cartography.trainingPlans[0] ? (
            <Link to={{pathname: "/plan-developpement-des-competences", state: intervention.cartography.id}} className="btn btn-primary waves-effect waves-light mx-1 mt-3 mb-5">
              <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Voir le plan de développement des compétences
            </Link>
            ) : (
              <div className="mb-4"></div>
            )
          }

        </>
      )
    }
    else if (
      intervention.cartography &&
      intervention.cartography.validate &&
      intervention.cartography.hasOwnProperty('trainingPlans')
    ) {
      return (
        <Link to={{pathname:`/creation-plan-developpement-des-competences`, state: intervention.cartography.id}} className="btn btn-success waves-effect waves-light mx-1 mb-5">
          <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Créer un plan de développement des compétences
        </Link>
      )
    }
    else {
      return <p>A venir</p>
    }
  }

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <CartographyRemoveArchiveModal isOpen={showModalCartographyArchived} toggle={toggleModalCartographyArchived} removeArchiveCartography={removeArchiveCartography} />

        <div className="page-content my-5">
          <Container fluid>

            <Row>
              <Col sm={8}>
                <Link to="/gestion-organisations" className="btn btn-primary waves-effect waves-light mx-2 mb-5">
                  <i className="uil uil-corner-up-right-alt me-1"></i>Retour à la liste des organisations
                </Link>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="10" className="mb-3">

                {error ? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-exclamation-octagon me-2"></i> Une erreur s'est produite : "{error}"
                  </UncontrolledAlert>
                ) : null}

                {successForm ? (
                  <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-check me-2"></i> {successForm}
                  </UncontrolledAlert>
                ) : null}

              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Informations sur l'organisation</h5>

            <Row className="mb-5">
              <Col md="4">
                <Card className="card h-100">
                  <CardBody className="mx-4">
                    <div className="text-center">

                      {
                        client.hasOwnProperty('user') &&
                        client.user.hasOwnProperty('image')
                          ? (
                            <img
                              className="avatar-title rounded-circle bg-white"
                              style={{ width: '80px', margin: '0 auto' }}
                              src={process.env.REACT_APP_URL + "/media/" + client.user.image.filePath}
                              alt="Header Avatar"
                            />
                          ) : null
                      }

                      <h5 className="mt-3 mb-1">{client.entName}</h5>
                      <p className="text-muted">{client.entCommercialCoverage}</p>

                      {client.hasOwnProperty('locality') && (
                        <p className="text-muted">
                          {client.locality.region.name} ({client.locality.departmentName})
                        </p>
                      )}

                    </div>

                    <hr className="my-4" />

                    <div className="text-muted">
                      <div className="table-responsive mt-4">

                        <div>
                          <p className="mb-1">Représentant :</p>
                          <h5 className="font-size-17">{client.contactFirstname} {client.contactLastname}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Fonction :</p>
                          <h5 className="font-size-17">{client.clientFunction}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Téléphone :</p>
                          <h5 className="font-size-17">{client.phone}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Email :</p>
                          <h5 className="font-size-17">{client.email}</h5>
                        </div>

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="8">

                <Card className="card h-100">
                  <CardBody className="mx-4 mt-3">

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Adresse :</p>
                      <h5 className="font-size-17">{client.entAddress} {client.entZipCode} {client.entCity}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Effectif :</p>
                      <h5 className="font-size-17">{client.entEffectif}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Pays :</p>
                      <h5 className="font-size-17">{client.entCountry}</h5>
                    </div>

                    {client.hasOwnProperty('societyType') && (
                      <div className="mt-3 mx-3">
                        <p className="mb-1">Statut de l'entreprise :</p>
                        <h5 className="font-size-17">{client.societyType.name}</h5>
                      </div>
                    )}

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code NAF :</p>
                      <h5 className="font-size-17">{client.entCodeNaf}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code SIRET :</p>
                      <h5 className="font-size-17">{client.entCodeSiret}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code TVA :</p>
                      <h5 className="font-size-17">{client.entCodeTva}</h5>
                    </div>

                  </CardBody>
                </Card>

              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des collaborateurs de l'organisation</h5>

            <Row>
              <Col sm={8}>
                <Link to={{ pathname: "/gestion-collaborateurs", state: clientId }} className="btn btn-primary waves-effect waves-light mx-2 mb-5">
                  <i className="uil uil-corner-up-right-alt me-1"></i>Gestion des collaborateurs
                </Link>
              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mb-2"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Information sur les interventions</h5>

            {map(client.interventions, intervention => (
              <Row key={intervention.id}>
                <Col lg={12}>
                  <Card className="mb-0 mt-4" style={ intervention.isArchived ? { background: '#FFEBF0' } : { background: 'inherit' } }>
                    <CardBody className="mx-5">

                      {intervention.isArchived && (
                        <Row className="mb-4 align-items-center">
                          <Col md={6}>
                            <p className="text-danger mb-0">Cette cartographie a été supprimée par l'organisation</p>
                          </Col>
                          <Col md={6}>
                            <button onClick={() => toggleModalCartographyArchived(intervention.cartography.id)} type="button" className="btn btn-danger waves-effect waves-light mx-4">
                              <i className="uil uil-corner-up-right-alt me-1"></i>Enlever la suppression
                            </button>
                          </Col>
                        </Row>
                      )}

                      <Row>

                        <Col md={6}>
                          {/* =============== INFORMATION SUR LE(S) CARTOGRAPHIES ================= */}

                          <p className="font-size-17 color-primary mb-4 mt-1"><i className="mdi mdi-arrow-right text-primary me-1"></i>La cartographie</p>

                          {
                            intervention.cartography
                              ? (
                                <Row>
                                  <Col lg={12}>

                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Date de création :</p>
                                      <p className="mb-0 mx-2">{moment(intervention.cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>
                                    </div>

                                    <div className="mt-3 d-flex align-items-center">
                                      <p className="mb-1">Statut de la cartographie : </p>

                                      {
                                        intervention.cartography.validate
                                          ?  (<Badge className="badge bg-pill bg-soft-success mx-3" color="dark" pill>Validé</Badge>)
                                          :  (<Badge className="badge bg-pill bg-soft-danger mx-3" color="dark" pill>En création et non validé</Badge>)
                                      }

                                    </div>

                                    <div className="mt-3">
                                      <Link to={{ pathname: "/cartographie", state: intervention.cartography.id }} className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                        <i className="mdi mdi-grid me-1"></i>Voir la cartographie
                                      </Link>
                                    </div>

                                  </Col>
                                </Row>
                              ) : (
                                <>
                                <Link to={{pathname:`/creation-cartographie`, state: intervention.id}} className="btn btn-primary waves-effect waves-light mx-1">
                                  <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Créer une cartographie
                                </Link>

                                  <div className="mt-3">
                                    <Button
                                      color="primary"
                                      className="btn btn-danger waves-effect waves-light mx-2 mb-3 mt-3 text-white"
                                      onClick={() => deleteIntervention(intervention.id)}
                                      id="btn-new-event"
                                    >
                                      Supprimer l'intervention
                                    </Button>
                                  </div>
                                </>
                              )
                          }
                        </Col>

                        <Col md={6}>

                          {/* =============== INFORMATION SUR LE(S) PLAN DE DEVELOPPEMENT DES COMPETENCES ================= */}

                          <p className="font-size-17 color-primary mb-4 mt-1"><i className="mdi mdi-arrow-right text-primary me-1"></i>Le plan de développement des compétences</p>

                          {trainingPlanDisplay(intervention)}
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}

            <div className="mt-3">
                <Button
                  color="primary"
                  className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3 text-white"
                  onClick={createInterventionDiagnostic}
                  id="btn-new-event"
                >
                  Ajouter une intervention
                </Button>
            </div>


            { !client.interventions && (
              <>
                <p className="mt-3">Aucun diagnostique en cours</p>

                <div className="mt-3">
                  <div className="mt-3">
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3 text-white"
                      onClick={createInterventionDiagnostic}
                      id="btn-new-event"
                    >
                      Commencer un diagnostique
                    </Button>
                  </div>

                </div>
              </>
            )}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientAccountDetail)
