import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  clientEmployees: [],
  clientEmployee: {},
  success: "",
  error: "",
  isLoading: false
}

const clientEmployeeAccountSlice = createSlice({
  name: "clientEmployeeAccount",
  initialState,

  reducers: {

    CLIENT_EMPLOYEES_ACCOUNT (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CLIENT_EMPLOYEE_ACCOUNT (state) {
      return { ...state, isLoading: true, success: "" }
    },

    GET_CLIENT_EMPLOYEES_ACCOUNT (state, action) {
      state.clientEmployees = action.payload
    },

    GET_CLIENT_EMPLOYEE_ACCOUNT (state, action) {
      state.clientEmployee = action.payload
    },

    ADD_CLIENT_EMPLOYEE_ACCOUNT (state) {
      state.isLoading = true
    },

    ADD_NEW_CLIENT_EMPLOYEE_ACCOUNT (state, action) {
      state.clientEmployee = action.payload
    },

    UPDATE_CLIENT_EMPLOYEE_ACCOUNT (state) {
      state.isLoading = true
    },

    UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER (state) {
      state.isLoading = true
    },

    DELETE_CLIENT_EMPLOYEE_ACCOUNT (state) {
      state.isLoading = true
      state.clientEmployee = {}
    },

    ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT (state) {
      state.isLoading = true
    },

    CLIENT_EMPLOYEE_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    CLIENT_EMPLOYEE_ACCOUNT_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR (state) {
      state.success = ""
      state.error = ""
    }
  }
});

const { reducer, actions } = clientEmployeeAccountSlice;

export const {
  CLIENT_EMPLOYEES_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT,
  GET_CLIENT_EMPLOYEES_ACCOUNT,
  GET_CLIENT_EMPLOYEE_ACCOUNT,
  ADD_CLIENT_EMPLOYEE_ACCOUNT,
  ADD_NEW_CLIENT_EMPLOYEE_ACCOUNT,
  UPDATE_CLIENT_EMPLOYEE_ACCOUNT,
  UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER,
  DELETE_CLIENT_EMPLOYEE_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT_SUCCESS,
  CLIENT_EMPLOYEE_ACCOUNT_ERROR,
  ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT,
  CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR
} = actions;

export default reducer;

export const selectClientEmployeeAccount = state => state.clientEmployeeAccount;
