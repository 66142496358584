import React, {useEffect} from "react"
import {Redirect, withRouter} from "react-router-dom"
import {Col, Container, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount } from "store/account/clientAccount/clientAccountSlice";
import {TRAINING_PLAN_BY_CLIENT} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {CARTOGRAPHY, CURRENT_CARTOGRAPHY_ID, selectCartography} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {selectAdvert, ADVERTS} from "store/advert/advertSlice";

// Components
import ClientFormationPlan from "./FormationPlan/ClientFormationPlan";
import AdvertCard from "components/Common/Advert/AdvertCard";

import placeholder from "assets/images/404-error.png"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';
import {selectTrainingPlan} from "../../../store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

const ClientPdc = ({isRole, location}) => {

  const clientId = Number(localStorage.getItem('clientId'))

  const { currentCartographyId } = useSelector(selectCartography);

  const dispatch = useDispatch()

  const { client } = useSelector(selectClientAccount);
  const { trainingPlan } = useSelector(selectTrainingPlan);
  const { adverts } = useSelector(selectAdvert);

  /* ============ start Searchbar Cartography =================  */

  const handleSelectTrainingPlanSubmit = () => {}

  /* ===========================================================  */

  /* ============ tri des PDC en ordre décroissant =================  */

  const interventionsDescending = (interventions) => {

    const allInterventions = interventions.filter(intervention => {
        if (intervention.hasOwnProperty('cartography') && !intervention.isArchived && intervention.cartography.trainingPlans[0] && intervention.cartography.validate) {
          return intervention
        }
    })
    return(allInterventions.reverse())
  }

  /* ===========================================================  */

  useEffect(() => {
    dispatch(TRAINING_PLAN_BY_CLIENT({ clientId: clientId }))
    //dispatch(CURRENT_CARTOGRAPHY_ID(0))
    dispatch(ADVERTS())
  }, [dispatch, clientId])

  const trainingPlanDisplay = () => {

    const carto = client.interventions.find( intervention =>
      intervention.cartography &&
      !intervention.isArchived &&
      intervention.cartography.validate &&
      intervention.cartography.trainingPlans.length !== 0)

    if (carto) {
      if (currentCartographyId !== 0) {
        return <ClientFormationPlan cartographyId={currentCartographyId} />
      } else {
        return <p>Veuillez choisir un Plan de développement des compétences dans le menu déroulant</p>
      }
    } else {
      return (
        <>
          <p className="mb-4 pt-4">Actuellement, aucun plan de développement des compétences n'est présent.</p>
          <p className="mb-4 pt-4">Une première cartographie doit être crée et validée auparavant.</p>
        </>
      )
    }
  }

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-3">
              <Col md="12">

                {map(adverts, (advert, index) => {
                  if (index === 0 && advert.isActive) {
                    return <AdvertCard
                      key={index}
                      img={
                        advert.image.hasOwnProperty('filePath')
                          ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                          : placeholder}
                      message={advert.message}
                      link={advert.link}
                      isTransparent={true}
                    />
                  } else {
                    return null
                  }
                })}

              </Col>
            </Row>

            <h5 className="font-size-22 color-secondary text-center mb-5">Plan de développement des compétences</h5>

            <AvForm onValidSubmit={(e, v) => handleSelectTrainingPlanSubmit(e, v)}>
              <Row className="align-items-center pb-5 mb-4" style={{ borderBottom: '1px solid #597887' }}>

                <Col lg={4}>
                  <h5 className="font-size-17">Choisir un plan de développement des compétences</h5>
                </Col>

                <Col lg={5}>
                  <AvField
                    className="form-select"
                    type="select"
                    name="cartography"
                    onChange={event => {
                      dispatch(CURRENT_CARTOGRAPHY_ID(event.target.value))
                      dispatch(CARTOGRAPHY({ cartographyId: event.target.value }))
                    }}
                    errorMessage="Choisir un plan de développement des compétences"
                  >
                    { currentCartographyId === 0
                      ? <option key="default" value="default">Choisir</option>
                      : <option key="default" value="default">{trainingPlan.title} ({moment(trainingPlan.createdAt).locale('fr', localization).format("DD MMMM yyyy")})</option>
                    }

                    {client.hasOwnProperty('interventions') && map(interventionsDescending(client.interventions), (intervention, key) => {
                      if (intervention.hasOwnProperty('cartography')) {
                        if (intervention.cartography.trainingPlans[0] && intervention.cartography.validate) {
                          return (
                            <>
                              <option key={`pdc-${intervention.cartography.trainingPlans[0].id}`} value={intervention.cartography.id}>
                                {intervention.cartography.trainingPlans[0]?.title} ({moment(intervention.cartography.trainingPlans[0]?.createdAt).locale('fr', localization).format("DD MMMM yyyy")})
                              </option>
                            </>
                          )
                        }
                      }
                    })}
                  </AvField>
                </Col>
              </Row>
            </AvForm>


            {/* =============== PLAN DE DEVELOPPEMENT DES COMPETENCES ================= */}

            { (client.hasOwnProperty('interventions') && client.interventions) ? (

              <Row>
                <Col lg={12}>
                  {trainingPlanDisplay()}
                </Col>
              </Row>
            ) : (
              <>
                <p className="mt-3">Aucun diagnostique en cours</p>
                <p className="mt-3">Veuillez créer un cartographie avant de pouvoir faire votre plan de développement des compétences</p>
              </>
            )}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientPdc)
