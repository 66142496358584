function capitalizeFirstLetter(string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}

function Arrondir( nbr, nbApVirg ) {
    return ( parseInt(nbr * Math.pow(10,nbApVirg) + 0.5) ) / Math.pow(10,nbApVirg);
}


function formatMillier( nbr ) {
    let nbrArround = Arrondir(nbr, 2);
    return new Intl.NumberFormat().format( nbrArround );
}

// Génération d'un mot de passe sécurisé

const dataLowercase = "azertyuiopqsdfghjklmwxcvbn".split('');
const dataUppercase = "AZERTYUIOPQSDFGHJKLMWXCVBN".split('');
const dataNumbers = "0123456789".split('');
//const dataSymbols = "!@#$%^&*-_=+\|:;',.>/?~".split('');

function generatePassword() {

    let passwordLength = 12;
    let newPassword = '';

    const data = [].concat(dataLowercase, dataUppercase, dataNumbers);

    for (let i = 0; i < passwordLength; i++) {
        newPassword += data[Math.floor(Math.random() * data.length)];
    }
    return newPassword
}

function generateUniqueId() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

export { capitalizeFirstLetter, generatePassword, formatMillier, generateUniqueId }
