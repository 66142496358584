import React, {useEffect} from "react"
import {Col, Container, Row} from "reactstrap"
//import {Card, CardBody, Media, Label} from "reactstrap"
import {Redirect} from "react-router-dom"
//import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
//import BootstrapTable from "react-bootstrap-table-next"
//import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// formatage date
//import moment from "moment";
//import localization from 'moment/locale/fr';

// Components
//import TransactionAllColumn from "./TransactionAllColumn";


const TransactionAll = ({ isRole, location }) => {
/*
  const clientInvoices = [
    {
      id: 1,
      date: "10 Juillet 2021",
      clientName: "Mamie mesure",
      trainingCompany: "Insaniam",
      trainingSelectedTotal: 3,
      priceTotal: 1867,
      edcMargin: 123,
      color: "success",
      status:"Payé",
    },
    {
      id: 2,
      date: "23 Octobre 2021",
      clientName: "Ma jolie licorne",
      trainingCompany: "Insaniam",
      trainingSelectedTotal: 5,
      priceTotal: 2768,
      edcMargin: 345,
      color: "warning",
      status:"En attente",
    }
  ]

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const paginateOptions = {
    sizePerPage: 10,
    totalSize: clientInvoices.length,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }
*/
  useEffect(() => {
    //dispatch(AUDITORS_ACCOUNT())
  }, [])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-5">
              <Col md="12">
                <p>Cette fonctionnalité est en cours de développement. Merci pour votre patience.</p>
              </Col>
            </Row>

            {/*
            <Row>
              <Col lg="12">

                <Card className="mt-3 mb-5">
                  <CardBody className="bg-gradient-primary">
                    <Media className="d-flex">

                      <Media body className="flex-1 align-self-center">
                        <div className="text-muted">
                          <h5 className="mb-3 text-white">Liste des transactions</h5>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">

                  <Link to="/ajout-transaction" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Ajouter une transaction
                  </Link>

                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                  {({paginationProps, paginationTableProps}) => (
                    <ToolkitProvider
                      keyField="id"
                      data={clientInvoices || []}
                      columns={TransactionAllColumn()}
                      bootstrap4
                      search
                      exportCSV={ { fileName: `liste des factures organisation (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row>

                            <Col sm="12" md="6">
                              <Label>
                                <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                                  Exporter le tableau en CSV
                                </ExportCSVButton>
                              </Label>
                            </Col>

                            <Col sm="12" md="6">
                              <Label className="float-end">{" "}
                                <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                              </Label>
                            </Col>

                          </Row>

                          <div className="table-responsive mb-4">

                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                              keyField="id"
                              hover={true}
                              headerWrapperClasses={"bg-transparent"}
                              {...toolkitProps.baseProps}
                              //onTableChange={handleTableChange}
                              {...paginationTableProps}
                            />
                            <div className="float-end">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </div>

                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
            */}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default TransactionAll
