import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  FORMATIONS,
  FORMATION,
  GET_FORMATIONS,
  GET_FORMATION,
  ADD_FORMATION,
  ADD_NEW_FORMATION,
  ADD_FORMATION_PRESTATAIRE_LINK,
  UPDATE_FORMATION,
  DELETE_FORMATION,
  DELETE_FORMATION_PRESTATAIRE_LINK,
  FORMATION_SUCCESS,
  ADD_FORMATION_SUCCESS,
  FORMATION_ERROR,
  FORMATION_STATUS,
  GET_FORMATION_STATUS,
  FORMATION_DATE,
  GET_FORMATION_DATE,
  ADD_FORMATION_DATE,
  DELETE_FORMATION_DATE,
  FORMATION_DEVIS_REQUEST_BY_USER,
  FORMATION_TRAINING_REQUEST_BY_USER
} from "./formationSlice"

import {GET_PRESTATAIRE, PRESTATAIRE} from "../prestataire/prestataireSlice";

import {
  getFormations,
  getFormation,
  postFormation,
  putFormation,
  deleteFormation,
  putFormationPrestataireLink,
  getFormationStatus,
  getFormationDate,
  postFormationDate,
  deleteFormationDate,
  getPrestataire,
  postdevisRequestByUser,
  postdevisTrainingByUser
} from "helpers/request_helper"
import {getProfile} from "../../helpers/request_helper";


function* viewFormations() {
  try {
    const response = yield call(getFormations)
    yield put(GET_FORMATIONS(response['hydra:member']))
    yield put(FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* viewFormation({ payload: { formationId } }) {
  try {
    const response = yield call(getFormation, formationId)
    yield put(GET_FORMATION(response))

    const prestataire = yield call(getPrestataire, response.prestataire.id);
    yield put(GET_PRESTATAIRE(prestataire));

    yield put(FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* addNewFormation({payload: {
  title, description, targetAudience, duration, durationUnit, priceHT, priceTTC, accessDelay, disabledAccessibility,
  modalities, evaluation, necessaryMaterial, satisfactionRate, successRate, equivalences, cpfEligible, cpfCode,
  cpfDesignation, prerequisite, educationalMethods, educationalTechnics, market, sanction, activeFormation,
  formationStatus, objectifs, programme, benefices, formationTrainingStatus, skillDomain, prestataire, role, history
}}) {

  try {

    const data = {
      title: title,
      description: description,
      targetAudience: targetAudience,
      duration: duration,
      durationUnit: durationUnit,
      priceHT: priceHT,
      priceTTC: priceTTC,
      accessDelay: accessDelay,
      disabledAccessibility: disabledAccessibility,
      modalities: modalities,
      evaluation: evaluation,
      necessaryMaterial: necessaryMaterial,
      satisfactionRate: satisfactionRate,
      successRate: successRate,
      equivalences: equivalences,
      cpfEligible: cpfEligible,
      cpfCode: cpfCode,
      cpfDesignation: cpfDesignation,
      objectifs: objectifs,
      prerequisite: prerequisite,
      programme: programme,
      educationalMethods: educationalMethods,
      educationalTechnics: educationalTechnics,
      market: market,
      sanction: sanction,
      benefices: benefices,
      activeFormation: activeFormation,
      formationStatus: formationStatus,
      formationTrainingStatus: formationTrainingStatus,
      skillDomain: skillDomain,
      prestataire: prestataire
    }

    const response = yield call(postFormation, data)
    yield put(ADD_NEW_FORMATION(response))
    yield put(FORMATION_SUCCESS("Ajout de la formation avec succès"))

    if (role === "prestataire") {
      history.push({ pathname: "/prestataire-formation-details", state: response.id })
    } else if (role === "admin") {
      history.push({ pathname: "/details-formation", state: response.id })
    }

  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* editFormation({ payload: {
  title, description, targetAudience, duration, durationUnit, priceHT, priceTTC, accessDelay, disabledAccessibility,
  modalities, evaluation, necessaryMaterial, satisfactionRate, successRate, equivalences, cpfEligible, cpfCode,
  cpfDesignation, prerequisite, educationalMethods, educationalTechnics, market, sanction, activeFormation,
  formationStatus, formationId, objectifs, programme, formationTrainingStatus, benefices, skillDomain
} }) {
  try {
    const response = yield call(putFormation, {
      title: title,
      description: description,
      targetAudience: targetAudience,
      duration: duration,
      durationUnit: durationUnit,
      priceHT: priceHT,
      priceTTC: priceTTC,
      accessDelay: accessDelay,
      disabledAccessibility: disabledAccessibility,
      modalities: modalities,
      evaluation: evaluation,
      necessaryMaterial: necessaryMaterial,
      satisfactionRate: satisfactionRate,
      successRate: successRate,
      equivalences: equivalences,
      cpfEligible: cpfEligible,
      cpfCode: cpfCode,
      cpfDesignation: cpfDesignation,
      objectifs: objectifs,
      prerequisite: prerequisite,
      programme: programme,
      educationalMethods: educationalMethods,
      educationalTechnics: educationalTechnics,
      market: market,
      sanction: sanction,
      benefices: benefices,
      activeFormation: activeFormation,
      formationStatus: formationStatus,
      formationTrainingStatus: formationTrainingStatus,
      formationId: formationId,
      skillDomain: skillDomain
    })
    yield put(GET_FORMATION(response))
    yield put(ADD_FORMATION_SUCCESS("Les informations de la formation ont bien été modifiées"))
  } catch (error) {
    yield put(FORMATION_ERROR(error))
  }
}


function* addFormationPrestataireLink({payload: { formationId, prestataire, history }}) {

  const data = {
    formationId: formationId,
    prestataire: prestataire
  }

  try {
    yield call(putFormationPrestataireLink, data)
    yield put(FORMATION_SUCCESS("Le prestataire a été ajouté"))
    history.push("/gestion-formations")
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* removeFormation({ payload: { formationId, role, prestataireId } }) {
  try {
    yield call(deleteFormation, formationId)
    const response = yield call(getFormations)

    if (role === "prestataire") {
      yield put(PRESTATAIRE({prestataireId: prestataireId}))
    } else if (role === "admin") {
      yield put(GET_FORMATIONS(response['hydra:member']))
    }

    yield put(ADD_FORMATION_SUCCESS(`Suppression de la formation avec succès !!`))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* removeFormationPrestataireLink({ payload: { formationId, prestataire, history}}) {
  const data = {
    formationId: formationId,
    prestataire: prestataire
  }

  try {
    yield call(putFormationPrestataireLink, data)
    yield put(FORMATION_SUCCESS("La formation a été retiré"))
    history.push({ pathname: "/details-formation", state: {formationId: formationId } })
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* viewFormationStatus() {
  try {
    const response = yield call(getFormationStatus)
    yield put(GET_FORMATION_STATUS(response['hydra:member']))
    yield put(FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* viewFormationDate({ payload: { formationDateId } }) {
  try {
    const response = yield call(getFormationDate, formationDateId)
    yield put(GET_FORMATION_DATE(response))
    yield put(FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* addFormationDate({payload: {formation, formationId, formationStart, formationEnd, place}}) {

  try {

    const data = {
      formationStart: formationStart,
      formationEnd: formationEnd,
      place: place,
      formation: formation,
      formationId: formationId
    }

    yield call(postFormationDate, data)
    yield put (FORMATION({ formationId: formationId }))
    yield put(FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* removeFormationDate({ payload: { formationDateId, formationId } }) {
  try {
    yield call(deleteFormationDate, formationDateId)
    yield put (FORMATION({ formationId: formationId }))
    yield put(ADD_FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* devisRequestByUser({ payload: { formation } }) {
  try {
    const userId = localStorage.getItem('id')
    const userProfile = yield call(getProfile, userId)

    const data = {
      formation: formation,
      client: userProfile.clientEmployee.client,
      clientEmployeeUser: userProfile
    }

    yield call(postdevisRequestByUser, data)

    yield put(ADD_FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}

function* devisTrainingByUser({ payload: { formation } }) {
  try {
    const userId = localStorage.getItem('id')
    const userProfile = yield call(getProfile, userId)

    const data = {
      formation: formation,
      client: userProfile.clientEmployee.client,
      clientEmployeeUser: userProfile
    }
    yield call(postdevisTrainingByUser, data)



    yield put(ADD_FORMATION_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_ERROR(error.message))
  }
}


export function* watchProfile() {
  yield takeEvery(FORMATIONS.type, viewFormations)
  yield takeEvery(FORMATION.type, viewFormation)
  yield takeEvery(FORMATION_STATUS.type, viewFormationStatus)
  yield takeEvery(ADD_FORMATION.type, addNewFormation)
  yield takeEvery(ADD_FORMATION_PRESTATAIRE_LINK.type, addFormationPrestataireLink)
  yield takeEvery(UPDATE_FORMATION.type, editFormation)
  yield takeEvery(DELETE_FORMATION.type, removeFormation)
  yield takeEvery(DELETE_FORMATION_PRESTATAIRE_LINK.type, removeFormationPrestataireLink)
  yield takeEvery(ADD_FORMATION_DATE.type, addFormationDate)
  yield takeEvery(DELETE_FORMATION_DATE.type, removeFormationDate)
  yield takeEvery(FORMATION_DATE.type, viewFormationDate)
  yield takeEvery(FORMATION_DEVIS_REQUEST_BY_USER.type, devisRequestByUser)
  yield takeEvery(FORMATION_TRAINING_REQUEST_BY_USER.type, devisTrainingByUser)
}

export default function* FormationSaga() {
  yield all([fork(watchProfile)])
}
