import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  GET_PRESTATAIRE_ACCOUNTS,
  GET_PRESTATAIRE_ACCOUNT,
  PRESTATAIRES_ACCOUNT,
  PRESTATAIRE_ACCOUNT,
  ADD_PRESTATAIRE_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_PRESTATAIRE_ACCOUNT,
  DELETE_PRESTATAIRE_ACCOUNT,
  PRESTATAIRE_ACCOUNT_SUCCESS,
  ADD_PRESTATAIRE_ACCOUNT_SUCCESS,
  PRESTATAIRE_ACCOUNT_ERROR
} from "./prestataireAccountSlice"

import {ADD_PRESTATAIRE_SUCCESS} from "store/prestataire/prestataireSlice";

import { getPrestatairesAccount, getPrestataireAccount, postPrestataireAccount, putPrestataireAccount, deletePrestataireAccount } from "helpers/request_helper"

function* viewPrestatairesAccount() {
  try {
    const response = yield call(getPrestatairesAccount)
    yield put(GET_PRESTATAIRE_ACCOUNTS(response['hydra:member']))
    yield put(PRESTATAIRE_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(PRESTATAIRE_ACCOUNT_ERROR(error.message))
  }
}

function* viewPrestataireAccount({ payload: { prestataireId } }) {
  try {
    const response = yield call(getPrestataireAccount, prestataireId)
    yield put(GET_PRESTATAIRE_ACCOUNT(response))
    yield put(PRESTATAIRE_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(PRESTATAIRE_ACCOUNT_ERROR(error.message))
  }
}

function* addNewPrestataireAccount({ payload: { email, roles, password, status, firstname, lastname, prestataire, history } }) {
  try {

    const data = {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname,
      roles: roles,
      status: status,
      prestataire: prestataire,
      isModifyPassword: false
    }

    const response = yield call(postPrestataireAccount, data)

    yield put(ADD_PRESTATAIRE_ACCOUNT(response))
    history.push("/gestion-prestataires")
    yield put(ADD_PRESTATAIRE_SUCCESS(`Ajout du prestataire "${response.prestataire.name}" avec succès (un mail avec les identifiants a été transmis)`))
  } catch (error) {
    if (error.response.data.violations) {
      yield put(PRESTATAIRE_ACCOUNT_ERROR(error.response.data.violations[0].message))
    } else if (error.response.data['hydra:description']) {
      yield put(PRESTATAIRE_ACCOUNT_ERROR(error.response.data['hydra:description']))
    } else {
      yield put(PRESTATAIRE_ACCOUNT_ERROR(error.message))
    }
  }
}

function* editPrestataireAccount({ payload: {
  name, address, zipCode, city, contactFirstname, contactLastname, contactFunction, contactPhone, contactEmail, codeNaf,
  codeSiret, codeTva, country, commercialCoverage, codeNda, activeAccount, prestataireId, locality, qualiopiCertification,
  presentation, subjectTva
} }) {
  try {
    const response = yield call(putPrestataireAccount, {
      prestataireId: prestataireId,
      name: name,
      address: address,
      locality: locality,
      zipCode: zipCode,
      city: city,
      presentation: presentation,
      contactFirstname: contactFirstname,
      contactLastname: contactLastname,
      contactFunction: contactFunction,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      codeNaf: codeNaf,
      codeSiret: codeSiret,
      codeTva: codeTva,
      subjectTva: subjectTva,
      country: country,
      commercialCoverage: commercialCoverage,
      codeNda: codeNda,
      qualiopiCertification: qualiopiCertification,
      activeAccount: activeAccount
    })
    yield put(GET_PRESTATAIRE_ACCOUNT(response))
    yield put(ADD_PRESTATAIRE_ACCOUNT_SUCCESS("Les informations du prestataire ont bien été modifiées"))
  } catch (error) {
    yield put(PRESTATAIRE_ACCOUNT_ERROR(error))
  }
}

function* removePrestataireAccount({ payload: { prestataireId } }) {
  try {
    yield call(deletePrestataireAccount, prestataireId)
    const response = yield call(getPrestatairesAccount)
    yield put(GET_PRESTATAIRE_ACCOUNTS(response['hydra:member']))
    yield put(ADD_PRESTATAIRE_ACCOUNT_SUCCESS(`Suppression du prestataire avec succès !!`))
  } catch (error) {
    if (error.response.status === 500) {
      yield put(PRESTATAIRE_ACCOUNT_ERROR("Le prestataire ne peut pas être supprimée !"))
    } else {
      yield put(PRESTATAIRE_ACCOUNT_ERROR(error.message))
    }
  }
}


export function* watchProfile() {
  yield takeEvery(PRESTATAIRES_ACCOUNT.type, viewPrestatairesAccount)
  yield takeEvery(PRESTATAIRE_ACCOUNT.type, viewPrestataireAccount)
  yield takeEvery(ADD_ACCOUNT.type, addNewPrestataireAccount)
  yield takeEvery(UPDATE_PRESTATAIRE_ACCOUNT.type, editPrestataireAccount)
  yield takeEvery(DELETE_PRESTATAIRE_ACCOUNT.type, removePrestataireAccount)
}


export default function* PrestataireAccountSaga() {
  yield all([fork(watchProfile)])
}
