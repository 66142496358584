import React from "react"
import {Row, Col, UncontrolledAlert} from "reactstrap"

const SuccessErrorAlert = ({ error, success }) => {
  return (
    <Row>
      <Col sm="12" md="10" className="mb-3">

        {error ? (
          <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
            <i className="uil uil-exclamation-octagon me-2"></i> Une erreur s'est produite : "{error}"
          </UncontrolledAlert>
        ) : null }

        { success ? (
          <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
            <i className="uil uil-check me-2"></i> {success}
          </UncontrolledAlert>
        ) : null }

      </Col>
    </Row>
  )
}

export default SuccessErrorAlert
