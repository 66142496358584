import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  localities: [],
  societyTypes: [],
  success: "",
  error: "",
  isLoading: false,
}

const globalDataSlice = createSlice({
  name: "globalData",
  initialState,

  reducers: {

    FORM_DATAS (state) {
      return { ...state }
    },

    GET_LOCALITIES (state, action) {
      state.isLoading = true
      state.localities = action.payload  // payload: localities (dept / region)
    },

    GET_SOCIETY_TYPES (state, action) {
      state.isLoading = true
      state.societyTypes = action.payload  // payload: society types
    },

    GLOBAL_DATA_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = ""
    },

    GLOBAL_DATA_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false  }
    }
  }
});

const { reducer, actions } = globalDataSlice;

export const {
  FORM_DATAS,
  GET_LOCALITIES,
  GET_SOCIETY_TYPES,
  GLOBAL_DATA_SUCCESS,
  GLOBAL_DATA_ERROR
} = actions;

export default reducer;

export const selectGlobalDatas = (state) => state.globalData;
