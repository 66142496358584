import { post, del, get, put, patch } from "./api_helper"
import * as url from "./url_helper"
import axios from "axios"

// token Verification
const token = localStorage.getItem('accessJWT');
const headers = { "Authorization" : `Bearer ${localStorage.getItem('accessJWT')}` }
const userId = localStorage.getItem('id')

/* =========== REFRESH TOKEN (pour méthode fetch) ================== */

function getLocalRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

/* =========== Login & PROFILE ================== */

// Login Method
const postLogin = data => post(url.POST_LOGIN_URL, data)

// Get profile
const getProfile = id => get(`${url.USER_PROFILE_URL}/${id}`, { headers, params: { id } })

// Edit profile
const editProfile = email => patch(`${url.USER_PROFILE_URL}/${userId}`, email,{ headers: {"Authorization" : `Bearer ${token}`, "Content-Type": 'application/merge-patch+json' }})

// Edit password profile
const editPasswordProfile = password => put(`${url.USER_PROFILE_URL}/${userId}`, password,{ headers })

// Edit user/avatar profile
const editAvatarProfile = image => patch(`${url.USER_PROFILE_URL}/${userId}`, image,{ headers: {"Authorization" : `Bearer ${token}`, "Content-Type": 'application/merge-patch+json' }})

// Add avatar profile
const postMediaObject = file => {

  try {

    return fetch(process.env.REACT_APP_API_URL + "/media_objects", {
      mode: "cors",
      method: "POST",
      headers: {"Authorization": `Bearer ${token}` },
      body: file
    })
      .then(result => {

        if (result.status === 401) {

          return axios.post(process.env.REACT_APP_API_URL + "/token/refresh", {
            refresh_token: getLocalRefreshToken()
          })
            .then(response => {

              localStorage.setItem("accessJWT", response.data.token)

              return fetch(process.env.REACT_APP_API_URL + "/media_objects", {
                mode: "cors",
                method: "POST",
                headers: {"Authorization": `Bearer ${response.data.token}`},
                body: file
              })
                .then(newResult => newResult)
                .then(data => {
                  return data.json()
                })
            })

        } else {
          return result.json()
        }
      })

  } catch (err) {
    console.log('Error', err)
  }
}

// Delete avatar profile
const deleteMediaObject = imageId => {

  try {

    return fetch(process.env.REACT_APP_API_URL + "/media_objects/" + imageId, {
      mode: "cors",
      method: "DELETE",
      headers: {"Authorization": `Bearer ${token}` }
    })
      .then(result => {

        if (result.status === 401) {

          return axios.post(process.env.REACT_APP_API_URL + "/token/refresh", {
            refresh_token: getLocalRefreshToken()
          })
            .then(response => {

              localStorage.setItem("accessJWT", response.data.token)

              return fetch(process.env.REACT_APP_API_URL + "/media_objects/" + imageId, {
                mode: "cors",
                method: "DELETE",
                headers: {"Authorization": `Bearer ${response.data.token}`},
              })
                .then(newResult => newResult)
                .then(data => {
                  return data.json()
                })
            })

        } else {
          return result.json()
        }
      })

  } catch (err) {
    console.log('Error', err)
  }
}

/* =========== POSTFORGETPASSWORD ================== */

// postForgetPwd
const postForgetPwd = data => post(process.env.REACT_APP_URL + url.POST_PASSWORD_FORGET, data, { headers })

// modify password
const resetForgetPwd = ({password, token}) => post(process.env.REACT_APP_URL + url.POST_PASSWORD_FORGET + token, {password: password},{ headers })

/* =========== GLOBAL DATAS ================== */

// Get localities
const getLocalities = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.LOCALITIES_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get society types
const getSocietyTypes = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.SOCIETY_TYPES_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get Regions
const getRegions = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.REGIONS_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

/* =========== USER ACCOUNT ================== */

// Get users account
const getAccounts = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.ACCOUNT_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get user account
const getAccount = userId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.ACCOUNT_URL}/${userId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get users account
const postAdminAccount = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.ACCOUNT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// modify users account
const putAccount = data => {
  const token = localStorage.getItem('accessJWT');
  const userData = {
    email: data.email,
    firstname: data.firstname,
    lastname: data.lastname,
    status: data.status,
    isModifyPassword: false
  }
  return put(`${url.ACCOUNT_URL}/${data.userId}`, userData, { headers: {"Authorization" : `Bearer ${token}`} })
}

const putPasswordAccount = data => {
  const token = localStorage.getItem('accessJWT');
  const userData = {
    password: data.password,
    isModifyPassword: true
  }
  return put(`${url.ACCOUNT_URL}/${data.userId}`, userData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove user account
const deleteAccount = userId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.ACCOUNT_URL}/${userId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Modify email for user account
const putEmailAccountSendMail = data => {
  return put("/mail/put_email_account_send_mail", data)
}

/* =========== CLIENT ACCOUNT ================== */

// Get all clients account
const getClientsAccount = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_ACCOUNT_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get client account
const getClientAccount = clientId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_ACCOUNT_URL}/${clientId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// POST client account
const postClientAccount = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.ACCOUNT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put client account
const putClientAccount = data => {
  const token = localStorage.getItem('accessJWT');
  const clientData = {
    clientId: data.clientId,
    clientFunction: data.clientFunction,
    phone: data.phone,
    entName: data.entName,
    entAddress: data.entAddress,
    locality: data.locality,
    entZipCode: data.entZipCode,
    entCity: data.entCity,
    entCodeNaf: data.entCodeNaf,
    entCodeSiret: data.entCodeSiret,
    entCodeTva: data.entCodeTva,
    entCountry: data.entCountry,
    entCommercialCoverage: data.entCommercialCoverage,
    societyType: data.societyType,
    entEffectif: data.entEffectif,
    contactFirstname: data.contactFirstname,
    contactLastname: data.contactLastname,
    email: data.email
  }
  return put(`${url.CLIENT_ACCOUNT_URL}/${data.clientId}`, clientData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove client account
const deleteClientAccount = clientId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.CLIENT_ACCOUNT_URL}/${clientId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== PRESTATAIRE ACCOUNT ================== */

// Get all prestataires account
const getPrestatairesAccount = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.PRESTATAIRE_ACCOUNT_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get prestataire account
const getPrestataireAccount = prestataireId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.PRESTATAIRE_ACCOUNT_URL}/${prestataireId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// POST new prestataire account
const postPrestataireAccount = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.ACCOUNT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put prestataire account
const putPrestataireAccount = data => {
  const token = localStorage.getItem('accessJWT');
  const prestataireData = {
    name: data.name,
    address: data.address,
    locality: data.locality,
    zipCode: data.zipCode,
    city: data.city,
    presentation: data.presentation,
    contactFirstname: data.contactFirstname,
    contactLastname: data.contactLastname,
    contactFunction: data.contactFunction,
    contactPhone: data.contactPhone,
    contactEmail: data.contactEmail,
    codeNaf: data.codeNaf,
    codeSiret: data.codeSiret,
    codeTva: data.codeTva,
    subjectTva: data.subjectTva,
    qualiopiCertification: data.qualiopiCertification,
    country: data.country,
    commercialCoverage: data.commercialCoverage,
    codeNda: data.codeNda,
    activeAccount: data.activeAccount
  }
  return put(`${url.PRESTATAIRE_ACCOUNT_URL}/${data.prestataireId}`, prestataireData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove prestataire account
const deletePrestataireAccount = prestataireId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.PRESTATAIRE_ACCOUNT_URL}/${prestataireId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== CLIENT EMPLOYEE ACCOUNT ================== */

// Get clients employee
const getClientEmployeesAccount = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_EMPLOYEE_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get client employee
const getClientEmployeeAccount = clientEmployeeId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_EMPLOYEE_URL}/${clientEmployeeId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Post clients employee
const postClientEmployeeAccount = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.ACCOUNT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put client account
const putClientEmployeeAccount = data => {
  const token = localStorage.getItem('accessJWT');

  const employeeData = {
    job: data.job,
    service: data.service,
    pole: data.pole,
    managerId: data.managerId,
    arrivalDate: data.arrivalDate,
    isArchived: data.isArchived,
  }
  return put(`${url.CLIENT_EMPLOYEE_URL}/${data.clientEmployeeId}`, employeeData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove client account
const deleteClientEmployeeAccount = clientEmployeeId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.CLIENT_EMPLOYEE_URL}/${clientEmployeeId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== CLIENT EMPLOYEE ================== */

// Get clients employee
const getClientEmployees = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_EMPLOYEE_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get client employee
const getClientEmployee = clientEmployeeId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CLIENT_EMPLOYEE_URL}/${clientEmployeeId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Post clients employee
const postClientEmployee = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.CLIENT_EMPLOYEE_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put client account
const putClientEmployee = data => {
  const token = localStorage.getItem('accessJWT');
  const clientEmployeeData = {
    firstname: data.firstname,
    lastname: data.lastname,
    job: data.job,
    service: data.service,
    pole: data.service,
    email: data.email,
    arrivalDate: data.arrivalDate
  }
  return put(`${url.CLIENT_EMPLOYEE_URL}/${data.clientEmployeeId}`, clientEmployeeData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove client account
const deleteClientEmployee = clientEmployeeId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.CLIENT_EMPLOYEE_URL}/${clientEmployeeId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== INTERVENTIONS ================== */

// Get interventions
const getInterventions = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.INTERVENTION_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get intervention
const getIntervention = interventionId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.INTERVENTION_URL}/${interventionId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Post Intervention
const postIntervention = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.INTERVENTION_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put Intervention
const putIntervention = data => {
  const token = localStorage.getItem('accessJWT');
  return put(`${url.INTERVENTION_URL}/${data.id}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove Intervention
const deleteIntervention = interventionId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.INTERVENTION_URL}/${interventionId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== PRESTATAIRES ================== */

// Get prestataires
const getPrestataires = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.PRESTATAIRE_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get prestataire
const getPrestataire = prestataireId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.PRESTATAIRE_URL}/${prestataireId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add prestataire
const postPrestataire = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.PRESTATAIRE_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put prestataire
const putPrestataire = data => {
  const token = localStorage.getItem('accessJWT');
  const prestataireData = {
    name: data.name,
    address: data.address,
    locality: data.locality,
    zipCode: data.zipCode,
    city: data.city,
    presentation: data.presentation,
    contactFirstname: data.contactFirstname,
    contactLastname: data.contactLastname,
    contactFunction: data.contactFunction,
    contactPhone: data.contactPhone,
    contactEmail: data.contactEmail,
    codeNaf: data.codeNaf,
    codeSiret: data.codeSiret,
    codeTva: data.codeTva,
    subjectTva: data.subjectTva,
    qualiopiCertification: data.qualiopiCertification,
    country: data.country,
    commercialCoverage: data.commercialCoverage,
    codeNda: data.codeNda,
    activeAccount: data.activeAccount
  }
  return put(`${url.PRESTATAIRE_URL}/${data.prestataireId}`, prestataireData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove prestataire
const deletePrestataire = prestataireId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.PRESTATAIRE_URL}/${prestataireId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Add intervenant
const postIntervenant = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.INTERVENANT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Get intervenant
const getIntervenant = intervenantId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.INTERVENANT_URL}/${intervenantId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Put intervenant
const putIntervenant = data => {
  const token = localStorage.getItem('accessJWT');

  const intervenantData = {
    intFirstname: data.intFirstname,
    intLastname: data.intLastname,
    qualification: data.qualification,
    intPhone: data.intPhone,
    intEmail: data.intEmail,
    image: data.image
  }

  return put(`${url.INTERVENANT_URL}/${data.intervenantId}`, intervenantData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove intervenant
const deleteIntervenant = intervenantId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.INTERVENANT_URL}/${intervenantId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Ajout / suppression d'une formation pour un intervenant
const putFormationIntervenant = data => {
  const token = localStorage.getItem('accessJWT');

  const intervenantData = {
    intervenants: data.intervenants
    }

  return put(`${url.FORMATION_URL}/${data.formationId}`, intervenantData, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== FORMATIONS ================== */

// Get formations
const getFormations = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get formation
const getFormation = formationId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_URL}/${formationId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add formation
const postFormation = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.FORMATION_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put formation
const putFormation = data => {
  const token = localStorage.getItem('accessJWT');
  const formationData = {
    title: data.title,
    description: data.description,
    targetAudience: data.targetAudience,
    duration: data.duration,
    durationUnit: data.durationUnit,
    priceHT: data.priceHT,
    priceTTC: data.priceTTC,
    accessDelay: data.accessDelay,
    disabledAccessibility: data.disabledAccessibility,
    modalities: data.modalities,
    evaluation: data.evaluation,
    necessaryMaterial: data.necessaryMaterial,
    satisfactionRate: data.satisfactionRate,
    successRate: data.successRate,
    equivalences: data.equivalences,
    cpfEligible: data.cpfEligible,
    cpfCode: data.cpfCode,
    cpfDesignation: data.cpfDesignation,
    objectifs: data.objectifs,
    prerequisite: data.prerequisite,
    programme: data.programme,
    educationalMethods: data.educationalMethods,
    educationalTechnics: data.educationalTechnics,
    market: data.market,
    sanction: data.sanction,
    benefices: data.benefices,
    activeFormation: data.activeFormation,
    formationStatus: data.formationStatus,
    formationTrainingStatus: data.formationTrainingStatus,
    skillDomain: data.skillDomain
  }

  return put(`${url.FORMATION_URL}/${data.formationId}`, formationData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put formation (associate prestataire to formation)
const putFormationPrestataireLink = data => {
  const token = localStorage.getItem('accessJWT');

  const formationData = {
    prestataire: data.prestataire
  }
  return put(`${url.FORMATION_URL}/${data.formationId}`, formationData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove formation
const deleteFormation = formationId => {
  const token = localStorage.getItem('accessJWT');

  return del(`${url.FORMATION_URL}/${formationId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Get formation Status
const getFormationStatus = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_STATUS_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get formations Date
const getFormationDate = formationDateId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_DATE_URL}/${formationDateId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add formation date
const postFormationDate = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.FORMATION_DATE_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove formation date
const deleteFormationDate = formationDateId => {
  const token = localStorage.getItem('accessJWT');

  return del(`${url.FORMATION_DATE_URL}/${formationDateId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// envoi d'une demande de formation par le user à l'admin
const postdevisRequestByUser = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`mail${url.FORMATION_DEVIS_REQUEST_BY_USER_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// envoi d'une demande de formation par le user à l'admin
const postdevisTrainingByUser = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`mail${url.FORMATION_DEVIS_TRAINING_BY_USER_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== CARTOGRAPHIES ================== */

// Get cartographies
const getCartographies = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CARTOGRAPHY_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get cartography
const getCartography = cartographyId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.CARTOGRAPHY_URL}/${cartographyId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// create new cartography
const postCartography = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.CARTOGRAPHY_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })

}

// update cartography (skills)
const putCartography = data => {
  const token = localStorage.getItem('accessJWT');

  const employeeActionData = {
    colorRisk: data.colorRisk,
    message: data.message,
  }

  return put(`${url.EMPLOYEE_ACTION_URL}/${data.employeeActionId}`, employeeActionData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// update cartography validation
const putCartographyValidate = data => {
  const token = localStorage.getItem('accessJWT');

  const cartographyData = {
    validate: data.validate,
    updatedAt: new Date()
  }

  return put(`${url.CARTOGRAPHY_URL}/${data.cartographyId}`, cartographyData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// update cartography validation
const putCartographyUpdatedAt = data => {
  const token = localStorage.getItem('accessJWT');

  const cartographyData = {
    updatedAt: new Date(),
  }

  return put(`${url.CARTOGRAPHY_URL}/${data.cartographyId}`, cartographyData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove employee in cartography
const deleteCartography = cartographyId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.CARTOGRAPHY_URL}/${cartographyId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== EMPLOYEE ================== */

// Get employee
const getEmployee = employeeId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.EMPLOYEE_URL}/${employeeId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add employee in cartography
const postEmployee = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.EMPLOYEE_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put employee in cartography
const putEmployee = data => {
  const token = localStorage.getItem('accessJWT');

  const employeeData = {
    firstname: data.firstname,
    lastname: data.lastname,
    job: data.job,
    service: data.service,
    arrivalDate: data.arrivalDate
  }

  return put(`${url.EMPLOYEE_URL}/${data.employeeId}`, employeeData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove employee in cartography
const deleteEmployee = employeeId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.EMPLOYEE_URL}/${employeeId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== SKILL ================== */

// Get skill
const getSkill = skillId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.SKILL_URL}/${skillId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add skill in cartography
const postSkill = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.SKILL_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put skill in cartography
const putSkill = data => {
  const token = localStorage.getItem('accessJWT');

  const skillData = {
    pole: data.pole,
    task: data.task,
    totalVeryGood: data.totalVeryGood,
    totalGood: data.totalGood,
    totalMedium: data.totalMedium,
    totalBad: data.totalBad,
    risk: data.risk,
    skillDomain: data.skillDomain,
    skillActivity: data.skillActivity,
    requiredLevel: data.requiredLevel
  }

  return put(`${url.SKILL_URL}/${data.skillId}`, skillData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put skill level in cartography
const putSkillRequiredLevel = data => {
  const token = localStorage.getItem('accessJWT');

  const skillData = {
    requiredLevel: data.requiredLevel
  }

  return put(`${url.SKILL_URL}/${data.skillId}`, skillData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove skill in cartography
const deleteSkill = skillId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.SKILL_URL}/${skillId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== EMPLOYEE SKILL (table de liaison) ================== */

// create employee_skill
const postEmployeeSkill = data => {
  const token = localStorage.getItem('accessJWT');

  const skillData = {
    employee: data.employee,
    skill: data.skill,
    message: data.message,
    notation: data.notation,
    colorRisk: data.colorRisk,
    isUpgrade: data.isUpgrade
  }

  return post(`${url.EMPLOYEE_SKILL_URL}`, skillData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put employee_skill in cartography
const putEmployeeSkillObjectif = data => {
  const token = localStorage.getItem('accessJWT');

  const skillData = {
    colorRiskObjectif: data.colorRiskObjectif,
    objectif: data.objectif,
  }

  return put(`${url.EMPLOYEE_SKILL_URL}/${data.employeeSkillId}`, skillData, { headers: {"Authorization" : `Bearer ${token}`} })
}

const putEmployeeSkill = data => {
  const token = localStorage.getItem('accessJWT');

  const skillData = {
    colorRisk: data.colorRisk,
    colorRiskUpgrade: data.colorRiskUpgrade,
    message: data.message,
    messageUpgrade: data.messageUpgrade,
    isUpgrade: data.isUpgrade,
    upgradeDate: data.upgradeDate,
    upgradeStatus: data.upgradeStatus,
    clientEmployeeId: data.clientEmployeeId,
    cartographyIdx: data.cartographyIdx
  }

  return put(`${url.EMPLOYEE_SKILL_URL}/${data.employeeSkillId}`, skillData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Delete employee_skill in cartography
const deleteEmployeeSkill = employeeSkillId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.EMPLOYEE_SKILL_URL}/${employeeSkillId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== SKILLS ACTIVITIES & DOMAINS ================== */

// Get skill
const getSkillActivities = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.SKILL_ACTIVITY_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get skill
const getSkillDomains = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.SKILL_DOMAIN_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

/* =========== TRAINING PLANS ================== */

// Get training plans
const getTrainingPlans = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.TRAINING_PLAN_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get training plan
const getTrainingPlan = trainingPlanId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.TRAINING_PLAN_URL}/${trainingPlanId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// create new training plan
const postTrainingPlan = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.TRAINING_PLAN_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// update training plan validation
const putTrainingPlanValidate = data => {
  const token = localStorage.getItem('accessJWT');

  const trainingPlanData = {
    validate: data.validate
  }

  return put(`${url.TRAINING_PLAN_URL}/${data.trainingPlanId}`, trainingPlanData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// create new training plan action
const postTrainingPlanAction = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.TRAINING_PLAN_ACTION_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Get training plan action
const getTrainingPlanAction = trainingPlanActionId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.TRAINING_PLAN_ACTION_URL}/${trainingPlanActionId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Put training plan action
const putTrainingPlanAction = data => {
  const token = localStorage.getItem('accessJWT');

  const actionData = {
    trainingDateDesired: data.trainingDateDesired,
    skillDomain: data.skillDomain,
    trainingPlace: data.trainingPlace,
    trainingStart: data.trainingStart,
    trainingEnd: data.trainingEnd,
    trainingCostTotal: data.trainingCostTotal,
    trainingCostHour: data.trainingCostHour,
    paymentFundingType: data.paymentFundingType,
    paymentFinanced: data.paymentFinanced,
    finished: data.finished,
    certificateReceived: data.certificateReceived,
    certificateExpiration: data.certificateExpiration,
    formation: data.formation,
    isValidate: data.isValidate,
    devisRequestSent: data.devisRequestSent,
    isCustomTrainingDate: data.isCustomTrainingDate,
    taskCustom: data.taskCustom,
    formationInternal: data.formationInternal
  }

  return put(`${url.TRAINING_PLAN_ACTION_URL}/${data.trainingPlanActionId}`, actionData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Delete training plan action
const deleteTrainingPlanAction = actionId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.TRAINING_PLAN_ACTION_URL}/${actionId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Get payment founding types
const getPaymentFoundingTypes = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.PAYMENT_FOUNDING_TYPE_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// envoi du devis de demande de formation
const postFormationDevis = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.FORMATION_DEVIS_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// envoi d'un mail avec la description de la formation
const postFormationResume = data => {
  const token = localStorage.getItem('accessJWT');
  return post("/mail/formation_resume", data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// envoi d'un mail avec la description de la formation interne
const postFormationInternalResume = data => {
  const token = localStorage.getItem('accessJWT');
  return post("/mail/formation_internal_resume", data, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== NOTIFICATIONS ================== */

// Get notifications
const getNotifications = userId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.NOTIFICATION_URL}?user=${userId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get notifications for admin
const getAdminNotifications = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.NOTIFICATION_URL}?allAdmin=1`, {headers: {"Authorization": `Bearer ${token}`}})
}

// create notification
const postNotification = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.NOTIFICATION_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Delete training plan action
const removeNotification = notificationId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.NOTIFICATION_URL}/${notificationId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

/* =========== ADVERT ================== */

// Get adverts
const getAdverts = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.ADVERT_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get advert
const getAdvert = advertId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.ADVERT_URL}/${advertId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Post advert
const postAdvert = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.ADVERT_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// modify advert
const putAdvert = data => {
  const token = localStorage.getItem('accessJWT');
  const advertData = {
    message: data.message,
    link: data.link,
    isActive: data.isActive
  }
  return put(`${url.ADVERT_URL}/${data.advertId}`, advertData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove advert
const deleteAdvert = advertId => {
  const token = localStorage.getItem('accessJWT');
  return del(`${url.ADVERT_URL}/${advertId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Edit advert image
const editAdvertImage = data => {
  const advertImage = {
    image: data.image
  }
  return patch(`${url.ADVERT_URL}/${data.advertId}`, advertImage,{ headers: {"Authorization" : `Bearer ${token}`, "Content-Type": 'application/merge-patch+json' }})
}

// envoi d'une demande de formation par le user à l'admin
const getSkillsPoleEmploi = data => {
  return post(`${url.POLE_EMPLOI_SKILLS}`, data)
}

/* =========== FORMATION INTERNALS ================== */

// Get all internal formations
const getFormationInternals = () => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_INTERNAL_URL}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get all internal formations filtering by client
const getFormationInternalsFilterByClient = clientId => {
  return get(`${url.FORMATION_INTERNAL_FILTER_BY_CLIENT_URL}/${clientId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Get formation
const getFormationInternal = formationInternalId => {
  const token = localStorage.getItem('accessJWT');
  return get(`${url.FORMATION_INTERNAL_URL}/${formationInternalId}`, {headers: {"Authorization": `Bearer ${token}`}})
}

// Add formation
const postFormationInternal = data => {
  const token = localStorage.getItem('accessJWT');
  return post(`${url.FORMATION_INTERNAL_URL}`, data, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Put formation
const putFormationInternal = data => {

  const token = localStorage.getItem('accessJWT');

  const formationData = {
    title: data.title,
    context: data.context,
    targetAudience: data.targetAudience,
    duration: data.duration,
    durationUnit: data.durationUnit,
    description: data.description,
    objectifStrategic: data.objectifStrategic,
    objectifOperational: data.objectifOperational,
    objectifEducational: data.objectifEducational,
    prerequisites: data.prerequisites,
    programme: data.programme,
    educationalTechnics: data.educationalTechnics,
    educationalMethods: data.educationalMethods,
    modalities: data.modalities,
    necessaryMaterial: data.necessaryMaterial,
    internal: data.internal,
    referentFirstname: data.referentFirstname,
    referentLastname: data.referentLastname,
    formationQualification: data.formationQualification,
    price: data.price,
    sanction: data.sanction,
    disabledAccessibility: data.disabledAccessibility,
    clientEmployees: data.clientEmployees,
    skillDomain: data.skillDomain
  }

  return put(`${url.FORMATION_INTERNAL_URL}/${data.formationInternalId}`, formationData, { headers: {"Authorization" : `Bearer ${token}`} })
}

// Remove formation
const deleteFormationInternal = formationInternalId => {
  const token = localStorage.getItem('accessJWT');

  return del(`${url.FORMATION_INTERNAL_URL}/${formationInternalId}`, { headers: {"Authorization" : `Bearer ${token}`} })
}

export {
  postLogin,
  postForgetPwd,
  resetForgetPwd,
  getLocalities,
  getSocietyTypes,
  getRegions,
  getProfile,
  editProfile,
  editPasswordProfile,
  postMediaObject,
  deleteMediaObject,
  editAvatarProfile,
  getAccounts,
  getAccount,
  putAccount,
  postAdminAccount,
  putPasswordAccount,
  deleteAccount,
  putEmailAccountSendMail,
  getClientsAccount,
  getClientAccount,
  putClientAccount,
  deleteClientAccount,
  postClientAccount,
  getClientEmployeesAccount,
  getClientEmployeeAccount,
  putClientEmployeeAccount,
  deleteClientEmployeeAccount,
  postClientEmployeeAccount,
  getPrestatairesAccount,
  getPrestataireAccount,
  postPrestataireAccount,
  putPrestataireAccount,
  deletePrestataireAccount,
  getClientEmployees,
  getClientEmployee,
  postClientEmployee,
  putClientEmployee,
  deleteClientEmployee,
  getInterventions,
  getIntervention,
  postIntervention,
  putIntervention,
  deleteIntervention,
  getPrestataires,
  getPrestataire,
  getIntervenant,
  postPrestataire,
  putPrestataire,
  deletePrestataire,
  postIntervenant,
  putIntervenant,
  deleteIntervenant,
  putFormationIntervenant,
  getFormations,
  getFormation,
  postFormation,
  putFormation,
  getFormationStatus,
  putFormationPrestataireLink,
  deleteFormation,
  getFormationDate,
  postFormationDate,
  deleteFormationDate,
  getCartographies,
  getCartography,
  postCartography,
  putCartography,
  putCartographyValidate,
  putCartographyUpdatedAt,
  deleteCartography,
  getEmployee,
  postEmployee,
  putEmployee,
  deleteEmployee,
  getSkill,
  postSkill,
  putSkill,
  putSkillRequiredLevel,
  deleteSkill,
  postEmployeeSkill,
  putEmployeeSkill,
  putEmployeeSkillObjectif,
  deleteEmployeeSkill,
  getSkillActivities,
  getSkillDomains,
  getTrainingPlans,
  getTrainingPlan,
  postTrainingPlan,
  putTrainingPlanValidate,
  postTrainingPlanAction,
  getTrainingPlanAction,
  deleteTrainingPlanAction,
  putTrainingPlanAction,
  postFormationDevis,
  postFormationResume,
  postFormationInternalResume,
  postdevisRequestByUser,
  postdevisTrainingByUser,
  getPaymentFoundingTypes,
  getNotifications,
  getAdminNotifications,
  postNotification,
  removeNotification,
  getAdverts,
  getAdvert,
  postAdvert,
  putAdvert,
  deleteAdvert,
  editAdvertImage,
  getSkillsPoleEmploi,
  getFormationInternals,
  getFormationInternal,
  postFormationInternal,
  putFormationInternal,
  deleteFormationInternal,
  getFormationInternalsFilterByClient
}
