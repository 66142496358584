import React, {useState} from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {isEmpty, map} from "lodash";
import {generateUniqueId} from "helpers/text_helper";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectSkill, POLE_EMPLOI_SKILLS} from "store/skillsDevelopmentPlan/skill/skillSlice";

const CartographyConfigurationEditSkillModal = ({ isOpen, toggle, addSkill}) => {

  const { skillDomains, skillActivities } = useSelector(selectSkill);

  const dispatch = useDispatch()

  const { skillsPoleEmploi, error } = useSelector(selectSkill);

  const [pole, setPole] = useState('')
  const [domain, setDomain] = useState(0)
  const [activity, setActivity] = useState(0)
  const [skillsSelection, setSkillsSelection] = useState([])

  const resetFields = () => {
    setPole('')
    setDomain(0)
    setActivity(0)
    setSkillsSelection([])
  }

  const deleteSkillSelection = skill => {
    const newSkillsSelection = skillsSelection.filter( skillSelection => skillSelection.id !== skill.id)
    setSkillsSelection(newSkillsSelection)
  }

  /* ============ start Searchbar Filter =================  */

  const [query, setQuery] = useState('');
  const [skillCustom, setSkillCustom] = useState('');

  const skillPeSearch = () => {

    // getSkillsPoleEmploi
    const data = { query }
    dispatch(POLE_EMPLOI_SKILLS(data))
  }

  /* ============ end Searchbar Filter =================  */

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-xl"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Ajouter une compétence à la cartographie ?</h5>
        <button
          type="button"
          onClick={() => {
            toggle()
            resetFields()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <AvForm onValidSubmit={() => {
          if (!isEmpty(skillsSelection)) {
            addSkill(pole, skillsSelection, domain, activity)
            setSkillsSelection([])
          }
        }}
        >

          <Row form>

            <Col className="col-6 mb-4">

              <h5 className="font-size-17 mt-4 mb-3">Pôle <span className="text-danger">*</span></h5>
              <AvField
                name="pole"
                type="text"
                errorMessage="Veuillez renseigner un pôle"
                validate={{ required: { value: true } }}
                value=""
                onChange={event => setPole(event.target.value)}
              />
            </Col>

            <Row>

              <Col className="col-6 mb-4">
                <h5 className="font-size-17 mb-3">Service <span className="text-danger">*</span></h5>
                <AvField
                  className="form-select"
                  type="select"
                  name="activity"
                  validate={{ required: { value: true } }}
                  onChange={event => setActivity(event.target.value)}
                  errorMessage="Veuillez choisir un service"
                  helpMessage="Choisir un service dans le menu déroulant"
                  >
                  <option key={`activity-${0}`} value="">Choisir</option>
                  {map(skillActivities, (activity, key) => (
                    <option key={`activity-${activity.id}`} value={activity.id}>{activity.name}</option>
                  ))}
                </AvField>
              </Col>

              <Col className="col-6 mb-4">
                <h5 className="font-size-17 mb-3">Domaine <span className="text-danger">*</span></h5>
                <AvField
                  className="form-select"
                  type="select"
                  name="domain"
                  validate={{ required: { value: true } }}
                  onChange={event => setDomain(event.target.value)}
                  errorMessage="Veuillez choisir un domaine"
                  helpMessage="Choisir un domaine dans le menu déroulant"
                >
                  >
                  <option key={`domain-${0}`} value="">Choisir</option>
                  {map(skillDomains, (domain, key) => (
                    <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                  ))}
                </AvField>
              </Col>

            </Row>

            <Row  className="mb-4">

              <h5 className="font-size-17 mt-4 mb-3">Compétences choisies (tâches) <span className="text-danger">*</span></h5>

              <Row>
                <Col className="col-12">
                  <ul>
                    { skillsSelection.length > 0
                      ? skillsSelection.map( skill => (
                        <li key={skill.id}>
                          {skill.title}
                          <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => deleteSkillSelection(skill)}>
                            <i className="uil uil-times font-size-18 text-danger mx-3"></i>
                          </span>
                        </li>

                      ))
                      : <p className="text-danger font-size-15">Veuillez choisir des compétences</p> }
                  </ul>
                </Col>
              </Row>

            </Row>

            <div style={{backgroundColor: '#f1f1f1' }}>
              <Row className="p-3 mb-3 mt-3 mx-3">

                <h5 className="font-size-17 mt-3 mb-3">Ajouter une compétence personnalisée</h5>

                <Row className="mb-3">
                  <Col className="col-6">
                    <AvField
                      name="customSkill"
                      type="text"
                      placeholder="Saisir une compétence"
                      validate={{ required: { value: false } }}
                      value={skillCustom}
                      onChange={ skill => setSkillCustom(skill.target.value)}
                    />
                  </Col>
                  <Col className="col-6">
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={ () => {
                        const id = generateUniqueId()
                        setSkillsSelection([...skillsSelection, { id, title: skillCustom }])
                        setSkillCustom('')
                      }}
                    >
                      Ajouter
                    </button>
                  </Col>
                </Row>

                <h5 className="font-size-17 mt-3 mb-3">Recherche de compétences via le site Pôle Emploi</h5>

                <Row>
                  <Col className="col-6">
                    <AvField
                      name="poleEmploiSearch"
                      type="text"
                      placeholder="Saisir des mots clés ..."
                      validate={{ required: { value: false } }}
                      value=""
                      onChange={ queryText => setQuery(queryText.target.value)}
                    />
                  </Col>
                  <Col className="col-6">
                    <button type="button" className="btn btn-primary me-2" onClick={skillPeSearch}>Lancer la recherche</button>
                  </Col>
                </Row>
              </Row>

              { error && <p className="text-danger px-3 pb-3 mb-4 mx-3">Une erreur s'est produite, veuillez réessayer ultérieurement</p> }

              <Row className="px-3 pb-3 mb-4 mx-3">
                {map(skillsPoleEmploi, skill => (
                  <Col lg="6" key={skill.reference}>
                    <p>
                      {skill.libelle}
                      <span
                        className="text-success"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const id = generateUniqueId()
                          setSkillsSelection([...skillsSelection, { id, title: skill.libelle }])
                        }}
                      >
                        <i className="uil uil-plus mx-2"></i>
                      </span>
                    </p>
                  </Col>
                ))}
              </Row>

              { skillsPoleEmploi.length >= 30 && <p className="text-danger text-center px-3 pb-5 mb-4 mx-3">Votre recherche comporte plus de 30 résultats, veuillez affiner la recherche</p>}

            </div>
          </Row>

          <Row>

            <p className="text-primary mx-3"><i className="uil uil-exclamation-circle font-size-20 me-1"></i> Lors de l'ajout, Une ligne par compétences choisies sera générée</p>

            <Col>
              <div className="text-end">

                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={() => {
                    toggle()
                    resetFields()
                  }}
                >
                  Annuler
                </button>

                <button type="submit" className="btn btn-success save-event">Ajouter des compétences</button>
              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>

    </Modal>
  )
}

export default CartographyConfigurationEditSkillModal

CartographyConfigurationEditSkillModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
