import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, Col, Container, Media, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Link, Redirect} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectProfile, GET_USER_PROFILE} from "store/auth/profile/profileSlice";
import {UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";
import {selectClientEmployeeAccount} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const UserEspacePersonnel = ({isRole, location}) => {

  const dispatch = useDispatch()

  const { user } = useSelector(selectProfile);
  const { error, success } = useSelector(selectClientEmployeeAccount);

  const userId = localStorage.getItem('id')

  const [job, setJob] = useState('')
  const [service, setService] = useState('')
  const [pole, setPole] = useState('')
  const [arrivalDate, setArrivalDate] = useState('')

  const firstname = localStorage.getItem('firstname').toUpperCase();
  const lastname = localStorage.getItem('lastname').toUpperCase();
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  const handleValidInformations = (e, v) => {

    const data = {
      job: v.job,
      service: v.service,
      arrivalDate: v.arrivalDate,
      pole: v.pole,
      clientEmployeeId: user.clientEmployee.id,
      userId
    }

    dispatch(UPDATE_CLIENT_EMPLOYEE_ACCOUNT_BY_USER(data))
  }

  useEffect(() => {
    dispatch(GET_USER_PROFILE(userId))
  }, [dispatch, userId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row>
              <Col lg={4}>

                <Media className="d-flex">

                  {/* Avatar circle */}
                  <div className="avatar-sm me-4">
                    <span className={`avatar-title rounded-circle bg-soft-secondary primary text-primary font-size-16`}>
                      {nameIcon}
                    </span>
                  </div>


                  <Media body className="flex-1 align-self-center">

                    <div className="pb-1 mb-0">

                      <h5 className="text-truncate font-size-17 mb-0">
                        <Link to="#" className="text-dark">{user.firstname} {user.lastname}</Link>
                      </h5>

                      {
                        user.email
                          ? <p className="text-muted font-size-16">{user.email}</p>
                          : <p className="font-size-15 text-danger">E-mail non-renseigné</p>
                      }

                    </div>
                  </Media>
                </Media>

                <Media className="d-flex mt-1">
                  <Media body className="flex-1 align-self-center">

                    <p className="mb-3 font-size-16 text-dark">Poste : <span className="text-primary">{user.hasOwnProperty('clientEmployee') && user.clientEmployee.job}</span></p>
                    <p className="text-muted mb-1 font-size-16">Pôle : <span className="text-primary">{user.hasOwnProperty('clientEmployee') && user.clientEmployee.pole}</span></p>
                    <p className="text-muted mb-3 font-size-16">Service : <span className="text-primary">{user.hasOwnProperty('clientEmployee') && user.clientEmployee.service}</span></p>
                    <p className="text-muted font-size-16">Date d'arrivée dans l'entreprise : <span className="text-primary">{user.hasOwnProperty('clientEmployee') ? moment(user.clientEmployee.arrivalDate).locale('fr', localization).format("DD MMMM yyyy") : ""}</span></p>

                  </Media>
                </Media>

              </Col>

              <Col lg={4}>

                <Card>
                  <CardBody>

                    <SuccessErrorAlert error={error} success={success} />

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidInformations(e, v)}
                    >
                      <div className="form-group">
                        <h5 className="font-size-17 mt-3 mb-3">Poste</h5>
                        <AvField
                          name="job"
                          value={job}
                          className="form-control"
                          placeholder="Votre poste"
                          type="text"
                          onChange={ event => setJob(event.target.value)}
                          errorMessage="Veuillez renseigner un poste"
                          value={user.hasOwnProperty('clientEmployee') && user.clientEmployee.job}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <h5 className="font-size-17 mt-3 mb-3">Pôle</h5>
                        <AvField
                          name="pole"
                          value={pole}
                          className="form-control"
                          placeholder="Votre pôle"
                          type="text"
                          onChange={ event => setPole(event.target.value)}
                          errorMessage="Veuillez renseigner un pôle"
                          value={user.hasOwnProperty('clientEmployee') && user.clientEmployee.pole}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <h5 className="font-size-17 mt-3 mb-3">Service</h5>
                        <AvField
                          name="service"
                          value={service}
                          className="form-control"
                          placeholder="Votre service"
                          type="text"
                          onChange={ event => setService(event.target.value)}
                          errorMessage="Veuillez renseigner un service"
                          value={user.hasOwnProperty('clientEmployee') && user.clientEmployee.service}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <h5 className="font-size-17 mt-3 mb-3">Date d'arrivée dans l'entreprise</h5>
                        <AvField
                          name="arrivalDate"
                          value={arrivalDate}
                          className="form-control"
                          type="date"
                          onChange={ event => setArrivalDate(event.target.value)}
                          errorMessage="Veuillez renseigner une date valide"
                          value={user.hasOwnProperty('clientEmployee') ? moment.utc(user.clientEmployee.arrivalDate).format("yyyy-MM-DD") : ""}

                          required
                        />
                      </div>

                      <div className="mt-4">
                        <Button type="submit" color="success">
                          Modifier mes informations professionnelles
                        </Button>
                      </div>
                    </AvForm>

                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>

                <Link to="/profil" className="btn btn-primary waves-effect waves-light mx-4 mb-3">
                  <i className="uil uil-corner-up-right-alt me-1"></i>Modifier mes informations de connexion
                </Link>

              </Col>

            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default UserEspacePersonnel
