import React, {useEffect} from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import {capitalize, map} from 'lodash'
import ReactApexChart from "react-apexcharts";
import {formatMillier} from "../../helpers/text_helper";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_ACCOUNT, selectClientAccount} from "store/account/clientAccount/clientAccountSlice";
import {TRAINING_PLANS_ANALYTICS, selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {selectAdvert, ADVERTS} from "store/advert/advertSlice";

import AdvertCard from "components/Common/Advert/AdvertCard";

import placeholder from "assets/images/404-error.png"

const DashboardClient = () => {

    const clientId = Number(localStorage.getItem('clientId'))

    const dispatch = useDispatch()
    const { client } = useSelector(selectClientAccount);
    const {
        moyFormationsFinishedWithCertificate,
        trainingPlanAnalytics,
        skillsWorked,
        formationAccessLevel,
        nbrFormationsCommittedInTheSixMouth,
        costFormationsFinishLastSixMouth
    } = useSelector(selectTrainingPlan);

    const { adverts } = useSelector(selectAdvert);

    // =============== BAR CHART =====================================

    const seriesBarTop = [
        {
            name: '',
            //data: formationFinished.yAxis,
            data: nbrFormationsCommittedInTheSixMouth.data
        },
    ]

    const optionsBarTop = {
        chart: {
            toolbar: {
                show: false,
            },
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                distributed: true,
            },
        },

        dataLabels: {
            enabled: false,
        },

        colors: ["#597887"],

        grid: {
            show: true,
        },

        legend: {
            show: false
        },

        xaxis: {
            //categories: formationFinished.xAxis,
            categories: nbrFormationsCommittedInTheSixMouth.categories,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },

        yaxis: {
            min: 0,
            decimalsInFloat: 0,
            labels: {
                formatter: (val) => { return Number(val) },
            }
        },

        tooltip: {
            enabled: false
        }
    }

    const seriesBarBottom = [
        {
            name: '',
            data: costFormationsFinishLastSixMouth.data
        },
    ]

    const optionsBarBottom = {
        chart: {
            toolbar: {
                show: false,
            },
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                distributed: true,
            },
        },

        dataLabels: {
            enabled: false,
        },

        colors: ["#597887"],

        grid: {
            show: true,
        },

        legend: {
            show: false
        },

        xaxis: {
            categories: costFormationsFinishLastSixMouth.categories,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },

        yaxis: {
            min: 0,
            decimalsInFloat: 0,
            tickAmount: 5,
            labels: {
                formatter: (val) => { return Number(val) },
            },
        },

        tooltip: {
            enabled: false
        }
    }

    const seriesPie = trainingPlanAnalytics.formationsPlannedAndFinished

    const optionsPie = {
        chart: {
            width: 300,
              type: 'pie',
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                return w.config.series[seriesIndex]
            },
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        labels: ['Formations prévues', 'Formations réalisées'],
        colors: ['#597887', '#a93631'],
        legend: {
            position: 'bottom'
        },
          responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    const seriesPieSkills = skillsWorked.series

    const optionsPieSkills = {
        chart: {
            width: 300,
            type: 'pie',
        },
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        labels: skillsWorked.labels,
        colors: ['#1F3864', '#5C9BD5', '#00E3DE', '#BDD7EE'],
        legend: {
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    // ================================================================

    useEffect(() => {
        dispatch(CLIENT_ACCOUNT({ clientId }))
        dispatch(TRAINING_PLANS_ANALYTICS({ clientId }))
        dispatch(ADVERTS())
    }, [dispatch, clientId])


        return (
          <React.Fragment>

              <div className="page-content mb-5 mt-5 pt-0">

                  <h4 className="mt-3 mb-4">
                      {client.hasOwnProperty('user') && (
                        `Bonjour ${capitalize(client.user.firstname)} ${capitalize(client.user.lastname)}`
                      )}
                  </h4>

                  {/* =============== ANALYTICS ================= */}

                  <Row className="align-items-stretch">
                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody  className="text-center">
                                  <p className="font-size-16 fw-medium">Nombre de formations engagées</p>

                                  {nbrFormationsCommittedInTheSixMouth.data &&
                                    <ReactApexChart options={optionsBarTop} series={seriesBarTop} type="bar" height="160" />
                                  }

                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody  className="text-center">
                                  <p className="font-size-16 fw-medium mb-2">Nombre de formations prévues et réalisées</p>

                                  {trainingPlanAnalytics.formationsPlannedAndFinished.length !== 0 &&
                                    <ReactApexChart options={optionsPie} series={seriesPie} type="pie" width={'100%'} />
                                  }

                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody className="text-center d-flex flex-column">
                                  <p className="font-size-16 fw-medium">Volume d'heures de formation</p>

                                  <div className="h-100 d-flex flex-column justify-content-center">
                                      <p className="mb-0 text-primary fw-medium font-size-14">Vos collaborateurs ont réalisé</p>
                                      <p className="text-primary fw-medium mb-0 mt-0" style={{ fontSize: '40px', fontWeight: 'bold', transform: 'translateY(-5px)' }}>{formatMillier(trainingPlanAnalytics.totalDurationFormationFinished)}</p>
                                      <p className="mb-0 text-primary fw-medium font-size-14" style={{ transform: 'translateY(-5px)' }}>heures de formations</p>
                                  </div>
                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody className="text-center d-flex flex-column">
                                  <p className="font-size-16 fw-medium">Durée moyenne des formations</p>

                                  <div className="h-100 d-flex flex-column justify-content-center">
                                      <p className="mb-0 text-primary fw-medium font-size-14">La durée moyenne des <br/>formations est de</p>
                                      <p className="text-primary fw-medium mb-0 mt-0" style={{ fontSize: '40px', fontWeight: 'bold', transform: 'translateY(-5px)' }}>{isFinite(trainingPlanAnalytics.averageDuration) ? trainingPlanAnalytics.averageDuration : 0} heures</p>
                                      <p className="mb-0 text-primary fw-medium font-size-14" style={{ transform: 'translateY(-5px)' }}>sur un total de {trainingPlanAnalytics.totalFormationsFinished} formations réalisées</p>
                                  </div>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>

                  <Row className="mb-5 align-items-stretch">
                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody  className="text-center">
                                  <p className="font-size-16 fw-medium mb-2">Coût des formations HT</p>

                                  <ReactApexChart options={optionsBarBottom} series={seriesBarBottom} type="bar" height="180" />

                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody  className="text-center">

                                  <p className="font-size-16 fw-medium mb-4">Niveau d'accès à la formation</p>

                                  <Row className="align-items-center justify-content-center">
                                      <Col md="4">

                                          <div style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              backgroundColor: '#a93631',
                                              height: `${formationAccessLevel.totalEmployeeWithFormationsFinishedNotLessThanOneYear * 100 / formationAccessLevel.totalEmployees}px`,
                                              width: '60px',
                                              color: '#fff',
                                              fontSize: '13px'
                                          }}>
                                              {formationAccessLevel.totalEmployeeWithFormationsFinishedNotLessThanOneYear}
                                          </div>

                                          { formationAccessLevel.totalEmployeeWithFormationsFinishedLessThanOneYear > 0 && (
                                              <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  backgroundColor: '#597887',
                                                  height: `${formationAccessLevel.totalEmployeeWithFormationsFinishedLessThanOneYear * 100 / formationAccessLevel.totalEmployees}px`,
                                                  width: '60px',
                                                  color: '#fff',
                                                  fontSize: '13px'
                                              }}>
                                                  {formationAccessLevel.totalEmployeeWithFormationsFinishedLessThanOneYear}
                                              </div>
                                            )
                                          }

                                      </Col>

                                      <Col md={8} className="mt-4">
                                          <p className="font-size-13 mb-4">
                                              <span style={{ padding: '0 8px', borderRadius: '50%', backgroundColor: '#a93631', marginRight: '10px' }}> </span>N'ont pas réalisé de formation depuis 1 an
                                          </p>
                                          <p className="font-size-13 mb-4">
                                              <span style={{ padding: '0 8px', borderRadius: '50%', backgroundColor: '#597887', marginRight: '10px' }}> </span>
                                              Ont réalisé au moins une formation depuis 1 an</p>
                                      </Col>

                                  </Row>

                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody  className="text-center">
                                  <p className="font-size-16 fw-medium mb-2">Compétences travaillées</p>

                                  { skillsWorked && skillsWorked.series[0] !== 0
                                    ? <ReactApexChart options={optionsPieSkills} series={seriesPieSkills} type="pie" width={'100%'} />
                                    : <p  className="font-size-13 mb-4 mt-5">Aucune formations réalisées actuellement</p>
                                  }

                              </CardBody>
                          </Card>
                      </Col>

                      <Col md="3" className="mb-4">
                          <Card className="mb-0" style={{ borderRadius: 15, height: '100%' }}>
                              <CardBody className="text-center d-flex flex-column">
                                  <p className="font-size-16 fw-medium">Taux de formations certifiantes</p>

                                  <div className="h-100 d-flex flex-column justify-content-center">
                                      <p className="mb-0 text-primary fw-medium font-size-14">Parmi les formations réalisées,</p>
                                      <p className="text-primary fw-medium mb-0 mt-0" style={{ fontSize: '40px', fontWeight: 'bold', transform: 'translateY(-5px)' }}>{moyFormationsFinishedWithCertificate ? moyFormationsFinishedWithCertificate : 0} %</p>
                                      <p className="mb-0 text-primary fw-medium font-size-14" style={{ transform: 'translateY(-5px)' }}>sont des formations débouchant sur un certificat</p>
                                  </div>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>

                  <Row className="mb-0">
                      <Col md="12">

                          {map(adverts, (advert, index) => {
                              if (index === 0 && advert.isActive) {
                                  return <AdvertCard
                                    key={index}
                                    img={
                                        advert.image.hasOwnProperty('filePath')
                                          ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                                          : placeholder}
                                    message={advert.message}
                                    link={advert.link}
                                    isTransparent={true}
                                  />
                              } else {
                                  return null
                              }
                          })}

                      </Col>
                  </Row>


                  <p className="mt-5 mb-5">Fichier de recettage :
                      <a
                        href="https://insaniam.notion.site/Fichier-de-recettage-Administrateur-cc04f869a6564a5caf412062a0dec711"
                        className="mx-2 color-success"
                        target="_blank"
                        rel="noreferrer"
                      >Lien</a>
                  </p>

              </div>

          </React.Fragment>
        )
}

export default DashboardClient
