import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Data
import { countries } from "helpers/data_helper"
import { generatePassword } from "helpers/text_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectPrestataireAccount, ADD_ACCOUNT, PRESTATAIRE_ACCOUNT_ERROR } from "store/account/prestataireAccount/prestataireAccountSlice";
import {selectGlobalDatas, FORM_DATAS} from "store/globalData/globalDataSlice";


// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const PrestataireAccountAdd = ({history, isRole, location}) => {

  const dispatch = useDispatch()
  const { error } = useSelector(selectPrestataireAccount);
  const { localities } = useSelector(selectGlobalDatas);

  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [localityId, setLocalityId] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [city, setCity] = useState("")
  const [contactFirstname, setContactFirstname] = useState("")
  const [contactLastname, setContactLastname] = useState("")
  const [contactFunction, setContactFunction] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [codeNaf, setCodeNaf] = useState("")
  const [codeSiret, setCodeSiret] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [country, setCountry] = useState("")
  const [commercialCoverage, setCommercialCoverage] = useState("")
  const [codeNda, setCodeNda] = useState("")
  const [activeAccount, setActiveAccount] = useState(false)
  const [qualiopiCertification, setQualiopiCertification] = useState(false)
  const [subjectTva, setSubjectTva] = useState(false)
  const [presentation, setPresentation] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const GeneratePasswordSecure = () => {
    const passwordSecure = generatePassword()
    setPassword(passwordSecure)
    setConfirmPassword(passwordSecure)
  }

  function handleValidSubmitPrestataire() {

    if (password !== confirmPassword) {
      dispatch(PRESTATAIRE_ACCOUNT_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else if (localityId === "Choisir ...") {
      dispatch(PRESTATAIRE_ACCOUNT_ERROR("Veuillez renseigner un département !!"))
    } else {

      const data = {
        email: contactEmail,
        password: password,
        firstname: contactFirstname,
        lastname: contactLastname,
        roles: ['ROLE_PRESTATAIRE'],
        status: true,
        prestataire: {
          name: name,
          address: address,
          locality: `/api/localities/${localityId}`,
          zipCode: zipCode,
          city: city,
          presentation: presentation,
          contactFirstname: contactFirstname,
          contactLastname: contactLastname,
          contactFunction: contactFunction,
          contactPhone: contactPhone,
          contactEmail: contactEmail,
          codeNaf: codeNaf,
          codeSiret: codeSiret,
          codeTva: codeTva,
          country: country,
          commercialCoverage: commercialCoverage,
          codeNda: codeNda,
          activeAccount: !qualiopiCertification ? false : activeAccount,
          qualiopiCertification: qualiopiCertification,
          subjectTva: subjectTva
        },
        history: history
      }
      dispatch(ADD_ACCOUNT(data))
    }
  }

  function handleInvalidSubmitPrestataire() {
    dispatch(PRESTATAIRE_ACCOUNT_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(FORM_DATAS())

    // génération d'un mot de passe sécurisé
    GeneratePasswordSecure()

  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-prestataires" title="Retour à la liste des prestataires" />
            <TitleBanner title="Ajout d'un prestataire" />
            <SuccessErrorAlert error={error} />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitPrestataire(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitPrestataire(e, v)}
                    >

                      <Row className="mb-4">

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-4 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations de l'entreprise</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom de l'entreprise <span className="text-danger">*</span></h5>
                              <AvField
                                name="name"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setName(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Adresse de l'entreprise <span className="text-danger">*</span></h5>
                              <AvField
                                name="address"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setAddress(event.target.value)}
                                errorMessage="Veuillez renseigner une adresse"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Département <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="locality"
                                onChange={event => setLocalityId(event.target.value)}
                                helpMessage="Choisir un département dans le menu déroulant"
                              >
                                <option key="null">Choisir ...</option>
                                {localities.map( locality => (
                                  <option key={locality.id} value={locality.id}>
                                    {locality.departmentCode} - {locality.departmentName}
                                  </option>
                                ))}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code postal <span className="text-danger">*</span></h5>
                              <AvField
                                name="zipCode"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setZipCode(event.target.value)}
                                errorMessage="Veuillez renseigner un code postal"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Ville <span className="text-danger">*</span></h5>
                              <AvField
                                name="city"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCity(event.target.value)}
                                errorMessage="Veuillez renseigner une ville"
                                required
                              />
                            </div>

                            <div className="form-group mb-5">
                              <h5 className="font-size-17 mt-5 mb-3">Pays <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="country"
                                onChange={event => setCountry(event.target.value)}
                                helpMessage="Choisir un pays dans le menu déroulant">
                                {countries.map((country, idx) => <option key={idx}>{country}</option>)}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Domaine d'activité <span className="text-danger">*</span></h5>
                              <AvField
                                name="commercialCoverage"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCommercialCoverage(event.target.value)}
                                errorMessage="Veuillez renseigner un domaine"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code NAF <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeNaf"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeNaf(event.target.value)}
                                errorMessage="Veuillez renseigner un code NAF"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">SIRET <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeSiret"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeSiret(event.target.value)}
                                errorMessage="Veuillez renseigner un SIRET"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code TVA <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeTva"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeTva(event.target.value)}
                                errorMessage="Veuillez renseigner un code TVA"
                                required
                              />
                            </div>

                            <div className="form-check mt-5">
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="subject-tva"
                                     onChange={() => subjectTva ? setSubjectTva(false) : setSubjectTva(true)}
                                     checked={subjectTva}
                              />
                              <Label className="form-check-label" htmlFor="subject-tva">Assujetti à la TVA ?</Label>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Numéro de déclaration d'activité (NDA) <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeNda"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeNda(event.target.value)}
                                errorMessage="Veuillez renseigner un numéro NDA"
                                required
                              />
                            </div>

                            <FormGroup>
                              <div className="form-check mt-5">
                                <Input type="checkbox"
                                       className="form-check-input"
                                       id="status-input"
                                       onChange={() => qualiopiCertification ? setQualiopiCertification(false) : setQualiopiCertification(true)}
                                       checked={qualiopiCertification}
                                />
                                <Label className="form-check-label" htmlFor="status-input">Certification qualiopi ?
                                  <span className="font-size-15 color-grey mx-2">(si décoché et en l'absence de certification, les formations du prestataire ne seront pas accessible)</span>
                                </Label>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <div className="form-check mt-5">
                                <Input type="checkbox"
                                       className="form-check-input"
                                       id="status-input"
                                       onChange={() => activeAccount ? setActiveAccount(false) : setActiveAccount(true)}
                                       checked={activeAccount}
                                />
                                <Label className="form-check-label" htmlFor="status-input">Statut actif ?
                                  <span className="font-size-15 color-grey mx-2">(si décoché, le prestataire sera suspendu et ses formations ne seront pas accessible)</span>
                                </Label>
                              </div>
                            </FormGroup>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-4 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Présentation du prestataire</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-3 mb-3">Présentation</h5>
                              <AvField
                                name="presentation"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="textarea"
                                rows="5"
                                validate={{
                                  required: {value: false},
                                  minLength: {value: 0},
                                  maxLength: {value: 1000}
                                }}
                                onChange={event => setPresentation(event.target.value)}
                              />
                              <p className="font-size-15 color-grey mx-2 mt-3">(la présentation est limitée à 1000 caractères)</p>
                            </div>

                            <h5 className="mb-5 mt-5 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Contact de l'entreprise</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactFirstname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactLastname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Fonction <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactFunction"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactFunction(event.target.value)}
                                errorMessage="Veuillez renseigner une fonction"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactEmail"
                                value={contactEmail}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setContactEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Téléphone <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactPhone"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactPhone(event.target.value)}
                                errorMessage="Veuillez renseigner un téléphone"
                                required
                              />
                            </div>

                            <Card outline color="primary" className="border">
                              <CardBody className="mx-2">

                                <h5 className="mb-4 mt-2 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Mot de passe de connexion</h5>

                                <p><i className="uil uil-question-circle font-size-16 text-info me-2"></i> Un mot de passe sécurisé est généré automatiquement par défaut</p>

                                <Button
                                  color="dark"
                                  outline
                                  className="waves-effect waves-light mb-4 mt-3"
                                  onClick={ () => GeneratePasswordSecure()}
                                >
                                  Générer un nouveau mot de passe ?
                                </Button>

                                <div className="form-group">
                                  <h5 className="font-size-17 mt-4 mb-4">Mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="password"
                                    value={password}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setPassword(event.target.value)}
                                    errorMessage="Veuillez renseigner un mot de passe"
                                    required
                                  />
                                </div>

                                <div className="form-group mt-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Confirmer le mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    errorMessage="Veuillez confirmer le mot de passe"
                                    required
                                  />
                                </div>

                              </CardBody>
                            </Card>

                          </div>
                        </Col>

                      </Row>

                      <SuccessErrorAlert error={error} />

                      <div className="d-flex align-items-center mt-2 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Ajouter</Button>
                        </div>

                        <Link to="/gestion-prestataires" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des prestataires
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(PrestataireAccountAdd)
