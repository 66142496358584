import React, {useEffect} from "react"
import {map} from "lodash";

// import images
import logo from "assets/images/logo-firmain.png"

// REACT-PDF
import { PDFViewer, Page, Document, Image, Text, View, StyleSheet } from '@react-pdf/renderer';

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectCartography, CARTOGRAPHY } from "store/skillsDevelopmentPlan/cartography/cartographySlice";

const CartographyResumePdf = ({ cartographyId, cartographyWidth }) => {

  const dispatch = useDispatch()
  const { cartography } = useSelector(selectCartography);

  useEffect(() => {
    dispatch(CARTOGRAPHY({ cartographyId: cartographyId }))
  }, [dispatch])

    return (
      <>
        {cartography.hasOwnProperty('intervention') && (
          <PDFViewer width="100%" height="1200" scrolling="yes" className="app">
            <Document>
              <Page size={cartographyWidth > 1190 ? 'A2' : 'A3'} orientation="landscape" wrap={false} style={styles.page}>

                <Image style={styles.logo} src={logo} />

                <View style={styles.titleContainer}>
                  <Text style={styles.reportTitle}>Cartographie de l'organisation : {cartography.intervention.client.entName}</Text>
                </View>

                <View style={styles.descContainer}>
                  <Text style={styles.reportDesc}>
                    Date de création : {" "}
                    {moment(cartography.intervention.createdAt).locale('fr', localization).format("DD MMMM yyyy")}
                  </Text>
                </View>

                <View style={styles.legend}>
                  <Text style={styles.legendTitle}>Légende : </Text>
                  <Text style={styles.legendRiskVeryGood}>Expert</Text>
                  <Text style={styles.legendRiskGood}>Confirmé</Text>
                  <Text style={styles.legendRiskMedium}>Débutant</Text>
                  <Text style={styles.legendRiskBad}>Non maîtrisé</Text>
                  <Text style={styles.legendRiskNotConcerned}>Non concerné</Text>
                </View>

                {/*Cartography*/}

                { cartography.skills.length !== 0 ? (
                  <View style={styles.cartographyCard}>

                    {/*Cartography Header*/}

                    <View style={styles.cartographyHead}>
                      <Text style={styles.cartographyHeadPole}>Pôle</Text>
                      <Text  style={styles.cartographyHeadService}>Service</Text>
                      <Text  style={styles.cartographyHeadDomain}>Domaine de compétences</Text>
                      <Text  style={styles.cartographyHeadTask}>Tâches</Text>

                      {map(cartography.skills[0].employeeSkills, (employeeSkill, index) => (
                        <Text style={styles.cartographyHeadEmployee}>{employeeSkill.employee.clientEmployee.user.firstname} {employeeSkill.employee.clientEmployee.user.lastname}</Text>
                      ))}

                      <Text  style={styles.cartographyHeadAction}>Actions à mener</Text>

                    </View>

                    {/*Cartography Rows */}

                    {map(cartography.skills, (skill, index) => (
                      <View style={styles.cartographyRow} wrap={false}>
                        <Text style={styles.cartographyRowPole}>{skill.pole}</Text>
                        <Text  style={styles.cartographyRowService}>{skill.skillActivity.name}</Text>
                        <Text  style={styles.cartographyRowDomain}>{skill.skillDomain.name}</Text>
                        <Text  style={styles.cartographyRowTask}>{skill.task}</Text>

                        {map(skill.employeeSkills, (employeeSkill, index) => {

                          if (employeeSkill.colorRisk === "very-good") {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text style={styles.cartographyRowEmployeeColorVeryGood}>{employeeSkill.message}{" "}</Text>
                              </View>
                            )
                          } else if (employeeSkill.colorRisk === "good") {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text style={styles.cartographyRowEmployeeColorGood}>{employeeSkill.message}{" "}</Text>
                              </View>
                            )
                          } else if (employeeSkill.colorRisk === "medium") {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text style={styles.cartographyRowEmployeeColorMedium}> {employeeSkill.message}{" "}</Text>
                              </View>
                            )
                          } else if (employeeSkill.colorRisk === "bad") {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text style={styles.cartographyRowEmployeeColorBad}> {employeeSkill.message}{" "}</Text>
                              </View>
                            )
                          } else if (employeeSkill.colorRisk === "not-concerned") {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text style={styles.cartographyRowEmployeeColorNotConcerned}> {employeeSkill.message}{" "}</Text>
                              </View>
                            )
                          } else {
                            return (
                              <View style={styles.cartographyRowEmployee}>
                                <Text>{" "}</Text>
                              </View>
                            )
                          }
                        })}

                        <View style={styles.cartographyRowAction}>
                          { skill.risk === 3 && <Text style={styles.cartographyRowActionGood}>OK</Text> }
                          { skill.risk === 2 && <Text style={styles.cartographyRowActionMedium}>Action de formation <br/>dans l'année préconisée</Text> }
                          { skill.risk === 1 && <Text style={styles.cartographyRowActionBad}>Action de formation immédiate préconisée</Text> }
                        </View>

                      </View>
                    ))}

                  </View>
                ) : (
                  <Text style={{ fontWeight: 600, marginTop: 50, fontSize: 16 }}>Aucune cartographie à afficher</Text>
                )}

              </Page>
            </Document>
          </PDFViewer>
        )}
      </>
    )
}

export default CartographyResumePdf

const bgColor = '#f5f6f8'
const colorPrimary = '#597887'
const colorText = '#495057'

const colorVeryGood = '#009644'
const colorGood = '#92D050'
const colorMedium = '#e5a65a'
const colorBad = '#a93631'
const colorNotConcerned = '#a0a0a0'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    backgroundColor: bgColor,
    fontSize: 12,
    color: colorText,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },

  logo: {
    width: 90,
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  // title
  titleContainer:{
    flexDirection: 'row',
    marginTop: 40,
  },

  reportTitle:{
    color: colorPrimary,
    fontSize: 15,
    marginBottom: 10,
    textAlign: 'center'
  },

  descContainer:{
    flexDirection: 'column',
    marginBottom: 10
  },

  reportDesc: {

  },

  // Legend
  legend: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },

  legendTitle: {
    color: colorPrimary
  },

  legendRiskVeryGood: {
    backgroundColor: colorVeryGood,
    color: '#fff',
    marginLeft: '10px',
    padding: '3px 10px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  legendRiskGood: {
    backgroundColor: colorGood,
    color: '#fff',
    marginLeft: '10px',
    padding: '3px 10px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  legendRiskMedium: {
    backgroundColor: colorMedium,
    color: '#fff',
    marginLeft: '10px',
    padding: '3px 10px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  legendRiskBad: {
    backgroundColor: colorBad,
    color: '#fff',
    marginLeft: '10px',
    padding: '3px 10px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  legendRiskNotConcerned: {
    backgroundColor: colorNotConcerned,
    color: '#fff',
    marginLeft: '10px',
    padding: '3px 10px',
    fontSize: '10px',
    borderRadius: '5px'
  },

  // cartography
  cartographyCard: {
    //backgroundColor: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    marginBottom: 20,
    marginTop: 10
  },

  // cartography header
  cartographyHead: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    height: 72,
    backgroundColor: colorPrimary,
    color: 'white',
    marginBottom: 5
  },
  cartographyHeadPole: {
    width: 140,
    fontSize: '15px',
    fontWeight: 600,
  },
  cartographyHeadService: {
    width: 110,
    fontSize: '15px',
    fontWeight: 600,
  },
  cartographyHeadDomain: {
    width: 200,
    fontSize: '15px',
    fontWeight: 600,
  },
  cartographyHeadTask: {
    width: 300,
    fontSize: '15px',
    fontWeight: 600,
  },
  cartographyHeadEmployee: {
    width: 150,
    fontSize: '15px',
    fontWeight: 600,
    marginLeft: 10,
    marginRight: 10
  },
  cartographyHeadAction: {
    width: 180,
    fontSize: '15px',
    fontWeight: 600,
  },

  // cartography body
  cartographyRow: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    height: 72,
    backgroundColor: 'white',
    color: 'black',
    marginTop: 5,
    marginBottom: 5
  },
  cartographyRowPole: {
    width: 140,
    fontSize: '15px',
    color: '#4f8ecb',
  },
  cartographyRowService: {
    width: 110,
    fontSize: '15px',
    fontWeight: 600,
    color: '#4f8ecb'
  },
  cartographyRowDomain: {
    width: 200,
    fontSize: '15px',
    fontWeight: 600,
    color: '#4f8ecb'
  },
  cartographyRowTask: {
    width: 300,
    fontSize: '15px',
    fontWeight: 600,
    color: colorText
  },
  cartographyRowEmployee: {
    width: 150,
    fontSize: '15px',
    fontWeight: 400,
    color: colorText,
    marginLeft: 10,
    marginRight: 10
  },
  cartographyRowEmployeeColorVeryGood: {
    fontSize: '15px',
    backgroundColor: colorVeryGood,
    color: 'white',
    borderRadius: 50,
    padding: '8px 10px',
  },
  cartographyRowEmployeeColorGood: {
    fontSize: '15px',
    backgroundColor: colorGood,
    color: 'white',
    borderRadius: 50,
    padding: '8px 10px'
  },
  cartographyRowEmployeeColorMedium: {
    fontSize: '15px',
    backgroundColor: colorMedium,
    color: 'white',
    borderRadius: 50,
    padding: '8px 10px'
  },
  cartographyRowEmployeeColorBad: {
    fontSize: '15px',
    backgroundColor: colorBad,
    color: 'white',
    borderRadius: 50,
    padding: '8px 10px'
  },
  cartographyRowEmployeeColorNotConcerned: {
    fontSize: '15px',
    backgroundColor: colorNotConcerned,
    color: 'white',
    borderRadius: 50,
    padding: '8px 10px'
  },
  cartographyRowAction: {
    width: 180,
    fontSize: '15px',
    fontWeight: 400,
    color: colorText,
    marginLeft: 15,
    marginRight: 15
  },
  cartographyRowActionGood: {
    backgroundColor: colorGood,
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    padding: '10px 10px',
    borderRadius: 7,
  },
  cartographyRowActionMedium: {
    backgroundColor: colorMedium,
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    padding: '10px 10px',
    borderRadius: 7,
  },
  cartographyRowActionBad: {
    backgroundColor: colorBad,
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    padding: '10px 10px',
    borderRadius: 7,
  }
});
