import React, {useEffect, useRef, useState} from "react"
import {Button, Card, CardBody, Col, Container, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Redirect, Link} from "react-router-dom"
import {isEmpty, map} from 'lodash'

import "./datatable.scss"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"

// REDUX
import {useDispatch, useSelector} from "react-redux";

import {
  selectCartography,
  CARTOGRAPHY_BY_CLIENT,
  UPDATE_CARTOGRAPHY_VALIDATE,
  UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE,
  DELETE_CARTOGRAPHY
} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

import {ADD_CARTOGRAPHY_MODAL_DATA, UPDATE_CARTOGRAPHY, CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {UPDATE_SKILL_REQUIRED_LEVEL} from "store/skillsDevelopmentPlan/skill/skillSlice";
import {ADD_INTERVENTION, ARCHIVED_INTERVENTION} from "store/intervention/interventionSlice";
import {selectClientAccount} from "store/account/clientAccount/clientAccountSlice";
import {selectAdvert, ADVERTS} from "store/advert/advertSlice";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import CartographyTableCreateColumn from 'pages/SkillsDevelopmentPlan/Cartography/CartographyTableColumn/CartographyTableCreateColumn'
import CartographyTableReadColumn from 'pages/SkillsDevelopmentPlan/Cartography/CartographyTableColumn/CartographyTableReadColumn'

import ClientCartographyValidateModal from "./ClientCartographyValidateModal";
import CartographyDevalidateModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyDevalidateModal";
import CartographyDeleteModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyDeleteModal";
import CartographyRequiredLevelModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyRequiredLevelModal";
import CartographyArchivedModal from "components/Common/Modal/Cartography/CartographyArchivedModal";
import CartographyModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyModal";
import AdvertCard from "components/Common/Advert/AdvertCard";

import placeholder from "assets/images/404-error.png"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ClientCartography = ({isRole, location, history}) => {

  const { SearchBar } = Search;

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()

  const [cartographyWidth, setCartographyWidth] = useState(0)
  const { adverts } = useSelector(selectAdvert);

  /* ========== Btn Scroll =========================== */
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  /* ================================================= */

  const { cartography } = useSelector(selectCartography);
  const { client, success, error } = useSelector(selectClientAccount);

  const [showModal, setShowModal] = useState(false)
  const [showModalValidate, setShowModalValidate] = useState(false)
  const [showModalDevalidate, setShowModalDevalidate] = useState(false)
  const [showModalDeleteCartography, setShowModalDeleteCartography] = useState(false)
  const [showModalRequiredLevel, setShowModalRequiredLevel] = useState(false)
  const [showModalCartographyArchived, setShowModalCartographyArchived] = useState(false)
  const [cartographyId, setCartographyId] = useState(0)

  const toggleModal = (employeeActionId, employeeFirstname, employeeLastname, message, colorRisk) => {

    if (!showModal) dispatch(ADD_CARTOGRAPHY_MODAL_DATA({
      employeeActionId: employeeActionId,
      employeeFirstname: employeeFirstname,
      employeeLastname: employeeLastname,
      message: message,
      colorRisk: colorRisk
    }))

    setShowModal(!showModal);
  };

  const toggleModalValidate = () => {
    setShowModalValidate(!showModalValidate);
  };

  const toggleModalDevalidate = () => {
    setShowModalDevalidate(!showModalDevalidate);
  };

  const toggleModalDeleteCartography = () => {
    setShowModalDeleteCartography(!showModalDeleteCartography);
  };

  const toggleModalRequiredLevel = (skillData) => {
    if (!showModal) dispatch(ADD_CARTOGRAPHY_MODAL_DATA(skillData))
    setShowModalRequiredLevel(!showModalRequiredLevel);
  };

  const toggleModalCartographyArchived = () => {
    setShowModalCartographyArchived(!showModalCartographyArchived);
  };

  const createInterventionDiagnostic = () => {
    dispatch(ADD_INTERVENTION({ clientId: clientId }))
  }

  const validateCartography = () => {

    if (cartography.hasOwnProperty('trainingPlans') && !isEmpty(cartography.trainingPlans)) {

      // Si le PDC existe déjà, mise à jour des lignes de demande de formation
      dispatch(UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE({
        validate: true,
        cartographyId: cartography.id,
        interventionId: cartography.intervention.id,
        history: history,
        clientName: cartography.intervention.client.entName,
        trainingPlanId: cartography.trainingPlans[0].id,
        trainingPlanActions: cartography.trainingPlans[0].trainingPlanActions,
        clientId: cartography.intervention.client.id,
      }))

    } else {
      dispatch(UPDATE_CARTOGRAPHY_VALIDATE({
        validate: true,
        cartographyId: cartography.id,
        interventionId: cartography.intervention.id,
        history: history,
        clientId: cartography.intervention.client.id
      }))
    }

    setShowModalValidate(!showModalValidate);
  };

  const devalidateCartography = () => {

    dispatch(UPDATE_CARTOGRAPHY_VALIDATE({
      validate: false,
      cartographyId: cartography.id,
      interventionId: cartography.intervention.id,
      clientId: cartography.intervention.client.id,
      clientAccountId: cartography.intervention.client.user.id,
      history: history
    }))

    setShowModalDevalidate(!showModalDevalidate);
  };

  const updateCartography = (event, data) => {

    dispatch(UPDATE_CARTOGRAPHY({
      colorRisk: data.colorRisk,
      message: data.message,
      employeeActionId: data.employeeActionId,
      cartographyId: cartography.id,
    }))
    setShowModal(!showModal);
  };

  const deleteCartography = () => {
    dispatch(DELETE_CARTOGRAPHY({ cartographyId: cartography.id, clientId }))

    setShowModalDeleteCartography(!showModalDeleteCartography);
  }

  const updateRequiredLevel = (event, data) => {
    dispatch(UPDATE_SKILL_REQUIRED_LEVEL({
      requiredLevel: parseInt(data.requiredLevel),
      skillId: data.skillId,
      cartographyId: cartography.id,
    }))

    setShowModalRequiredLevel(!showModalRequiredLevel);
  };

  const archivedCartography = interventionId => {

    const data = {
      interventionId,
      isArchived: true,
      clientId
    }

    dispatch(ARCHIVED_INTERVENTION(data))
    setShowModalCartographyArchived(!showModalCartographyArchived);
  };

  /* ============ start Searchbar Cartography =================  */

  const handleSelectCartographySubmit = () => {}

  /* ===========================================================  */

  /* ============ tri des cartographie en ordre décroissant =================  */

  const interventionsDescending = (interventions) => {

    const allInterventions = interventions.filter(intervention => {
      if (intervention.hasOwnProperty('cartography') && !intervention.isArchived) {
        return intervention
      }
    })

    return allInterventions.reverse()
  }

  /* ===========================================================  */

  const paginateOptions = {
    sizePerPage: 8,
    totalSize: cartography.skills ? cartography.skills.length : 0,
    custom: true,
    nextPageText: 'Suivant',
    prePageText: 'Précédent',
  }

  useEffect(() => {
    dispatch(CARTOGRAPHY_BY_CLIENT({ clientId: clientId }))
    dispatch(ADVERTS())

    if (ref.current) setCartographyWidth(ref.current.scrollWidth)
  }, [dispatch, clientId, ref])


  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        {!cartography.validate && <CartographyModal isOpen={showModal} toggle={toggleModal} updateCartography={updateCartography} />}
        <ClientCartographyValidateModal isOpen={showModalValidate} toggle={toggleModalValidate} validateCartography={validateCartography} />
        <CartographyDevalidateModal isOpen={showModalDevalidate} toggle={toggleModalDevalidate} devalidateCartography={devalidateCartography} />
        <CartographyDeleteModal isOpen={showModalDeleteCartography} toggle={toggleModalDeleteCartography} deleteCartography={deleteCartography} />
        <CartographyRequiredLevelModal isOpen={showModalRequiredLevel} toggle={toggleModalRequiredLevel} updateRequiredLevel={updateRequiredLevel} />
        <CartographyArchivedModal isOpen={showModalCartographyArchived} toggle={toggleModalCartographyArchived} archivedCartography={archivedCartography} />

        <div className="page-content my-5">
          <Container fluid>

            <SuccessErrorAlert error={error} success={success} />

            <Row className="mb-2">
              <Col md="12">

                {map(adverts, (advert, index) => {
                  if (index === 0 && advert.isActive) {
                    return <AdvertCard
                      key={index}
                      img={
                        advert.image.hasOwnProperty('filePath')
                          ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                          : placeholder}
                      message={advert.message}
                      link={advert.link}
                      isTransparent={true}
                    />
                  } else {
                    return null
                  }
                })}

              </Col>
            </Row>

            <h5 className="font-size-22 color-secondary text-center mb-5">Cartographie</h5>

            {/* vérification si processus de diagnostique existant */}

            { (client.hasOwnProperty('interventions') && client.interventions) ? (
              <Row>
                <Col lg={12}>

                      { client.interventions[0].cartography ? (
                        <>

                          <Row className="align-items-center pb-5" style={{ borderBottom: '1px solid #597887' }}>
                            <Col lg={2}>
                              <h5 className="font-size-17 mb-0">Choisir une cartographie</h5>
                            </Col>

                            <Col lg={6}>
                              <AvForm onValidSubmit={(e, v) => handleSelectCartographySubmit(e, v)}>
                                <Row>
                                  <Col className="col-8">
                                    <AvField
                                      className="form-select"
                                      type="select"
                                      name="cartography"
                                      onChange={event => {
                                        setCartographyId(event.target.value)
                                        dispatch(CARTOGRAPHY({ cartographyId: event.target.value }))
                                      }}
                                      errorMessage="Choisir une cartographie"
                                    >
                                      {client.hasOwnProperty('interventions') && map(interventionsDescending(client.interventions), intervention => {
                                        if (intervention.id && intervention.hasOwnProperty('cartography')) {
                                          return (
                                            <option key={`intervention-${intervention.id}`} value={intervention.cartography.id}>
                                              {moment(intervention.cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}
                                            </option>
                                          )
                                        }
                                      })}
                                    </AvField>
                                  </Col>
                                </Row>
                              </AvForm>
                            </Col>

                            <Col lg={4}>
                                <Link to="/ma-cartographie-creation" className="btn btn-primary waves-effect waves-light mx-2 text-white">
                                  <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Création d'une nouvelle cartographie
                                </Link>
                            </Col>
                          </Row>

                          { cartography.validate ? (
                            <>
                              {/* Mode lecture non modifiable (après validation) */}

                              <p className="mt-5">Cartographie sélectionnée : {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

                              <Row className="mb-5 mt-3 align-items-center py-3" style={{ backgroundColor: 'rgba(169, 54, 49, .1)' }}>

                                <Col lg={5}>
                                  <p className="text-danger mb-0">La cartographie est validé, pour la modifier, enlever la validation</p>
                                </Col>

                                <Col lg={7}>
                                  <div className="d-flex">
                                    <button  onClick={() => toggleModalDevalidate()} className="btn btn-danger waves-effect waves-light mx-2">
                                      <i className="uil uil-corner-up-right-alt me-1"></i>Enlever la validation
                                    </button>

                                    <button  onClick={() => toggleModalCartographyArchived()} type="button" className="btn btn-danger waves-effect waves-light mx-4">
                                      <i className="uil uil-corner-up-right-alt me-1"></i>Supprimer la cartographie
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </>
                            ) : (
                            <>
                              <p className="mt-5">Cartographie sélectionnée : {moment(cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

                              { !isEmpty(cartography.skills) ? (
                                <Row className="mb-5 align-items-center justify-content-center py-3 mt-3" style={{ backgroundColor: 'rgba(56, 133, 99, .1)' }}>
                                  <Col sm={6}>
                                    <div className="d-flex">
                                      <button  onClick={() => toggleModalValidate()} type="button" className="btn btn-success waves-effect waves-light mx-4">
                                        <i className="uil uil-corner-up-right-alt me-1"></i>Valider la cartographie
                                      </button>

                                      <button  onClick={() => toggleModalCartographyArchived()} type="button" className="btn btn-danger waves-effect waves-light mx-4">
                                        <i className="uil uil-corner-up-right-alt me-1"></i>Supprimer la cartographie
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Row className="mb-5 align-items-center justify-content-center py-3 mt-3" style={{ backgroundColor: 'rgba(169, 54, 49, .1)' }}>
                                  <Col sm={3}>
                                    <button  onClick={() => toggleModalDeleteCartography()} className="btn btn-danger waves-effect waves-light mx-2">
                                      <i className="uil uil-corner-up-right-alt me-1"></i>Supprimer la cartographie
                                    </button>
                                </Col>
                                </Row>
                              )}
                            </>
                          )}

                        { cartography.validate ? (
                          <div className="mt-5">

                            { isEmpty(cartography.trainingPlans) && (
                              <Link to={{pathname:`/mon-pdc-creation`, state: cartography.id}} className="btn btn-success waves-effect waves-light mx-1 mb-5">
                                <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Créer un plan de développement des compétences
                              </Link>
                            )}

                              <Row>
                                <Col lg={12} className="mt-2 mb-5">
                                  <span className="text-primary">Légende :</span>
                                  <span className="badge bg-risk-very-good mx-2">Expert</span>
                                  <span className="badge bg-risk-good mx-2">Confirmé</span>
                                  <span className="badge bg-risk-medium mx-2">Débutant</span>
                                  <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                                  <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                                </Col>
                              </Row>

                              <Row className="mt-4">
                                <Col md="12 position-relative">

                                  <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                                    {({paginationProps, paginationTableProps}) => (
                                      <ToolkitProvider
                                        keyField="id"
                                        data={cartography.skills || []}
                                        columns={CartographyTableReadColumn(cartography)}
                                        bootstrap4
                                        search
                                        cellEdit={cellEditFactory({mode: "click"})}
                                      >
                                        {toolkitProps => (
                                          <React.Fragment>

                                            <Row>
                                              <Col sm="12" md="6">
                                                { (cartography.validate) && (
                                                  <Link to={{pathname:`/ma-cartographie-pdf`, state: {cartographyId: cartography.id, cartographyWidth: cartographyWidth }}} className="btn btn-primary waves-effect waves-light me-1">
                                                    Exporter en PDF <i className="uil uil-download-alt ms-2"></i>
                                                  </Link>
                                                )}
                                              </Col>

                                              <Col sm="12" md="6">
                                                <Label className="float-end">{" "}
                                                  <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                                </Label>
                                              </Col>
                                            </Row>

                                            <div className="table-responsive mb-4" ref={ref}>

                                              <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                              <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                              <BootstrapTable
                                                keyField="id"
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                hover={true}
                                                classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                                cellEdit={cellEditFactory({
                                                  mode: "click",
                                                  autoSelectText: true,
                                                })}
                                                headerWrapperClasses={"bg-transparent"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                              <div className="float-end">
                                                <PaginationListStandalone {...paginationProps} />
                                              </div>
                                            </div>

                                          </React.Fragment>
                                        )}
                                      </ToolkitProvider>
                                    )}
                                  </PaginationProvider>
                                </Col>
                              </Row>
                            </div>
                        ) : (
                          <>

                            {/* Mode création modifiable (non validé par l'organisation) */}

                            <h5 className="font-size-18 color-secondary mb-4 mt-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Edition de la cartographie</h5>

                            <Row>
                              <Col sm={12}>
                                <Link to={{ pathname: "/ma-cartographie-configuration", state: cartographyId !== 0 ? cartographyId : cartography.id }} className="btn btn-primary waves-effect waves-light mx-2 mb-5">
                                  <i className="uil uil-corner-up-right-alt me-1"></i>Mise à jour des compétences et des collaborateurs
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12} className="mt-0 mb-5">
                                <span className="text-primary">Légende :</span>
                                <span className="badge bg-risk-very-good mx-2">Expert</span>
                                <span className="badge bg-risk-good mx-2">Confirmé</span>
                                <span className="badge bg-risk-medium mx-2">Débutant</span>
                                <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                                <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                              </Col>
                            </Row>

                            <Card className="mb-5 pt-4 pb-3">
                              <CardBody className="mx-5">

                                { !isEmpty(cartography.skills) ? (
                                  <Row>
                                    <Col md="12 position-relative">

                                      <PaginationProvider pagination={paginationFactory(paginateOptions)}>

                                        {({paginationProps, paginationTableProps}) => (
                                          <ToolkitProvider
                                            keyField="id"
                                            data={cartography.skills || []}
                                            columns={CartographyTableCreateColumn(toggleModal, toggleModalRequiredLevel, cartography)}
                                            bootstrap4
                                            search
                                          >
                                            {toolkitProps => (
                                              <React.Fragment>

                                                <Row>
                                                  <Col>
                                                    <Label className="float-end">{" "}
                                                      <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                                                    </Label>
                                                  </Col>
                                                </Row>

                                                <div className="table-responsive mb-4" ref={ref}>

                                                  <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                                  <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                                  <BootstrapTable
                                                    keyField="id"
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    hover={true}
                                                    classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                                    headerWrapperClasses={"bg-transparent"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                  />
                                                  <div className="float-end">
                                                    <PaginationListStandalone {...paginationProps} />
                                                  </div>
                                                </div>

                                              </React.Fragment>
                                            )}
                                          </ToolkitProvider>
                                        )}
                                      </PaginationProvider>
                                    </Col>
                                  </Row>
                                ) : (
                                  <>
                                    <p>La cartographie est vide.</p>
                                    <p>Veuillez ajouter des compétences à la cartographie.</p>
                                  </>
                                )}

                              </CardBody>
                            </Card>

                          </>
                        )}
                        </>

                      ) : (
                        <>
                        <h5 className="font-size-18 color-secondary mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Création d'une cartographie</h5>

                        <Row>
                          <Col sm={12}>

                            <p>Actuellement, aucune cartographie n'est présente.</p>


                          <Link to="/ma-cartographie-creation" className="btn btn-primary waves-effect waves-light mx-1">
                            <i className="uil uil-clipboard-notes font-size-18"></i>{" "}Création d'une nouvelle cartographie
                          </Link>

                          </Col>
                        </Row>
                        </>
                      )}


                </Col>
              </Row>
            ) : (
              <>
                <p className="mt-3">Aucun diagnostique en cours</p>

                <div className="mt-3">
                  <div className="mt-3">
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3 text-white"
                      onClick={createInterventionDiagnostic}
                      id="btn-new-event"
                    >
                      Commencer un diagnostique
                    </Button>
                  </div>

                </div>

              </>
            )}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default ClientCartography
