import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  users: [],
  user: {},
  success: "",
  successForm: "",
  successDataForm: "",
  successPasswordForm: "",
  error: "",
  errorDataForm: "",
  errorPasswordForm: "",
  isLoading: false,
  showModal: false
}

const accountSlice = createSlice({
  name: "account",
  initialState,

  reducers: {

    ACCOUNTS (state) {
      return { ...state, successForm: ""  }
    },

    ACCOUNT (state) {
      return { ...state, error: "" }
    },

    ADMIN_ACCOUNT (state) {
      state.isLoading = true
    },

    GET_ACCOUNTS (state, action) {
      state.isLoading = true
      state.users = action.payload  // payload: users
    },

    GET_ACCOUNT (state, action) {
      state.isLoading = true
      state.user = action.payload  // payload: user
    },

    ADD_ACCOUNT (state, action) {
      state.user = action.payload
    },

    UPDATE_ACCOUNT (state) {
      state.isLoading = true
    },

    UPDATE_PASSWORD_ACCOUNT (state) {
      state.isLoading = true
    },

    DELETE_ACCOUNT (state) {
      state.isLoading = true
      state.user = {}
    },

    ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADD_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorDataForm = ""
      state.errorPasswordForm = ""
      state.successForm = action.payload
    },

    ADD_ACCOUNT_DATA_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorDataForm = ""
      state.errorPasswordForm = ""
      state.successDataForm = action.payload
      state.successPasswordForm = ""
    },

    ADD_ACCOUNT_PASSWORD_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.errorDataForm = ""
      state.errorPasswordForm = ""
      state.successPasswordForm = action.payload
      state.successDataForm = ""
    },

    ACCOUNT_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false  }
    },

    ACCOUNT_FORM_DATA_ERROR (state, action) {
      return { ...state, errorDataForm: action.payload, errorPasswordForm: "", successPasswordForm: "", successDataForm: "" }
    },

    ACCOUNT_FORM_PASSWORD_ERROR (state, action) {
      return { ...state, errorPasswordForm: action.payload, errorDataForm: "", successPasswordForm: "", successDataForm: "" }
    },

    RESET_ALERT_MESSAGE (state) {
      state.error = ""
      state.success = ""
    }
  }
});

const { reducer, actions } = accountSlice;

export const {
  ACCOUNTS,
  ACCOUNT,
  ADMIN_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_PASSWORD_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_SUCCESS,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_DATA_SUCCESS,
  ADD_ACCOUNT_PASSWORD_SUCCESS,
  ACCOUNT_ERROR,
  ACCOUNT_FORM_DATA_ERROR,
  ACCOUNT_FORM_PASSWORD_ERROR,
  RESET_ALERT_MESSAGE
} = actions;

export default reducer;

export const selectAccount = (state) => state.account;
