import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  INTERVENTIONS,
  INTERVENTION,
  GET_INTERVENTIONS,
  GET_INTERVENTION,
  ADD_INTERVENTION,
  ADD_NEW_INTERVENTION,
  UPDATE_INTERVENTION_DIAGNOSTIC,
  DELETE_INTERVENTION,
  ARCHIVED_INTERVENTION,
  INTERVENTION_SUCCESS,
  INTERVENTION_ERROR,
} from "./interventionSlice"

import {
  getInterventions,
  getIntervention,
  postIntervention,
  putIntervention,
  deleteIntervention,
} from "helpers/request_helper"
import {CLIENT_ACCOUNT_INTERVENTION, GET_CLIENT_ACCOUNT} from "../account/clientAccount/clientAccountSlice";
import {getClientAccount} from "../../helpers/request_helper";
import {CARTOGRAPHY_BY_CLIENT} from "../skillsDevelopmentPlan/cartography/cartographySlice";

function* viewInterventions() {
  try {
    const response = yield call(getInterventions)
    yield put(GET_INTERVENTIONS(response['hydra:member']))
    yield put(INTERVENTION_SUCCESS(""))
  } catch (error) {
    yield put(INTERVENTION_ERROR(error.message))
  }
}

function* viewIntervention({ payload: { interventionId } }) {
  try {
    const response = yield call(getIntervention, interventionId)
    yield put(GET_INTERVENTION(response))
    yield put(INTERVENTION_SUCCESS(""))
  } catch (error) {
    yield put(INTERVENTION_ERROR(error.message))
  }
}

function* addNewIntervention({payload: { clientId }}) {

  try {
    const data = {
      client: `/api/clients/${clientId}`,
      createdAt: new Date(),
      interventionStatus: "/api/intervention_statuses/4",
      isArchived: false
    }

    const response = yield call(postIntervention, data)
    yield put(ADD_NEW_INTERVENTION(response))

    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    yield put(INTERVENTION_SUCCESS(""))
  } catch (error) {
    yield put(INTERVENTION_ERROR(error.message))
  }
}

function* removeIntervention({ payload: { clientId, interventionId } }) {
  try {
    yield call(deleteIntervention, interventionId)

    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    yield put(INTERVENTION_SUCCESS(`Suppression de l'intervention avec succès !!`))
  } catch (error) {
    yield put(INTERVENTION_ERROR(error.message))
  }
}

function* editInterventionStartDiagnostic({ payload: { interventionId } }) {
  try {

    yield call(putIntervention, {
      id: interventionId,
      interventionStatus: "/api/intervention_statuses/2"
    })

    yield put (INTERVENTION({ interventionId: interventionId }))
    yield put(INTERVENTION_SUCCESS("L'intervention a été actualisée"))
  } catch (error) {
    yield put(INTERVENTION_ERROR(error))
  }
}

function* archivedIntervention({ payload: { isArchived, interventionId, clientId } }) {
  try {

    yield call(putIntervention, {
      id: interventionId,
      isArchived
    })

    yield put (INTERVENTION({ interventionId: interventionId }))

    if (!isArchived) {
      yield put (CLIENT_ACCOUNT_INTERVENTION({ clientId: clientId }))
      yield put(INTERVENTION_SUCCESS("La cartographie a bien été remise avec succès !"))
    } else {
      yield put (CARTOGRAPHY_BY_CLIENT({ clientId: clientId }))
      yield put(INTERVENTION_SUCCESS("La cartographie a été supprimée"))
    }

  } catch (error) {
    yield put(INTERVENTION_ERROR(error))
  }
}

export function* watchProfile() {
  yield takeEvery(INTERVENTIONS.type, viewInterventions)
  yield takeEvery(INTERVENTION.type, viewIntervention)
  yield takeEvery(ADD_INTERVENTION.type, addNewIntervention)
  yield takeEvery(DELETE_INTERVENTION.type, removeIntervention)
  yield takeEvery(UPDATE_INTERVENTION_DIAGNOSTIC.type, editInterventionStartDiagnostic)
  yield takeEvery(ARCHIVED_INTERVENTION.type, archivedIntervention)
}

export default function* InterventionSaga() {
  yield all([fork(watchProfile)])
}
