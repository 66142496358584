import {Redirect} from "react-router-dom";

// Dashboard
import DashboardScene from "scenes/DashboardScene"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import ForgetPwd from "pages/Authentication/ForgetPassword"
import ForgetPwdReset from "pages/Authentication/ForgetPasswordReset"
import PageFaq from "pages/Utility/page-faq"
import Page404 from "pages/Utility/page-404"
import Page500 from "pages/Utility/page-500"
import Contact from "pages/Utility/page-contact"
import MentionsLegales from "pages/Utility/page-mentions-legales"
import PolitiqueConfidentialite from "pages/Utility/page-politique-confidentialite"
import LockScreen from "pages/Authentication/LockScreen"
import UserProfile from "pages/Authentication/UserProfile";
import PageNotPermission from "pages/Utility/page-not-permission";

// ADMINISTRATOR DASHBOARD

import Account from "pages/Administrator/Account/Account";
import AccountAdminAdd from "pages/Administrator/Account/AccountAdminAdd";
import AccountEdit from "pages/Administrator/Account/AccountEdit";

import Cartography from "pages/Administrator/SkillsDevelopmentPlan/Cartography/Cartography";
import CartographyCreate from "pages/Administrator/SkillsDevelopmentPlan/Cartography/CartographyCreate";
import CartographyConfiguration from "pages/Administrator/SkillsDevelopmentPlan/Cartography/CartographyConfiguration";
import CartographyPdf from "pages/Administrator/SkillsDevelopmentPlan/Cartography/CartographyPdf";

import FormationPlan from "pages/Administrator/SkillsDevelopmentPlan/FormationPlan/FormationPlan";
import FormationPlanNew from "pages/Administrator/SkillsDevelopmentPlan/FormationPlan/FormationPlanNew";
import FormationPlanEdit from "pages/Administrator/SkillsDevelopmentPlan/FormationPlan/FormationPlanEdit";

import ClientAccount from "pages/Administrator/Client/ClientAccount";
import ClientAccountAdd from "pages/Administrator/Client/ClientAccountAdd";
import ClientAccountDetail from "pages/Administrator/Client/ClientAccountDetail";
import ClientAccountEdit from "pages/Administrator/Client/ClientAccountEdit";

import TransactionAll from "pages/Administrator/Transaction/TransactionAll";
import TransactionAdd from "pages/Administrator/Transaction/TransactionAdd";
import TransactionDetail from "pages/Administrator/Transaction/TransactionDetail";

import PrestataireAll from "pages/Administrator/Prestataire/PrestataireAll";
import PrestataireAccountAdd from "pages/Administrator/Prestataire/PrestataireAccountAdd";
import PrestataireDetail from "pages/Administrator/Prestataire/PrestataireDetail";
import PrestataireEdit from "pages/Administrator/Prestataire/PrestataireEdit";

import IntervenantGestion from "pages/Administrator/Prestataire/Intervenant/IntervenantGestion";
import IntervenantAdd from "pages/Administrator/Prestataire/Intervenant/IntervenantAdd";
import IntervenantEdit from "pages/Administrator/Prestataire/Intervenant/IntervenantEdit";

import ClientEmployeeAccountAdd from "pages/Administrator/ClientEmployee/ClientEmployeeAccountAdd";
import ClientEmployeeOrganisationAdd from "pages/Administrator/ClientEmployee/ClientEmployeeOrganisationAdd";
import ClientEmployeeGestion from "pages/Administrator/Client/ClientEmployeeGestion";
import ClientEmployeeGestionArchive from "pages/Administrator/Client/ClientEmployeeGestionArchive";

import FormationAll from "pages/Administrator/Formation/FormationAll";
import FormationAdd from "pages/Administrator/Formation/FormationAdd";
import FormationDetail from "pages/Administrator/Formation/FormationDetail/FormationDetail";
import FormationInternalChoiceDetail from "pages/Administrator/Formation/FormationDetail/FormationInternalChoiceDetail";
import FormationEdit from "pages/Administrator/Formation/FormationEdit";
import FormationPlanSelectFormation from "pages/Administrator/Formation/FormationPlanSelectFormation";

import FormationInternalAdd from "pages/Administrator/FormationInternal/FormationInternalAdd";
import FormationInternalEdit from "pages/Administrator/FormationInternal/FormationInternalEdit";
import FormationInternalAll from "pages/Administrator/FormationInternal/FormationInternalAll";
import FormationInternalDetail from "pages/Administrator/FormationInternal/FormationInternalDetail";

// CLIENT DASHBOARD

import ClientInformation from 'pages/Client/Information/ClientInformation'
import ClientTeam from "pages/Client/Team/ClientTeam";
import ClientTeamArchive from "pages/Client/Team/ClientTeamArchive";
import ClientTeamEmployeeAccountAdd from "pages/Client/Team/ClientTeamEmployeeAccountAdd";
import ClientPoleList from "pages/Client/Team/ClientPoleList";
import ClientOrganigram from "pages/Client/Team/ClientOrganigram";
import ClientFicheMission from "pages/Client/Team/ClientFicheMission";
import ClientFicheMissionDetail from "pages/Client/Team/ClientFicheMissionDetail";

import ClientPdc from "pages/Client/SkillsDevelopmentPlan/ClientPdc";
import ClientPdcCreate from "pages/Client/SkillsDevelopmentPlan/ClientPdcCreate";

import ClientCartography from "pages/Client/SkillsDevelopmentPlan/Cartography/ClientCartography";

import ClientCartographyPdf from "pages/Client/SkillsDevelopmentPlan/Cartography/ClientCartographyPdf";
import ClientCartographyCreate from "pages/Client/SkillsDevelopmentPlan/Cartography/ClientCartographyCreate";
import ClientCartographyConfiguration from "pages/Client/SkillsDevelopmentPlan/Cartography/ClientCartographyConfiguration";

import ClientFormation from "pages/Client/Formation/ClientFormation";
import ClientFormationDetail from "pages/Client/Formation/ClientFormationDetail";
import ClientFormationEdit from "pages/Client/Formation/ClientFormationEdit";
import ClientFormationSelectFormation from "pages/Client/Formation/ClientFormationSelectFormation";
import ClientFormationPlanSelectFormationInternalDetail from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanSelectFormationInternalDetail";


import ClientCatalogueFormation from "pages/Client/CatalogueFormation/ClientCatalogueFormation";
import ClientCatalogueFormationDetail from "pages/Client/CatalogueFormation/ClientCatalogueFormationDetail";

import ClientSeFormerAutrement from "pages/Client/SeFormerAutrement/ClientSeFormerAutrement";

import ClientFormationInternalAdd from "pages/Client/FormationInternal/ClientFormationInternalAdd";
import ClientFormationInternalEdit from "pages/Client/FormationInternal/ClientFormationInternalEdit";
import ClientFormationInternalAll from "pages/Client/FormationInternal/ClientFormationInternalAll";
import ClientFormationInternalDetail from "pages/Client/FormationInternal/ClientFormationInternalDetail";
import ClientFormationInternalIntervenantGestion from "pages/Client/FormationInternal/ClientFormationInternalIntervenantGestion";

// USER DASHBOARD

import UserFicheMission from "pages/User/MySkills/UserFicheMission";
import UserPlanCharge from "pages/User/MySkills/UserPlanCharge";

import UserCatalogueFormation from "pages/User/CatalogueFormation/UserCatalogueFormation";
import UserCatalogueFormationDetail from "pages/User/CatalogueFormation/UserCatalogueFormationDetail";

import UserSeFormerAutrement from "pages/User/SeFormerAutrement/UserSeFormerAutrement";

import UserEspacePersonnel from "pages/User/EspacePersonnel/UserEspacePersonnel";

// PRESTATAIRE DASHBOARD

import PrestataireInformationView from "pages/Prestataire/Information/PrestataireInformationView";
import PrestataireInformationEdit from "pages/Prestataire/Information/PrestataireInformationEdit";

import PrestataireIntervenantGestion from "pages/Prestataire/Intervenant/PrestataireIntervenantGestion";
import PrestataireIntervenantAdd from "pages/Prestataire/Intervenant/PrestataireIntervenantAdd";
import PrestataireIntervenantEdit from "pages/Prestataire/Intervenant/PrestataireIntervenantEdit";

import PrestataireFormationAll from "pages/Prestataire/Formation/PrestataireFormationAll";
import PrestataireFormationAdd from "pages/Prestataire/Formation/PrestataireFormationAdd";
import PrestataireFormationEdit from "pages/Prestataire/Formation/PrestataireFormationEdit";
import PrestataireFormationDetail from "pages/Prestataire/Formation/PrestataireFormationDetail";

const privateRoutes = [
   // exemple de route avec rôle obligatoire => utiliser dans le composant la condition (et passer en paramètre location et isRole):
  // const ComponentName = = ({ props, isRole, location }) => { ... }
  // if (verifRoleRoute(isRole)) { return ( ... ) } else { <Redirect to={{ pathname: "/page-404", state: { from: location } }}/> }
  //{ path: "/dashboard", component: DashboardScene, role: 'ROLE_ADMIN' },

  { path: "/dashboard", component: DashboardScene },
  { path: "/profil", component: UserProfile },

  // Super administrateur
  { path: "/gestion-comptes", component: Account, role: 'ROLE_ADMIN' },
  { path: "/ajout-super-administrateur", component: AccountAdminAdd, role: 'ROLE_ADMIN' },
  { path: "/modifier-compte", component: AccountEdit, role: 'ROLE_ADMIN' },

  { path: "/cartographie", component: Cartography, role: 'ROLE_ADMIN' },
  { path: "/creation-cartographie", component: CartographyCreate, role: 'ROLE_ADMIN' },
  { path: "/configuration-cartographie", component: CartographyConfiguration, role: 'ROLE_ADMIN' },
  { path: "/cartographie-export-pdf", component: CartographyPdf, role: 'ROLE_ADMIN' },

  { path: "/plan-developpement-des-competences", component: FormationPlan, role: 'ROLE_ADMIN' },
  { path: "/creation-plan-developpement-des-competences", component: FormationPlanNew, role: 'ROLE_ADMIN' },
  { path: "/modifier-plan-developpement-des-competences", component: FormationPlanEdit, role: 'ROLE_ADMIN' },

  { path: "/gestion-organisations", component: ClientAccount, role: 'ROLE_ADMIN' },
  { path: "/ajout-organisation", component: ClientAccountAdd, role: 'ROLE_ADMIN' },
  { path: "/details-organisation", component: ClientAccountDetail, role: 'ROLE_ADMIN' },
  { path: "/modifier-organisation", component: ClientAccountEdit, role: 'ROLE_ADMIN' },

  { path: "/ajout-user", component: ClientEmployeeAccountAdd, role: 'ROLE_ADMIN' },
  { path: "/ajout-organisation-user", component: ClientEmployeeOrganisationAdd, role: 'ROLE_ADMIN' },
  { path: "/gestion-collaborateurs", component: ClientEmployeeGestion, role: 'ROLE_ADMIN' },
  { path: "/gestion-collaborateurs-archive", component: ClientEmployeeGestionArchive, role: 'ROLE_ADMIN' },

  { path: "/gestion-transactions", component: TransactionAll, role: 'ROLE_ADMIN' },
  { path: "/ajout-transaction", component: TransactionAdd, role: 'ROLE_ADMIN' },
  { path: "/detail-transaction", component: TransactionDetail, role: 'ROLE_ADMIN' },

  { path: "/gestion-prestataires", component: PrestataireAll, role: 'ROLE_ADMIN' },
  { path: "/ajout-prestataire", component: PrestataireAccountAdd, role: 'ROLE_ADMIN' },
  { path: "/details-prestataire", component: PrestataireDetail, role: 'ROLE_ADMIN' },
  { path: "/modifier-prestataire", component: PrestataireEdit, role: 'ROLE_ADMIN' },

  { path: "/gestion-intervenants", component: IntervenantGestion, role: 'ROLE_ADMIN' },
  { path: "/ajout-intervenant", component: IntervenantAdd, role: 'ROLE_ADMIN' },
  { path: "/modifier-intervenant", component: IntervenantEdit, role: 'ROLE_ADMIN' },

  { path: "/gestion-formations", component: FormationAll, role: 'ROLE_ADMIN' },
  { path: "/ajout-formation", component: FormationAdd, role: 'ROLE_ADMIN' },
  { path: "/details-formation", component: FormationDetail, role: 'ROLE_ADMIN' },
  { path: "/details-formation-interne", component: FormationInternalChoiceDetail, role: 'ROLE_ADMIN' },
  { path: "/modifier-formation", component: FormationEdit, role: 'ROLE_ADMIN' },
  { path: "/choisir-formation", component: FormationPlanSelectFormation, role: 'ROLE_ADMIN' },

  { path: "/ajout-formation-interne", component: FormationInternalAdd, role: 'ROLE_ADMIN' },
  { path: "/edition-formation-interne", component: FormationInternalEdit, role: 'ROLE_ADMIN' },
  { path: "/gestion-formations-internes", component: FormationInternalAll, role: 'ROLE_ADMIN' },
  { path: "/gestion-formation-interne-detail", component: FormationInternalDetail, role: 'ROLE_ADMIN' },

  // Administrateur (Client)

  { path: "/liste-collaborateurs", component: ClientTeam, role: 'ROLE_CLIENT' },
  { path: "/liste-collaborateurs-archive", component: ClientTeamArchive, role: 'ROLE_CLIENT' },
  { path: "/liste-collaborateurs-ajout", component: ClientTeamEmployeeAccountAdd, role: 'ROLE_CLIENT' },
  { path: "/liste-poles", component: ClientPoleList, role: 'ROLE_CLIENT' },
  { path: "/organigramme", component: ClientOrganigram, role: 'ROLE_CLIENT' },
  { path: "/fiches-mission", component: ClientFicheMission, role: 'ROLE_CLIENT' },
  { path: "/fiches-mission-detail", component: ClientFicheMissionDetail, role: 'ROLE_CLIENT' },
  { path: "/mon-espace", component: ClientInformation, role: 'ROLE_CLIENT' },

  { path: "/ma-cartographie", component: ClientCartography, role: 'ROLE_CLIENT' },
  { path: "/ma-cartographie-configuration", component: ClientCartographyConfiguration, role: 'ROLE_CLIENT' },
  { path: "/ma-cartographie-pdf", component: ClientCartographyPdf, role: 'ROLE_CLIENT' },
  { path: "/ma-cartographie-creation", component: ClientCartographyCreate, role: 'ROLE_CLIENT' },

  { path: "/mon-plan-developpement-competences", component: ClientPdc, role: 'ROLE_CLIENT' },
  { path: "/mon-pdc", component: ClientFormation, role: 'ROLE_CLIENT' },
  { path: "/mon-pdc-creation", component: ClientPdcCreate, role: 'ROLE_CLIENT' },
  { path: "/mon-pdc-modifier", component: ClientFormationEdit, role: 'ROLE_CLIENT' },
  { path: "/mon-pdc-choisir", component: ClientFormationSelectFormation, role: 'ROLE_CLIENT' },
  { path: "/mon-pdc-detail-formation-interne", component: ClientFormationPlanSelectFormationInternalDetail, role: 'ROLE_CLIENT' },

  { path: "/mon-catalogue-de-formation", component: ClientCatalogueFormation, role: 'ROLE_CLIENT' },
  { path: "/mon-catalogue-de-formation-detail", component: ClientCatalogueFormationDetail, role: 'ROLE_USER' },

  { path: "/ma-formation-details", component: ClientFormationDetail, role: 'ROLE_CLIENT' },

  { path: "/formation-autrement", component: ClientSeFormerAutrement, role: 'ROLE_CLIENT' },

  { path: "/mes-formations-internes", component: ClientFormationInternalAll, role: 'ROLE_CLIENT' },
  { path: "/mes-formations-internes-ajout", component: ClientFormationInternalAdd, role: 'ROLE_CLIENT' },
  { path: "/ma-formation-interne-details", component: ClientFormationInternalDetail, role: 'ROLE_CLIENT' },
  { path: "/ma-formation-interne-modifier", component: ClientFormationInternalEdit, role: 'ROLE_CLIENT' },
  { path: "/ma-formation-interne-intervenants", component: ClientFormationInternalIntervenantGestion, role: 'ROLE_CLIENT' },

  // Utilisateur (User)

  { path: "/ma-fiche-de-mission", component: UserFicheMission, role: 'ROLE_USER' },
  { path: "/mon-plan-de-charge", component: UserPlanCharge, role: 'ROLE_USER' },

  { path: "/catalogue-de-formation", component: UserCatalogueFormation, role: 'ROLE_USER' },
  { path: "/catalogue-de-formation-detail", component: UserCatalogueFormationDetail, role: 'ROLE_USER' },

  { path: "/se-former-autrement", component: UserSeFormerAutrement, role: 'ROLE_USER' },
  { path: "/mon-espace-personnel", component: UserEspacePersonnel, role: 'ROLE_USER' },

  // Prestataire
  { path: "/prestataire-informations", component: PrestataireInformationView, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-informations-modifier", component: PrestataireInformationEdit, role: 'ROLE_PRESTATAIRE' },

  { path: "/prestataire-intervenants", component: PrestataireIntervenantGestion, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-intervenants-ajout", component: PrestataireIntervenantAdd, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-intervenants-modifier", component: PrestataireIntervenantEdit, role: 'ROLE_PRESTATAIRE' },

  { path: "/prestataire-formations", component: PrestataireFormationAll, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-formation-ajout", component: PrestataireFormationAdd, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-formation-modifier", component: PrestataireFormationEdit, role: 'ROLE_PRESTATAIRE' },
  { path: "/prestataire-formation-details", component: PrestataireFormationDetail, role: 'ROLE_PRESTATAIRE' },

  // FAQS
  { path: "/faq", component: PageFaq },

  // this route should be at the end of all other routes
  { path: "/", component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/mot-passe-oublie", component: ForgetPwd },
  { path: "/reset-mot-de-passe/:token", component: ForgetPwdReset },

  { path: "/page-404", component: Page404 },
  { path: "/page-500", component: Page500 },
  { path: "/contact", component: Contact },
  { path: "/mentions-legales", component: MentionsLegales },
  { path: "/politique-confidentialite", component: PolitiqueConfidentialite },
  { path: "/page-not-permission", component: PageNotPermission },

  { path: "/lock-screen", component: LockScreen },
]

export { privateRoutes, authRoutes }

