import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectLogin, LOGOUT_USER } from "store/auth/login/loginSlice";

const Logout = props => {

  const dispatch = useDispatch();
  const login = useSelector(selectLogin);

  useEffect(() => {
    const data = { rememberme: login.rememberme, history: props.history }
    dispatch(LOGOUT_USER(data))
  })

  return <></>
}

export default withRouter(Logout)

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func
}
