import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
    GET_PROFILE,
    GET_USER_PROFILE,
    EDIT_USER_PROFILE,
    PROFILE_SUCCESS,
    PROFILE_ERROR,
    EDIT_PASSWORD_PROFILE,
    ADD_AVATAR_PROFILE,
    DELETE_AVATAR_PROFILE,
    PROFILE_AVATAR_SUCCESS,
    PROFILE_AVATAR_ERROR
} from "./profileSlice"

import { getProfile, editProfile, editPasswordProfile, editAvatarProfile, postMediaObject, deleteMediaObject } from "helpers/request_helper"
import { LOGIN_MESSAGE_SUCCESS } from "store/auth/login/loginSlice";

function* fetchProfile() {
    try {
        const userId = localStorage.getItem('id')

        const response = yield call(getProfile, userId)
        yield put(GET_PROFILE(response))
        yield put(PROFILE_SUCCESS(''))
    } catch (error) {
        yield put(PROFILE_ERROR(error))
    }
}

function* fetchEditProfile({ payload: { email, history } }) {
    try {
        const response = yield call(editProfile, {
            email: email
        })
        yield put(GET_PROFILE(response))
        localStorage.removeItem("accessJWT")
        localStorage.removeItem("refreshToken")
        localStorage.setItem("username", email)
        yield put(LOGIN_MESSAGE_SUCCESS("Votre adresse mail a été modifiée !"))
        history.push("/login")

    } catch (error) {
        yield put(PROFILE_ERROR(error))
    }
}

function* fetchPasswordProfile({ payload: { password } }) {
    try {
        const response = yield call(editPasswordProfile, {
            password: password
        })
        yield put(GET_PROFILE(response))
        yield put(PROFILE_SUCCESS('Votre mot de passe a bien été modifié'))
    } catch (error) {
        yield put(PROFILE_ERROR(error))
    }
}

function* addAvatarProfile({ payload: { file } }) {
    try {
        const image = yield call(postMediaObject, file)
        const response = yield call(editAvatarProfile, { image: `/api/media_objects/${image.id}` })
        yield put(GET_PROFILE(response))
        yield put(PROFILE_AVATAR_SUCCESS('Votre avatar a bien été modifié'))
    } catch (error) {
        yield put(PROFILE_AVATAR_ERROR(error))
    }
}

function* deleteAvatarProfile({ payload: { imageId } }) {
    try {
        const response = yield call(editAvatarProfile, { image: null })
        yield put(GET_PROFILE(response))
        yield call(deleteMediaObject, imageId)
        yield put(PROFILE_AVATAR_SUCCESS('Votre avatar a bien été supprimé'))
    } catch (error) {
        yield put(PROFILE_AVATAR_ERROR(error))
    }
}

export function* watchProfile() {
    yield takeEvery(GET_USER_PROFILE.type, fetchProfile)
    yield takeEvery(EDIT_USER_PROFILE.type, fetchEditProfile)
    yield takeEvery(EDIT_PASSWORD_PROFILE.type, fetchPasswordProfile)
    yield takeEvery(ADD_AVATAR_PROFILE.type, addAvatarProfile)
    yield takeEvery(DELETE_AVATAR_PROFILE.type, deleteAvatarProfile)
}

export default function* ProfileSaga() {
    yield all([fork(watchProfile)])
}
