import React from "react"
import {Link} from "react-router-dom";
import {capitalize} from "lodash";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const FormationPlanReadColumn = (toggleModalDetailFormation, toggleModalDetailFormationInternal, cartographyId, clientId) => [

  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    headerStyle: {
      fontSize: '15px'
    },
    align: 'center',
    formatter: (cellContent, row) => (
      <>
        <Link to={{ pathname: "/modifier-plan-developpement-des-competences", state: row.id }} className="btn btn-link waves-effect px-3 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>
      </>
    )
  },

  {
    dataField: "employee.clientEmployee.user.lastname",
    text: "Nom",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 font-size-15" style={{minWidth: '60px'}}>{capitalize(column)}</p>
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => `${row.employee.clientEmployee.user.lastname}`,
  },

  {
    dataField: "employee.clientEmployee.user.firstname",
    text: "Prénom",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 font-size-15" style={{minWidth: '80px'}}>{capitalize(column)}</p>
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => `${row.employee.clientEmployee.user.firstname}`,
  },

  {
    dataField: "employee.clientEmployee.pole",
    text: "Pôle",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    headerStyle: {
      fontSize: '15px'
    },
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 font-size-15">{column}</p>
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "employee.clientEmployee.arrivalDate",
    text: "Date d’entrée dans l'organisation",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      return <p className="mb-0 font-size-15">{moment.utc(column).locale('fr', localization).format("L")}</p>
    },
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => `${moment.utc(row.employee.clientEmployee.arrivalDate).locale('fr', localization).format("L")}`,
  },

  {
    dataField: "skillDomain.name",
    text: "Compétence",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 font-size-15">{column}</p>
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => `${row.skillDomain.name}`,
  },

  {
    dataField: "employeeSkill.skill.task",
    text: "Tâche",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      if (row.hasOwnProperty('employeeSkill') && row.employeeSkill.skill.task) {
        return <p className="mb-0 font-size-15" style={{minWidth: '150px'}}>{column}</p>
      } else {
        return <p className="mb-0 font-size-15" style={{minWidth: '150px'}}>{row.taskCustom}</p>
      }
    },
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "formation.title",
    text: "Intitulé de la formation",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.hasOwnProperty('formation')) {
        return (
          <Link to={{ pathname: "/details-formation", state: {formationId: row.formation.id, url: 'formationPlan' }}}>
            <p className="mb-0 color-primary font-size-15" style={{minWidth: '220px'}}>{cellContent}</p>
          </Link>
        )
      } else if (row.hasOwnProperty('formationInternal')) {
        console.log(row)
        return (
          <Link to={{pathname: "/details-formation-interne", state: {formationInternalId: row.formationInternal.id, cartographyId: cartographyId,  url: 'planFormation'}}}>
            <p className="mb-0 color-primary font-size-15" style={{minWidth: '220px'}}>{row.formationInternal.title}</p>
          </Link>
        )
      } else if (row.skillDomain) {
        return(
          <Link to={{ pathname: "/choisir-formation", state: {trainingPlanActionId: row.id, clientId: clientId} }} className="btn btn-primary waves-effect waves-light mx-0 font-size-15" style={{minWidth: '180px'}}>
            Choisir une formation
          </Link>
        )
      }
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => {
      if (row.hasOwnProperty('formation')) {
        return `${row.formation.title}`
      } else {
        return ""
      }
    },
  },

  {
    dataField: "formation.prestataire.name",
    text: "Prestataire",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (column, row) => {
      if (row.hasOwnProperty('formation')) {
        return (
          <p className="mb-0 color-table-secondary font-size-15"  style={{minWidth: '120px'}}>{column}</p>
        )
      } else if (row.hasOwnProperty('formationInternal')) {
        return (
          <p className="mb-0 font-size-15"  style={{minWidth: '120px'}}>Interne</p>
        )
      } else {
        return ''
      }
    },
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1  color-sort"></i>);
      return null;
    },
    csvFormatter: (cellContent, row) => {
      if (row.hasOwnProperty('formation')) {
        return `${row.formation.prestataire.name}`
      } else {
        return ""
      }
    },
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Suivi de la formation",
    headerAlign: 'center',
    headerStyle: {
      fontSize: '15px'
    },
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => {
      if (row.hasOwnProperty('formation')) {
        return (
          <button
            type="button"
            className="btn btn-link waves-effect px-3 text-primary"
            onClick={() => toggleModalDetailFormation(row)}
            data-toggle="modal"
            data-target="modal-delete"
          >
            <i className="uil uil-info-circle font-size-20"></i>
          </button>
        )
      } else if (row.hasOwnProperty('formationInternal')) {
        return (
          <button
            type="button"
            className="btn btn-link waves-effect px-3 text-primary"
            onClick={() => toggleModalDetailFormationInternal(row)}
            data-toggle="modal"
            data-target="modal-delete"
          >
            <i className="uil uil-info-circle font-size-20"></i>
          </button>
        )
      } else {
        return ''
      }
    }
  },
]

export default FormationPlanReadColumn
