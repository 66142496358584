import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  NOTIFICATIONS,
  ADMIN_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "./notificationSlice";

import { getNotifications, getAdminNotifications, postNotification, removeNotification } from "helpers/request_helper"

function* viewNotifications({payload: { userId }}) {
  try {
    const response = yield call(getNotifications, userId);
    yield put(NOTIFICATION_SUCCESS(response['hydra:member']));
  } catch (error) {
    yield put(NOTIFICATION_ERROR(error.message));
  }
}

function* viewAdminNotifications() {
  try {
    const response = yield call(getAdminNotifications);
    yield put(NOTIFICATION_SUCCESS(response['hydra:member']));
  } catch (error) {
    yield put(NOTIFICATION_ERROR(error.message));
  }
}

function* addNotification({payload: { userId, title, message, link, linkState, allAdmin, notificationRead }}) {
  try {

    const data = {
      user: userId ? `/api/users/${userId}` : null,
      title: title,
      message: message,
      link: link,
      linkState: linkState,
      allAdmin: allAdmin,
      createdAt: new Date(),
      notificationRead: notificationRead
    }

    yield call(postNotification, data)
    yield put(NOTIFICATION_SUCCESS(""));
  } catch (error) {
    yield put(NOTIFICATION_ERROR(error.message));
  }
}

function* deleteNotification({ payload: {notificationId} }) {
  try {
    yield call(removeNotification, notificationId);
    yield put(DELETE_NOTIFICATION_SUCCESS(notificationId));
  } catch (error) {
    yield put(DELETE_NOTIFICATION_ERROR(error.message));
  }
}

export function* watchProfile() {
  yield takeEvery(NOTIFICATIONS.type, viewNotifications)
  yield takeEvery(ADMIN_NOTIFICATIONS.type, viewAdminNotifications)
  yield takeEvery(ADD_NOTIFICATION.type, addNotification)
  yield takeEvery(DELETE_NOTIFICATION.type, deleteNotification)
}

export default function* NotificationSaga() {
  yield all([fork(watchProfile)])
}
