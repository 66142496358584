import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  prestataires: [],
  prestataire: {},
  success: "",
  successForm: "",
  error: "",
  isLoading: false,
  showModal: false
}

const prestataireAccountSlice = createSlice({
  name: "prestataireAccount",
  initialState,

  reducers: {

    PRESTATAIRES_ACCOUNT (state) {
      return { ...state, successForm: ""  }
    },

    PRESTATAIRE_ACCOUNT (state) {
      return { ...state }
    },

    GET_PRESTATAIRE_ACCOUNTS (state, action) {
      state.isLoading = true
      state.prestataires = action.payload  // payload: prestataires
    },

    GET_PRESTATAIRE_ACCOUNT (state, action) {
      state.isLoading = true
      state.prestataire = action.payload  // payload: prestataire
    },

    ADD_ACCOUNT (state) {
      state.isLoading = true
    },

    ADD_PRESTATAIRE_ACCOUNT (state, action) {
      state.prestataire = action.payload
    },

    UPDATE_PRESTATAIRE_ACCOUNT (state) {
      state.isLoading = true
    },

    DELETE_PRESTATAIRE_ACCOUNT (state) {
      state.isLoading = true
      state.prestataire = {}
    },

    PRESTATAIRE_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADD_PRESTATAIRE_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.successForm = action.payload
    },

    PRESTATAIRE_ACCOUNT_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false  }
    }
  }
});

const { reducer, actions } = prestataireAccountSlice;

export const {
  PRESTATAIRES_ACCOUNT,
  PRESTATAIRE_ACCOUNT,
  GET_PRESTATAIRE_ACCOUNTS,
  GET_PRESTATAIRE_ACCOUNT,
  ADD_ACCOUNT,
  ADD_PRESTATAIRE_ACCOUNT,
  UPDATE_PRESTATAIRE_ACCOUNT,
  DELETE_PRESTATAIRE_ACCOUNT,
  PRESTATAIRE_ACCOUNT_SUCCESS,
  ADD_PRESTATAIRE_ACCOUNT_SUCCESS,
  PRESTATAIRE_ACCOUNT_ERROR,
} = actions;

export default reducer;

export const selectPrestataireAccount = (state) => state.prestataireAccount;
