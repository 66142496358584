import React from "react"
import {Row, Col} from "reactstrap"
import {Link} from "react-router-dom";

const GoBackBtn = ({ link, title }) => {
  return (
    <Row>
      <Col sm={8}>
        <Link to={link} className="btn btn-primary waves-effect waves-light mx-2 mb-3">
          <i className="uil uil-corner-up-right-alt me-1"></i>{title}
        </Link>
      </Col>
    </Row>
  )
}

export default GoBackBtn
