import React from "react"
import PropTypes from "prop-types"
import {Modal} from "reactstrap"

// REDUX
import { useSelector } from "react-redux";
import { selectTrainingPlan } from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {capitalize} from "lodash";

const ClientFormationPlanResumeRequestModal = ({ isOpen, toggle, resumeRequest }) => {

  const { trainingPlanAction } = useSelector(selectTrainingPlan);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Envoyer la description de la formation choisie au collaborateur{" "}
          {trainingPlanAction.hasOwnProperty('employee') && capitalize(trainingPlanAction.employee.clientEmployee.user.firstname)}
          {" "}
          {trainingPlanAction.hasOwnProperty('employee') && capitalize(trainingPlanAction.employee.clientEmployee.user.lastname)}
          {" "}?
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
          Vous pouvez envoyer à{" "}
          {trainingPlanAction.hasOwnProperty('employee') && capitalize(trainingPlanAction.employee.clientEmployee.user.firstname)}
          {" "}
          {trainingPlanAction.hasOwnProperty('employee') && capitalize(trainingPlanAction.employee.clientEmployee.user.lastname)}
          {" "}un descriptif de la formation choisie :{" "}
          {trainingPlanAction.hasOwnProperty('formation') && trainingPlanAction.formation.title}.
        </p>

        <p>Lors de la confirmation, un email sera envoyé automatiquement au collaborateur avec le descriptif de la formation.</p>

      </div>

      <div className="modal-footer">

        <button
          type="button"
          className="btn btn-success"
          onClick={() => resumeRequest(trainingPlanAction.id, trainingPlanAction.formation, trainingPlanAction.employee.clientEmployee.user)}
        >
          Envoyer
        </button>

        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default ClientFormationPlanResumeRequestModal

ClientFormationPlanResumeRequestModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
