import PropTypes from 'prop-types'
import React, {useState, useEffect} from "react"
import {Container, Row, Col, Card, CardBody, Media, Button, UncontrolledAlert, Form, CardTitle} from "reactstrap"
import jwt_decode from "jwt-decode";
import Dropzone from "react-dropzone"

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation"

// Redux
import {withRouter, Link} from "react-router-dom"

// Components
import UserProfileDeleteAvatarModal from "./UserProfileDeleteAvatarModal";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  selectProfile,
  GET_USER_PROFILE,
  EDIT_USER_PROFILE,
  EDIT_PASSWORD_PROFILE,
  ADD_AVATAR_PROFILE,
  DELETE_AVATAR_PROFILE,
  PROFILE_ERROR
} from "store/auth/profile/profileSlice";

const UserProfile = ({history}) => {

  const dispatch = useDispatch()
  const userProfile = useSelector(selectProfile);

  const firstname = localStorage.getItem('firstname').toUpperCase();
  const lastname = localStorage.getItem('lastname').toUpperCase();
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  const userId = localStorage.getItem('id')

  const role = localStorage.getItem("accessJWT") ? jwt_decode(localStorage.getItem("accessJWT")).roles[0] : ''

  const [showModalAvatarRemove, setShowModalAvatarRemove] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [selectedFiles, setselectedFiles] = useState([])

  const toggleModalAvatarRemove = () => {
  setShowModalAvatarRemove(!showModalAvatarRemove);
  };

  const deleteAvatarImage = () => {

    if (userProfile.user.hasOwnProperty('image')) {
      dispatch(DELETE_AVATAR_PROFILE({imageId: userProfile.user.image.id}))
      setselectedFiles([])
    }

    setShowModalAvatarRemove(!showModalAvatarRemove);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    dispatch(GET_USER_PROFILE(userId))
    setselectedFiles([])
  }, [dispatch, userId])

  function handleValidSubmitUsername() {
    dispatch(EDIT_USER_PROFILE({ email: email, history: history }))
  }

  function handleValidSubmitPassword() {

    if (password !== confirmPassword) {
      dispatch(PROFILE_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else {
      dispatch(EDIT_PASSWORD_PROFILE({ password: password }))
      setPassword('')
      setConfirmPassword('')
    }
  }

  const handleAddAvatar = () => {

    if (selectedFiles.length === 1) {
      if (userProfile.user.hasOwnProperty('image')) {
        dispatch(DELETE_AVATAR_PROFILE({imageId: userProfile.user.image.id}))

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(ADD_AVATAR_PROFILE({file: formData}))
      } else {

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(ADD_AVATAR_PROFILE({file: formData}))
      }
    }

    setselectedFiles([])
  }

  const roleUser = () => {
    if (role === 'ROLE_ADMIN') return 'Administrateur'
    if (role === 'ROLE_CLIENT') return 'Organisation'
    if (role === 'ROLE_USER') return 'Utilisateur'
    if (role === 'ROLE_PRESTATAIRE') return 'Prestataire'
  }

  return (
    <React.Fragment>

      <UserProfileDeleteAvatarModal isOpen={showModalAvatarRemove} toggle={toggleModalAvatarRemove} deleteAvatarImage={deleteAvatarImage} />

      <div className="page-content my-5">
        <Container fluid>

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <Media className="d-flex">

                    {/* Avatar circle */}
                    <div className="avatar-sm me-4">

                      {userProfile.user.hasOwnProperty('image') ? (
                        <img
                          className="avatar-title rounded-circle bg-white"
                          src={process.env.REACT_APP_URL + "/media/" + userProfile.user.image.filePath}
                          alt="Header Avatar"
                        />
                      ) : (
                        <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-15`}>
                          {nameIcon}
                        </span>
                        )}

                    </div>

                    <Media body className="flex-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-3">Bienvenue {userProfile.user.firstname}</h5>
                        <p className="mb-0">Nom d'utilisateur : <span className="color-text mx-1">{userProfile.user.email}</span></p>
                        <p className="mb-0">Prénom : <span className="color-text mx-1">{userProfile.user.firstname}</span></p>
                        <p className="mb-0">Nom : <span className="color-text mx-1">{userProfile.user.lastname}</span></p>
                        <p className="mb-0">Rôle : <span className="color-text mx-1">{roleUser()}</span></p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="6">

              <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier l'email d'utilisateur</h5>

              <Card>
                <CardBody>

                  {userProfile.error ? (
                    <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                      <i className="uil uil-exclamation-octagon me-2"></i> Une erreur s'est produite !!
                    </UncontrolledAlert>
                  ) : null }

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => handleValidSubmitUsername(e, v)}
                  >
                    <div className="form-group">
                      <h5 className="font-size-17 mt-3 mb-3">Nom d'utilisateur</h5>
                      <AvField
                        name="email"
                        value={email}
                        className="form-control"
                        placeholder="Saisir un nouvel email d'utilisateur"
                        type="email"
                        onChange={ event => setEmail(event.target.value)}
                        errorMessage="Veuillez renseigner un email"
                        required
                      />
                    </div>

                    <div className="text-center mt-4">
                      <Button type="submit" color="success">
                        Mettre à jour l'email d'utilisateur
                      </Button>
                    </div>
                  </AvForm>

                </CardBody>
              </Card>

              <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Changer le mot de passe</h5>

              <Card>
                <CardBody>

                  {userProfile.error ? (
                    <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                      <i className="uil uil-exclamation-octagon me-2"></i> {userProfile.error}
                    </UncontrolledAlert>
                  ) : null }

                  { userProfile.success ? (
                    <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                      <i className="uil uil-check me-2"></i> {userProfile.success}
                    </UncontrolledAlert>
                  ) : null }

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => handleValidSubmitPassword(e, v)}
                  >
                    <div className="form-group">
                      <h5 className="font-size-17 mt-3 mb-3">Nouveau mot de passe</h5>
                      <AvField
                        name="password"
                        value={password}
                        className="form-control"
                        placeholder="Mot de passe"
                        type="password"
                        onChange={ event => setPassword(event.target.value)}
                        errorMessage="Veuillez renseigner un mot de passe"
                        required
                      />
                    </div>

                    <div className="form-group mt-4 mb-5">
                      <h5 className="font-size-17 mt-5 mb-3">Confirmer le nouveau mot de passe</h5>
                      <AvField
                        name="confirmPassword"
                        value={confirmPassword}
                        className="form-control"
                        placeholder="Mot de passe"
                        type="password"
                        onChange={ event => setConfirmPassword(event.target.value)}
                        errorMessage="Veuillez confirmer le mot de passe"
                        required
                      />
                    </div>

                    <div className="text-center mt-4">
                      <Button type="submit" color="success">
                        Modifier le mot de passe
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>

            </Col>

            <Col sm="6">

              <h5 className="mb-4 mt-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier l'avatar</h5>

              <Card>
                <CardBody>

                  { userProfile.successAvatar ? (
                    <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                      <i className="uil uil-check me-2"></i> {userProfile.successAvatar}
                    </UncontrolledAlert>
                  ) : null }

                  <CardTitle className="h4">{role === 'ROLE_USER' ? "Votre image de profil" : "Logo de l'entreprise" }</CardTitle>

                  <div className="avatar-sm me-4 my-3">

                    {userProfile.user.hasOwnProperty('image') ? (
                      <img
                        className="avatar-title rounded-circle bg-white"
                        src={process.env.REACT_APP_URL + "/media/" + userProfile.user.image.filePath}
                        alt="Header Avatar"
                      />
                    ) : (
                      <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-15`}>
                        {nameIcon}
                      </span>
                    )}

                  </div>

                  <p className="card-title-desc">
                    {userProfile.user.hasOwnProperty('image') ? 'Choisissez une nouvelle image pour votre Avatar' : 'Choisissez une image pour votre Avatar'}
                  </p>

                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                      maxFiles={1}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted uil uil-cloud-upload"/>
                            </div>
                            <h5 className="mx-3">Glisser une image ici ou cliquer pour télécharger</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>

                    <div className="text-center d-flex justify-content-center mt-4">
                      <button
                        type="button"
                        className="btn btn-success waves-effect waves-light mx-2"
                        onClick={handleAddAvatar}
                      >
                        {userProfile.user.hasOwnProperty('image') ? "Modifier l'avatar" : "Enregistrer"}
                      </button>

                      {userProfile.user.hasOwnProperty('image') && (
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mx-2"
                          onClick={toggleModalAvatarRemove}
                        >
                          Supprimer l'avatar
                        </button>
                      )}
                    </div>

                  </Form>

                </CardBody>
              </Card>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}
