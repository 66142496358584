import React, {useEffect} from "react"
import {Col, Container, Row} from "reactstrap"
import {Redirect} from "react-router-dom"
import {map} from "lodash";


// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectClientAccount, CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";
import {selectClientEmployeeAccount, ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT, CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import ClientEmployeeArchiveCard from "components/Common/Card/ClientEmployeeArchiveCard";

const ClientEmployeeGestionArchive = ({isRole, location}) => {

  const dispatch = useDispatch()
  const { client, error } = useSelector(selectClientAccount);
  const { success } = useSelector(selectClientEmployeeAccount);

  // récupération de l'id passé dans la route
  const clientId = location.state

  const archivedEmployee = clientEmployee => {
    const data = {
      clientEmployeeId: clientEmployee.id,
      clientId,
      isArchived: false,
    }
    dispatch(ARCHIVED_CLIENT_EMPLOYEE_ACCOUNT(data))
  };

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT({ clientId: clientId }))
    dispatch( CLIENT_EMPLOYEE_ACCOUNT_RESET_SUCCESS_ERROR())
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <SuccessErrorAlert error={error} success={success} />
            <GoBackBtn link={{ pathname: "/gestion-collaborateurs", state: clientId }} title="Retour aux détails de l'organisation" />

            <Row className="mb-5">
              <Col md="12">

                <h5 className="font-size-22 color-secondary text-center mb-5">Vos collaborateurs archivés</h5>

                <Row>
                  {map(client.clientEmployees, employee => (
                    employee.isArchived && (
                      <ClientEmployeeArchiveCard
                        employee={employee}
                        key={'formation-' + employee.id}
                        archivedEmployee={archivedEmployee}
                      />
                    )
                  ))}
                </Row>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default ClientEmployeeGestionArchive
