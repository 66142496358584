import PropTypes from 'prop-types';
import React from "react";

import {Switch, BrowserRouter as Router, Redirect} from "react-router-dom";

// Import Routes all
import {privateRoutes, authRoutes} from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Redux
import {useSelector} from "react-redux";
import {selectLayout} from "./store/layout/layoutSlice";

// Import scss
import "./assets/scss/theme.scss";

const App = props => {

    const layout = useSelector(selectLayout);

    function getLayout() {
        let layoutCls;

        switch (layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout = getLayout();

    const pageNotFound = localStorage.getItem("accessJWT") ? <Redirect to="/page-404" /> : <Redirect to="/login" />

    return (
        <React.Fragment>
            <Router>

                <Switch>
                    {authRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                        />
                    ))}

                    {privateRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={Layout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                            isRole={route.role}
                            exact
                        />
                    ))}

                    { pageNotFound }

                </Switch>

            </Router>
        </React.Fragment>
    );
};

App.propTypes = {
    layout: PropTypes.any
};

export default App;
