import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  clientEmployees: [],
  clientEmployee: {},
  clientEmployeeSkills: [],
  cartographiesNotArchived: [],
  certificates: [],
  modal: {},
  clientEmployeeAnalytics: [],
  lastFormation: {},
  nextFormation: {},
  formationFinished: { yAxis: [], xAxis: ['2021', '2022', '2023', '2024']},
  nbrHourTraining: 0,
  success: "",
  error: "",
  isLoading: false
}

const clientEmployeeSlice = createSlice({
  name: "clientEmployee",
  initialState,

  reducers: {

    CLIENT_EMPLOYEES (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CLIENT_EMPLOYEE (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CLIENT_EMPLOYEE_SKILLS (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CLIENT_EMPLOYEE_SKILLS_BY_USER (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CERTIFICATES (state) {
      return { ...state, isLoading: true, success: "" }
    },

    CERTIFICATES_BY_ADMIN (state) {
      return { ...state, isLoading: true, success: "" }
    },

    GET_DATA_EMPLOYEE (state, action) {
      state.clientEmployee = action.payload
    },

    GET_CLIENT_EMPLOYEES (state, action) {
      state.clientEmployees = action.payload
    },

    GET_CLIENT_EMPLOYEE (state, action) {
      state.clientEmployee = action.payload
    },

    GET_CLIENT_EMPLOYEE_CARTO_NOT_ARCHIVED (state, action) {
      state.cartographiesNotArchived = action.payload
    },

    GET_CLIENT_EMPLOYEE_SKILLS (state, action) {
      state.clientEmployeeSkills = action.payload
    },

    GET_CERTIFICATES (state, action) {
      state.certificates = action.payload
    },

    ADD_CLIENT_EMPLOYEE (state) {
      state.isLoading = true
    },

    ADD_CLIENT_EMPLOYEE_MODAL_DATA (state, action) {
      state.modal = action.payload
    },

    UPDATE_CLIENT_EMPLOYEE (state) {
      state.isLoading = true
    },

    UPDATE_CLIENT_EMPLOYEE_SKILL (state) {
      state.isLoading = true
    },

    UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF (state) {
      state.isLoading = true
    },

    DELETE_CLIENT_EMPLOYEE (state) {
      state.isLoading = true
      state.clientEmployee = {}
    },

    CLIENT_EMPLOYEE_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    CLIENT_EMPLOYEE_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    /* ========= DASHBOARD & ANALYTICS ============== */

    GET_CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER (state, action) {
      state.clientEmployeeAnalytics = action.payload
    },

    GET_LAST_FORMATION (state, action) {
      state.lastFormation = action.payload
    },

    GET_NEXT_FORMATION (state, action) {
      state.nextFormation = action.payload
    },

    GET_NBR_FORMATION_FINISHED (state, action) {
      state.formationFinished = action.payload
    },

    GET_NBR_HOUR_FORMATION (state, action) {
      state.nbrHourTraining = action.payload
    },

  }
});

const { reducer, actions } = clientEmployeeSlice;

export const {
  CLIENT_EMPLOYEES,
  CLIENT_EMPLOYEE,
  CLIENT_EMPLOYEE_SKILLS,
  CLIENT_EMPLOYEE_SKILLS_BY_USER,
  CERTIFICATES,
  CERTIFICATES_BY_ADMIN,
  GET_DATA_EMPLOYEE,
  GET_CLIENT_EMPLOYEES,
  GET_CLIENT_EMPLOYEE,
  GET_CLIENT_EMPLOYEE_CARTO_NOT_ARCHIVED,
  GET_CLIENT_EMPLOYEE_SKILLS,
  GET_CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER,
  GET_CERTIFICATES,
  ADD_CLIENT_EMPLOYEE,
  ADD_CLIENT_EMPLOYEE_MODAL_DATA,
  UPDATE_CLIENT_EMPLOYEE,
  UPDATE_CLIENT_EMPLOYEE_SKILL,
  UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF,
  DELETE_CLIENT_EMPLOYEE,
  CLIENT_EMPLOYEE_SUCCESS,
  CLIENT_EMPLOYEE_ERROR,
  CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER,
  GET_LAST_FORMATION,
  GET_NEXT_FORMATION,
  GET_NBR_FORMATION_FINISHED,
  GET_NBR_HOUR_FORMATION
} = actions;

export default reducer;

export const selectClientEmployee = state => state.clientEmployee;
