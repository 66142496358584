import jwt_decode from "jwt-decode";

// Vérification du rôle de l'utilisateur contenu dans le token
const verifRoleToken = (role) => {
    const tokenRole = localStorage.getItem("accessJWT") ? jwt_decode(localStorage.getItem("accessJWT")).roles : []
    return tokenRole[0] === role
}

// Vérification du rôle avec le rôle du token si un rôle est passé dans la route (AllRoutes.js)
// dans le composant, écrire : verifRoleRoute(isRole) && ( ... )
const  verifRoleRoute = (isRole) => {
    const tokenRole = localStorage.getItem("accessJWT") ? jwt_decode(localStorage.getItem("accessJWT")).roles : []
    return tokenRole.filter(role => role === isRole)[0]
}

export {
    verifRoleToken,
    verifRoleRoute
}
