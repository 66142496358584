import React from "react"
import { Badge } from "reactstrap"
import { Link } from "react-router-dom"

const roleUser = (role) => {
  if (role[0] === 'ROLE_ADMIN') return 'Super Administrateur'
  if (role[0] === 'ROLE_CLIENT') return 'Administrateur'
  if (role[0] === 'ROLE_USER') return 'Utilisateur'
  if (role[0] === 'ROLE_PRESTATAIRE') return 'Prestataire'
}

const AccountColumn = toggleModal => [
  {
    dataField: "id",
    text: "ID",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">#{row.id}</p>
      </>
    ),
    csvFormatter: (cellContent, row) => `${row.id}`,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "email",
    text: "Email",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.email}</p>
      </>
    ),
    csvFormatter: (cellContent, row) => `${row.email}`,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "lastname",
    text: "Prénom Nom",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => (
      <>
        <p className="mb-0">{row.firstname} {row.lastname}</p>
      </>
    ),
    csvFormatter: (cellContent, row) => `${row.firstname} ${row.lastname}`,
    sortValue: (cell, row) => row.lastname,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "roles",
    text: "Rôle",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column) => (
      <>
        <p className="mb-0">{roleUser(column)}</p>
      </>
    ),
    csvFormatter: (column) => `${roleUser(column)}`,
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "client",
    text: "Associé à",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.client) {
        return (
          <Link to={{pathname: "/details-organisation", state: row.client.id}}>
            <p className="mb-0 color-primary">{row.client.entName}</p>
          </Link>)
      } else if (row.clientEmployee) {
        return (
        <Link to={{pathname: "/details-organisation", state: row.clientEmployee.client.id}}>
          <p className="mb-0 color-primary">{row.clientEmployee.client.entName}</p>
        </Link>)
      } else {
        return <p className="mb-0"></p>
      }
    },
    csvFormatter: (cellContent, row) => {
      if (row.client) {
        return `${row.client.entName}`
      } else {
        return ""
      }
    },
    sortValue: (cell, row) => {
      if (row.client) {
        return row.client.entName
      } else {
        return
      }
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "status",
    text: "Statut",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.status) {
        return <Badge className="badge bg-pill bg-soft-success" color="success" pill>compte actif</Badge>
      } else {
        return <Badge className="badge bg-pill bg-soft-warning" color="warning" pill>compte suspendu</Badge>
      }
    },
    csvFormatter: (cellContent, row) => {
      return (row.status) ? "compte actif" : "compte suspendu"
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <div className="flex justify-content-center" style={{ minWidth: "130px"}}>
        <Link to={{ pathname: "/modifier-compte", state: row.id }} className="btn btn-link waves-effect px-2 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>


        <button
          type="button"
          className="btn btn-link waves-effect text-danger"
          onClick={() => toggleModal(row)}
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-trash-alt font-size-18"></i>
        </button>

      </div>
    ),
    csvExport: false
  },
]

export default AccountColumn
