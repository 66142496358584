import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { isEmpty } from "lodash";

// Login Redux States

import {
  PRESTATAIRES,
  PRESTATAIRE,
  PRESTATAIRES_FILTER,
  INTERVENANT,
  GET_PRESTATAIRES,
  GET_PRESTATAIRE,
  GET_PRESTATAIRES_FILTER,
  GET_INTERVENANT,
  PRESTATAIRE_SUCCESS,
  UPDATE_PRESTATAIRE,
  DELETE_PRESTATAIRE,
  ADD_INTERVENANT,
  UPDATE_INTERVENANT,
  DELETE_INTERVENANT,
  UPDATE_FORMATION_INTERVENANT,
  ADD_PRESTATAIRE_SUCCESS,
  ADD_INTERVENANT_SUCCESS,
  PRESTATAIRE_ERROR,
  DELETE_AVATAR_INTERVENANT,
  ADD_AVATAR_INTERVENANT,
  UPDATE_AVATAR_INTERVENANT,
  INTERVENANT_AVATAR_SUCCESS,
  INTERVENANT_AVATAR_ERROR,
} from "./prestataireSlice";

import { FORM_DATAS } from "../globalData/globalDataSlice";

import {
  getPrestataires,
  getPrestataire,
  putPrestataire,
  deletePrestataire,
  postIntervenant,
  getIntervenant,
  putIntervenant,
  deleteIntervenant,
  putFormationIntervenant,
  postMediaObject,
  deleteMediaObject,
} from "helpers/request_helper";

function* viewPrestataires() {
  try {
    const response = yield call(getPrestataires);
    yield put(GET_PRESTATAIRES(response["hydra:member"]));
    yield put(PRESTATAIRE_SUCCESS(""));
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* viewPrestataire({ payload: { prestataireId } }) {
  try {
    yield put(FORM_DATAS());
    const response = yield call(getPrestataire, prestataireId);
    yield put(GET_PRESTATAIRE(response));
    yield put(PRESTATAIRE_SUCCESS(""));
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* viewPrestatairesFilter() {
  try {
    const response = yield call(getPrestataires);

    const filterArray = []

    response["hydra:member"].forEach( prestataire => {
      if (prestataire.formations.some( el => el.activeFormation === true )) {
        filterArray.push(prestataire)
      }
    })

    yield put(GET_PRESTATAIRES_FILTER(filterArray));
    yield put(PRESTATAIRE_SUCCESS(""));
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* viewIntervenant({ payload: { intervenantId } }) {
  try {
    const response = yield call(getIntervenant, intervenantId);
    yield put(GET_INTERVENANT(response));
    yield put(PRESTATAIRE_SUCCESS(""));
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* editPrestataire({
  payload: {
    name,
    address,
    zipCode,
    city,
    contactFirstname,
    contactLastname,
    contactFunction,
    contactPhone,
    contactEmail,
    codeNaf,
    codeSiret,
    codeTva,
    country,
    commercialCoverage,
    codeNda,
    activeAccount,
    prestataireId,
    locality,
    qualiopiCertification,
    presentation,
    subjectTva,
  },
}) {
  try {
    const response = yield call(putPrestataire, {
      prestataireId: prestataireId,
      name: name,
      address: address,
      locality: locality,
      zipCode: zipCode,
      city: city,
      presentation: presentation,
      contactFirstname: contactFirstname,
      contactLastname: contactLastname,
      contactFunction: contactFunction,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      codeNaf: codeNaf,
      codeSiret: codeSiret,
      codeTva: codeTva,
      subjectTva: subjectTva,
      country: country,
      commercialCoverage: commercialCoverage,
      codeNda: codeNda,
      qualiopiCertification: qualiopiCertification,
      activeAccount: activeAccount,
    });
    yield put(GET_PRESTATAIRE(response));
    yield put(
      ADD_PRESTATAIRE_SUCCESS(
        "Les informations du prestataire ont bien été modifiées"
      )
    );
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error));
  }
}

function* removePrestataire({ payload: { prestataireId } }) {
  try {
    const prestataire = yield call(getPrestataire, prestataireId);

    if (isEmpty(prestataire.formations) && isEmpty(prestataire.prestataireIntervenants)) {
      yield call(deletePrestataire, prestataireId);
      const response = yield call(getPrestataires);
      yield put(GET_PRESTATAIRES(response["hydra:member"]));
      yield put(
        ADD_PRESTATAIRE_SUCCESS(`Suppression du prestataire avec succès !!`)
      );
    } else {
      yield put(
        PRESTATAIRE_ERROR(
          "Ce prestataire ne peut pas être supprimé car des intervenants ou des formations y sont associées. Pour supprimer le prestataire, vous devez supprimer ces formations et intervenants avant !"
        )
      );
    }
  } catch (error) {
    yield put(
      PRESTATAIRE_ERROR(
        "Erreur de Serveur, nous travaillons actuellement afin de résoudre l'inconvénient nous empêchant de compléter votre demande..."
      )
    );
  }
}

function* addNewIntervenant({
  payload: {
    intFirstname,
    intLastname,
    qualification,
    intPhone,
    intEmail,
    prestataire,
    prestataireId,
    imageData,
    role,
    history,
  },
}) {
  try {
    const data = {
      intFirstname: intFirstname,
      intLastname: intLastname,
      qualification: qualification,
      intPhone: intPhone,
      intEmail: intEmail,
      prestataire: prestataire,
    };
    const response = yield call(postIntervenant, data);
    yield put(PRESTATAIRE_SUCCESS(""));

    if (imageData.length === 1) {
      const formData = new FormData();

      imageData.forEach((file) => {
        formData.append("file", file);
      });

      const responseImage = yield call(postMediaObject, formData);

      yield call(putIntervenant, {
        image: `/api/media_objects/${responseImage.id}`,
        intervenantId: response.id,
      });
    }

    if (role === "prestataire") {
      history.push("/prestataire-intervenants");
    } else if (role === "admin") {
      history.push({ pathname: "/gestion-intervenants", state: prestataireId });
    }
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* editIntervenant({
  payload: {
    intFirstname,
    intLastname,
    qualification,
    intPhone,
    intEmail,
    intervenantId,
  },
}) {
  try {
    const response = yield call(putIntervenant, {
      intFirstname: intFirstname,
      intLastname: intLastname,
      qualification: qualification,
      intPhone: intPhone,
      intEmail: intEmail,
      intervenantId: intervenantId,
    });
    yield put(GET_INTERVENANT(response));
    yield put(
      ADD_INTERVENANT_SUCCESS("Les informations ont bien été modifiées")
    );
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error));
  }
}

function* removeIntervenant({ payload: { intervenantId, prestataireId } }) {
  try {
    const response = yield call(getIntervenant, intervenantId);

    yield call(deleteIntervenant, intervenantId);
    yield put(PRESTATAIRE({ prestataireId: prestataireId }));

    // suppression de l'avatar associé
    if (response.image.id) {
      yield call(deleteMediaObject, response.image.id);
    }

    yield put(
      ADD_INTERVENANT_SUCCESS(`Suppression de l'intervenant avec succès !!`)
    );
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* editFormationIntervenant({
  payload: { formationId, intervenants, prestataireId },
}) {
  try {
    const data = {
      formationId: formationId,
      intervenants: intervenants,
    };
    yield call(putFormationIntervenant, data);
    yield put(PRESTATAIRE({ prestataireId: prestataireId }));
    yield put(PRESTATAIRE_SUCCESS(""));
  } catch (error) {
    yield put(PRESTATAIRE_ERROR(error.message));
  }
}

function* addAvatarIntervenant({ payload: { file, intervenantId } }) {
  try {
    const image = yield call(postMediaObject, file);

    const response = yield call(putIntervenant, {
      image: `/api/media_objects/${image.id}`,
      intervenantId: intervenantId,
    });

    yield put(GET_INTERVENANT(response));
    yield put(INTERVENANT_AVATAR_SUCCESS("Votre image a bien été ajoutée"));
  } catch (error) {
    yield put(INTERVENANT_AVATAR_ERROR(error));
  }
}

function* updateAvatarIntervenant({ payload: { file, intervenantId } }) {
  try {
    const image = yield call(postMediaObject, file);

    const response = yield call(putIntervenant, {
      image: `/api/media_objects/${image.id}`,
      intervenantId: intervenantId,
    });
    yield put(GET_INTERVENANT(response));

    yield put(INTERVENANT_AVATAR_SUCCESS("Votre image a bien été modifiée"));
  } catch (error) {
    yield put(INTERVENANT_AVATAR_ERROR(error));
  }
}

function* deleteAvatarIntervenant({ payload: { imageId, intervenantId } }) {
  try {
    const response = yield call(putIntervenant, {
      image: null,
      intervenantId: intervenantId,
    });
    yield put(GET_INTERVENANT(response));

    yield call(deleteMediaObject, imageId);
    yield put(INTERVENANT_AVATAR_SUCCESS("Votre image a bien été supprimée"));
  } catch (error) {
    yield put(INTERVENANT_AVATAR_ERROR(error));
  }
}

export function* watchProfile() {
  yield takeEvery(PRESTATAIRES.type, viewPrestataires);
  yield takeEvery(PRESTATAIRE.type, viewPrestataire);
  yield takeEvery(PRESTATAIRES_FILTER.type, viewPrestatairesFilter);
  yield takeEvery(INTERVENANT.type, viewIntervenant);
  yield takeEvery(UPDATE_PRESTATAIRE.type, editPrestataire);
  yield takeEvery(DELETE_PRESTATAIRE.type, removePrestataire);
  yield takeEvery(ADD_INTERVENANT.type, addNewIntervenant);
  yield takeEvery(UPDATE_INTERVENANT.type, editIntervenant);
  yield takeEvery(DELETE_INTERVENANT.type, removeIntervenant);
  yield takeEvery(UPDATE_FORMATION_INTERVENANT.type, editFormationIntervenant);
  yield takeEvery(ADD_AVATAR_INTERVENANT.type, addAvatarIntervenant);
  yield takeEvery(UPDATE_AVATAR_INTERVENANT.type, updateAvatarIntervenant);
  yield takeEvery(DELETE_AVATAR_INTERVENANT.type, deleteAvatarIntervenant);
}

export default function* PrestataireSaga() {
  yield all([fork(watchProfile)]);
}
