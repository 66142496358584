import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Data
import { generatePassword } from "helpers/text_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectClientEmployeeAccount, ADD_CLIENT_EMPLOYEE_ACCOUNT, CLIENT_EMPLOYEE_ACCOUNT_ERROR} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";
import {selectClientAccount, CLIENTS_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";
import {CLIENT_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import TitleBanner from "components/Common/TitleBanner";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const ClientEmployeeAccountAdd = ({history, isRole, location}) => {

  const dispatch = useDispatch()

  const { clients, client } = useSelector(selectClientAccount);
  const { error } = useSelector(selectClientEmployeeAccount);

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [job, setJob] = useState('')
  const [service, setService] = useState('')
  const [pole, setPole] = useState('')
  const [managerId, setManagerId] = useState(null)
  const [arrivalDate, setArrivalDate] = useState('')
  const [clientId, setClientId] = useState("")

  const GeneratePasswordSecure = () => {
    const passwordSecure = generatePassword()
    setPassword(passwordSecure)
    setConfirmPassword(passwordSecure)
  }

  function handleValidSubmitEmployee() {

    if (password !== confirmPassword) {
      dispatch(CLIENT_EMPLOYEE_ACCOUNT_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else if (clientId === "Choisir ...") {
      dispatch(CLIENT_EMPLOYEE_ACCOUNT_ERROR("Veuillez choisir une organisation !!"))
    } else {

      const data = {
        email,
        password,
        firstname,
        lastname,
        roles: ['ROLE_USER'],
        status: true,
        clientEmployee: {
          job,
          service,
          arrivalDate,
          client: `/api/clients/${clientId}`,
          pole,
          managerId: managerId !== 'Choisir ...' ? Number(managerId) : null,
          isArchived: false
        },
        history,
        isAdmin: true
      }
      dispatch(ADD_CLIENT_EMPLOYEE_ACCOUNT(data))
    }
  }

  function handleInvalidSubmitEmployee() {
    dispatch(CLIENT_EMPLOYEE_ACCOUNT_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(CLIENTS_ACCOUNT())

    // génération d'un mot de passe sécurisé
    GeneratePasswordSecure()

    dispatch(CLIENT_ACCOUNT({ clientId: clientId }))
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-comptes" title="Retour à la liste des comptes" />
            <TitleBanner title="Ajout d'un nouveau compte utilisateur" />
            <SuccessErrorAlert error={error} />

            <Row>
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5 pt-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitEmployee(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitEmployee(e, v)}
                    >

                      <Row>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations personnelles</h5>


                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom <span className="text-danger">*</span></h5>
                              <AvField
                                name="firstname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                validate={{
                                  minLength: {value: 2},
                                  maxLength: {value: 255}
                                }}
                                onChange={event => setFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom (entre 2 et 255 caractères)"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom <span className="text-danger">*</span></h5>
                              <AvField
                                name="lastname"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="email"
                                value={email}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <Card outline color="primary" className="border">
                              <CardBody className="mx-2">

                                <h5 className="mb-4 mt-2 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Mot de passe de connexion</h5>

                                <p><i className="uil uil-question-circle font-size-16 text-info me-2"></i> Un mot de passe sécurisé est généré automatiquement par défaut</p>

                                <Button
                                  color="dark"
                                  outline
                                  className="waves-effect waves-light mb-4 mt-3"
                                  onClick={ () => GeneratePasswordSecure()}
                                >
                                  Générer un nouveau mot de passe ?
                                </Button>

                                <div className="form-group">
                                  <h5 className="font-size-17 mt-4 mb-4">Mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="password"
                                    value={password}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setPassword(event.target.value)}
                                    errorMessage="Veuillez renseigner un mot de passe"
                                    required
                                  />
                                </div>

                                <div className="form-group mt-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Confirmer le mot de passe <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    errorMessage="Veuillez confirmer le mot de passe"
                                    required
                                  />
                                </div>

                              </CardBody>
                            </Card>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 color-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations professionnelles</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Organisation <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="clientId"
                                onChange={event => setClientId(event.target.value)}
                                errorMessage="Veuillez choisir une organisation"
                                helpMessage="Choisir une organisation dans le menu déroulant"
                              >
                                <option key="null">Choisir ...</option>
                                {clients.map( client => (
                                  <option key={client.id} value={client.id}>
                                    {client.entName}
                                  </option>
                                ))}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Poste <span className="text-danger">*</span></h5>
                              <AvField
                                name="job"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setJob(event.target.value)}
                                errorMessage="Veuillez renseigner un poste"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Service <span className="text-danger">*</span></h5>
                              <AvField
                                name="service"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setService(event.target.value)}
                                errorMessage="Veuillez renseigner un service"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Pôle <span className="text-danger">*</span></h5>
                              <AvField
                                name="pole"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setPole(event.target.value)}
                                errorMessage="Veuillez renseigner un pôle"
                                required
                              />
                            </div>

                            { clientId && clientId !== "Choisir ..." && (
                              <div className="form-group mb-4">
                                <h5 className="font-size-17 mt-5 mb-3">Responsable hiérarchique</h5>
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="managerId"
                                  validate={{ required: { value: false } }}
                                  onChange={event => setManagerId(event.target.value)}
                                >
                                  <option value={null} key={"default"}>Choisir ...</option>

                                  {map(client.clientEmployees, employee => {
                                    return <option key={`team-${employee.id}`} value={employee.id}>{employee.user.firstname} {employee.user.lastname}</option>
                                  })}
                                </AvField>
                              </div>
                            )}

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Date d'arrivée dans le service <span className="text-danger">*</span></h5>
                              <AvField
                                name="arrivalDate"
                                value=""
                                className="form-control"
                                placeholder=""
                                type="date"
                                onChange={event => setArrivalDate(event.target.value)}
                                errorMessage="Veuillez renseigner une date valide"
                                required
                              />
                            </div>

                          </div>
                        </Col>
                      </Row>

                      <SuccessErrorAlert error={error} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Ajouter le collaborateur</Button>
                        </div>

                        <Link to="/gestion-comptes" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des comptes
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(ClientEmployeeAccountAdd)
