import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {isEmpty, map} from "lodash";

// Login Redux States

import {
  CARTOGRAPHIES,
  CARTOGRAPHY,
  GET_CARTOGRAPHIES,
  GET_CARTOGRAPHY,
  ADD_CARTOGRAPHY,
  ADD_CARTOGRAPHY_WITH_INTERVENTION,
  ADD_NEW_CARTOGRAPHY,
  UPDATE_CARTOGRAPHY,
  UPDATE_CARTOGRAPHY_VALIDATE,
  UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE,
  DELETE_CARTOGRAPHY,
  CARTOGRAPHY_SUCCESS,
  CARTOGRAPHY_ERROR, CARTOGRAPHY_BY_CLIENT
} from "./cartographySlice"

import { GET_CLIENT_ACCOUNT } from "store/account/clientAccount/clientAccountSlice";
import { UPDATE_SKILL_RISK } from "store/skillsDevelopmentPlan/skill/skillSlice";
import { ADD_NOTIFICATION } from "store/notification/notificationSlice";
import {ADD_NEW_INTERVENTION} from "store/intervention/interventionSlice";

import {
  getCartographies,
  getCartography,
  putCartography,
  getTrainingPlan,
  postTrainingPlanAction,
  putCartographyValidate,
  postCartography,
  deleteCartography,
  getClientAccount,
  putIntervention,
  postIntervention
} from "helpers/request_helper"


function* viewCartographies() {
  try {
    const response = yield call(getCartographies)
    yield put(GET_CARTOGRAPHIES(response['hydra:member']))
    yield put(CARTOGRAPHY_SUCCESS(""))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* viewCartography({ payload: { cartographyId } }) {
  try {
    const response = yield call(getCartography, cartographyId)
    yield put(GET_CARTOGRAPHY(response))

    const clientResponse = yield call(getClientAccount, response.intervention.client.id)
    yield put(GET_CLIENT_ACCOUNT(clientResponse))

    yield put(CARTOGRAPHY_SUCCESS(""))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* viewCartographyByClient({ payload: { clientId } }) {
  try {

    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    const interventionsSort = client.interventions.filter( intervention => {
      if (intervention.hasOwnProperty('cartography')) {
        return intervention
      }
    })

    const cartography = yield call(getCartography, interventionsSort[interventionsSort.length - 1].cartography.id)
    yield put(GET_CARTOGRAPHY(cartography))

    yield put(CARTOGRAPHY_SUCCESS(""))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* addCartography({payload: { createdAt, validate, intervention, cartographyStatus, interventionId, history }}) {

  try {
    const data = {
      createdAt: createdAt,
      validate: validate,
      intervention: intervention,
      cartographyStatus: cartographyStatus
    }
    const response = yield call(postCartography, data)
    yield put(ADD_NEW_CARTOGRAPHY(response))
    yield call(putIntervention, {
      id: interventionId,
      interventionStatus: "/api/intervention_statuses/4"
    })
    yield put(CARTOGRAPHY_SUCCESS(""))

    history.push({ pathname: "/cartographie", state: response.id })

  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* addCartographyWithIntervention({ payload: { clientId, createdAt, history } }) {

  try {

    // Création d'un nouvelle intervention

    const data = {
      client: `/api/clients/${clientId}`,
      createdAt: new Date(),
      interventionStatus: "/api/intervention_statuses/4",
      isArchived: false
    }

    const newIntervention = yield call(postIntervention, data)
    yield put(ADD_NEW_INTERVENTION(newIntervention))

    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    // Création d'un nouvelle cartographie

    const cartographyData = {
      createdAt: createdAt,
      validate: false,
      intervention:`/api/interventions/${newIntervention.id}`,
      cartographyStatus: "/api/cartography_statuses/1",
      interventionId: newIntervention.id,
    }
    yield call(postCartography, cartographyData)

    history.push({ pathname: "/ma-cartographie" })

    yield put(CARTOGRAPHY_SUCCESS(""))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* editCartography({ payload: { colorRisk, message, employeeActionId, cartographyId } }) {
  try {
    const response = yield call(putCartography, {
      colorRisk: colorRisk,
      message: message,
      employeeActionId: employeeActionId
    })

    yield put(UPDATE_SKILL_RISK({ skillId: response.skill.id, cartographyId: cartographyId }))
    yield put(CARTOGRAPHY_SUCCESS(''))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* editCartographyValidate({ payload: { validate, cartographyId, adminRole, history, clientAccountId, clientId } }) {
  try {
    yield call(putCartographyValidate, {
      validate: validate,
      cartographyId: cartographyId
    })
    yield put(ADD_NOTIFICATION({
      userId: clientAccountId,
      title: "Validation d'une cartographie",
      message: `La cartographie a été validée.`,
      link: "/ma-cartographie-details",
      linkState: cartographyId,
      allAdmin: false,
      notificationRead: false
    }))

    yield put(CARTOGRAPHY({ cartographyId: cartographyId }))

    yield put(CARTOGRAPHY_SUCCESS(''))
    if (adminRole) {
      history.push({ pathname: "/details-organisation", state: clientId })
    } else {
      history.push({ pathname: "/ma-cartographie"})
    }
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

// nouvelle validation après dévalidation (+ mise à jour des demandes de formation dans PDC)
function* editCartographyValidateBeforeDevalidate({ payload: {validate, cartographyId, adminRole, history, clientId} }) {
  try {
    yield call(putCartographyValidate, {
      validate: validate,
      cartographyId: cartographyId,
    })

    yield put(CARTOGRAPHY({ cartographyId: cartographyId }))

    /*  ============ actualisation des demande de formation sur le plan de formation déjà existant ============= */

    const cartographyData = yield call(getCartography, cartographyId)

    const trainingPlan = yield call(getTrainingPlan, cartographyData.trainingPlans[0].id)

    // 1) récupérer le training plan avec les trainingPlanAction
    let actualTrainingPlan = trainingPlan.trainingPlanActions;

    let newEmployeesNeedTrainingInCartography = [];

    map(cartographyData.skills, skill => {

     // récupération des skills qui sont moyen ou mauvais dans la cartographie
     if (skill.risk === 2 || skill.risk === 1) {
       {map(skill.employeeSkills, employeeSkill => {

         // récupération des employées qui ont un risque moyen ou mauvais
         if (employeeSkill.colorRisk === 'bad' || employeeSkill.colorRisk === 'medium') {
           newEmployeesNeedTrainingInCartography.push({skillDomainId: skill.skillDomain.id, skillActivityId: skill.skillActivity.id, skillTask: skill.task, employeeSkill: employeeSkill})
         }
       })}
     }
    })

    if (isEmpty(actualTrainingPlan)) {

      // 3) ajouter les lignes au pdc

      for (let i = 0; i < newEmployeesNeedTrainingInCartography.length; i++) {

        const data = {
          employee: `/api/employees/${newEmployeesNeedTrainingInCartography[i].employeeSkill.employee.id}`,
          employeeSkill: `/api/employee_skills/${newEmployeesNeedTrainingInCartography[i].employeeSkill.id}`,
          trainingPlan: `/api/training_plans/${trainingPlan.id}`,
          certificateReceived: false,
          finished: false,
          isValidate: false,
          devisRequestSent: false,
          skillDomain: `/api/skill_domains/${newEmployeesNeedTrainingInCartography[i].skillDomainId}`,
          skillActivity: `/api/skill_activities/${newEmployeesNeedTrainingInCartography[i].skillActivityId}`,
          isCustomTrainingDate: false,
          taskCustom: newEmployeesNeedTrainingInCartography[i].skillTask
        }

        yield call(postTrainingPlanAction, data)
      }

    } else {

      // boucler le tableau et vérifier si la demande de formation est déjà présente dans le PDC

      let newDatas = []

      map(actualTrainingPlan, el => {
        newEmployeesNeedTrainingInCartography.forEach( item => {
          if (item.skillTask !== el.employeeSkill.skill.task && item.employeeSkill.employee.id !== el.employee.id) {
            newDatas.push({
              skillDomainId: item.skillDomainId,
              skillActivityId: item.skillActivityId,
              skillTask: item.skillTask,
              employeeSkill: item.employeeSkill
            })
          } else if (item.skillTask === el.employeeSkill.skill.task && item.employeeSkill.employee.id !== el.employee.id) {
            newDatas.push({
              skillDomainId: item.skillDomainId,
              skillActivityId: item.skillActivityId,
              skillTask: item.skillTask,
              employeeSkill: item.employeeSkill
            })
          }
        })
      })

      // enlever les doublons dans le nouveau tableau d'objets

      let jsonObject = newDatas.map(JSON.stringify); // on converti en JSON

      let uniqueSet = new Set(jsonObject); // on enlève les doublons
      let newDatasUnique = Array.from(uniqueSet).map(JSON.parse); // on reconverti en Array

      const newDatasUniqueFiltering = newDatasUnique.filter( data => {
        return !actualTrainingPlan.some( tp => data.skillTask === tp.employeeSkill.skill.task && data.employeeSkill.employee.id === tp.employee.id)
      })

      // 3) ajouter les lignes au pdc

      for (let i = 0; i < newDatasUniqueFiltering.length; i++) {

        const data = {
          employee: `/api/employees/${newDatasUniqueFiltering[i].employeeSkill.employee.id}`,
          employeeSkill: `/api/employee_skills/${newDatasUniqueFiltering[i].employeeSkill.id}`,
          trainingPlan: `/api/training_plans/${trainingPlan.id}`,
          certificateReceived: false,
          finished: false,
          isValidate: false,
          devisRequestSent: false,
          skillDomain: `/api/skill_domains/${newDatasUniqueFiltering[i].skillDomainId}`,
          skillActivity: `/api/skill_activities/${newDatasUniqueFiltering[i].skillActivityId}`,
          isCustomTrainingDate: false,
          taskCustom: newDatasUniqueFiltering[i].skillTask
        }

        yield call(postTrainingPlanAction, data)
      }

    }

    yield put(CARTOGRAPHY_SUCCESS(''))
    if (adminRole) {
      history.push({ pathname: "/details-organisation", state: clientId })
    } else {
      history.push({ pathname: "/ma-cartographie"})
    }
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

function* removeCartography({ payload: { cartographyId, clientId } }) {
  try {

    yield call(deleteCartography, cartographyId)

    const client = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(client))

    const interventionsSort = client.interventions.filter( intervention => {
      if (intervention.hasOwnProperty('cartography')) {
        return intervention
      }
    })

    const cartography = yield call(getCartography, interventionsSort[interventionsSort.length - 1].cartography.id)
    yield put(GET_CARTOGRAPHY(cartography))

    const clientResponse = yield call(getClientAccount, cartography.intervention.client.id)
    yield put(GET_CLIENT_ACCOUNT(clientResponse))

    yield put(CARTOGRAPHY_SUCCESS(""))
  } catch (error) {
    yield put(CARTOGRAPHY_ERROR(error.message))
  }
}

export function* watchProfile() {
  yield takeEvery(CARTOGRAPHIES.type, viewCartographies)
  yield takeEvery(CARTOGRAPHY.type, viewCartography)
  yield takeEvery(CARTOGRAPHY_BY_CLIENT.type, viewCartographyByClient)
  yield takeEvery(ADD_CARTOGRAPHY.type, addCartography)
  yield takeEvery(ADD_CARTOGRAPHY_WITH_INTERVENTION.type, addCartographyWithIntervention)
  yield takeEvery(UPDATE_CARTOGRAPHY.type, editCartography)
  yield takeEvery(UPDATE_CARTOGRAPHY_VALIDATE.type, editCartographyValidate)
  yield takeEvery(UPDATE_CARTOGRAPHY_VALIDATE_BEFORE_DEVALIDATE.type, editCartographyValidateBeforeDevalidate)
  yield takeEvery(DELETE_CARTOGRAPHY.type, removeCartography)
}

export default function* CartographySaga() {
  yield all([fork(watchProfile)])
}
