import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Button} from "reactstrap";
import { Link } from "react-router-dom";

const FormationInternalResumeCard = ({ formationInternal, toggleModalSelectFormationInternal, choiceVisible, linkButtonDetail }) => {

  return (
    <Card outline color="secondary" className="border">

      <CardHeader className="bg-transparent">
        <h5 className="my-0 text-primary"><i className="uil uil-graduation-cap mx-2"></i>{formationInternal.title}</h5>
      </CardHeader>

      <CardBody className="py-2">

        <CardTitle>

          <p className="font-size-15 color-text mb-4">
            {formationInternal.description ? formationInternal.description : 'Pas de description'}
          </p>

          <Link to={linkButtonDetail}
                className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3">
            <i className="uil uil-graduation-cap mx-2 font-size-18"></i>Détails de la formation interne
          </Link>

          { choiceVisible && (
            <Button
              color="warning"
              className="btn btn-success waves-effect waves-light mx-2 py-2"
              onClick={() => toggleModalSelectFormationInternal(formationInternal.id, formationInternal.price)}
              id="btn-select-formation"
            >
              Choisir
            </Button>
          )}

        </CardTitle>
      </CardBody>
    </Card>
  );
};

export default FormationInternalResumeCard;

