import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isRole,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("accessJWT")) {
        return (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>
        );
      //} else if (tokenRole.filter(role => role === isRole)[0] !== isRole) {
          //return (
              //<Redirect to={{ pathname: "/page-not-permission", state: { from: props.location } }}/>
          //);
      } else {
          return(
              <Layout>
                  <Component {...props} isRole={isRole} />
              </Layout>
          )
      }
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
