import React, {useEffect} from "react"
import {Badge, Card, CardBody, Col, Container, Row} from "reactstrap"
import {Link, Redirect} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectPrestataire, PRESTATAIRE} from "store/prestataire/prestataireSlice";


const PrestataireInformationView = ({ isRole, location }) => {

  const dispatch = useDispatch()
  const { prestataire } = useSelector(selectPrestataire);

  const prestataireId = Number(localStorage.getItem('prestataireId'))

  useEffect(() => {
    dispatch(PRESTATAIRE({prestataireId: prestataireId}))
  }, [dispatch, prestataireId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-5 mt-5">
              <Col md="4">

                <Card className="card h-100">
                  <CardBody className="mx-4">
                    <div className="text-center">
                      <h5 className="mt-3 mb-1">{prestataire.name}</h5>
                      <p className="text-muted">{prestataire.commercialCoverage}</p>

                      {prestataire.hasOwnProperty('locality') && (
                        <p className="text-muted">
                          {prestataire.locality.region.name} ({prestataire.locality.departmentName})
                        </p>
                      )}

                    </div>

                    <hr className="my-4"/>

                    <div className="text-muted">
                      <div className="table-responsive mt-4">

                        <div>
                          <p className="mb-1">Représentant :</p>
                          <h5 className="font-size-17">{prestataire.contactFirstname} {prestataire.contactLastname}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Fonction :</p>
                          <h5 className="font-size-17">{prestataire.contactFunction}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Téléphone :</p>
                          <h5 className="font-size-17">{prestataire.contactPhone}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Email :</p>
                          <h5 className="font-size-17">{prestataire.contactEmail}</h5>
                        </div>

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="8">

                <Card className="card">
                  <CardBody className="mx-4 mt-3">
                    <div className="mx-3">

                      <p className="mb-1">Description :</p>

                      { prestataire.presentation ? (
                        <ul style={{ paddingLeft: '0px' }}>
                          {prestataire.presentation && prestataire.presentation.split('\n').map((item, idx) => (
                            <li key={idx} className="font-size-17 color-text mb-4" style={{ listStyleType: 'none' }}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <h5 className="font-size-17 mt-2">Non renseigné</h5>
                      )}

                    </div>
                  </CardBody>
                </Card>

                <Card className="card mb-0">
                  <CardBody className="mx-4 mt-3">

                    <div className="mx-3">
                      <p className="mb-1">Adresse :</p>
                      <h5 className="font-size-17">{prestataire.address} {prestataire.zipCode} {prestataire.city}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Pays :</p>
                      <h5 className="font-size-17">{prestataire.country}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code NAF :</p>
                      <h5 className="font-size-17">{prestataire.codeNaf}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code SIRET :</p>
                      <h5 className="font-size-17">{prestataire.codeSiret}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code TVA :</p>
                      <h5 className="font-size-17">{prestataire.codeTva}</h5>
                    </div>

                    <div className="mt-3 mx-3 d-flex align-items-center">
                      <p className="mb-1">Assujetti à la TVA ? </p>
                      <h5 className="mx-3">{
                        prestataire.subjectTva
                          ? <Badge className="badge bg-pill bg-soft-success" color="success" pill>Oui</Badge>
                          : <Badge className="badge bg-pill bg-soft-danger" color="danger" pill>Non</Badge>
                      }</h5>
                    </div>

                    <div className="mt-3 mx-3 mb-4">
                      <p className="mb-1">Numéro de déclaration d'activité (NDA) :</p>
                      <h5 className="font-size-17">{prestataire.codeNda}</h5>
                    </div>

                    <div className="mx-3 mt-4 d-flex align-items-center">
                      <p className="mb-1">Certification Qualiopi : </p>
                      <h5 className="mx-3 ">{
                        prestataire.qualiopiCertification
                          ? <Badge className="badge bg-pill bg-soft-success" color="success" pill>Oui</Badge>
                          : <Badge className="badge bg-pill bg-soft-danger" color="danger" pill>Non</Badge>
                      }</h5>
                    </div>

                  </CardBody>
                </Card>

              </Col>
            </Row>

            <Row>
              <Col sm={8}>
                <Link to="/prestataire-informations-modifier" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                  <i className="uil uil-corner-up-right-alt me-1"></i>Modifier mes informations
                </Link>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default PrestataireInformationView
