import createSagaMiddleware from "redux-saga";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootSaga from "./rootSaga";

import layoutReducer from "./layout/layoutSlice";

import loginReducer from "./auth/login/loginSlice";
import forgetpwdReducer from "./auth/forgetpwd/forgetpwdSlice";
import profileReducer from "./auth/profile/profileSlice";

import accountReducer from "./account/account/accountSlice";
import clientAccountReducer from "./account/clientAccount/clientAccountSlice";
import clientEmployeeAccountReducer from "./account/clientEmployeeAccount/clientEmployeeAccountSlice";
import prestataireAccountReducer from "./account/prestataireAccount/prestataireAccountSlice";

import clientEmployeeReducer from "./clientEmployee/clientEmployeeSlice";

import interventionReducer from "./intervention/interventionSlice";
import prestataireReducer from "./prestataire/prestataireSlice";
import formationReducer from "./formation/formationSlice";

import employeeReducer from "./skillsDevelopmentPlan/employee/employeeSlice";
import skillReducer from "./skillsDevelopmentPlan/skill/skillSlice";
import cartographyReducer from "./skillsDevelopmentPlan/cartography/cartographySlice"
import trainingPlanReducer from "./skillsDevelopmentPlan/trainingPlan/trainingPlanSlice"

import globalDataReducer from "./globalData/globalDataSlice";

import notificationReducer from "./notification/notificationSlice";

import formationInternalReducer from "./formationInternal/formationInternalSlice";

import advertReducer from "./advert/advertSlice";

const reducers = combineReducers({
    layout: layoutReducer,
    login: loginReducer,
    forgetpwd: forgetpwdReducer,
    profile: profileReducer,
    account: accountReducer,
    clientAccount: clientAccountReducer,
    clientEmployeeAccount: clientEmployeeAccountReducer,
    clientEmployee: clientEmployeeReducer,
    prestataireAccount: prestataireAccountReducer,
    employee: employeeReducer,
    skill: skillReducer,
    intervention: interventionReducer,
    prestataire: prestataireReducer,
    formation: formationReducer,
    cartography: cartographyReducer,
    trainingPlan: trainingPlanReducer,
    globalData: globalDataReducer,
    notification: notificationReducer,
    advert: advertReducer,
    formationInternal: formationInternalReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
