import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Card, CardBody, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button} from "reactstrap";
import classnames from "classnames";

import Rating from "react-rating"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// import charts
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectFormation, FORMATION, FORMATION_TRAINING_REQUEST_BY_USER, FORMATION_DEVIS_REQUEST_BY_USER } from "store/formation/formationSlice";
import { PRESTATAIRES } from "store/prestataire/prestataireSlice";
import {selectIntervention} from "store/intervention/interventionSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";

import UserCatalogueFormationDetailColumn from "./UserCatalogueFormationDetailColumn";
import UserCatalogueFormationTrainingRequestModal from "./UserCatalogueFormationTrainingRequestModal";
import UserCatalogueFormationDevisRequestModal from "./UserCatalogueFormationDevisRequestModal";

const UserCatalogueFormationDetail = ({isRole, location}) => {

  // récupération de l'id passé dans la route
  const routeId = location.state.formationId

  const dispatch = useDispatch()
  const { formation } = useSelector(selectFormation);
  const { intervention } = useSelector(selectIntervention);

  const [activeTab, setActiveTab] = useState("1");

  const [showModalTrainingRequest, setShowModalTrainingRequest] = useState(false)
  const [showModalDevisRequest, setShowModalDevisRequest] = useState(false)

  const toggleModalTrainingRequest = () => {
    setShowModalTrainingRequest(!showModalTrainingRequest);
  };

  const toggleModalDevisRequest = () => {
    setShowModalDevisRequest(!showModalDevisRequest);
  };

  const formationtoggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const devisRequest = () => {
    dispatch(FORMATION_DEVIS_REQUEST_BY_USER({ formation }))
    setShowModalDevisRequest(!showModalDevisRequest);
  }

  const trainingRequest = () => {
    dispatch(FORMATION_TRAINING_REQUEST_BY_USER({ formation }))
    setShowModalTrainingRequest(!showModalTrainingRequest);
  }

  useEffect(() => {
    dispatch(FORMATION({formationId: routeId}))
    dispatch(PRESTATAIRES())
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <UserCatalogueFormationTrainingRequestModal isOpen={showModalTrainingRequest} toggle={toggleModalTrainingRequest} trainingRequest={trainingRequest} />
        <UserCatalogueFormationDevisRequestModal isOpen={showModalDevisRequest} toggle={toggleModalDevisRequest} devisRequest={devisRequest} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/catalogue-de-formation" title="Retour au catalogue de formation" />

            <Row>
              <Col sm={8}>

                { (location.state.url === 'planFormation') && (
                  <Link to="/mon-plan-developpement-competences" className="btn btn-primary waves-effect' +
                    ' waves-light mx-2 mb-5">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Retour au plan de développement des compétences
                  </Link>
                )}

                { (location.state.url === 'formationDetails') && (
                  <Link to={{ pathname: "/mon-pdc", state: intervention.hasOwnProperty('cartography') && intervention.cartography.id }} className="btn btn-primary waves-effect' +
                    ' waves-light mx-2 mb-5">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Retour à la liste des formations
                  </Link>
                )}

              </Col>
            </Row>

            <Row>
              <Col xl="4">
                <Card className="card h-100">

                  {formation.hasOwnProperty("prestataire") ? (

                    <CardBody>
                      <div className="text-center">

                        <h5 className="mt-3 mb-1">{formation.prestataire.name}</h5>
                        <p className="text-muted">{formation.prestataire.commercialCoverage}</p>

                      </div>

                      <div className="text-center mx-4">

                        <p className="mb-1 mt-5">Description du prestataire :</p>

                        { formation.prestataire.presentation ? (
                          <ul style={{ paddingLeft: '0px' }}>
                            {formation.prestataire.presentation && formation.prestataire.presentation.split('\n').map((item, idx) => (
                              <li key={idx} className="font-size-17 color-text mb-4" style={{ listStyleType: 'none' }}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <h5 className="font-size-17 mt-2">Non renseigné</h5>
                        )}
                      </div>

                      <hr className="my-4" />

                      <div className="text-center mt-5">
                        <h5 className="mb-3 color-primary font-size-17">Intervenants pressentis de la formation :</h5>

                        <Row>
                          <Col lg={12}>
                            <Card className="mb-0">
                              <CardBody className="mt-0">

                                <ToolkitProvider
                                  keyField="id"
                                  data={formation.intervenants || []}
                                  columns={UserCatalogueFormationDetailColumn()}
                                  bootstrap4
                                >
                                  {toolkitProps => (
                                    <React.Fragment>

                                      <div className="table-responsive">
                                        <BootstrapTable
                                          responsive
                                          remote
                                          hover={true}
                                          bordered={false}
                                          striped={false}
                                          classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                          {...toolkitProps.baseProps}
                                        />
                                      </div>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>

                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                      </div>

                    </CardBody>

                  ) : (
                    <></>
                  )}
                </Card>
              </Col>

              <Col xl="8">

                <h5 className="font-size-24 color-primary mb-5 text-center">{formation.title}</h5>

                <div className="d-flex justify-content-center">

                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light mx-4"
                    onClick={() => toggleModalTrainingRequest(formation)}
                    id="btn-select-formation"
                  >
                    <i className="uil uil-envelope-alt mx-2 font-size-18"></i>Demander cette formation à mon entreprise
                  </Button>

                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light mx-4"
                    onClick={() => toggleModalDevisRequest(formation)}
                    id="btn-select-formation"
                  >
                    <i className="uil uil-envelope-alt mx-2 font-size-18"></i>Envoyer un mail à l'organisme
                  </Button>

                </div>

                <div className="text-center">
                  <h5 className="font-size-17 mt-5 mb-3">Public visé par la formation</h5>
                  <p className="text-muted">{formation.targetAudience}</p>
                </div>


                <div className="px-4 py-4 d-flex justify-content-between text-center">

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Durée de la formation</h5>
                    <p className="text-muted">{formation.duration} {formation.durationUnit}</p>
                  </div>

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Prix de vente (HT)</h5>
                    { formation.priceHT ? (
                      <p className="text-muted">{Number(formation.priceHT + formation.priceHT * 0.1).toFixed(2)} €</p>
                    ) : (
                      <p className="text-muted">Non précisé</p>
                    )}
                  </div>

                  <div>
                    <h5 className="font-size-17 mt-4 mb-3">Prix catalogue de vente (TTC)</h5>
                    { formation.priceTTC ? (
                      <p className="text-muted">{Number((formation.priceHT + formation.priceHT * 0.1) + ((formation.priceHT + formation.priceHT * 0.1) * 0.2) ).toFixed(2)} €</p>
                    ) : (
                      <p className="text-muted">Non précisé</p>
                    )}
                  </div>

                  <div className="mx-5">
                    <h5 className="font-size-17 mt-4 mb-3">Délais d’accès</h5>
                    <p className="text-muted">{formation.accessDelay}</p>
                  </div>

                  <div className="mx-5">
                    <h5 className="font-size-17 mt-4 mb-3">Accessibilité handicap</h5>
                    <p className="text-muted">
                      {formation.disabledAccessibility ? (
                        <i className="uil uil-check font-size-22 color-success"></i>
                      ) : (
                        <i className="uil uil-times font-size-22 color-danger"></i>
                      )}
                    </p>
                  </div>

                </div>

                <Card className="mb-0">

                  {/* =========== DEBUT ONGLETS ================== */}

                  <Nav tabs className="nav-tabs-custom nav-justified">

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "1"})}
                        onClick={() => formationtoggle("1") }
                      >
                        <i className="uil uil-crosshair font-size-20"></i>
                        <span className="d-none d-sm-block">Objectifs</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "2"})}
                        onClick={ () => formationtoggle("2") }
                      >
                        <i className="uil uil-file-check-alt font-size-20"></i>
                        <span className="d-none d-sm-block">Prérequis</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "3"})}
                        onClick={ () => formationtoggle("3") }
                      >
                        <i className="uil uil-book-reader font-size-20"></i>
                        <span className="d-none d-sm-block">Programme</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "4"})}
                        onClick={ () => formationtoggle("4") }
                      >
                        <i className="uil uil-graduation-cap font-size-20"></i>
                        <span className="d-none d-sm-block">Pédagogie</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "5"})}
                        onClick={ () => formationtoggle("5") }
                      >
                        <i className="uil uil-chart-line font-size-20"></i>
                        <span className="d-none d-sm-block">Bénéfices</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  {/* =========== FIN ONGLETS ================== */}

                  <TabContent activeTab={activeTab} className="p-4">

                    {/* =========== TAB 1 ================== */}

                    <TabPane tabId="1">
                      <div>
                        <div>

                          { formation.objectifs
                            ? (
                              <>
                                <ul className="activity-feed mb-0 ps-2">

                                  <li className="feed-item" key="_obj_1">
                                    <div className="feed-item-list">

                                      <h5 className="font-size-17 my-3">Objectifs de la formation</h5>

                                      <ul>
                                        {formation.objectifs && formation.objectifs.split('\n').map((item, idx) => <li key={idx}>{item}</li>)}
                                      </ul>

                                    </div>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <p>Aucun</p>
                            )}

                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 2 ================== */}

                    <TabPane tabId="2">
                      <div>

                        <div>
                          <ul className="activity-feed mb-0 ps-2">

                            <li className="feed-item" key="_pre_1">
                              <div className="feed-item-list">

                                <h5 className="font-size-17 my-3">Prérequis de la formation</h5>

                                <ul>
                                  {formation.prerequisite && formation.prerequisite.split('\n').map((item, idx) => <li key={idx}>{item}</li>)}
                                </ul>

                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 3 ================== */}

                    <TabPane tabId="3">
                      <div>

                        <div>

                          { formation.programme
                            ? (
                              <>
                                {/*<h5 className="font-size-17 my-3">Contenu de la formation</h5>*/}
                                <div className="mt-3" dangerouslySetInnerHTML={{__html: formation.programme}}></div>
                              </>
                            ) : (
                              <p>Aucun</p>
                            )}

                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 4 ================== */}

                    <TabPane tabId="4">
                      <div>

                        <div>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_moy_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Moyens pédagogiques</h5>
                                <p className="text-muted">{formation.educationalMethods}</p>
                              </div>
                            </li>

                            <li className="feed-item" key="_moy_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Méthodes pédagogiques</h5>
                                <p className="text-muted">{formation.educationalTechnics}</p>
                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </TabPane>

                    {/* =========== TAB 5 ================== */}

                    <TabPane tabId="5">
                      <div>
                        <div>

                          <ul className="activity-feed mb-0 ps-2">

                            <li className="feed-item" key="_sanc_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Sanction de la formation</h5>
                                <p className="text-muted">{formation.sanction}</p>
                              </div>
                            </li>

                            <li className="feed-item" key="_sanc_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Débouchés de la formation</h5>
                                <p className="text-muted">{formation.market}</p>
                              </div>
                            </li>

                          </ul>

                          { formation.benefices && (
                            <>
                              <h5 className="font-size-17">Bénéfices</h5>

                              <ul style={{ listStyleType: "none" }}>
                                {formation.benefices && formation.benefices.split('\n').map((benefice, idx) => <li key={idx}>{benefice}</li>)}
                              </ul>
                            </>
                          )}

                        </div>

                      </div>
                    </TabPane>

                  </TabContent>
                </Card>

                <div className="mt-3 px-4 py-4">

                  <div className="mb-4">
                    <h5 className="font-size-17 mb-2">Domaine de compétence de la formation : </h5>
                    <p className="text-muted mb-0">{formation.hasOwnProperty('skillDomain') && formation.skillDomain.name}</p>
                  </div>

                  <div className="mb-5">
                    <h5 className="font-size-17 mb-2">Description de la formation : </h5>
                    <p className="text-muted mb-0">{formation.description}</p>
                  </div>

                  <div className="mb-4 d-flex">
                    <h5 className="font-size-17">Modalités de la formation : </h5>
                    <p className="text-muted mx-3">{formation.modalities}</p>
                  </div>

                  <div className="mb-4 d-flex">
                    <h5 className="font-size-17">Modalités d’évaluations : </h5>
                    <p className="text-muted mx-3">{formation.evaluation}</p>
                  </div>

                  <div className="mb-4 d-flex">
                    <h5 className="font-size-17">Matériel nécessaire pour réaliser la formation : </h5>
                    <p className="text-muted mx-3">{formation.necessaryMaterial}</p>
                  </div>

                  <div className="mb-4 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Taux de satisfaction de la formation : </h5>

                    {formation.satisfactionRate ? (
                      <Rating
                        emptySymbol={ <i className="mdi mdi-star-outline text-muted font-size-22" /> }
                        fullSymbol={ <i className="mdi mdi-star text-warning font-size-22" /> }
                        initialRating={formation.satisfactionRate * 0.05}
                        readonly
                        fractions={10}
                        className="mx-3"
                      />
                    ) : (
                      <p className="mb-0 mx-3">Non précisé</p>
                    )}
                  </div>

                  <div className="mb-4 d-flex">
                    <h5 className="font-size-17">Equivalences de la formation : </h5>
                    <p className="text-muted mx-3">{formation.equivalences}</p>
                  </div>

                  {formation.cpfEligible ? (
                    <>
                      <div className="mb-4 d-flex">
                        <h5 className="font-size-17">Code CPF de la formation : </h5>
                        <p className="text-muted mx-3">{formation.cpfCode}</p>
                      </div>

                      <div className="mb-4 d-flex">
                        <h5 className="font-size-17">Désignation CPF de la formation : </h5>
                        <p className="text-muted mx-3">{formation.cpfDesignation}</p>
                      </div>
                    </>
                  ) : (
                    <p className="color-danger mb-5">Cette formation n'est pas éligible au CPF</p>
                  )}

                  <div className="mb-5 d-flex align-items-center">
                    <h5 className="font-size-17 mb-0">Taux de réussite à la formation : </h5>

                    {formation.successRate ? (
                      <Rating
                        emptySymbol={ <i className="mdi mdi-star-outline text-muted font-size-22" /> }
                        fullSymbol={ <i className="mdi mdi-star text-warning font-size-22" /> }
                        initialRating={formation.successRate * 0.05}
                        readonly
                        fractions={10}
                        className="mx-3"
                      />
                    ) : (
                      <p className="mb-0 mx-3">Non précisé</p>
                    )}
                  </div>

                </div>

                <div className="d-flex justify-content-center mb-3">

                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light mx-4"
                    onClick={() => toggleModalTrainingRequest(formation)}
                    id="btn-select-formation"
                  >
                    <i className="uil uil-envelope-alt mx-2 font-size-18"></i>Demander cette formation à mon entreprise
                  </Button>

                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light mx-4"
                    onClick={() => toggleModalDevisRequest(formation)}
                    id="btn-select-formation"
                  >
                    <i className="uil uil-envelope-alt mx-2 font-size-18"></i>Envoyer un mail à l'organisme
                  </Button>

                </div>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(UserCatalogueFormationDetail)
