// LOGIN
export const POST_LOGIN_URL = "/login_check"

// FORGOT PASSWORD
export const POST_PASSWORD_FORGET = "/forgot_password/"

// PROFILE
export const USER_PROFILE_URL = "/users"

// PROFILE
export const MEDIA_OBJECT_URL = "/media_objects"

// GLOBAL DATAS
export const LOCALITIES_URL = "/localities"
export const SOCIETY_TYPES_URL = "/society_types"
export const REGIONS_URL = "/locality_regions"

// USER ACCOUNT
export const ACCOUNT_URL = "/users"

// CLIENT ACCOUNT
export const CLIENT_ACCOUNT_URL = "/clients"

// PRESTATAIRE ACCOUNT
export const PRESTATAIRE_ACCOUNT_URL = "/prestataires"

// CLIENT EMPLOYEE
export const CLIENT_EMPLOYEE_URL = "/client_employees"

// EMPLOYEE
export const EMPLOYEE_URL = "/employees"

// SKILL
export const SKILL_URL = "/skills"
export const SKILL_ACTIVITY_URL = "/skill_activities"
export const SKILL_DOMAIN_URL = "/skill_domains"

// EMPLOYEE SKILL
export const EMPLOYEE_SKILL_URL = "/employee_skills"

// INTERVENTION
export const INTERVENTION_URL = "/interventions"

// PRESTATAIRE
export const PRESTATAIRE_URL = "/prestataires"

// INTERVENANT
export const INTERVENANT_URL = "/prestataire_intervenants"

// FORMATION
export const FORMATION_URL = "/formations"
export const FORMATION_STATUS_URL = "/formation_statuses"
export const FORMATION_DATE_URL = "/formation_dates"

// CARTOGRAPHY
export const CARTOGRAPHY_URL = "/cartographies"

// CARTOGRAPHY
export const EMPLOYEE_ACTION_URL = "/employee_skills"

// TRAINING PLAN
export const TRAINING_PLAN_URL = "/training_plans"

// TRAINING PLAN
export const PAYMENT_FOUNDING_TYPE_URL = "/payment_founding_types"

// TRAINING PLAN ACTION
export const TRAINING_PLAN_ACTION_URL = "/training_plan_actions"

// FORMATION DEVIS
export const FORMATION_DEVIS_URL = "/formation_devis"
export const FORMATION_DEVIS_REQUEST_BY_USER_URL = "/formation_devis_request_by_user"
export const FORMATION_DEVIS_TRAINING_BY_USER_URL = "/formation_devis_training_by_user"

// POLE EMPLOI
export const POLE_EMPLOI_SKILLS = "pole_emploi_skills"

// NOTIFICATION
export const NOTIFICATION_URL = "/notifications"

// ADVERT
export const ADVERT_URL = "/adverts"

// FORMATION INTERNAL
export const FORMATION_INTERNAL_URL = "/formation_internals"
export const FORMATION_INTERNAL_FILTER_BY_CLIENT_URL = "/all_formation_internal_by_client"
