import React from "react";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {map} from "lodash";

const PrestataireIntervenantGestionFormationCard = ({color, formation, toggleModalIntervenantRemove, toggleModalIntervenantAdd}) => {

  return (
    <Card outline color={color} className="border">

      <CardHeader className="bg-transparent">
        <h5 className={`my-0 text-${color}`}><i className="uil uil-graduation-cap mx-2"></i>{formation.title}</h5>
      </CardHeader>

      <CardBody className="py-2">

        <CardTitle>
          <div className="d-flex align-items-center mb-3">
            <h6 className="mb-0">code CPF :</h6>
            <p className="font-size-15 text-muted mx-2 mb-0">{formation.cpfCode}</p>
          </div>

          <h6 className="mb-1">Désignation CPF :</h6>
          <p className="font-size-15 text-muted mb-0">{formation.cpfDesignation}</p>

        </CardTitle>


        <div className="my-4">

          <h6 className={`mb-2 text-${color}`}>Intervenants pressentis :</h6>

          <ul className="mb-1">
            {map(formation.intervenants, (intervenant, key) => (
              <li className="my-0 color-text" key={"_int_" + key}>
                {intervenant.intFirstname} {intervenant.intLastname}
                <button
                  type="button"
                  className="btn btn-link waves-effect px-3 text-danger"
                  onClick={() => toggleModalIntervenantRemove(intervenant.id, formation.id, formation.intervenants)}
                  data-toggle="modal"
                  data-target="modal-delete"
                >
                  <i className="uil uil-times font-size-17"></i>
                </button>
              </li>
            ))}

          </ul>

          <button
            type="button"
            className={`btn btn-secondary waves-effect px-3 mt-2 font-size-15`}
            onClick={() => toggleModalIntervenantAdd(formation.id, formation.intervenants)}
            data-toggle="modal"
            data-target="modal-delete"
          >
            + Ajouter un intervenant à la liste
          </button>

        </div>


      </CardBody>

    </Card>
  );
};

export default PrestataireIntervenantGestionFormationCard;

