import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap"
import {Redirect} from "react-router-dom"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {PRESTATAIRES, selectPrestataire} from "store/prestataire/prestataireSlice";
import {selectSkill, SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import FormationResumeCard from "components/Common/Card/FormationResumeCard";

const UserCatalogueFormation = ({isRole, location}) => {

  const dispatch = useDispatch()

  const { prestataires, error, success } = useSelector(selectPrestataire);
  const {skillDomains} = useSelector(selectSkill);

  const [skillDomainId, setSkillDomainId] = useState('')

  const handleSelectSkillSubmit = () => {}

  const handleSearchSubmit = () => {}

  /* ============ start Searchbar Filter =================  */

  const [query, setQuery] = useState('');

  const renderFormationBySearch = (formation, queryText) => {

    const formattedQuery = queryText.toUpperCase();

    const titleUpper = formation.title.toUpperCase();
    const descriptionUpper = formation.description.toUpperCase();

    if (titleUpper.includes(formattedQuery) || descriptionUpper.includes(formattedQuery)) {
      return (
        (formation.activeFormation) && (
          <Col sm={4} key={'formation-' + formation.id}>
            <FormationResumeCard
              formation={formation}
              linkButtonDetail={{pathname: "/catalogue-de-formation-detail", state: {formationId: formation.id}}}
              choiceVisible={false}
            />
          </Col>
        )
      )
    }
  }

  /* ============ end Searchbar Filter =================  */

  const prestataireNameDisplay = () => {

    const prestataireName = document.querySelectorAll('.prestataire-name')

    setTimeout( () => {
      prestataireName.forEach( name => {
        if (!name.childNodes[1].childElementCount) {
          name.childNodes[0].style.display = 'none'
        } else {
          name.childNodes[0].style.display = 'block'
        }
      })
    }, 500)
  }

  useEffect(() => {
    dispatch(PRESTATAIRES())
    //dispatch(PRESTATAIRES_FILTER())
    dispatch(SKILL_DOMAIN_ACTIVITY())
  }, [dispatch])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <h5 className="font-size-22 color-secondary text-center mb-5">Catalogue de formation</h5>

            <SuccessErrorAlert error={error} success={success} />

            <Row>
              <Col lg={12}>


                    <h5 className="font-size-18 color-secondary mb-4 mt-3">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Retrouvez ci-dessous l'ensemble des formations disponibles
                    </h5>

                <Row>
                  <Col lg={6}>
                    <AvForm onValidSubmit={(e, v) => handleSelectSkillSubmit(e, v)}>
                      <Row>
                        <Col className="col-8 mb-5">
                          <h5 className="font-size-17 mb-3">Filtrer les formations par compétence</h5>
                          <AvField
                            className="form-select"
                            type="select"
                            name="skillDomainId"
                            onChange={event => {
                              setSkillDomainId(event.target.value)
                            }}
                            errorMessage="Choisir une compétence"
                            helpMessage="Choisir une compétence pour filtrer les formations par domaine de compétences"
                          >
                            <option key="default" value="">Voir toutes les formations</option>

                            {map(skillDomains, (domain, key) => (
                              <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                            ))}
                          </AvField>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>

                  <Col lg={6}>
                    {!skillDomainId && (
                      <AvForm onValidSubmit={(e, v) => handleSearchSubmit(e, v)}>
                        <Row>
                          <h5 className="font-size-17 mb-3">Filtrer les formations par mot-clé (titre ou description)</h5>
                          <Col className="col-8 mb-5">
                            <div className="form-inline mb-3">
                              <div className="search-box ml-2">
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control rounded border-0"
                                    name="search"
                                    onChange={ queryText => {
                                      setQuery(queryText.target.value)
                                    }}
                                    placeholder="Rechercher par mot-clé ..."
                                  />
                                  <i className="mdi mdi-magnify search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    )}
                  </Col>
                </Row>


                    <Row>
                      <Col lg={12}>

                        <Row>

                          {map(prestataires, (prestataire, index) => (
                            <div key={index}>
                              {prestataire.formations.length !== 0 && (
                                <div className="prestataire-name">
                                  <h5 className="font-size-18 color-secondary mb-4 mt-3">
                                    <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                                    {prestataire.name}
                                  </h5>

                                  <Row>
                                    {map(prestataire.formations, (formation, index) => (

                                      (!skillDomainId
                                          ? renderFormationBySearch(formation, query)
                                          : (formation.activeFormation && formation.skillDomain.id === Number(skillDomainId)) && (
                                          <Col sm={4} key={'formation-' + index}>
                                            <FormationResumeCard
                                              formation={formation}
                                              linkButtonDetail={{pathname: "/catalogue-de-formation-detail", state: {formationId: formation.id}}}
                                              choiceVisible={false}
                                            />
                                          </Col>
                                        )
                                      ))

                                    )}
                                  </Row>
                                </div>
                              )}
                            </div>
                          ))}

                        </Row>

                      </Col>
                    </Row>

                    { prestataireNameDisplay()}


              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default UserCatalogueFormation
