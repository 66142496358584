import React, {useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import {Card, CardBody, Col, Row} from "reactstrap";
import {map} from "lodash";

import { capitalizeFirstLetter } from 'helpers/text_helper'

// REDUX
import {selectAccount, ACCOUNT} from "store/account/account/accountSlice";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER, selectClientEmployee} from "store/clientEmployee/clientEmployeeSlice";
import {selectAdvert, ADVERTS} from "store/advert/advertSlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

import AdvertCard from "components/Common/Advert/AdvertCard";

import placeholder from "assets/images/404-error.png"

const DashboardUser = () => {

  const userId = localStorage.getItem('id')

  const dispatch = useDispatch()

  const account = useSelector(selectAccount);
  const { lastFormation, nextFormation, formationFinished, nbrHourTraining } = useSelector(selectClientEmployee);
  const { adverts } = useSelector(selectAdvert);

  // =============== BAR CHART =====================================

    const series = [
      {
        name: '',
        data: formationFinished.yAxis,
      },
    ]
    const options = {
      chart: {
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '45%',
          distributed: true,
        },
      },

      dataLabels: {
        enabled: false,
      },

      colors: ["#597887"],

      grid: {
        show: false,
      },

      legend: {
        show: false
      },

      xaxis: {
        categories: formationFinished.xAxis,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },

      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        labels: {
          formatter: (val) => { return Number(val) },
        }
      },

      tooltip: {
        enabled: false
      }
    }

  // ================================================================

  useEffect(() => {
    dispatch(ACCOUNT({ userId }))
    dispatch(CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER({ userId }))
    dispatch(ADVERTS())
  }, [dispatch, userId])

  return (
    <div className="mt-5">
      <Row className="mb-5">
        <Col md="12" className="mx-3">
          <h4>Bonjour {capitalizeFirstLetter(account.user.firstname)} {capitalizeFirstLetter(account.user.lastname)}</h4>
        </Col>
      </Row>

      <Row className="mb-5 align-items-stretch">
        <Col md="4" className="mb-4">
          <Card className="mx-4 mb-0" style={{ borderRadius: 15, height: '100%', marginRight: '50px' }}>
            <CardBody  className="text-center">
              <p className="font-size-16 fw-medium mb-2">Nombre de formations réalisées</p>

              <ReactApexChart options={options} series={series} type="bar" height="250" />

            </CardBody>
          </Card>
        </Col>

        <Col md="4" className="mb-4">
          <Card className="mx-4" style={{ borderRadius: 15, height: '100%' }}>
            <CardBody className="text-center d-flex flex-column">
              <p className="font-size-16 fw-medium">Volume d'heures de formation</p>

              <div className="h-100 d-flex flex-column justify-content-center">
                <p className="text-primary fw-medium mb-0">Vous avez réalisé</p>
                <p className="text-primary fw-medium mb-0 mt-0" style={{ fontSize: '60px', fontWeight: 'bold', transform: 'translateY(-10px)' }}>{nbrHourTraining}</p>
                <p className="mb-0 text-primary fw-medium" style={{ transform: 'translateY(-20px)' }}>heures de formations</p>
              </div>

            </CardBody>
          </Card>
        </Col>

        <Col md="4" className="mb-4">

          <Card className="mx-4" style={{ borderRadius: 15 }}>
            <CardBody className="text-center font-size-15">
              <p className="font-size-16 fw-medium">Prochaine formation prévue</p>

              {nextFormation
                ? (
                  <>
                    <p className="text-primary fw-medium mb-0">{nextFormation.hasOwnProperty('formation') && nextFormation.formation.title}</p>
                    <p className="text-primary mb-0">{nextFormation.hasOwnProperty('trainingEnd') && moment(nextFormation.trainingEnd).locale('fr', localization).format("DD/MM/yyyy")}</p>
                    <p className="fw-light mb-0">{nextFormation.hasOwnProperty('formation') && nextFormation.formation.prestataire.name}</p>
                  </>
                ) : (
                  <p>Aucune formation actuellement</p>
                )}

            </CardBody>
          </Card>

          <Card className="mx-4" style={{ marginBottom: 0, borderRadius: 15 }}>
            <CardBody className="text-center  font-size-15">
              <p className="font-size-16  fw-medium">Dernière formation suivie</p>

              {lastFormation
                ? (
                  <>
                    <p className="text-primary fw-medium mb-0">{lastFormation.hasOwnProperty('formation') && lastFormation.formation.title}</p>
                    <p className="text-primary mb-0">{lastFormation.hasOwnProperty('trainingEnd') && moment(lastFormation.trainingEnd).locale('fr', localization).format("DD/MM/yyyy")}</p>
                    <p className="fw-light mb-0">{lastFormation.hasOwnProperty('formation') && lastFormation.formation.prestataire.name}</p>
                  </>
                ) : (
                  <p>Aucune formation actuellement</p>
                )}

            </CardBody>
          </Card>

        </Col>
      </Row>


      <Row className="mb-0">
        <Col md="12">

          {map(adverts, (advert, index) => {
            if (index === 0 && advert.isActive) {
              return <AdvertCard
                key={index}
                img={
                  advert.image.hasOwnProperty('filePath')
                    ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                    : placeholder}
                message={advert.message}
                link={advert.link}
                isTransparent={false}
              />
            } else {
              return null
            }
          })}

        </Col>
      </Row>

      <p className="mt-5 mb-5">Fichier de recettage :
        <a
          href="https://www.notion.so/insaniam/Fichier-de-recettage-Utilisateur-4857afb1c0ca4fe98ae4208cc5edc7f0"
          className="mx-2 color-success"
          target="_blank"
          rel="noreferrer"
        >Lien</a>
      </p>

    </div>
  )
}

export default DashboardUser
