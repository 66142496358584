import React from "react"

const PrestataireDetailColumnIntervenant = () => [

  {
    dataField: "intLastname",
    text: "Intervenant",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => (
          <p className="mb-0">{row.intFirstname} {row.intLastname}</p>
    ),
  },

  {
    dataField: "intPhone",
    text: "Téléphone",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true
  },

  {
    dataField: "intEmail",
    text: "Email",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true
  },

]

export default PrestataireDetailColumnIntervenant
