import React, {useState} from "react"
import PropTypes from "prop-types"
import {Modal} from "reactstrap"
import {map} from "lodash";
import {useSelector} from "react-redux";
import {selectTrainingPlan} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

const FormationPlanAddActionModal = ({ isOpen, toggle, addAction, cartography}) => {

  const { trainingPlan } = useSelector(selectTrainingPlan);
  const [employeeId, setEmployeeId] = useState("")

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Ajouter une demande de formation ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Choisir un collaborateur dans la liste déroulante :</p>

        <select
          name="intervenantSelect"
          className="form-control"
          onChange={event => setEmployeeId(event.target.value)}
        >
          <option key="null">Choisir ...</option>

          {cartography.employees && (
            <>
              {map(cartography.employees, (employee, key) => (
                <option key={employee.id} value={employee.id}>
                  {employee.clientEmployee.user.firstname} {employee.clientEmployee.user.lastname}
                </option>
              ))}
            </>
          )}

        </select>

      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={() => addAction(employeeId, trainingPlan.id)}>Ajouter la demande</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default FormationPlanAddActionModal

FormationPlanAddActionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
