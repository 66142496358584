import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  skill: {},
  skillDomains: [],
  skillActivities: [],
  skillsPoleEmploi: [],
  success: "",
  error: "",
  isLoading: false
}

const skillSlice = createSlice({
  name: "skill",
  initialState,

  reducers: {

    SKILL (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_SKILL (state, action) {
      state.skill = action.payload
    },

    ADD_DATA_SKILL (state, action) {
      state.skill = action.payload
    },

    ADD_SKILL (state) {
      state.isLoading = true
    },

    UPDATE_SKILL (state) {
      state.isLoading = true
    },

    UPDATE_SKILL_REQUIRED_LEVEL (state) {
      state.isLoading = true
    },

    UPDATE_SKILL_RISK (state) {
      state.isLoading = true
    },

    DELETE_SKILL (state) {
      state.isLoading = true
      state.skill = {}
    },

    SKILL_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    SKILL_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    /* ========== SKILLS ACTIVITIES & DOMAINES ============= */

    SKILL_DOMAIN_ACTIVITY (state) {
      state.isLoading = true
    },

    ADD_SKILL_ACTIVITIES (state, action) {
      state.skillActivities = action.payload  // payload: skill activities
    },

    ADD_SKILL_DOMAINS (state, action) {
      state.skillDomains = action.payload  // payload: skill domains
    },

    POLE_EMPLOI_SKILLS (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_POLE_EMPLOI_SKILLS (state, action) {
      state.skillsPoleEmploi = action.payload
    },

    POLE_EMPLOI_RESET (state) {
      state.skillsPoleEmploi = []
    }

  }
});

const { reducer, actions } = skillSlice;

export const {
  SKILL,
  GET_SKILL,
  ADD_DATA_SKILL,
  SKILL_DOMAIN_ACTIVITY,
  ADD_SKILL_ACTIVITIES,
  ADD_SKILL_DOMAINS,
  ADD_SKILL,
  UPDATE_SKILL,
  UPDATE_SKILL_REQUIRED_LEVEL,
  UPDATE_SKILL_RISK,
  DELETE_SKILL,
  SKILL_SUCCESS,
  SKILL_ERROR,
  POLE_EMPLOI_SKILLS,
  GET_POLE_EMPLOI_SKILLS,
  POLE_EMPLOI_RESET
} = actions;

export default reducer;

export const selectSkill = (state) => state.skill;
