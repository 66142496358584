import React from "react";
import { Card, Col, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";

const PrestataireIntervenantGestionCard = ({intervenant, toggleModal}) => {

  const firstname = intervenant.intFirstname;
  const lastname = intervenant.intLastname;
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <CardBody>
            <Media className="d-flex">

              {/* Avatar circle */}
              <div className="avatar-sm me-4">

                {intervenant.hasOwnProperty('image') ? (
                  <img
                    className="avatar-title rounded-circle bg-white"
                    src={process.env.REACT_APP_URL + "/media/" + intervenant.image.filePath}
                    alt="Header Avatar"
                  />
                ) : (
                  <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-15`}>
                    {nameIcon}
                  </span>
                )}

              </div>


              <Media body className="flex-1 align-self-center">

                <div className="border-bottom pb-1 mb-3">

                  <h5 className="text-truncate font-size-16 mb-1">
                    <Link to="#" className="text-dark">{intervenant.intFirstname} {intervenant.intLastname}</Link>
                  </h5>

                  <p className="text-muted">{intervenant.qualification}</p>

                </div>

                <h5 className="font-size-15 mb-2">{intervenant.intPhone}</h5>
                <h5 className="font-size-15 mb-3">{intervenant.intEmail}</h5>

                <div>
                  <Link to={{ pathname: "/prestataire-intervenants-modifier", state: intervenant.id }} className="btn btn-link waves-effect px-3 text-info">
                    <i className="uil uil-pen font-size-18"></i>
                  </Link>

                  <button
                    type="button"
                    className="btn btn-link waves-effect px-3 text-danger"
                    onClick={ () => toggleModal(intervenant) }
                    data-toggle="modal"
                    data-target="modal-delete"
                  >
                    <i className="uil uil-trash-alt font-size-18"></i>
                  </button>
                </div>

              </Media>
            </Media>

          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default PrestataireIntervenantGestionCard;

