import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Col, Container, Row} from "reactstrap";
import { map } from "lodash";


// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  selectPrestataire,
  PRESTATAIRE,
  ADD_NEW_INTERVENANT,
  DELETE_INTERVENANT,
  UPDATE_FORMATION_INTERVENANT,
  ADD_FORMATION_INTERVENANT
} from "store/prestataire/prestataireSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import IntervenantGestionCard from "./IntervenantGestionCard"
import IntervenantGestionModalRemove from "./IntervenantGestionModalRemove"
import IntervenantGestionFormationCard from './IntervenantGestionFormationCard'

import IntervenantGestionFormationModalRemove from "./IntervenantGestionFormationModalRemove"
import IntervenantGestionFormationModalAdd from "./IntervenantGestionFormationModalAdd"

const IntervenantGestion = ({isRole, location}) => {

  // récupération de l'id passé dans la route
  const routeId = location.state

  const dispatch = useDispatch()
  const {prestataire, intervenantFormation} = useSelector(selectPrestataire)

  const [showModal, setShowModal] = useState(false)
  const [showModalIntervenantRemove, setShowModalIntervenantRemove] = useState(false)
  const [showModalIntervenantAdd, setShowModalIntervenantAdd] = useState(false)

  const toggleModal = intervenantData => {
    if (!showModal) dispatch(ADD_NEW_INTERVENANT(intervenantData))
    setShowModal(!showModal);
  };

  const toggleModalIntervenantRemove = (intervenantId, formationId, formation) => {
    if (!showModalIntervenantRemove) dispatch(ADD_FORMATION_INTERVENANT({
      intervenantId: intervenantId,
      formationId: formationId,
      formation: formation
    }))
    setShowModalIntervenantRemove(!showModalIntervenantRemove);
  };

  const toggleModalIntervenantAdd = (formationId, formation) => {
    if (!showModalIntervenantAdd) dispatch(ADD_FORMATION_INTERVENANT({
      formationId: formationId,
      formation: formation
    }))
    setShowModalIntervenantAdd(!showModalIntervenantAdd);
  };

  const deleteIntervenant = intervenantId => {
    dispatch(DELETE_INTERVENANT({
      intervenantId: intervenantId,
      prestataireId: prestataire.id
    }))
    setShowModal(!showModal);
  };

  const deleteFormationIntervenant = () => {

    const prestataireIntervenants = []

    intervenantFormation.formation.forEach(intervenant => {
      if (intervenant.id !== intervenantFormation.intervenantId) {
        prestataireIntervenants.push(`/api/prestataire_intervenants/${intervenant.id}`)
      }
    })

    const data = {
      formationId: intervenantFormation.formationId,
      intervenants: prestataireIntervenants,
      prestataireId: prestataire.id
    }
    dispatch(UPDATE_FORMATION_INTERVENANT(data))
    setShowModalIntervenantRemove(!showModalIntervenantRemove);
  };

  const addFormationIntervenant = (intervenantId) => {

    const prestataireIntervenants = []

    intervenantFormation.formation.forEach(intervenant => {
      prestataireIntervenants.push(`/api/prestataire_intervenants/${intervenant.id}`)
    })
    prestataireIntervenants.push(`/api/prestataire_intervenants/${intervenantId}`)

    const data = {
      formationId: intervenantFormation.formationId,
      intervenants: prestataireIntervenants,
      prestataireId: prestataire.id
    }
    dispatch(UPDATE_FORMATION_INTERVENANT(data))
    setShowModalIntervenantAdd(!showModalIntervenantAdd);
  };

  useEffect(() => {
    dispatch(PRESTATAIRE({prestataireId: routeId}))
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <IntervenantGestionModalRemove isOpen={showModal} toggle={toggleModal} deleteIntervenant={deleteIntervenant} />
        <IntervenantGestionFormationModalAdd isOpen={showModalIntervenantAdd} toggle={toggleModalIntervenantAdd} addFormationIntervenant={addFormationIntervenant} />
        <IntervenantGestionFormationModalRemove isOpen={showModalIntervenantRemove} toggle={toggleModalIntervenantRemove} deleteFormationIntervenant={deleteFormationIntervenant} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link={{ pathname: "/details-prestataire", state: routeId }} title="Retour au prestataire" />
            <TitleBanner title={`Prestataire ${prestataire.name} - Gestion des formations et des intervenants`} />
            <SuccessErrorAlert error={prestataire.error} />


            <h5 className="font-size-18 color-secondary mt-0 mb-5">
              <i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des intervenants
            </h5>

            <Row>
              {map(prestataire.prestataireIntervenants, (intervenant, key) => (
                <IntervenantGestionCard intervenant={intervenant} toggleModal={toggleModal} key={"_intervenant_" + key}/>
              ))}
            </Row>

            <Row className="mb-2">
              <Col md={6}>
                <div className="mt-3 mb-5">
                  <Link to={{ pathname: "/ajout-intervenant", state: routeId }} className="btn btn-primary waves-effect waves-light">
                    <i className="uil uil-corner-up-right-alt me-2"></i> Ajouter un nouvel intervenant
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
            <h5 className="font-size-18 color-secondary mt-3 mb-3">
              <i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des formations proposées
            </h5>

            <p className="font-size-14 mb-4">
              <i className="uil uil-lightbulb-alt font-size-17 mx-2"></i>
              Si la formation est jaune,  son état est suspendu !
            </p>
          </Row>

            <Row>
                {map(prestataire.formations, (formation, key) => (
                  <Col lg={4} key={"_formation_" + key}>
                    <IntervenantGestionFormationCard
                      color={formation.activeFormation ? "primary": "warning"}
                      toggleModalIntervenantRemove={toggleModalIntervenantRemove}
                      toggleModalIntervenantAdd={toggleModalIntervenantAdd}
                      formation={formation}
                    />
                  </Col>
                ))}
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(IntervenantGestion)
