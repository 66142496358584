import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap"
import {Link, Redirect} from "react-router-dom"

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Data
import { countries } from "helpers/data_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectPrestataire, PRESTATAIRE, UPDATE_PRESTATAIRE, PRESTATAIRE_ERROR } from "store/prestataire/prestataireSlice";
import {selectGlobalDatas} from "store/globalData/globalDataSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import {AvField, AvForm} from "availity-reactstrap-validation";


const PrestataireInformationEdit = ({ isRole, location }) => {

  const dispatch = useDispatch()
  const { prestataire, error, successForm } = useSelector(selectPrestataire);
  const { localities } = useSelector(selectGlobalDatas);

  const [name, setName] = useState("prestataire.name")
  const [address, setAddress] = useState("prestataire.address")
  const [localityId, setLocalityId] = useState(prestataire.hasOwnProperty('locality') ? prestataire.locality.id : "")
  const [zipCode, setZipCode] = useState("prestataire.zipCode")
  const [city, setCity] = useState("")
  const [contactFirstname, setContactFirstname] = useState("")
  const [contactLastname, setContactLastname] = useState("")
  const [contactFunction, setContactFunction] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [codeNaf, setCodeNaf] = useState("")
  const [codeSiret, setCodeSiret] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [country, setCountry] = useState("")
  const [commercialCoverage, setCommercialCoverage] = useState("")
  const [codeNda, setCodeNda] = useState("")
  const [qualiopiCertification, setQualiopiCertification] = useState(true)
  const [subjectTva, setSubjectTva] = useState(true)
  const [presentation, setPresentation] = useState("")    // si problème, remplacer \n par &#13;&#10;

  const prestataireId = Number(localStorage.getItem('prestataireId'))

  function handleValidSubmitPrestataire() {

    const data = {
      prestataireId: prestataireId,
      name: name,
      address: address,
      locality: localityId ? `/api/localities/${localityId}` : undefined,
      zipCode: zipCode,
      city: city,
      presentation: presentation,
      contactFirstname: contactFirstname,
      contactLastname: contactLastname,
      contactFunction: contactFunction,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      codeNaf: codeNaf,
      codeSiret: codeSiret,
      codeTva: codeTva,
      country: country,
      commercialCoverage: commercialCoverage,
      codeNda: codeNda,
      subjectTva: subjectTva,
      qualiopiCertification: qualiopiCertification
    }
    dispatch(UPDATE_PRESTATAIRE(data))
  }

  function handleInvalidSubmitPrestataire() {
    dispatch(PRESTATAIRE_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(PRESTATAIRE({ prestataireId: prestataireId }))
    setQualiopiCertification(prestataire.qualiopiCertification)
    setSubjectTva(prestataire.subjectTva)
    setName(prestataire.name)
    setAddress(prestataire.address)
    setZipCode(prestataire.zipCode)
    setCity(prestataire.city)
    setContactFirstname(prestataire.contactFirstname)
    setContactLastname(prestataire.contactLastname)
    setContactFunction(prestataire.contactFunction)
    setContactPhone(prestataire.contactPhone)
    setContactEmail(prestataire.contactEmail)
    setCodeNaf(prestataire.codeNaf)
    setCodeSiret(prestataire.codeSiret)
    setCodeTva(prestataire.codeTva)
    setCountry(prestataire.country)
    setCommercialCoverage(prestataire.commercialCoverage)
    setCodeNda(prestataire.codeNda)
    setPresentation(prestataire.presentation)
  }, [
    dispatch,
    prestataireId,
    prestataire.qualiopiCertification,
    prestataire.subjectTva,
    prestataire.name,
    prestataire.address,
    prestataire.zipCode,
    prestataire.city,
    prestataire.contactFirstname,
    prestataire.contactLastname,
    prestataire.contactFunction,
    prestataire.contactPhone,
    prestataire.contactEmail,
    prestataire.codeNaf,
    prestataire.codeSiret,
    prestataire.codeTva,
    prestataire.country,
    prestataire.commercialCoverage,
    prestataire.codeNda,
    prestataire.presentation
  ])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/prestataire-informations" title="Retour à mes informations" />
            <SuccessErrorAlert error={error} success={successForm} />


            <Row className="mt-4">
              <Col lg={12}>
                <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitPrestataire(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitPrestataire(e, v)}
                    >

                      <Row>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-4 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informations de l'entreprise</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom de l'entreprise <span className="text-danger">*</span></h5>
                              <AvField
                                name="name"
                                value={prestataire.name}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setName(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Adresse de l'entreprise <span className="text-danger">*</span></h5>
                              <AvField
                                name="address"
                                value={prestataire.address}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setAddress(event.target.value)}
                                errorMessage="Veuillez renseigner une adresse"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Département <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="locality"
                                onChange={event => setLocalityId(event.target.value)}
                                helpMessage="Choisir un département dans le menu déroulant"
                              >
                                {prestataire.hasOwnProperty('locality') && (
                                  <>
                                    <option>{prestataire.locality.departmentCode} - {prestataire.locality.departmentName}</option>
                                    {localities.map( locality => (
                                      <option key={locality.id} value={locality.id}>
                                        {locality.departmentCode} - {locality.departmentName}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code postal <span className="text-danger">*</span></h5>
                              <AvField
                                name="zipCode"
                                value={prestataire.zipCode}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setZipCode(event.target.value)}
                                errorMessage="Veuillez renseigner un code postal"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Ville <span className="text-danger">*</span></h5>
                              <AvField
                                name="city"
                                value={prestataire.city}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCity(event.target.value)}
                                errorMessage="Veuillez renseigner une ville"
                                required
                              />
                            </div>

                            <div className="form-group mb-5">
                              <h5 className="font-size-17 mt-5 mb-3">Pays <span className="text-danger">*</span></h5>
                              <AvField
                                className="form-select"
                                type="select"
                                name="country"
                                onChange={event => setCountry(event.target.value)}
                                helpMessage="Choisir un pays dans le menu déroulant">
                                <option>{prestataire.country}</option>
                                {countries.map((country, idx) => <option key={idx}>{country}</option>)}
                              </AvField>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Domaine d'activité <span className="text-danger">*</span></h5>
                              <AvField
                                name="commercialCoverage"
                                value={prestataire.commercialCoverage}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCommercialCoverage(event.target.value)}
                                errorMessage="Veuillez renseigner un domaine"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code NAF <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeNaf"
                                value={prestataire.codeNaf}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeNaf(event.target.value)}
                                errorMessage="Veuillez renseigner un code NAF"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">SIRET <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeSiret"
                                value={prestataire.codeSiret}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeSiret(event.target.value)}
                                errorMessage="Veuillez renseigner un SIRET"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Code TVA <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeTva"
                                value={prestataire.codeTva}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeTva(event.target.value)}
                                errorMessage="Veuillez renseigner un code TVA"
                                required
                              />
                            </div>

                            <div className="form-check mt-5">
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="subject-tva"
                                     onChange={() => subjectTva ? setSubjectTva(false) : setSubjectTva(true)}
                                     checked={subjectTva}
                              />
                              <Label className="form-check-label" htmlFor="subject-tva">Assujetti à la TVA ?</Label>
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Numéro de déclaration d'activité (NDA) <span className="text-danger">*</span></h5>
                              <AvField
                                name="codeNda"
                                value={prestataire.codeNda}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setCodeNda(event.target.value)}
                                errorMessage="Veuillez renseigner un numéro NDA"
                                required
                              />
                            </div>

                            <FormGroup>
                              <div className="form-check mt-5">
                                <Input type="checkbox"
                                       className="form-check-input"
                                       id="qualiopi-input"
                                       onChange={() => qualiopiCertification ? setQualiopiCertification(false) : setQualiopiCertification(true)}
                                       checked={qualiopiCertification}
                                />
                                <Label className="form-check-label" htmlFor="qualiopi-input">Certification qualiopi ?
                                  <span className="font-size-15 color-grey mx-2">(si décoché et en l'absence de certification, les formations du prestataire ne seront pas accessible)</span>
                                </Label>
                              </div>
                            </FormGroup>

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mx-5">

                            <h5 className="mb-5 mt-4 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Présentation du prestataire</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-3 mb-3">Présentation</h5>
                              <AvField
                                name="presentation"
                                value={prestataire.presentation}
                                className="form-control"
                                placeholder=""
                                type="textarea"
                                rows="5"
                                validate={{
                                  required: {value: false},
                                  minLength: {value: 0},
                                  maxLength: {value: 1000}
                                }}
                                onChange={event => setPresentation(event.target.value)}
                              />
                              <p className="font-size-15 color-grey mx-2 mt-3">(la présentation est limitée à 1000 caractères)</p>
                            </div>

                            <h5 className="mb-5 mt-5 text-primary"><i className="mdi mdi-arrow-right text-primary me-1"></i> Contact de l'entreprise</h5>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Prénom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactFirstname"
                                value={prestataire.contactFirstname}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactFirstname(event.target.value)}
                                errorMessage="Veuillez renseigner un prénom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Nom du représentant <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactLastname"
                                value={prestataire.contactLastname}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactLastname(event.target.value)}
                                errorMessage="Veuillez renseigner un nom"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Fonction <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactFunction"
                                value={prestataire.contactFunction}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactFunction(event.target.value)}
                                errorMessage="Veuillez renseigner une fonction"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactEmail"
                                value={prestataire.contactEmail}
                                className="form-control"
                                placeholder=""
                                type="email"
                                onChange={event => setContactEmail(event.target.value)}
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-3">Téléphone <span className="text-danger">*</span></h5>
                              <AvField
                                name="contactPhone"
                                value={prestataire.contactPhone}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setContactPhone(event.target.value)}
                                errorMessage="Veuillez renseigner un téléphone"
                                required
                              />
                            </div>

                          </div>
                        </Col>

                      </Row>

                      <SuccessErrorAlert error={error} success={successForm} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Modifier les informations</Button>
                        </div>

                        <Link to="/prestataire-informations" className="btn btn-primary waves-effect waves-light">
                          <i className="uil uil-corner-up-right-alt me-1"></i>Retour à mes informations
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default PrestataireInformationEdit
