import React from "react"
import PropTypes from "prop-types"
import {Badge, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {useSelector} from "react-redux";

// REDUX
import {selectClientEmployee} from "store/clientEmployee/clientEmployeeSlice";


const ClientFicheMissionSkillUpgradeModal = ({ isOpen, toggle, skillUpgrade }) => {

  const { modal } = useSelector(selectClientEmployee);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <ModalHeader toggle={toggle}>
        <p className="modal-title mt-0">Mise à niveau de la compétence ?</p>
        <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>

      <ModalBody>

        <p>Evaluation de compétence actuelle</p>
        { modal.colorRisk === 'very-good' && <Badge className="badge badge-cartography pill bg-risk-very-good" pill>&ensp;{modal.message}</Badge>}
        { modal.colorRisk === 'good' && <Badge className="badge badge-cartography pill bg-risk-good" pill>&ensp;{modal.message}</Badge>}
        { modal.colorRisk === 'medium' && <Badge className="badge badge-cartography pill bg-risk-medium" pill>&ensp;{modal.message}</Badge>}
        { modal.colorRisk === 'bad' && <Badge className="badge badge-cartography pill bg-risk-bad" pill>&ensp;{modal.message}</Badge>}
        { modal.colorRisk === 'not-concerned' && <Badge className="badge badge-cartography pill bg-risk-not-concerned" pill>&ensp;{modal.message}</Badge>}
        { modal.colorRisk === 'default' && <p>{modal.message}</p>}

        <p className="mt-4">Evaluation de compétence demandée</p>
        { modal.colorRiskUpgrade === 'very-good' && <Badge className="badge badge-cartography pill bg-risk-very-good" pill>&ensp;{modal.messageUpgrade}</Badge>}
        { modal.colorRiskUpgrade === 'good' && <Badge className="badge badge-cartography pill bg-risk-good" pill>&ensp;{modal.messageUpgrade}</Badge>}
        { modal.colorRiskUpgrade === 'medium' && <Badge className="badge badge-cartography pill bg-risk-medium" pill>&ensp;{modal.messageUpgrade}</Badge>}
        { modal.colorRiskUpgrade === 'bad' && <Badge className="badge badge-cartography pill bg-risk-bad" pill>&ensp;{modal.messageUpgrade}</Badge>}
        { modal.colorRiskUpgrade === 'not-concerned' && <Badge className="badge badge-cartography pill bg-risk-not-concerned" pill>&ensp;{modal.messageUpgrade}</Badge>}
        { modal.colorRiskUpgrade === 'default' && <p>{modal.message}</p>}

          <Row>
            <Col>
              <div className="text-end mt-5">
                <button type="button" className="btn btn-success me-2" onClick={() => skillUpgrade('accord', modal.colorRiskUpgrade, modal.messageUpgrade, modal.employeeSkillId, modal.cartographyId )}>Accepter</button>
                <button type="button" className="btn btn-danger me-2" onClick={() => skillUpgrade('refus', modal.colorRiskUpgrade, modal.messageUpgrade, modal.employeeSkillId, modal.cartographyId )}>Refuser</button>
                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
              </div>
            </Col>
          </Row>

      </ModalBody>
    </Modal>
  )
}

export default ClientFicheMissionSkillUpgradeModal

ClientFicheMissionSkillUpgradeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
