import React, {useEffect, useState} from "react"
import {Redirect} from "react-router-dom"
import {Col, Container, Row, Card, CardBody } from "reactstrap"
import SimpleBar from "simplebar-react";
import {isEmpty, map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectCartography, CARTOGRAPHY } from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import { selectEmployee, ADD_DATA_EMPLOYEE, ADD_EMPLOYEE_FROM_TEAM, DELETE_EMPLOYEE } from "store/skillsDevelopmentPlan/employee/employeeSlice";
import { ADD_DATA_SKILL, ADD_SKILL, UPDATE_SKILL, DELETE_SKILL, SKILL_DOMAIN_ACTIVITY, POLE_EMPLOI_RESET } from "store/skillsDevelopmentPlan/skill/skillSlice";
import {selectClientAccount} from "store/account/clientAccount/clientAccountSlice";
import {GET_CLIENT_EMPLOYEE_ACCOUNT, UPDATE_CLIENT_EMPLOYEE_ACCOUNT} from "store/account/clientEmployeeAccount/clientEmployeeAccountSlice";

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import CartographyConfigurationEmployeeCard from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationEmployeeCard";

import CartographyConfigurationRemoveEmployeeModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationRemoveEmployeeModal";
import ClientEmployeeEditModal from "components/Common/Modal/ClientEmployeeConfiguration/ClientEmployeeEditModal";

import CartographyConfigurationAddEmployeeSelectModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationAddEmployeeSelectModal";

import CartographyConfigurationRemoveSkillModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationRemoveSkillModal";
import CartographyConfigurationAddSkillModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationAddSkillModal";
import CartographyConfigurationEditSkillModal from "pages/SkillsDevelopmentPlan/Cartography/CartographyConfiguration/CartographyConfigurationEditSkillModal";

import DotLoader from "react-spinners/DotLoader";

const ClientCartographyConfiguration = ({ isRole, location }) => {

  const clientId = Number(localStorage.getItem('clientId'))

  // récupération de l'id passé dans la route
  const routeId = location.state

  const dispatch = useDispatch()

  const { cartography, error, success } = useSelector(selectCartography);
  const { isLoading } = useSelector(selectEmployee);
  const { client } = useSelector(selectClientAccount);

  const [showModalEmployeeRemove, setShowModalEmployeeRemove] = useState(false)
  const [showModalEmployeeEdit, setShowModalEmployeeEdit] = useState(false)

  const [showModalEmployeeAddSelect, setShowModalEmployeeAddSelect] = useState(false)

  const [showModalSkillRemove, setShowModalSkillRemove] = useState(false)
  const [showModalSkillAdd, setShowModalSkillAdd] = useState(false)
  const [showModalSkillEdit, setShowModalSkillEdit] = useState(false)

  const toggleModalEmployeeRemove = employeeData => {
    if (!showModalEmployeeRemove) dispatch(ADD_DATA_EMPLOYEE(employeeData))
    setShowModalEmployeeRemove(!showModalEmployeeRemove);
  };

  const toggleModalEmployeeEdit = employeeData => {
    if (!showModalEmployeeEdit) dispatch(GET_CLIENT_EMPLOYEE_ACCOUNT(employeeData))
    setShowModalEmployeeEdit(!showModalEmployeeEdit);
  };

  const toggleModalSkillRemove = skillData => {
    if (!showModalSkillRemove) dispatch(ADD_DATA_SKILL(skillData))
    setShowModalSkillRemove(!showModalSkillRemove);
  };

  const toggleModalSkillAdd = skillData => {
    dispatch(POLE_EMPLOI_RESET())
    if (!showModalSkillAdd) {
      dispatch(ADD_DATA_SKILL(skillData))
      dispatch(SKILL_DOMAIN_ACTIVITY())
    }
    setShowModalSkillAdd(!showModalSkillAdd);
  };

  const toggleModalSkillEdit = skillData => {
    dispatch(POLE_EMPLOI_RESET())
    if (!showModalSkillEdit) {
      dispatch(ADD_DATA_SKILL(skillData))
      dispatch(SKILL_DOMAIN_ACTIVITY())
    }
    setShowModalSkillEdit(!showModalSkillEdit);
  };

  const deleteEmployee = employeeId => {
    dispatch(DELETE_EMPLOYEE({ employeeId: employeeId, cartographyId: cartography.id }))
    setShowModalEmployeeRemove(!showModalEmployeeRemove);
  };

  const editEmployee = (e, v, userId, clientEmployeeId, originEmail) => {

    const data = {
      job: v.job,
      service: v.service,
      arrivalDate: v.arrivalDate,
      pole: v.pole,
      userId,
      clientEmployeeId,
      clientId,
      firstname: v.firstname,
      lastname: v.lastname,
      email: v.email,
      cartographyId: cartography.id,
      originEmail,
      managerId: v.managerId ? (v.managerId !== 'Choisir ...' && v.managerId !== 'Aucun' ? Number(v.managerId) : null) : undefined,
    }

    dispatch(UPDATE_CLIENT_EMPLOYEE_ACCOUNT(data))
    setShowModalEmployeeEdit(!showModalEmployeeEdit);
  };

  /* =========== ADD EMPLOYEE WITH SELECT IN TEAM ============ */

  const toggleModalEmployeeAddSelect = () => {
    setShowModalEmployeeAddSelect(!showModalEmployeeAddSelect);
  };

  const addEmployeeSelect = (clientEmployeesId) => {

    const skillsArray = map(cartography.skills, skill => {
      return skill.id
    })

    if (!isEmpty(clientEmployeesId)) {
      const data = {
        clientEmployeesId: clientEmployeesId,
        cartography: `/api/cartographies/${cartography.id}`,
        cartographyId: cartography.id,
        skillsId: skillsArray
      }
      dispatch(ADD_EMPLOYEE_FROM_TEAM(data))
    }

    setShowModalEmployeeAddSelect(!showModalEmployeeAddSelect);
  };

  /* ========= END EMPLOYEE WITH SELECT IN TEAM ================ */

  const deleteSkill = skillId => {
    dispatch(DELETE_SKILL({ skillId: skillId, cartographyId: cartography.id }))
    setShowModalSkillRemove(!showModalSkillRemove);
  };

  const editSkill = (e, v) => {

    const data = {
      pole: v.pole,
      task: v.task,
      totalVeryGood: 0,
      totalGood: 0,
      totalMedium: 0,
      totalBad: 0,
      risk: 3,
      skillActivity: v.activity ? `/api/skill_activities/${v.activity}` : undefined,
      skillDomain: v.domain ? `/api/skill_domains/${v.domain}`: undefined,
      requiredLevel: 2,
      skillId: v.skillId,
      cartographyId: cartography.id
    }
    dispatch(UPDATE_SKILL(data))

    setShowModalSkillEdit(!showModalSkillEdit);
  };

  const addSkill = (pole, skillsSelection, domain, activity) => {

    const employeesArray = map(cartography.employees, employee => {
      return employee.id
    })

    map( skillsSelection, skillSelection => {
      const data = {
        pole: pole,
        task: skillSelection.title,
        skillActivity: `/api/skill_activities/${activity}`,
        skillDomain: `/api/skill_domains/${domain}`,
        totalVeryGood: 0,
        totalGood: 0,
        totalMedium: 0,
        totalBad: 0,
        risk: 3,
        requiredLevel: 2,
        cartography: `/api/cartographies/${cartography.id}`,
        cartographyId: cartography.id,
        employeesId: employeesArray
      }
      dispatch(ADD_SKILL(data))
    })

    setShowModalSkillAdd(!showModalSkillAdd);
  };

  useEffect(() => {
    dispatch(CARTOGRAPHY({ cartographyId: routeId }))
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <CartographyConfigurationRemoveEmployeeModal isOpen={showModalEmployeeRemove} toggle={toggleModalEmployeeRemove} deleteEmployee={deleteEmployee} />
        <ClientEmployeeEditModal isOpen={showModalEmployeeEdit} toggle={toggleModalEmployeeEdit} editEmployee={editEmployee} clientEmployees={client.clientEmployees} />

        <CartographyConfigurationAddEmployeeSelectModal isOpen={showModalEmployeeAddSelect} toggle={toggleModalEmployeeAddSelect} addEmployeeSelect={addEmployeeSelect} client={client} employeesInCartography={cartography.employees} />

        <CartographyConfigurationRemoveSkillModal isOpen={showModalSkillRemove} toggle={toggleModalSkillRemove} deleteSkill={deleteSkill} />
        <CartographyConfigurationEditSkillModal isOpen={showModalSkillEdit} toggle={toggleModalSkillEdit} editSkill={editSkill} />
        <CartographyConfigurationAddSkillModal isOpen={showModalSkillAdd} toggle={toggleModalSkillAdd} addSkill={addSkill} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link={{ pathname: "/ma-cartographie" }} title="Retour à la cartographie" />
            <TitleBanner title="Configuration d'une cartographie" />
            <SuccessErrorAlert error={error} success={success} />

            <Row className="mb-4 align-items-center">
              <Col lg="6">
                <h5 className="font-size-18 color-secondary"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des collaborateurs de la cartographie</h5>
              </Col>

              <Col lg="6">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={toggleModalEmployeeAddSelect}
                  data-toggle="modal"
                  data-target="modal-add"
                >
                  Ajouter un nouveau collaborateur à la cartographie
                </button>
              </Col>
            </Row>

            <Row>
              <Col lg="12">

                {/* =============== GESTION DES EMPLOYEES ================= */}

                {isLoading ? (
                  <Row className="justify-content-center my-5">
                    <DotLoader size={40} color="#597887" />
                  </Row>
                ) : (
                  <Row>
                    {map(cartography.employees, (employee, key) => (
                      <CartographyConfigurationEmployeeCard
                        employee={employee}
                        toggleModalEmployeeRemove={toggleModalEmployeeRemove}
                        toggleModalEmployeeEdit={toggleModalEmployeeEdit}
                        key={"_employee_" + key}
                      />
                    ))}
                  </Row>
                  )}

              </Col>
            </Row>

            <Row className="mb-4 mt-5 align-items-center">
              <Col lg="6">
                <h5 className="font-size-18 color-secondary"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des compétences de la cartographie</h5>
              </Col>

              <Col lg="6">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={toggleModalSkillAdd}
                  data-toggle="modal"
                  data-target="modal-add"
                >
                  Ajouter une nouvelle compétence
                </button>
              </Col>
            </Row>

            <Row>
              <Col lg="7">

                {/* =============== GESTION DES COMPETENCES ================= */}

                <Card>
                  <CardBody>

                    <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '800px' }}>

                      {isEmpty(cartography.skills) && <p>Aucune compétence actuellement</p>}

                      {map(cartography.skills, (skill, key) => (
                        <li className="feed-item" key={"_skill_" + key}>

                          <p className="mb-1 font-size-16 text-primary">
                            {skill.hasOwnProperty('pole') && skill.pole}
                            <i className="uil uil-angle-right-b mx-2 color-text"></i>
                            {skill.hasOwnProperty('skillActivity') && skill.skillActivity.name}
                            <i className="uil uil-angle-right-b mx-2 color-text"></i>
                            {skill.hasOwnProperty('skillDomain') && skill.skillDomain.name}
                          </p>

                          <p className="mt-0 mb-0 color-text">{skill.task}</p>

                          <div>
                            <button
                              type="button"
                              className="btn btn-link waves-effect px-0 text-info"
                              onClick={ () => toggleModalSkillEdit(skill) }
                              data-toggle="modal"
                              data-target="modal-skill-edit"
                            >
                              <i className="uil uil-pen font-size-18"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-link waves-effect px-3 text-danger"
                              onClick={ () => toggleModalSkillRemove(skill) }
                              data-toggle="modal"
                              data-target="modal-skill-delete"
                            >
                              <i className="uil uil-trash-alt font-size-18"></i>
                            </button>
                          </div>
                        </li>
                      ))}

                    </SimpleBar>

                  </CardBody>
                </Card>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }
}

export default ClientCartographyConfiguration
