import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    rememberme: false,
    error: "",
    isLoading: false,
    success: ''
}

const loginSlice = createSlice({
    name: "login",
    initialState,

    reducers: {

        LOGIN_USER(state) {
            state.isLoading = true
        },

        EDIT_USER(state, action) {
            state.user = action.payload
        },

        LOGIN_REMEMBER(state, action) {
            state.rememberme = action.payload  // payload: { user, history }
        },

        LOGIN_SUCCESS (state) {
            state.isLoading = false  // payload: user
            state.error = ""
        },

        LOGIN_MESSAGE_SUCCESS (state, action) {
            state.success = action.payload
        },

        LOGIN_RESET_SUCCESS (state, action) {
            state.success = action.payload
        },

        LOCK_SCREEN_USER (state) {
            return { ...state }  // payload: { history }
        },

        LOGOUT_USER (state) {
            state.user = {}
        },

        API_ERROR (state, action) {
            return { ...state, error: action.payload, loading: false  }  // payload: error
        }
    }
});

const { reducer, actions } = loginSlice;

export const { LOGIN_USER,  EDIT_USER, LOGIN_REMEMBER, LOGIN_SUCCESS, LOGIN_MESSAGE_SUCCESS, LOGIN_RESET_SUCCESS, LOGOUT_USER, LOCK_SCREEN_USER, API_ERROR } = actions;

export default reducer;

export const selectLogin = (state) => state.login;
