import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNT,
  CLIENTS_ACCOUNT,
  CLIENT_ACCOUNT,
  CLIENT_ACCOUNT_INTERVENTION,
  ADD_ACCOUNT,
  ADD_CLIENT_ACCOUNT,
  UPDATE_CLIENT_ACCOUNT,
  DELETE_CLIENT_ACCOUNT,
  CLIENT_ACCOUNT_SUCCESS,
  ADD_CLIENT_ACCOUNT_SUCCESS,
  CLIENT_ACCOUNT_ERROR
} from "./clientAccountSlice"

import {INTERVENTION} from "store/intervention/interventionSlice";
import { TRAINING_PLAN } from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

import { getClientsAccount, getClientAccount, postClientAccount, putClientAccount, deleteClientAccount } from "helpers/request_helper"


function* viewClientsAccount() {
  try {
    const response = yield call(getClientsAccount)
    yield put(GET_CLIENT_ACCOUNTS(response['hydra:member']))
    yield put(CLIENT_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_ACCOUNT_ERROR(error.message))
  }
}

function* viewClientAccount({ payload: { clientId } }) {
  try {
    const response = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(response))
    yield put(CLIENT_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_ACCOUNT_ERROR(error.message))
  }
}

function* viewClientAccountIntervention({ payload: { clientId } }) {
  try {
    const response = yield call(getClientAccount, clientId)
    yield put(GET_CLIENT_ACCOUNT(response))

    if (response.intervention) {
      yield put(INTERVENTION({ interventionId: response.intervention.id }))

      if (response.intervention.cartography && response.intervention.cartography.trainingPlans[0]) {
        yield put (TRAINING_PLAN({trainingPlanId: response.intervention.cartography.trainingPlans[0].id}))
      }
    }

    yield put(CLIENT_ACCOUNT_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_ACCOUNT_ERROR(error.message))
  }
}

function* addNewClientAccount({ payload: {email, roles, password, status, firstname, lastname, client, history } }) {
  try {

    const data = {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname,
      roles: roles,
      status: status,
      client: client,
      isModifyPassword: false
    }

    const response = yield call(postClientAccount, data)
    yield put(ADD_CLIENT_ACCOUNT(response))

    history.push({ pathname: "/details-organisation", state: response.client.id })
    yield put(ADD_CLIENT_ACCOUNT_SUCCESS(`Ajout de l'organisation "${response.client.entName}" avec succès (un mail avec les identifiants a été transmis)`))
  } catch (error) {
    if (error.response.data.violations) {
      yield put(CLIENT_ACCOUNT_ERROR(error.response.data.violations[0].message))
    } else if (error.response.data['hydra:description']) {
      yield put(CLIENT_ACCOUNT_ERROR(error.response.data['hydra:description']))
    } else {
      yield put(CLIENT_ACCOUNT_ERROR(error.message))
    }
  }
}

function* editClientAccount({ payload: {
  clientId, clientFunction, email, phone, entName, entAddress, entZipCode, entCity, entCodeNaf, entCodeSiret,
  entCodeTva, entCountry, entCommercialCoverage, contactFirstname, contactLastname, entEffectif, locality, societyType
} }) {
  try {
    const response = yield call(putClientAccount, {
      clientId: clientId,
      clientFunction: clientFunction,
      phone: phone,
      entName: entName,
      entAddress: entAddress,
      locality: locality,
      entZipCode: entZipCode,
      entCity: entCity,
      entCodeNaf: entCodeNaf,
      entCodeSiret: entCodeSiret,
      entCodeTva: entCodeTva,
      entCountry: entCountry,
      entCommercialCoverage: entCommercialCoverage,
      entEffectif: entEffectif,
      societyType: societyType,
      contactFirstname: contactFirstname,
      contactLastname: contactLastname,
      email: email
    })
    yield put(GET_CLIENT_ACCOUNT(response))
    yield put(ADD_CLIENT_ACCOUNT_SUCCESS("Les informations de l'organisation ont bien été modifiées"))
  } catch (error) {
    yield put(CLIENT_ACCOUNT_ERROR(error))
  }
}

function* removeClientAccount({ payload: { clientId } }) {
  try {
    yield call(deleteClientAccount, clientId)

    const response = yield call(getClientsAccount)
    yield put(GET_CLIENT_ACCOUNTS(response['hydra:member']))

    yield put(ADD_CLIENT_ACCOUNT_SUCCESS(`Suppression de l'organisation avec succès !!`))
  } catch (error) {
    if (error.response.status === 500) {
      yield put(CLIENT_ACCOUNT_ERROR("L'organisation ne peut pas être supprimée !"))
    } else {
      yield put(CLIENT_ACCOUNT_ERROR(error.message))
    }
  }
}


export function* watchProfile() {
  yield takeEvery(CLIENTS_ACCOUNT.type, viewClientsAccount)
  yield takeEvery(CLIENT_ACCOUNT.type, viewClientAccount)
  yield takeEvery(CLIENT_ACCOUNT_INTERVENTION.type, viewClientAccountIntervention)
  yield takeEvery(ADD_ACCOUNT.type, addNewClientAccount)
  yield takeEvery(UPDATE_CLIENT_ACCOUNT.type, editClientAccount)
  yield takeEvery(DELETE_CLIENT_ACCOUNT.type, removeClientAccount)
}


export default function* ClientAccountSaga() {
  yield all([fork(watchProfile)])
}
