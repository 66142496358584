import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

// Import menuDropdown
//import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "assets/images/logo-firmain-dark.png"
import logoWhite from "assets/images/logo-firmain-dark.png"
import logoDark from "assets/images/logo-firmain-dark.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { selectLayout, TOGGLE_LEFT_MENU } from "store/layout/layoutSlice"

const Header = props => {

  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);

  function toggleFullscreen() {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      /* alternative standard method */

      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="17" />
              </span>
            </Link>

            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoWhite} alt="" height="19" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={ () => dispatch(TOGGLE_LEFT_MENU(!layout.leftMenu)) }
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

        </div>

        <div className="d-flex">

          <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              onClick={() => {
                toggleFullscreen();
              }}
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path"></i>
            </button>
          </div>

          <NotificationDropdown />

          <ProfileMenu />

          {/*
          <div className="dropdown d-inline-block">
            <button
              onClick={() => {
                dispatch(SHOW_RIGHT_SIDEBAR (!layout.showRightSidebar))
              }}
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="uil-cog"></i>
            </button>
          </div>
          */}

        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Header)

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}
