import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import { withRouter } from "react-router-dom"

// Other Layout related Component
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar"

// Redux
import { useDispatch, useSelector } from "react-redux";
import {selectLayout} from "store/layout/layoutSlice";
import {CHANGE_LAYOUT, CHANGE_LAYOUT_WIDTH, CHANGE_TOPBAR_THEME} from "store/layout/layoutSlice";

const Layout = props => {

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);

  useEffect(() => {
    if (layout.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = props.location.pathname

    let currentage = title.charAt(1).toUpperCase() + title.slice(2)
    document.title = currentage + " | Firmain"

    dispatch(CHANGE_LAYOUT("horizontal"))

    if (layout.topbarTheme) {
      dispatch(CHANGE_TOPBAR_THEME(layout.topbarTheme))
    }

    if (layout.layoutWidth) {
      dispatch(CHANGE_LAYOUT_WIDTH(layout.layoutWidth))
    }
  }, [dispatch, layout.isPreloader, layout.layoutWidth, layout.topbarTheme, props.location.pathname])

  /**
   * Opens the menu - mobile
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  return (
    <React.Fragment>
      <div id="preloader">
          <div id="status">
              <div className="spinner">
                  <i className="uil-shutter-alt spin-icon"></i>
              </div>
          </div>
      </div>

      <div id="layout-wrapper">
        <header id="page-topbar">
          <Header
            theme={layout.topbarTheme}
            isMenuOpened={layout.isMenuOpened}
            openLeftMenuCallBack={openMenu}
          >
          </Header>
          <Navbar menuOpen={layout.isMenuOpened} />
        </header>
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>

      {layout.showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  )

}

export default withRouter(Layout)

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
}
