import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  clients: [],
  client: {},
  success: "",
  successForm: "",
  error: "",
  isLoading: false,
  showModal: false
}

const clientAccountSlice = createSlice({
  name: "clientAccount",
  initialState,

  reducers: {

    CLIENTS_ACCOUNT (state) {
      return { ...state, successForm: ""  }
    },

    CLIENT_ACCOUNT (state) {
      return { ...state }
    },

    CLIENT_ACCOUNT_FORMATION (state) {
      return { ...state }
    },

    CLIENT_ACCOUNT_INTERVENTION (state) {
      return { ...state }
    },

    ADD_ACCOUNT (state) {
      state.isLoading = true
    },

    GET_CLIENT_ACCOUNTS (state, action) {
      state.isLoading = true
      state.clients = action.payload  // payload: clients
    },

    GET_CLIENT_ACCOUNT (state, action) {
      state.isLoading = true
      state.client = action.payload  // payload: client
    },

    ADD_CLIENT_ACCOUNT (state, action) {
      state.client = action.payload
    },

    UPDATE_CLIENT_ACCOUNT (state) {
      state.isLoading = true
    },

    DELETE_CLIENT_ACCOUNT (state) {
      state.isLoading = true
      state.client = {}
    },

    CLIENT_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADD_CLIENT_ACCOUNT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.successForm = action.payload
    },

    CLIENT_ACCOUNT_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false  }
    },
  }
});

const { reducer, actions } = clientAccountSlice;

export const {
  CLIENTS_ACCOUNT,
  CLIENT_ACCOUNT,
  CLIENT_ACCOUNT_INTERVENTION,
  CLIENT_ACCOUNT_FORMATION,
  ADD_ACCOUNT,
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNT,
  ADD_CLIENT_ACCOUNT,
  UPDATE_CLIENT_ACCOUNT,
  DELETE_CLIENT_ACCOUNT,
  CLIENT_ACCOUNT_SUCCESS,
  ADD_CLIENT_ACCOUNT_SUCCESS,
  CLIENT_ACCOUNT_ERROR
} = actions;

export default reducer;

export const selectClientAccount = (state) => state.clientAccount;
