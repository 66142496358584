import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  trainingPlans: [],
  trainingPlan: {},
  trainingPlanAction: {},
  trainingPlanActionFilterFormation: [],
  trainingPlanActionFilterFormationInternal: [],
  paymentFoundingTypes: [],
  moyFormationsFinishedWithCertificate: 0,
  trainingPlanAnalytics: {
    totalFormationsFinished: 0,
    averageDuration: 0,
    totalDurationFormationFinished: 0,
    formationsFinished: 0,
    formationsPlannedAndFinished: []
  },
  skillsWorked: { series: [], labels: [] },
  formationAccessLevel: {
    totalEmployeeWithFormationsFinishedLessThanOneYear: 0,
    totalEmployeeWithFormationsFinishedNotLessThanOneYear: 0,
    totalEmployees: 0
  },
  nbrFormationsCommittedInTheSixMouth: {data: [], categories: []},
  costFormationsFinishLastSixMouth: {data: [], categories: []},
  success: "",
  error: "",
  isLoading: false
}

const trainingPlanSlice = createSlice({
  name: "trainingPlan",
  initialState,

  reducers: {

    TRAINING_PLANS (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    TRAINING_PLAN (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    TRAINING_PLAN_BY_CLIENT (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    TRAINING_PLAN_ACTION (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    TRAINING_PLANS_CARTOGRAPHY (state, action) {
      state.trainingPlans = action.payload
    },

    TRAINING_PLANS_ANALYTICS (state) {
      return { ...state, isLoading: true, success: "" }
    },

    GET_TRAINING_PLANS (state, action) {
      state.trainingPlans = action.payload
    },

    GET_TRAINING_PLAN (state, action) {
      state.trainingPlan = action.payload
    },

    GET_TRAINING_PLANS_CARTOGRAPHY (state, action) {
      state.trainingPlans = action.payload  // payload: training plans from a cartography
    },

    GET_TRAINING_PLAN_ACTION (state, action) {
      state.trainingPlanAction = action.payload
    },

    ADD_TRAINING_PLAN (state) {
      state.isLoading = true
    },

    ADD_TRAINING_PLAN_ACTION (state) {
      state.isLoading = true
    },

    ADD_NEW_TRAINING_PLAN (state, action) {
      state.trainingPlan = action.payload
    },

    UPDATE_TRAINING_PLAN_ACTION (state) {
      state.isLoading = true
    },

    UPDATE_TRAINING_PLAN_VALIDATE (state) {
      state.isLoading = true
    },

    DELETE_TRAINING_PLAN_ACTION (state) {
      state.isLoading = true
      state.trainingPlanAction = {}
    },

    TRAINING_PLAN_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    TRAINING_PLAN_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },

    /* ========== Payment founding types ============= */

    TRAINING_PLAN_ACTION_FILTER_FORMATION (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_TRAINING_PLAN_ACTION_FILTER_FORMATION (state, action) {
      state.trainingPlanActionFilterFormation = action.payload  // formation filtrées en fonction d'une compétence
    },

    GET_TRAINING_PLAN_ACTION_FILTER_FORMATION_INTERNAL (state, action) {
      state.trainingPlanActionFilterFormationInternal = action.payload  // formation interne filtrées en fonction d'une compétence
    },

    /* ========== Payment founding types ============= */

    PAYMENT_FOUNDING_TYPES (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    ADD_PAYMENT_FOUNDING_TYPES (state, action) {
      state.paymentFoundingTypes = action.payload  // payload: payment founding types
    },

    /* ========== Devis Formation ============= */

    TRAINING_PLAN_ACTION_DEVIS_FORMATION (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    /* ========== descriptif formation ============= */

    TRAINING_PLAN_ACTION_RESUME_REQUEST (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    /* ========= DASHBOARD & ANALYTICS ============== */

    GET_AVERAGE_FORMATIONS_FINISHED_WITH_CERTIFICATE (state, action) {
      state.moyFormationsFinishedWithCertificate = action.payload
    },

    GET_TRAINING_PLANS_ANALYTICS (state, action) {
      state.trainingPlanAnalytics = action.payload
    },

    GET_SKILLS_WORKED (state, action) {
      state.skillsWorked = action.payload
    },

    GET_FORMATION_ACCESS_LEVEL (state, action) {
      state.formationAccessLevel = action.payload
    },



    GET_FORMATIONS_COMMITTED_IN_THE_SIX_MOUTH (state, action) {
      state.nbrFormationsCommittedInTheSixMouth = action.payload
    },

    GET_COST_FORMATIONS_FINISHED_LAST_SIX_MOUTH (state, action) {
      state.costFormationsFinishLastSixMouth = action.payload
    },

  }
});

const { reducer, actions } = trainingPlanSlice;

export const {
  TRAINING_PLANS,
  TRAINING_PLAN,
  TRAINING_PLAN_BY_CLIENT,
  TRAINING_PLAN_ACTION,
  TRAINING_PLANS_CARTOGRAPHY,
  TRAINING_PLANS_ANALYTICS,
  GET_TRAINING_PLANS,
  GET_TRAINING_PLAN,
  GET_TRAINING_PLANS_CARTOGRAPHY,
  GET_TRAINING_PLAN_ACTION,
  ADD_TRAINING_PLAN,
  ADD_TRAINING_PLAN_ACTION,
  ADD_NEW_TRAINING_PLAN,
  UPDATE_TRAINING_PLAN_ACTION,
  UPDATE_TRAINING_PLAN_VALIDATE,
  DELETE_TRAINING_PLAN_ACTION,
  TRAINING_PLAN_SUCCESS,
  TRAINING_PLAN_ERROR,
  TRAINING_PLAN_ACTION_FILTER_FORMATION,
  GET_TRAINING_PLAN_ACTION_FILTER_FORMATION,
  GET_TRAINING_PLAN_ACTION_FILTER_FORMATION_INTERNAL,
  PAYMENT_FOUNDING_TYPES,
  ADD_PAYMENT_FOUNDING_TYPES,
  TRAINING_PLAN_ACTION_DEVIS_FORMATION,
  TRAINING_PLAN_ACTION_RESUME_REQUEST,
  GET_AVERAGE_FORMATIONS_FINISHED_WITH_CERTIFICATE,
  GET_TRAINING_PLANS_ANALYTICS,
  GET_SKILLS_WORKED,
  GET_FORMATION_ACCESS_LEVEL,
  GET_FORMATIONS_COMMITTED_IN_THE_SIX_MOUTH,
  GET_COST_FORMATIONS_FINISHED_LAST_SIX_MOUTH
} = actions;

export default reducer;

export const selectTrainingPlan = state => state.trainingPlan;
