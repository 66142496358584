import React from "react"
import { Container, Row, Col } from "reactstrap"

// import images
import logoBretagne from "assets/images/logo-bretagne.jpg"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row className="align-items-center">

            <Col md={3}>
              <div className="text-sm d-none d-sm-block">
                Réalisé par <a href="https://insaniam.com" target="_blank" rel="noreferrer">Insaniam</a>
              </div>
              <div className="text-sm d-none d-sm-block mt-3">
                Firmain
              </div>
            </Col>

            <Col md={6}>
              <div className="text-sm-center d-none d-sm-block mb-3">
                <a href="https://firmain.fr/politique-de-confidentialite" className="mx-3" target="_blank" rel="noreferrer">Politique de confidentialité</a>
              </div>
              <div className="text-sm-center d-none d-sm-block">
                <a href="https://firmain.fr/mentions-legales" className="mx-3" target="_blank" rel="noreferrer">Mentions légales</a>
              </div>
            </Col>

            <Col md={3}>
              <div className="text-sm-center d-none d-sm-block">
                <img src={logoBretagne} alt="logo bretagne" height="80" className="mb-2" />
                <p>Avec le soutien de la Région Bretagne</p>
              </div>
            </Col>

          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
