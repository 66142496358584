import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  interventions: [],
  intervention: {},
  interventionDate: {},
  success: "",
  error: "",
  isLoading: false
}

const interventionSlice = createSlice({
  name: "intervention",
  initialState,

  reducers: {

    INTERVENTIONS (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    INTERVENTION (state) {
      return { ...state, isLoading: true, success: ""  }
    },

    GET_INTERVENTIONS (state, action) {
      state.interventions = action.payload  // payload: interventions
    },

    GET_INTERVENTION (state, action) {
      state.intervention = action.payload  // payload: intervention
    },

    ADD_INTERVENTION (state) {
      state.isLoading = true
    },

    ADD_NEW_INTERVENTION (state, action) {
      state.intervention = action.payload
    },

    UPDATE_INTERVENTION_DIAGNOSTIC (state) {
      state.isLoading = true
    },

    DELETE_INTERVENTION (state) {
      state.isLoading = true
    },

    ARCHIVED_INTERVENTION (state) {
      state.isLoading = true
    },

    INTERVENTION_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    INTERVENTION_ERROR (state, action) {
      return { ...state, error: action.payload, isLoading: false  }
    },
  }
});

const { reducer, actions } = interventionSlice;

export const {
  INTERVENTIONS,
  INTERVENTION,
  GET_INTERVENTIONS,
  GET_INTERVENTION,
  ADD_INTERVENTION,
  ADD_NEW_INTERVENTION,
  UPDATE_INTERVENTION_DIAGNOSTIC,
  DELETE_INTERVENTION,
  ARCHIVED_INTERVENTION,
  INTERVENTION_SUCCESS,
  INTERVENTION_ERROR
} = actions;

export default reducer;

export const selectIntervention = (state) => state.intervention;
