import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { map } from "lodash";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectClientAccount, CLIENT_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";

const FormationInternalIntervenantGestionModalAdd = ({ isOpen, toggle, addFormationInternalIntervenant, formationInternal }) => {

  const dispatch = useDispatch()
  const { client } = useSelector(selectClientAccount);

  useEffect(() => {
    if (formationInternal.hasOwnProperty('client')) {
      dispatch(CLIENT_ACCOUNT({ clientId: formationInternal.client.id }))
    }
  }, [dispatch, formationInternal])

  const [intervenantSelect, setIntervenantSelect] = useState("")

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Ajouter un intervenant à la formation interne ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Choisir un intervenant dans la liste déroulante :</p>

        <select
          //type="select"
          name="intervenantSelect"
          className="form-control"
          onChange={event => setIntervenantSelect(event.target.value)}
        >
          <option key="null">Choisir ...</option>

          {map(client.clientEmployees, intervenant => (
            !intervenant.isArchived &&
            <option key={intervenant.id} value={intervenant.id}>
              {intervenant.user.firstname} {intervenant.user.lastname}
            </option>
          ))}

        </select>

      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={() => addFormationInternalIntervenant(intervenantSelect)}>Ajouter l'intervenant</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default FormationInternalIntervenantGestionModalAdd

FormationInternalIntervenantGestionModalAdd.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
