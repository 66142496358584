import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectFormation, FORMATION, UPDATE_FORMATION, FORMATION_ERROR, FORMATION_STATUS, FORMATION_DATE, ADD_FORMATION_DATE, DELETE_FORMATION_DATE} from "store/formation/formationSlice";
import {selectSkill, SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

// Components
import TitleBanner from "components/Common/TitleBanner";
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import FormationModalCreateDate from "./FormationModalCreateDate";
import FormationModalRemoveDate from "./FormationModalRemoveDate";

const FormationEdit = ({history, isRole, location}) => {

  const dispatch = useDispatch()
  const {formation, formationStatus, error, successForm} = useSelector(selectFormation);
  const {skillDomains} = useSelector(selectSkill);

  const [title, setTitle] = useState("")
  const [skillDomainId, setSkillDomainId] = useState(formation.hasOwnProperty('skillDomain') ? formation.skillDomain.id : "")
  const [targetAudience, setTargetAudience] = useState("")
  const [prerequisite, setPrerequisite] = useState("")
  const [educationalMethods, setEducationalMethods] = useState("")
  const [educationalTechnics, setEducationalTechnics] = useState("")
  const [duration, setDuration] = useState("")
  const [durationUnit, setDurationUnit] = useState("heures")
  const [modalities, setModalities] = useState("")
  const [sanction, setSanction] = useState("")
  const [evaluation, setEvaluation] = useState("")
  const [necessaryMaterial, setNecessaryMaterial] = useState("")
  const [accessDelay, setAccessDelay] = useState("")
  const [disabledAccessibility, setDisabledAccessibility] = useState("")
  const [satisfactionRate, setSatisfactionRate] = useState("")
  const [successRate, setSuccessRate] = useState("")
  const [market, setMarket] = useState("")
  const [equivalences, setEquivalences] = useState("")
  const [priceHT, setPriceHT] = useState("")
  const [cpfEligible, setCpfEligible] = useState(false)
  const [cpfCode, setCpfCode] = useState("")
  const [cpfDesignation, setCpfDesignation] = useState("")
  const [formationStatuses, setFormationStatuses] = useState(formation.hasOwnProperty('formationStatus') ? formation.formationStatus.id : "")
  const [description, setDescription] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [objectifs, setObjectifs] = useState("")    // si problème, remplacer \n par &#13;&#10;
  const [programme, setProgramme] = useState("")
  const [benefices, setBenefices] = useState("") // si problème, remplacer \n par// si problème, remplacer \n par &#13;&#10;

  /* ====================================== */

  // récupération de l'id passé dans la route
  const routeId = location.state

  function handleValidSubmitFormation() {

    const activeFormationChange = () => {
      if (formationStatuses === '1') {
        return true
      } else if (formationStatuses === '2') {
        return false
      } else if (formationStatuses === '3') {
        return false
      } else {
        return undefined
      }
    }

    const data = {
      title: title,
      description: description,
      targetAudience: targetAudience,
      duration: Number(duration),
      durationUnit: durationUnit,
      priceHT: Number(priceHT),
      priceTTC: Number(priceHT) + Number(priceHT * 0.2),
      accessDelay: accessDelay,
      disabledAccessibility: disabledAccessibility,
      modalities: modalities,
      evaluation: evaluation,
      necessaryMaterial: necessaryMaterial,
      satisfactionRate: Number(satisfactionRate),
      successRate: Number(successRate),
      equivalences: equivalences,
      cpfEligible: cpfEligible,
      cpfCode: cpfEligible ? cpfCode : null,
      cpfDesignation: cpfEligible ? cpfDesignation : null,
      objectifs: objectifs,
      prerequisite: prerequisite,
      programme: programme,
      educationalMethods: educationalMethods,
      educationalTechnics: educationalTechnics,
      market: market,
      sanction: sanction,
      benefices: benefices,
      activeFormation: activeFormationChange(),
      formationStatus: formationStatuses ? `/api/formation_statuses/${formationStatuses}` : undefined,
      formationTrainingStatus: "/api/formation_training_statuses/1",
      formationId: routeId,
      skillDomain: skillDomainId ? `/api/skill_domains/${skillDomainId}` : undefined,
      role: "admin",
      history: history
    }
    dispatch(UPDATE_FORMATION(data))
  }

  function handleInvalidSubmitFormation() {
    dispatch(FORMATION_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  /* ============= Modal Create / Delete Formation Date ============== */

  const [showModalCreateFormationDate, setShowModalCreateFormationDate] = useState(false);
  const [showModalRemoveFormationDate, setShowModalRemoveFormationDate] = useState(false);

  const toggleModalCreateFormationDate = () => {
    setShowModalCreateFormationDate(!showModalCreateFormationDate);
  };

  const toggleModalRemoveFormationDate = (formationDateId) => {
    if (!showModalRemoveFormationDate) dispatch(FORMATION_DATE({
      formationDateId: formationDateId
    }))
    setShowModalRemoveFormationDate(!showModalRemoveFormationDate);
  };

  const createFormationDate = (formationId, formationStart, formationEnd, place) => {

    const data = {
      formationStart: formationStart,
      formationEnd: formationEnd,
      place: place,
      formation: `/api/formations/${formationId}`,
      formationId: formation.id
    }

    dispatch(ADD_FORMATION_DATE(data))

    setShowModalCreateFormationDate(!showModalCreateFormationDate);
  };

  const removeFormationDate = (formationDateId) => {
    dispatch(DELETE_FORMATION_DATE({
      formationDateId: formationDateId,
      formationId: formation.id
    }))
    setShowModalRemoveFormationDate(!showModalRemoveFormationDate);
  };

  /* ======================================================== */

  useEffect(() => {
    dispatch(FORMATION({formationId: routeId}))
    dispatch(SKILL_DOMAIN_ACTIVITY())
    dispatch(FORMATION_STATUS())
    setCpfEligible(formation.cpfEligible)
    setTitle(formation.title)
    setDescription(formation.description)
    setTargetAudience(formation.targetAudience)
    setDuration(formation.duration)
    setDurationUnit(formation.durationUnit)
    setPriceHT(formation.priceHT)
    setAccessDelay(formation.accessDelay)
    setDisabledAccessibility(formation.disabledAccessibility)
    setModalities(formation.modalities)
    setEvaluation(formation.evaluation)
    setNecessaryMaterial(formation.necessaryMaterial)
    setSatisfactionRate(formation.satisfactionRate)
    setSuccessRate(formation.successRate)
    setEquivalences(formation.equivalences)
    setCpfCode(formation.cpfCode)
    setCpfDesignation(formation.cpfDesignation)
    setObjectifs(formation.objectifs)
    setPrerequisite(formation.prerequisite)
    setProgramme(formation.programme)
    setEducationalMethods(formation.educationalMethods)
    setEducationalTechnics(formation.educationalTechnics)
    setMarket(formation.market)
    setSanction(formation.sanction)
    setBenefices(formation.benefices)
  }, [
    dispatch,
    routeId,
    formation.cpfEligible,
    formation.title,
    formation.description,
    formation.targetAudience,
    formation.duration,
    formation.priceHT,
    formation.accessDelay,
    formation.disabledAccessibility,
    formation.modalities,
    formation.evaluation,
    formation.necessaryMaterial,
    formation.satisfactionRate,
    formation.successRate,
    formation.equivalences,
    formation.cpfCode,
    formation.cpfDesignation,
    formation.objectifs,
    formation.prerequisite,
    formation.programme,
    formation.educationalMethods,
    formation.educationalTechnics,
    formation.market,
    formation.sanction,
    formation.benefices
  ])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <FormationModalCreateDate isOpen={showModalCreateFormationDate} toggle={toggleModalCreateFormationDate} createFormationDate={createFormationDate}/>
        <FormationModalRemoveDate isOpen={showModalRemoveFormationDate} toggle={toggleModalRemoveFormationDate} removeFormationDate={removeFormationDate}/>

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-formations" title="Retour à la liste des formations" />
            <TitleBanner title="Modification d'une formation" />
            <SuccessErrorAlert error={error} success={successForm} />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitFormation(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitFormation(e, v)}
                    >
                      <Row>
                        <Col xl="12">

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Titre de la formation</h5>
                            <AvField
                              name="title"
                              value={formation.title}
                              className="form-control"
                              placeholder=""
                              type="text"
                              onChange={event => setTitle(event.target.value)}
                              errorMessage="Le titre de la formation est requis"
                              required
                            />
                          </div>

                          <div className=" text-center">
                            <h5 className="font-size-17 mt-5 mb-3">Public visé par la formation</h5>
                            <div className="form-group mb-4">
                              <AvField
                                name="targetAudience"
                                value={formation.targetAudience}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setTargetAudience(event.target.value)}
                                errorMessage="Veuillez renseigner le public visé"
                                required
                              />
                            </div>
                          </div>

                          <div className="px-4 py-4 d-flex justify-content-between text-center">

                            <div>
                              <h5 className="font-size-17 mt-5">Durée de la formation</h5>
                              <div className="mb-4">
                                <AvField
                                  name="duration"
                                  value={formation.duration}
                                  placeholder=""
                                  type="number"
                                  onChange={event => setDuration(event.target.value)}
                                />
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="durationUnit"
                                  errorMessage="Une unité de temps est requise"
                                  onChange={event => setDurationUnit(event.target.value)}
                                >
                                  <option>{formation.durationUnit}</option>
                                  <option key="heures">heures</option>
                                  <option key="jours">jours</option>
                                </AvField>
                              </div>
                            </div>

                            <div>
                              <h5 className="font-size-17 mt-5 mx-5">Prix de vente (HT)</h5>
                              <div className="d-flex mx-4">
                                <AvField
                                  name="priceHT"
                                  value={formation.priceHT}
                                  placeholder=""
                                  type="number"
                                  onChange={event => setPriceHT(event.target.value)}
                                />
                                <p className="mx-2">€</p>
                              </div>
                            </div>

                            <div className="mx-5">
                              <h5 className="font-size-17 mt-5 mb-3">Délais d’accès</h5>
                              <AvField
                                name="accessDelay"
                                value={formation.accessDelay}
                                placeholder=""
                                type="string"
                                onChange={event => setAccessDelay(event.target.value)}
                              />
                            </div>

                            <div className="mx-5">
                              <h5 className="font-size-17 mt-5 mb-3">Accessibilité handicap</h5>
                              <Input type="checkbox"
                                     className="form-check-input"
                                     id="status-input"
                                     onChange={() => disabledAccessibility ? setDisabledAccessibility(false) : setDisabledAccessibility(true)}
                                     checked={disabledAccessibility}
                              />
                            </div>
                          </div>

                          <h5 className="font-size-17 mt-4 mb-3">Description de la formation</h5>

                          <AvField
                            name="description"
                            value={formation.description}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            validate={{
                              required: {value: false},
                              minLength: {value: 0},
                              maxLength: {value: 800}
                            }}
                            onChange={event => setDescription(event.target.value)}
                          />
                          <p className="font-size-15 color-grey mx-2 mt-2">(la description est limitée à 800 caractères)</p>

                          <h5 className="font-size-17 mt-4 mb-3">Objectifs</h5>

                          <AvField
                            name="objectifs"
                            value={formation.objectifs}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setObjectifs(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-5 mb-3">Prérequis de la formation</h5>

                          <AvField
                            name="prerequisite"
                            value={formation.prerequisite}
                            className="form-control"
                            placeholder=""
                            type="textarea"
                            rows="5"
                            onChange={event => setPrerequisite(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-5 mb-3">Programme</h5>

                          {programme && (
                            <ReactQuill theme="snow" value={programme} onChange={setProgramme}/>
                          )}

                          <h5 className="font-size-17 mt-5 mb-4">Pédagogie</h5>

                          <ul className="activity-feed ps-2 my-3">

                            <li className="feed-item" key="_moy_1">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Moyens pédagogiques</h5>
                                <AvField
                                  name="educationalMethods"
                                  value={formation.educationalMethods}
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalMethods(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="_moy_2">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Méthodes pédagogiques</h5>
                                <AvField
                                  name="educationalTechnics"
                                  value={formation.educationalTechnics}
                                  placeholder=""
                                  className="form-control"
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setEducationalTechnics(event.target.value)}
                                />
                              </div>
                            </li>

                          </ul>

                          <h5 className="font-size-17 mt-5 mb-4">Bénéfices</h5>

                          <ul className="activity-feed mb-0 ps-2">

                            <li className="feed-item" key="sanction">
                              <div className="feed-item-list">
                                <h5 className="font-size-17">Sanction de la formation</h5>
                                <AvField
                                  name="sanction"
                                  value={formation.sanction}
                                  placeholder=""
                                  type="string"
                                  onChange={event => setSanction(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="debouche">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Débouchés de la formation</h5>
                                <AvField
                                  name="market"
                                  value={formation.market}
                                  placeholder=""
                                  type="string"
                                  onChange={event => setMarket(event.target.value)}
                                />
                              </div>
                            </li>

                            <li className="feed-item" key="benefices">
                              <div className="feed-item-list">
                                <h5 className="font-size-17 mb-3">Bénéfices</h5>

                                <AvField
                                  name="benefices"
                                  value={formation.benefices}
                                  className="form-control"
                                  placeholder=""
                                  type="textarea"
                                  rows="5"
                                  onChange={event => setBenefices(event.target.value)}
                                />

                              </div>
                            </li>
                          </ul>

                          <h5 className="font-size-17 mt-4 mb-3">Modalités de la formation :</h5>

                          <AvField
                            name="modalities"
                            value={formation.modalities}
                            className="form-control"
                            placeholder=""
                            type="string"
                            onChange={event => setModalities(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-4 mb-3">Modalités d’évaluations :</h5>

                          <AvField
                            name="evaluation"
                            value={formation.evaluation}
                            placeholder=""
                            className="form-control"
                            type="string"
                            onChange={event => setEvaluation(event.target.value)}
                          />

                          <h5 className="font-size-17 mt-4 mb-3">Matériel nécessaire pour réaliser la formation : </h5>

                          <AvField
                            name="necessaryMaterial"
                            value={formation.necessaryMaterial}
                            className="form-control"
                            placeholder=""
                            type="string"
                            onChange={event => setNecessaryMaterial(event.target.value)}
                          />

                          <div className="mb-5 mt-5 d-flex align-items-center">
                            <h5 className="font-size-17 mb-0">Taux de satisfaction de la formation (en %) : </h5>
                            <AvField
                              name="satisfactionRate"
                              value={formation.satisfactionRate}
                              placeholder=""
                              className="mx-3"
                              type="number"
                              validate={{
                                min: {value: 0, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'},
                                max: {value: 100, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'}
                              }}
                              onChange={event => setSatisfactionRate(event.target.value)}
                            />
                          </div>

                          <div className="mb-5 mt-5 d-flex align-items-center">
                            <h5 className="font-size-17">Taux de réussite à la formation (en %) : </h5>
                            <AvField
                              name="successRate"
                              value={formation.successRate}
                              placeholder=""
                              className="mx-3"
                              type="number"
                              validate={{
                                min: {value: 0, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'},
                                max: {value: 100, errorMessage: 'Le taux doit être un pourcentage entre 0 et 100'}
                              }}
                              onChange={event => setSuccessRate(event.target.value)}
                            />
                          </div>

                          <h5 className="font-size-17 mt-4 mb-3">Equivalences de la formation : </h5>

                          <AvField
                            name="equivalences"
                            value={formation.equivalences}
                            placeholder=""
                            className="form-control"
                            type="string"
                            onChange={event => setEquivalences(event.target.value)}
                          />

                          <div className="mb-5 mt-5 d-flex align-items-center">
                            <h5 className="font-size-17 mb-0">Eligible au CPF ?</h5>
                            <Input type="checkbox"
                                   className="form-check-input mx-3"
                                   id="status-input"
                                   onChange={() => cpfEligible ? setCpfEligible(false) : setCpfEligible(true)}
                                   checked={cpfEligible}
                            />
                          </div>

                          {cpfEligible ? (
                            <>
                              <div className="mb-5 d-flex align-items-center">
                                <h5 className="font-size-17">Code CPF de la formation : </h5>
                                <AvField
                                  name="cpfCode"
                                  value={formation.cpfCode}
                                  placeholder=""
                                  className="mx-3"
                                  type="string"
                                  onChange={event => setCpfCode(event.target.value)}
                                />
                              </div>

                              <h5 className="font-size-17 mt-4 mb-3">Désignation CPF de la formation : </h5>

                              <AvField
                                name="cpfDesignation"
                                value={formation.cpfDesignation}
                                placeholder=""
                                className="form-control"
                                type="string"
                                onChange={event => setCpfDesignation(event.target.value)}
                              />

                            </>
                          ) : (
                            <>
                            </>
                          )}

                          <Row className="mt-5">
                            <Col lg={8}>

                              <Card outline color="primary" className="border">
                                <CardBody className="mx-2">

                                  <div className="form-group mb-4">
                                    <h5 className="font-size-17 mt-3 mb-3">Statut de la formation</h5>
                                    <AvField
                                      className="form-select"
                                      type="select"
                                      name="formationStatus"
                                      onChange={event => setFormationStatuses(event.target.value)}
                                      errorMessage="Veuillez choisir un statut pour la formation"
                                    >
                                      {formation.hasOwnProperty('formationStatus') && (
                                        <option key="default" value={formation.formationStatus.id}>{formation.formationStatus.name}</option>
                                      )}
                                      {formationStatus.map(status => (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                      ))}
                                    </AvField>
                                    <p className="mt-3">Choisir un statut de formation dans le menu déroulant</p>
                                  </div>

                                </CardBody>
                              </Card>

                              <div className="form-group mb-4 mt-5">
                                <h5 className="font-size-17 mt-3 mb-3">Domaine de compétence de la formation</h5>
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="skillDomainId"
                                  validate={{required: {value: false}}}
                                  onChange={event => setSkillDomainId(event.target.value)}
                                  errorMessage="Veuillez choisir un domaine de formation"
                                  helpMessage="Choisir un domaine dans le menu déroulant">
                                  >
                                  {formation.hasOwnProperty('skillDomain') ? (
                                    <option key={`domain-${formation.skillDomain.id}`} value={formation.skillDomain.id}>{formation.skillDomain.name}</option>
                                  ) : (
                                    <option key="default" value="">Choisir</option>
                                  )}
                                  {map(skillDomains, (domain, key) => (
                                    <option key={`domain-${domain.id}`} value={domain.id}>{domain.name}</option>
                                  ))}
                                </AvField>
                              </div>

                            </Col>
                          </Row>


                        </Col>
                      </Row>

                      <SuccessErrorAlert error={error} success={successForm} />

                      <div className="d-flex align-items-center mt-5 mb-3">

                        <div className="mx-5">
                          <Button type="submit" color="success" className="w-md">Modifier la formation</Button>
                        </div>

                        <Link to="/gestion-formations" className="btn btn-primary waves-effect waves-light">
                          Retour à la liste des formations
                        </Link>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">

                    <h5 className="font-size-18 color-primary mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i>Ajouter des dates de formation</h5>

                    <p>Sessions de formation :</p>

                    {formation.hasOwnProperty('formationDates') && formation.formationDates.length !== 0 ? (
                      <>
                        {map(formation.formationDates, (formationDate, index) => (

                          <p className="font-size-15 text-muted mb-0">
                            Du <span className="color-table-secondary mx-1">{moment.utc(formationDate.formationStart).locale('fr', localization).format("DD MMMM yyyy à HH:mm")}</span>
                            {" "}au{" "}
                            <span className="color-table-secondary mx-1">{moment.utc(formationDate.formationEnd).locale('fr', localization).format("DD MMMM yyyy à HH:mm")}</span>
                            {" "}à {formationDate.place}
                            <button
                              type="button"
                              className="btn btn-link waves-effect px-2 text-danger"
                              style={{paddingBottom: '12px'}}
                              onClick={() => toggleModalRemoveFormationDate(formationDate.id)}
                              data-toggle="modal"
                              data-target="modal-delete"
                            >
                              <i className="uil uil-trash-alt font-size-18 color-danger"></i>
                            </button>
                          </p>
                        ))}
                      </>
                    ) : (
                      <p>Pas de date actuellement</p>
                    )}


                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light mt-3"
                      onClick={toggleModalCreateFormationDate}
                      id="btn-add-date-formation"
                    >
                      Ajouter une nouvelle date
                    </Button>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(FormationEdit)
