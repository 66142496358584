import React, {useState, useEffect} from "react"
import PropTypes from 'prop-types'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"
import {withRouter, Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"

// Role Verification
import {verifRoleToken} from "helpers/role_helper"

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectProfile, GET_USER_PROFILE} from "store/auth/profile/profileSlice";

const ProfileMenu = () => {

  const userId = localStorage.getItem('id')

  const dispatch = useDispatch()
  const userProfile = useSelector(selectProfile);

  const firstname = localStorage.getItem('firstname') ? localStorage.getItem('firstname').toUpperCase() : 'a';
  const lastname = localStorage.getItem('lastname') ? localStorage.getItem('lastname').toUpperCase(): 'a';
  const nameIcon = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setUsername] = useState("Admin")

  const profile = {error: false, success: false}

  useEffect(() => {
    if (localStorage.getItem("accessJWT")) {
      const obj = localStorage.getItem("accessJWT")
      setUsername(obj.username)
      dispatch(GET_USER_PROFILE(userId))
    }
  }, [dispatch, profile.success, userId])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >

          {/* Avatar circle */}
          <div className="avatar-sm me-4">

            {userProfile.user.hasOwnProperty('image') ? (
              <img
                className="avatar-title rounded-circle bg-white px-1 py-1"
                src={process.env.REACT_APP_URL + "/media/" + userProfile.user.image.filePath}
                alt="Header Avatar"
              />
            ) : (
              <span className="avatar-title rounded-circle bg-white primary color-primary font-size-15">
                {nameIcon}
              </span>
            )}
          </div>

          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <DropdownItem tag="a" href="/profil">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            Mon profil{" "}
          </DropdownItem>

          {verifRoleToken('ROLE_CLIENT') && (
            <DropdownItem tag="a" href="https://firmain.fr/faq-entreprise">
              {" "}
              <i className="uil uil-question-circle font-size-18 align-middle text-muted me-1"></i>
              FAQ{" "}
            </DropdownItem>
          )}
          <DropdownItem tag="a" href="/contact" href="https://firmain.fr/contact" target="_blank" rel="noreferrer">
            {" "}
            <i className="uil uil-phone font-size-18 align-middle text-muted me-1"></i>
            Contact{" "}
          </DropdownItem>

          <div className="dropdown-divider"/>

          <DropdownItem tag="a" href="/lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            Verrouillage écran
          </DropdownItem>

          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>Déconnexion</span>
          </Link>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ProfileMenu))

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}


