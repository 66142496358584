import React from "react"

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const UserFicheMissionCertificateColumn = () => [

  {
    dataField: "formation.title",
    text: "Formation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>{column}</p>
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
  {
    dataField: "certificateExpiration",
    text: "Expiration du certificat / habilitation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (column, row) => {
      if (column) {
        return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>{moment(column).locale('fr', localization).format("DD MMMM yyyy")}</p>
      } else {
        return <p className="mb-0 mt-2" style={{minWidth: '80px'}}>Aucune date renseignée</p>
      }
    },
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },
]

export default UserFicheMissionCertificateColumn
