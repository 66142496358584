import React from "react"
import { Modal } from "reactstrap"

const UserProfileDeleteAvatarModal = ({ isOpen, toggle, deleteAvatarImage }) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Retirer une image ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Voulez-vous vraiment supprimer votre image d'avatar ?</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => deleteAvatarImage()}>Retirer l'image</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default UserProfileDeleteAvatarModal
