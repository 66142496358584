import React, {useState} from "react"
import {Redirect} from "react-router-dom"
import {Col, Container, Row, Card, CardBody} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper"

// Redux
import {useDispatch} from "react-redux";
import { ADD_CARTOGRAPHY_WITH_INTERVENTION } from "store/skillsDevelopmentPlan/cartography/cartographySlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";

const ClientCartographyCreate = ({ isRole, location, history }) => {

  const dispatch = useDispatch()

  const clientId = Number(localStorage.getItem('clientId'))

  const [createdAt, setCreatedAt] = useState("")

  function handleValidCartographySubmit() {

    const data = {
      clientId: clientId,
      createdAt: createdAt,
      history: history
    }
    dispatch(ADD_CARTOGRAPHY_WITH_INTERVENTION(data))
  }

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>


        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn
              link={{ pathname: "/ma-cartographie" }}
              title="Retour aux informations"
            />

            <Row>
              <Col lg="12" className="mt-4">
                <Card>
                  <CardBody>

                    <h5 className="font-size-18 color-secondary mt-3 mb-5">
                      <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                      Création d'une cartographie
                    </h5>

                    <AvForm onValidSubmit={(e, v) => handleValidCartographySubmit(e, v)}>

                      <Row form>
                        <Col className="col-4 mb-3">
                          <AvField
                            name="createdAt"
                            label="Date de création"
                            type="Date"
                            errorMessage="Veuillez renseigner une date"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            validate={{ required: { value: true } }}
                            onChange={event => setCreatedAt(event.target.value)}
                          />
                        </Col>

                      </Row>

                      <button type="submit" className="btn btn-success save-event mt-3">Créer</button>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }
}

export default ClientCartographyCreate
