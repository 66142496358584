import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, Row} from "reactstrap"
import {AvForm} from "availity-reactstrap-validation";
import {Link} from "react-router-dom";
import jwt_decode from "jwt-decode";
import Select from "react-select"

const CartographyConfigurationAddEmployeeSelectModal = ({ isOpen, toggle, client, addEmployeeSelect, employeesInCartography}) => {

  const role = localStorage.getItem("accessJWT") ? jwt_decode(localStorage.getItem("accessJWT")).roles[0] : ''

  const [clientEmployeesId, setClientEmployeesId] = useState([])
  const [optionGroupEmployees, setOptionGroupEmployees] = useState([])

  const handleEmployees = employees => {
    setClientEmployeesId(employees)
  }

  const getEmployees = (employees) => {

    const newOptionGroupEmployees = [ { options: [] }]

    employees.forEach(employee => {
      if (employeesInCartography.some( employeeInCartography => employeeInCartography.clientEmployee.id === employee.id || employee.isArchived)) {
        return null
      } else {
        newOptionGroupEmployees[0].options.push({ label: `${employee.user.firstname} ${employee.user.lastname}`, value: employee.id })
      }
    })
    setOptionGroupEmployees(newOptionGroupEmployees)
  }

  useEffect(() => {
    setClientEmployeesId([])
    if (client.clientEmployees) getEmployees(client.clientEmployees)
  }, [client, toggle])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Ajouter des collaborateurs à la cartographie ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        <AvForm onValidSubmit={() => addEmployeeSelect(clientEmployeesId)}>

          <Row form>

            <Col className="col-12 mb-1">

              <h5 className="font-size-17 mt-4 mb-3">Choisir un ou des collaborateur(s) se trouvant dans la liste de l'ensemble du personnel de l'organisation</h5>

              <Select
                value={clientEmployeesId}
                onChange={handleEmployees}
                isMulti={true}
                options={optionGroupEmployees}
                className="select2-selection"
                placeholder="Choisir ..."
              />

            </Col>

          </Row>

          <Row>
            <Col>
              <div className="text-end mt-4 mb-4">
                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>
                <button type="submit" className="btn btn-success save-event">Ajouter le(s) collaborateur(s)</button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="modal-body text-center mb-0">
                <p className="mb-0 font-size-15">Le collaborateur n'est pas présent dans le menu ?</p>

                {role === 'ROLE_CLIENT' &&
                  <Link to="/liste-collaborateurs" className="font-size-15">Ajouter un nouveau compte collaborateur</Link>
                }

                {role === 'ROLE_ADMIN' &&
                  <Link to={{ pathname: "/gestion-collaborateurs", state: client.id }} className="font-size-15">Ajouter un nouveau compte collaborateur</Link>
                }

              </div>
            </Col>
          </Row>

        </AvForm>

      </ModalBody>

    </Modal>
  )
}

export default CartographyConfigurationAddEmployeeSelectModal

CartographyConfigurationAddEmployeeSelectModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
