import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  adverts: [],
  advert: {},
  success: "",
  error: {},
  isLoading: false,
  successImage: "",
  errorImage: ""
}

const advertSlice = createSlice({
  name: "advert",
  initialState,

  reducers: {

    ADVERTS (state) {
      return { ...state, isLoading: true }
    },

    ADVERT (state) {
      return { ...state, isLoading: true, success: "" }
    },

    GET_ADVERTS (state, action) {
      state.adverts = action.payload  // payload: formations
    },

    GET_ADVERT (state, action) {
      state.advert = action.payload  // payload: formation
    },

    ADD_ADVERT (state) {
      state.isLoading = true
    },

    UPDATE_ADVERT (state) {
      state.isLoading = true
    },

    DELETE_ADVERT (state) {
      state.isLoading = true
      state.advert = {}
    },

    ADD_ADVERT_IMAGE (state) {
      return {...state}
    },

    DELETE_ADVERT_IMAGE (state) {
      return {...state}
    },

    ADVERT_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.success = action.payload
    },

    ADVERT_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false }  // payload: error
    },

    ADVERT_IMAGE_SUCCESS (state, action) {
      state.isLoading = false
      state.successImage = action.payload
    },

    ADVERT_IMAGE_ERROR (state, action) {
      state.errorImage = action.payload
    },
  }
});

const { reducer, actions } = advertSlice;

export const {
  ADVERTS,
  ADVERT,
  GET_ADVERTS,
  GET_ADVERT,
  ADD_ADVERT,
  UPDATE_ADVERT,
  DELETE_ADVERT,
  ADD_ADVERT_IMAGE,
  DELETE_ADVERT_IMAGE,
  ADVERT_SUCCESS,
  ADVERT_ERROR,
  ADVERT_IMAGE_SUCCESS,
  ADVERT_IMAGE_ERROR
} = actions;

export default reducer;

export const selectAdvert = (state) => state.advert;
