import React from "react"
import PropTypes from "prop-types"
import {Modal} from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectFormation} from "store/formation/formationSlice";

const PrestataireModalRemoveFormationLink = ({isOpen, toggle, deleteFormationLink, prestataire}) => {

  const formation = useSelector(selectFormation)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Supprimer le lien ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p className="color-red">Confirmez-vous la suppression du lien entre le prestataire "{prestataire && prestataire.name}" et
          la formation "{formation.formation.title}" ?</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => deleteFormationLink(formation.formation.id)}>Supprimer le lien</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default PrestataireModalRemoveFormationLink

PrestataireModalRemoveFormationLink.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
