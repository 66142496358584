import React, {useEffect, useState} from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import {Link} from "react-router-dom";

import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

import {useDispatch, useSelector} from "react-redux";
import { selectNotification, NOTIFICATIONS, ADMIN_NOTIFICATIONS, DELETE_NOTIFICATION } from "store/notification/notificationSlice";

const NotificationDropdown = () => {

  const userId = localStorage.getItem('id')

  const dispatch = useDispatch()
  const { notifications } = useSelector(selectNotification);

  const [menu, setMenu] = useState(false)

  const deleteNotification = (notificationId) => {
    dispatch(DELETE_NOTIFICATION({ notificationId: notificationId }))
  }

  useEffect(() => {
    if (verifRoleRoute("ROLE_ADMIN")) {
      dispatch(ADMIN_NOTIFICATIONS())
    } else {
      dispatch(NOTIFICATIONS({ userId: userId }))
    }
  }, [dispatch, userId])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell text-white"></i>
          {notifications.length !== 0 && <span className="badge bg-danger rounded-pill">{notifications.length}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">Notifications</h6>
              </Col>
              <div className="col-auto">

              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "350px" }}>

            {notifications.length === 0 && <p className="mx-4 mt-4">Aucune notification</p>}

            { map(notifications, (notification, key) => (
              <div className="text-reset notification-item" key={"_notification_" + key}>
                <div className="d-flex align-items-start">

                  <div className="avatar-xs me-1">
                    <i className="uil uil-exclamation-octagon me-2 font-size-22 color-warning"></i>
                  </div>

                  <div className="flex-1">

                    <Link to={{pathname: notification.link, state: notification.linkState }}>

                      <h6 className="mt-0 mb-1">{notification.title}</h6>

                      <div className="font-size-14 text-muted">
                        <p className="mb-1">{notification.message}</p>
                      </div>

                    </Link>

                    {/*
                    <div className="font-size-14 text-muted">
                      <p className="mb-0 text-primary"><i className="mdi mdi-clock-outline"/>{" "}{moment(notification.createdAt).fromNow()}</p>
                    </div>
                    */}

                    <p className="text-danger font-size-14 mt-1" style={{cursor: 'pointer'}} onClick={ () => deleteNotification(notification.id)}>Supprimer</p>

                  </div>
                </div>
              </div>
            ))}

          </SimpleBar>

          {/*
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
                <i className="uil-arrow-circle-right me-1"></i>
              {" "}View all{" "}
            </Link>
          </div>
          */}

        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default NotificationDropdown
