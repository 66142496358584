import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
  notifications: [],
  notification: [],
  success: "",
  error: {},
  isLoading: false
}

const notificationSlice = createSlice({
  name: "notification",
  initialState,

  reducers: {

    NOTIFICATIONS (state) {
      return { ...state, isLoading: true }
    },

    ADMIN_NOTIFICATIONS (state) {
      return { ...state, isLoading: true }
    },

    ADD_NOTIFICATION (state) {
      return { ...state, isLoading: true }
    },

    /* ============== DELETE NOTIFICATION ================= */

    DELETE_NOTIFICATION (state) {
      return { ...state, isLoading: true }
    },

    DELETE_NOTIFICATION_SUCCESS (state, action) {
      return {
        ...state,
        isLoading: false,
        notifications: state.notifications.filter(
          notification => notification.id.toString() !== action.payload.toString()  // payload: notification
        )
      };
    },

    DELETE_NOTIFICATION_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false }  // payload: error
    },

    /* =================== RESET ===================== */

    RESET_NOTIFICATIONS (state) {
      return { ...state, notifications: [] }
    },

    /* =============================================== */

    NOTIFICATION_SUCCESS (state, action) {
      state.isLoading = false
      state.error = ""
      state.notifications = action.payload  // payload: notifications
    },

    NOTIFICATION_ERROR (state, action) {
      return { ...state, error: action.payload, loading: false }  // payload: error
    },

  }
});

const { reducer, actions } = notificationSlice;

export const {
  NOTIFICATIONS,
  ADMIN_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATIONS,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} = actions;

export default reducer;

export const selectNotification = (state) => state.notification;
