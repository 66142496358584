import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Card, CardTitle, Col, Form, Modal, ModalBody, Row, UncontrolledAlert} from "reactstrap"
import {isEmpty} from "lodash";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";

// REDUX
import {useDispatch} from "react-redux";
import {DELETE_ADVERT_IMAGE, ADD_ADVERT_IMAGE} from "store/advert/advertSlice";

const AdvertImageEditModal = ({ isOpen, toggle, adverts, success }) => {

  const dispatch = useDispatch()

  const [showModalAdvertRemove, setShowModalAdvertRemove] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])

  const advertImageRemove = () => {
    if (adverts[0].hasOwnProperty('image')) {
      dispatch(DELETE_ADVERT_IMAGE({imageId: adverts[0].image.id, advertId: adverts[0].id}))
      setselectedFiles([])
    }

    setShowModalAdvertRemove(!showModalAdvertRemove);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleAddAdvertImage = () => {

    if (selectedFiles.length === 1) {
      if (adverts[0].hasOwnProperty('image')) {
        dispatch(DELETE_ADVERT_IMAGE({imageId: adverts[0].image.id}))

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(ADD_ADVERT_IMAGE({file: formData, advertId: adverts[0].id}))
      } else {

        const formData = new FormData();

        selectedFiles.forEach(file => {
          formData.append('file', file);
        })

        dispatch(ADD_ADVERT_IMAGE({file: formData, advertId: adverts[0].id}))
      }
    }

    setselectedFiles([])
  }

  useEffect(() => {
    setselectedFiles([])
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-xl"
      tabIndex="-1"
      toggle={toggle}
    >

      <div className="modal-header">
        <h5 className="modal-title mt-0">Modifier l'image du bandeau publicitaire ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody>

        { success ? (
          <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
            <i className="uil uil-check me-2"></i> {success}
          </UncontrolledAlert>
        ) : null }

        <CardTitle className="h4">Image du bandeau publicitaire</CardTitle>

          {!isEmpty(adverts) && adverts[0].hasOwnProperty('image') ? (
            <div className="avatar-sm me-4 my-3">
              <img
                className="avatar-title rounded-circle bg-white"
                src={process.env.REACT_APP_URL + "/media/" + adverts[0].image.filePath}
                alt="Header Advert"
              />
            </div>
          ) : (
            <p className="pt-3">Aucune image</p>
          )}


        <p className="card-title-desc">
          {!isEmpty(adverts) && adverts[0].hasOwnProperty('image') ? 'Choisissez une nouvelle image pour votre publicité' : 'Choisissez une image pour votre publicité'}
        </p>

        <Form>
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
            maxFiles={1}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                  </div>
                  <h5 className="mx-3">Glisser une image ici ou cliquer pour télécharger</h5>
                </div>
              </div>
            )}
          </Dropzone>

          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>

          <div className="text-center d-flex justify-content-center mt-4">
            <button
              type="button"
              className="btn btn-success waves-effect waves-light mx-2"
              onClick={handleAddAdvertImage}
            >
              {!isEmpty(adverts) && adverts[0].hasOwnProperty('image') ? "Modifier l'image" : "Enregistrer"}
            </button>

            {!isEmpty(adverts) && adverts[0].hasOwnProperty('image') && (
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light mx-2"
                onClick={advertImageRemove}
              >
                Supprimer l'image
              </button>
            )}
          </div>

        </Form>

      </ModalBody>

    </Modal>
  )
}

export default AdvertImageEditModal

AdvertImageEditModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
