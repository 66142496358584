import React, {useEffect, useRef, useState} from "react"
import {Col, Row, Label} from "reactstrap"

// Editable
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {
  selectTrainingPlan,
  TRAINING_PLAN,
  TRAINING_PLANS_CARTOGRAPHY,
  ADD_TRAINING_PLAN_ACTION,
  DELETE_TRAINING_PLAN_ACTION,
  TRAINING_PLAN_ACTION,
  TRAINING_PLAN_ACTION_DEVIS_FORMATION,
  TRAINING_PLAN_ACTION_RESUME_REQUEST,
  UPDATE_TRAINING_PLAN_ACTION
} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";

import {selectCartography} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {UPDATE_TRAINING_PLAN_VALIDATE} from "store/skillsDevelopmentPlan/trainingPlan/trainingPlanSlice";
import {SKILL_DOMAIN_ACTIVITY} from "store/skillsDevelopmentPlan/skill/skillSlice";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

import ClientFormationPlanReadColumn from "./ClientFormationPlanReadColumn";
import ClientFormationPlanDevisRequestModal from "./ClientFormationPlanDevisRequestModal";
import ClientFormationPlanResumeRequestModal from "./ClientFormationPlanResumeRequestModal";
import ClientFormationPlanResumeRequestInternalModal from "./ClientFormationPlanResumeRequestInternalModal";
import FormationPlanAddActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanAddActionModal";
import FormationPlanRemoveActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanRemoveActionModal";
import FormationPlanValidateModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanValidateModal";
import FormationPlanDevalidateModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDevalidateModal";
import ClientFormationPlanColumn from "pages/Client/SkillsDevelopmentPlan/FormationPlan/ClientFormationPlanColumn";
import FormationPlanDetailFormationModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDetailFormationModal";
import FormationPlanDetailFormationInternalModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDetailFormationInternalModal";
import FormationPlanDomainSelectActionModal from "pages/SkillsDevelopmentPlan/FormationPlan/FormationPlanDomainSelectActionModal";
import DotLoader from "react-spinners/DotLoader";

const ClientFormationPlan = ({history, cartographyId}) => {

  const { ExportCSVButton } = CSVExport;

  const dispatch = useDispatch()

  /* ========== Btn Scroll =========================== */
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  /* ================================================= */

  const { trainingPlan } = useSelector(selectTrainingPlan);
  const { cartography, isLoading } = useSelector(selectCartography);

  const [showModalValidate, setShowModalValidate] = useState(false)
  const [showModalDevalidate, setShowModalDevalidate] = useState(false)
  const [showModalActionAdd, setShowModalActionAdd] = useState(false)
  const [showModalActionDelete, setShowModalActionDelete] = useState(false)
  const [showModalActionDomainSelect, setShowModalActionDomainSelect] = useState(false)
  const [showModalDevisRequest, setShowModalDevisRequest] = useState(false)
  const [showModalResumeRequest, setShowModalResumeRequest] = useState(false)
  const [showModalResumeRequestInternal, setShowModalResumeRequestInternal] = useState(false)
  const [showModalDetailFormation, setShowModalDetailFormation] = useState(false)
  const [showModalDetailFormationInternal, setShowModalDetailFormationInternal] = useState(false)

  const toggleModalValidate = (trainingPlanId) => {
    if (!showModalValidate) dispatch(TRAINING_PLAN({trainingPlanId: trainingPlanId}))
    setShowModalValidate(!showModalValidate);
  };

  const toggleModalDevalidate = (trainingPlanId) => {
    if (!showModalDevalidate) dispatch(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    setShowModalDevalidate(!showModalDevalidate);
  };

  const toggleModalActionAdd = (trainingPlanId) => {
    if (!showModalActionAdd) dispatch(TRAINING_PLAN({ trainingPlanId: trainingPlanId }))
    setShowModalActionAdd(!showModalActionAdd);
  };

  const toggleModalActionDelete = (actionData) => {
    if (!showModalActionDelete) dispatch(TRAINING_PLAN_ACTION({ trainingPlanActionId: actionData.id }))
    setShowModalActionDelete(!showModalActionDelete);
  };

  const validateTrainingPlan = () => {

    dispatch(UPDATE_TRAINING_PLAN_VALIDATE({
      validate: true,
      trainingPlanId: trainingPlan.id,
      interventionId: cartography.intervention.id,
      clientId: cartography.intervention.client.id,
      history: history
    }))

    setShowModalValidate(!showModalValidate);
  };

  const devalidateTrainingPlan = () => {
    dispatch(UPDATE_TRAINING_PLAN_VALIDATE({
      validate: false,
      trainingPlanId: trainingPlan.id,
      interventionId: cartography.intervention.id,
      clientId: cartography.intervention.client.id,
      history: history
    }))

    setShowModalDevalidate(!showModalDevalidate);
  };

  const addAction = (employeeId, trainingPlanId) => {

    const data = {
      employee: `/api/employees/${employeeId}`,
      trainingPlan: `/api/training_plans/${trainingPlanId}`,
      certificateReceived: false,
      finished: false,
      isValidate: false,
      devisRequestSent: false,
      cartographyId: cartographyId,
      isCustomTrainingDate: false,
      trainingPlanId: trainingPlanId
    }

    dispatch(ADD_TRAINING_PLAN_ACTION(data))
    setShowModalActionAdd(!showModalActionAdd);
  };

  const deleteAction = (id, trainingPlanId) => {
    dispatch(DELETE_TRAINING_PLAN_ACTION({ actionId: id, cartographyId: cartographyId, trainingPlanId: trainingPlanId }))
    setShowModalActionDelete(!showModalActionDelete);
  };

  /* =========== Demande de devis ========================================= */

  const toggleModalDevisRequest = (trainingPlanAction) => {
    if (!showModalDevisRequest) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalDevisRequest(!showModalDevisRequest);
  };

  const devisRequest = (trainingPlanActionId, formationTitle, prestataireAccountId, prestataireEmail) => {
    dispatch(TRAINING_PLAN_ACTION_DEVIS_FORMATION({
      trainingPlanAction: `/api/training_plan_actions/${trainingPlanActionId}`,
      trainingPlanActionId: trainingPlanActionId,
      cartographyId: cartographyId,
      clientName: cartography.intervention.client.entName,
      formationTitle: formationTitle,
      prestataireAccountId: prestataireAccountId,
      prestataireEmail: prestataireEmail
    }))
    setShowModalDevisRequest(!showModalDevisRequest);
  }

  /* ===================================================================== */

  /* =========== Envoi description formation au collaborateur ============ */

  const toggleModalResumeRequest = (trainingPlanAction) => {
    if (!showModalResumeRequest) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalResumeRequest(!showModalResumeRequest);
  };

  const resumeRequest = (trainingPlanActionId, formation, clientEmployeeUser) => {

    dispatch(TRAINING_PLAN_ACTION_RESUME_REQUEST({
      trainingPlanActionId,
      formation,
      clientEmployeeUser,
      internal: false
    }))

    setShowModalResumeRequest(!showModalResumeRequest);
  }

  const toggleModalResumeRequestInternal = (trainingPlanAction) => {
    if (!showModalResumeRequestInternal) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalResumeRequestInternal(!showModalResumeRequestInternal);
  };

  const resumeRequestInternal = (trainingPlanActionId, formationInternal, clientEmployeeUser) => {

    dispatch(TRAINING_PLAN_ACTION_RESUME_REQUEST({
      trainingPlanActionId,
      formationInternal,
      clientEmployeeUser,
      internal: true
    }))

    setShowModalResumeRequestInternal(!showModalResumeRequestInternal);
  }

  /* ========================================= */

  /* =========== Descriptif des détails de la formation ============ */

  const toggleModalDetailFormation = (trainingPlanAction) => {
    if (!showModalDetailFormation) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalDetailFormation(!showModalDetailFormation);
  };

  const detailFormation = () => {
    setShowModalDetailFormation(!showModalDetailFormation);
  }

  const toggleModalDetailFormationInternal = (trainingPlanAction) => {
    if (!showModalDetailFormationInternal) dispatch(TRAINING_PLAN_ACTION({trainingPlanActionId: trainingPlanAction.id}))
    setShowModalDetailFormationInternal(!showModalDetailFormationInternal);
  };

  const detailFormationInternal = () => {
    setShowModalDetailFormationInternal(!showModalDetailFormationInternal);
  }

  /* ========================================= */

  /* =========== Choix de la compétence si absent ============ */

  const toggleModalActionDomainSelect = (actionData) => {
    if (!showModalActionDomainSelect) {
      dispatch(SKILL_DOMAIN_ACTIVITY())
      dispatch(TRAINING_PLAN_ACTION({ trainingPlanActionId: actionData.id }))
    }
    setShowModalActionDomainSelect(!showModalActionDomainSelect);
  };

  const domainSelectAction = (skillDomainId, trainingPlanActionId) => {
    const data = {
      skillDomain: skillDomainId ? `/api/skill_domains/${skillDomainId}` : undefined,
      trainingPlanActionId: trainingPlanActionId,
      trainingPlanId: trainingPlan.id
    }
    dispatch(UPDATE_TRAINING_PLAN_ACTION(data))
    setShowModalActionDomainSelect(!showModalActionDomainSelect);
  };

  /* ========================================= */

  useEffect(() => {
    dispatch(TRAINING_PLANS_CARTOGRAPHY({cartographyId: cartographyId}))
  }, [dispatch, cartographyId])

    return (
      <React.Fragment>

        <FormationPlanValidateModal isOpen={showModalValidate} toggle={toggleModalValidate} validateTrainingPlan={validateTrainingPlan} />
        <FormationPlanDevalidateModal isOpen={showModalDevalidate} toggle={toggleModalDevalidate} devalidateTrainingPlan={devalidateTrainingPlan} />
        <FormationPlanDetailFormationModal isOpen={showModalDetailFormation} toggle={toggleModalDetailFormation} detailFormation={detailFormation} />
        <FormationPlanDetailFormationInternalModal isOpen={showModalDetailFormationInternal} toggle={toggleModalDetailFormationInternal} detailFormationInternal={detailFormationInternal} />

        <FormationPlanAddActionModal isOpen={showModalActionAdd} toggle={toggleModalActionAdd} addAction={addAction} cartography={cartography}/>
        <FormationPlanRemoveActionModal isOpen={showModalActionDelete} toggle={toggleModalActionDelete} deleteAction={deleteAction} />
        <FormationPlanDomainSelectActionModal isOpen={showModalActionDomainSelect} toggle={toggleModalActionDomainSelect} domainSelectAction={domainSelectAction} />
        <ClientFormationPlanDevisRequestModal isOpen={showModalDevisRequest} toggle={toggleModalDevisRequest} devisRequest={devisRequest} />
        <ClientFormationPlanResumeRequestModal isOpen={showModalResumeRequest} toggle={toggleModalResumeRequest} resumeRequest={resumeRequest} />
        <ClientFormationPlanResumeRequestInternalModal isOpen={showModalResumeRequestInternal} toggle={toggleModalResumeRequestInternal} resumeRequestInternal={resumeRequestInternal} />

        {/*<SuccessErrorAlert error={error} success={success} />*/}

        {trainingPlan && (
          trainingPlan.validate
            ? (
              <>
                <p className="mb-2">Titre: {trainingPlan.title}</p>
                <p className="mb-4">Plan sélectionné : {moment(trainingPlan.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>

                {/* Mode lecture non modifiable (après validation) */}

                <Row>
                  <Col sm={12}>
                    <p className="text-danger mb-4">Le plan de développement des compétences a été validé</p>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <button  onClick={() => toggleModalDevalidate(trainingPlan.id)} type="button" className="btn btn-danger waves-effect waves-light mx-2 mb-5">
                      <i className="uil uil-corner-up-right-alt me-1"></i>Enlever la validation
                    </button>
                  </Col>
                </Row>


                {isLoading ? (
                  <Row className="justify-content-center my-5">
                    <DotLoader size={40} color="#597887" />
                  </Row>
                ) : (
                  <Row className="mb-5">
                    <Col md="12 position-relative">

                      {trainingPlan && (

                          <ToolkitProvider
                            keyField="id"
                            data={trainingPlan.trainingPlanActions || []}
                            columns={ClientFormationPlanReadColumn(
                              toggleModalDetailFormation,
                              toggleModalDetailFormationInternal,
                              toggleModalDevisRequest,
                              toggleModalResumeRequest,
                              toggleModalResumeRequestInternal

                            )}
                            bootstrap4
                            exportCSV={ { fileName: `Plan de developpement des competences - (${moment(new Date()).locale('fr', localization).format("DD-MM-yyyy [à] hh[h]mm")}).csv` } }
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                <Row>

                                  <Col sm="12" md="6">
                                    <Label>
                                      <ExportCSVButton { ...toolkitProps.csvProps } className="btn btn-primary waves-effect waves-light mx-2 mb-3 mt-3">
                                        Exporter le tableau en CSV
                                      </ExportCSVButton>
                                    </Label>
                                  </Col>

                                </Row>

                                <div className="table-responsive mb-4 table-with-vertical-scroll" ref={ref}>

                                  <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                                  <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    hover={true}
                                    classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                                    /*rowClasses={ (row, rowIndex) => {
                                      let classes = null;
                                      if (row.isValidate) classes = 'bg-formation-valid';
                                      return classes;
                                    } }*/
                                    {...toolkitProps.baseProps}
                                    //onTableChange={handleTableChange}

                                  />
                                </div>

                              </React.Fragment>
                            )}
                          </ToolkitProvider>

                      )}

                    </Col>
                  </Row>
                  )}
              </>
            ) : (
              <>
                <Row className="align-items-end mb-5">
                  <Col lg={6}>
                    <p className="mb-2">Titre: {trainingPlan.title}</p>
                    <p className="mb-4">Date de création: {moment(trainingPlan.createdAt).locale('fr', localization).format("DD MMMM yyyy")}</p>
                  </Col>

                  <Col lg={6}>
                    {/* Mode création modifiable (non validé par l'organisation) */}

                    <button
                      type="button"
                      className="btn btn-success waves-effect waves-light mb-3 mx-2"
                      onClick={() => toggleModalActionAdd(trainingPlan.id)}
                      data-toggle="modal"
                      data-target="modal-add"
                    >
                      <i className="uil uil-corner-up-right-alt me-2"></i> Ajouter une demande de formation
                    </button>


                      <button
                        onClick={() => toggleModalValidate(trainingPlan.id)}
                        type="button"
                        className="btn btn-success waves-effect waves-light mb-3 mx-2"
                      >
                        <i className="uil uil-corner-up-right-alt me-1"></i>Valider le plan de développement des compétences
                      </button>


                 </Col>
                </Row>

                {isLoading ? (
                  <Row className="justify-content-center my-5">
                    <DotLoader size={40} color="#597887" />
                  </Row>
                ) : (
                  <Row className="mb-3">
                    <Col md="12">

                      {trainingPlan && (
                        <>
                          <div className="table-responsive mb-4 table-with-vertical-scroll" ref={ref}>

                            <button className="btn-caret btn-caret__left" onClick={() => scroll(-280)}><i className="uil uil-angle-left font-size-22"></i></button>
                            <button className="btn-caret btn-caret__right" onClick={() => scroll(280)}><i className="uil uil-angle-right font-size-22"></i></button>

                            <BootstrapTable
                              keyField="id"
                              data={trainingPlan.trainingPlanActions || []}
                              columns={ClientFormationPlanColumn(
                                toggleModalDetailFormation,
                                toggleModalDetailFormationInternal,
                                toggleModalActionDelete,
                                toggleModalActionDomainSelect
                              )}
                              responsive
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                            />
                          </div>
                        </>
                      )}

                    </Col>
                  </Row>
                )}
              </>
            )
        )}

      </React.Fragment>
    )

  }

export default ClientFormationPlan
