import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"

import { withRouter, Link } from "react-router-dom"

import {Row, Col, Container, CardBody, Card, UncontrolledAlert} from "reactstrap"

// Redux//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logo from "assets/images/logo-firmain.png"
import logolight from "assets/images/logo-firmain.png"

// Redux
import {useDispatch, useSelector} from "react-redux";
import { selectLogin, LOGIN_USER, LOGIN_REMEMBER } from "store/auth/login/loginSlice";

// expiration time to value in localStorage
import {getLocalStorageWithExpiry} from "helpers/storage_helper";

const Login = (props) => {

  const dispatch = useDispatch();
  const { rememberme, error, success } = useSelector(selectLogin);

  const [checked, setChecked] = useState(getLocalStorageWithExpiry("rememberme"));
  const [passwordShown, setPasswordShown] = useState(false);

  const handleValidSubmit = (event, values) => {
     const data = {
       email: values.email,
       password: values.password,
       rememberme: rememberme,
       history: props.history
     }
     dispatch(LOGIN_USER(data))
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block mt-3">
        <a href="https://firmain.fr" className="text-dark mx-4" target="_blank" rel="noreferrer">
          <i className="mdi mdi-home-variant h2"></i>
        </a>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <a href="https://firmain.fr" className="mb-5 d-block auth-logo" target="_blank" rel="noreferrer">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </a>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>

              {error ? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                    <i className="uil uil-exclamation-octagon me-2"></i> {error}
                  </UncontrolledAlert>
              ) : null }

              { success ? (
                <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                  <i className="uil uil-check me-2"></i> {success}
                </UncontrolledAlert>
              ) : null }

              <Card>

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Firmain</h5>
                    <p className="text-muted login-subtitle">Bienvenue !</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                          <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                            <i className="uil uil-exclamation-octagon me-2"></i> {props.error}
                          </UncontrolledAlert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Identifiant (adresse e-mail)"
                          value={ (localStorage.getItem("username")) ? (localStorage.getItem("username")) : '' }
                          className="form-control"
                          placeholder="Saisir un identifiant"
                          type="email"
                          errorMessage="Veuillez saisir un mail valide"
                          required
                        />
                      </div>

                      <div className="mb-3">

                        <div className="float-end">
                          <Link to="/mot-passe-oublie" className="forgot-link text-muted">Mot de passe oublié ?</Link>
                        </div>

                        <h5 className="font-size-17 mt-4 mb-3">Mot de passe</h5>
                      </div>

                      <div className="form-group mb-4">
                        <div className="d-flex align-items-center">

                          <div style={{ width: '300px' }}>
                            <AvField
                              name="password"
                              value=""
                              type={passwordShown ? "text" : "password"}
                              required
                              placeholder="Saisir un mot de passe"
                              errorMessage="Veuillez saisir un mot de passe"
                            />
                          </div>

                          <div style={{ cursor: 'pointer' }} onClick={togglePasswordVisiblity}>
                            <i className="uil uil-eye font-size-22 mx-3 text-primary"></i>
                          </div>

                        </div>
                      </div>

                        <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          onChange={ () => {
                            dispatch(LOGIN_REMEMBER(!rememberme))
                            checked ? setChecked(true) : setChecked(false)
                          }}
                          checked={ rememberme ? "checked" : ""}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Se souvenir de moi
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Se connecter
                        </button>
                      </div>

                    </AvForm>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 mb-5 text-center">
                <p>Firmain</p>
              </div>
            </Col>
          </Row>

          <div className="text-sm-center d-none d-sm-block">
            <a href="https://firmain.fr/politique-de-confidentialite" className="mx-3" target="_blank" rel="noreferrer">Politique de confidentialité</a>
            -
            <a href="https://firmain.fr/mentions-legales" className="mx-3" target="_blank" rel="noreferrer">Mentions légales</a>
            -
            <a href="https://firmain.fr/contact" className="mx-3" target="_blank" rel="noreferrer">Contact</a>
          </div>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter((withTranslation()(Login)))

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
