import React, {useEffect} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap"
import {Redirect} from "react-router-dom"

// REDUX
import {CLIENT_ACCOUNT, selectClientAccount} from "store/account/clientAccount/clientAccountSlice";
import {useDispatch, useSelector} from "react-redux";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

const ClientInformation = ({isRole, location}) => {

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()
  const { client } = useSelector(selectClientAccount);

  useEffect(() => {
    dispatch(CLIENT_ACCOUNT({ clientId }))
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            {/* =============== INFORMATION DE L'ENTREPRISE ================= */}

            <h5 className="font-size-18 color-secondary mt-5 mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Mes informations</h5>

            <Row className="mb-5">
              <Col md="4">
                <Card className="card h-100">
                  <CardBody className="mx-4">
                    <div className="text-center">
                      <h5 className="mt-3 mb-1">{client.entName}</h5>
                      <p className="text-muted">{client.entCommercialCoverage}</p>
                    </div>

                    <hr className="my-4" />

                    <div className="text-muted">
                      <div className="table-responsive mt-4">

                        <div>
                          <p className="mb-1">Représentant :</p>
                          <h5 className="font-size-17">{client.contactFirstname} {client.contactLastname}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Fonction :</p>
                          <h5 className="font-size-17">{client.clientFunction}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Téléphone :</p>
                          <h5 className="font-size-17">{client.phone}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Email :</p>
                          <h5 className="font-size-17">{client.email}</h5>
                        </div>

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="8">

                <Card className="card h-100">
                  <CardBody className="mx-4 mt-3">

                    <p className="font-size-17 color-primary mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i>Informations sur mon organisation</p>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Adresse :</p>
                      <h5 className="font-size-17">{client.entAddress} {client.entZipCode} {client.entCity}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Effectif :</p>
                      <h5 className="font-size-17">{client.entEffectif}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Pays :</p>
                      <h5 className="font-size-17">{client.entCountry}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code NAF :</p>
                      <h5 className="font-size-17">{client.entCodeNaf}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code SIRET :</p>
                      <h5 className="font-size-17">{client.entCodeSiret}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code TVA :</p>
                      <h5 className="font-size-17">{client.entCodeTva}</h5>
                    </div>

                  </CardBody>
                </Card>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default ClientInformation
