import React, {useEffect, useState} from "react"
import {Col, Container, Label, Row} from "reactstrap"
import {Redirect} from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {capitalize, isEmpty, map} from "lodash";

//REDUX
import {useDispatch, useSelector} from "react-redux";
import {ADD_CLIENT_EMPLOYEE_MODAL_DATA, UPDATE_CLIENT_EMPLOYEE_SKILL, CLIENT_EMPLOYEE_SKILLS, UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF, CERTIFICATES_BY_ADMIN, selectClientEmployee} from "store/clientEmployee/clientEmployeeSlice";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Components
import GoBackBtn from "components/Common/GoBackBtn";

import ClientFicheMissionDetailColumn from "./ClientFicheMissionDetailColumn";
import ClientFicheMissionCertificateColumn from "./ClientFicheMissionCertificateColumn";
import ClientFicheMissionObjectifModal from "./ClientFicheMissionObjectifModal";
import ClientFicheMissionSkillUpgradeModal from "./ClientFicheMissionSkillUpgradeModal";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ClientFicheMissionDetail = ({isRole, location}) => {

  // récupération de l'id passé dans la route
  const clientEmployeeId = location.state

  const { SearchBar } = Search;

  const dispatch = useDispatch()

  const { clientEmployee, clientEmployeeSkills, cartographiesNotArchived, certificates } = useSelector(selectClientEmployee);

  const [cartographyIdx, setCartographyIdx] = useState("")
  const [showModalObjectif, setShowModalObjectif] = useState(false)
  const [showModalSkillUpgrade, setShowModalSkillUpgrade] = useState(false)

  const toggleModalObjectif = (objectif, colorRiskObjectif, employeeSkillId) => {
    if (!showModalObjectif) dispatch(ADD_CLIENT_EMPLOYEE_MODAL_DATA({ objectif, colorRiskObjectif, employeeSkillId }))
    setShowModalObjectif(!showModalObjectif);
  };

  const toggleModalSkillUpgrade = (message, colorRisk, messageUpgrade, colorRiskUpgrade, employeeSkillId, cartographyId) => {
    if (!showModalSkillUpgrade) dispatch(ADD_CLIENT_EMPLOYEE_MODAL_DATA({ message, colorRisk, messageUpgrade, colorRiskUpgrade, employeeSkillId, cartographyId }))
    setShowModalSkillUpgrade(!showModalSkillUpgrade);
  };

  const updateObjectif = (event, data) => {
    dispatch(UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF({
      colorRiskObjectif: data.colorRiskObjectif,
      objectif: data.objectif,
      employeeSkillId: data.employeeSkillId,
      clientEmployeeId,
      cartographyIdx
    }))
    setShowModalObjectif(!showModalObjectif);
  };

  const skillUpgrade = (status, colorRiskUpgrade, messageUpgrade, employeeSkillId, cartographyId) => {

    if (status === "accord") {
      dispatch(UPDATE_CLIENT_EMPLOYEE_SKILL({
        colorRiskUpgrade,
        messageUpgrade,
        employeeSkillId,
        upgradeStatus: "accord",
        cartographyId,
        userId: clientEmployee.user.id,
        clientEmployeeId,
        cartographyIdx
      }))
    } else {
      dispatch(UPDATE_CLIENT_EMPLOYEE_SKILL({
        colorRiskUpgrade,
        messageUpgrade,
        employeeSkillId,
        upgradeStatus: 'refus',
        cartographyId,
        userId: clientEmployee.user.id,
        clientEmployeeId,
        cartographyIdx
      }))
    }

    setShowModalSkillUpgrade(!showModalSkillUpgrade);
  };

  const handleSelectCartographySubmit = () => {}

  useEffect(() => {
    dispatch(CLIENT_EMPLOYEE_SKILLS({ clientEmployeeId, cartographyIdx }))
    dispatch(CERTIFICATES_BY_ADMIN({ clientEmployeeId }))
  }, [dispatch, clientEmployeeId, cartographyIdx])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientFicheMissionObjectifModal isOpen={showModalObjectif} toggle={toggleModalObjectif} updateObjectif={updateObjectif} />
        <ClientFicheMissionSkillUpgradeModal isOpen={showModalSkillUpgrade} toggle={toggleModalSkillUpgrade} skillUpgrade={skillUpgrade} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/fiches-mission" title="Retour aux fiches de mission" />

            <h5 className="font-size-18 color-secondary mt-3 mb-4">
              <i className="mdi mdi-arrow-right text-secondary me-1"></i>
              {clientEmployee.hasOwnProperty('user') && (
                `Fiche de mission: ${capitalize(clientEmployee.user.firstname)} ${capitalize(clientEmployee.user.lastname)}`
              )}
            </h5>

            <Row>
              <Col lg={12}>
                    <div className="mt-2 mb-5">
                      <p className="mb-1">Pôle : {clientEmployee.pole}</p>
                      <p className="mb-1">Service : {clientEmployee.service}</p>
                      <p className="mb-1">Poste : {clientEmployee.job}</p>
                      <p className="mb-1">Date d'arrivée dans l'entreprise : {moment(clientEmployee.arrivalDate).locale('fr', localization).format("DD MMMM yyyy")}</p>
                    </div>
              </Col>
            </Row>

            {/* Menu déroulant pour choisir la fiche de mission correspondant à la cartographie */}

            { clientEmployee.hasOwnProperty('employees') && (
              <AvForm onValidSubmit={(e, v) => handleSelectCartographySubmit(e, v)}>
                <Row>
                  <Col className="col-8 mb-3">

                    { cartographiesNotArchived.length !== 0 && (
                      <>
                        <h5 className="font-size-17 mb-3">Choisir une cartographie pour voir la fiche de mission associée</h5>

                        <AvField
                          className="form-select"
                          type="select"
                          name="cartography"
                          onChange={event => {
                            setCartographyIdx(event.target.value)
                            dispatch(CLIENT_EMPLOYEE_SKILLS({ clientEmployeeId, cartographyIdx: event.target.value }))
                          }}
                          errorMessage="Choisir une cartographie"
                        >
                          {map(cartographiesNotArchived, (employee, index) => {
                            return (
                              <option key={`employee-${employee.cartography.id}`} value={index}>
                                {moment(employee.cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}
                              </option>
                            )
                          })}

                        </AvField>
                      </>
                    )}
                  </Col>
                </Row>
              </AvForm>
            )}

            { !isEmpty(cartographiesNotArchived) && (

              <p className="mb-1">Mise à jour de la cartographie :{" "}

                {cartographyIdx
                  ? cartographiesNotArchived[cartographyIdx] && cartographiesNotArchived[cartographyIdx].cartography.updatedAt
                    ? moment(cartographiesNotArchived[cartographyIdx].cartography.updatedAt).locale('fr', localization).format("DD MMMM yyyy")
                    : 'Aucune mise à jour'
                  : moment(cartographiesNotArchived.length > 0 && cartographiesNotArchived[0].cartography.updatedAt).locale('fr', localization).format("DD MMMM yyyy")
                }
              </p>
            )}

            { cartographiesNotArchived.length !== 0 && (
              <Row>
                <Col lg={12} className="mt-5">
                  <span className="text-primary">Légende :</span>
                  <span className="badge bg-risk-very-good mx-2">Expert</span>
                  <span className="badge bg-risk-good mx-2">Confirmé</span>
                  <span className="badge bg-risk-medium mx-2">Débutant</span>
                  <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                  <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                </Col>
              </Row>
            )}

            <Row className="mb-5 mt-4">
              <Col md="12 position-relative">

                {clientEmployeeSkills.length !== 0 && cartographiesNotArchived.length !== 0 ? (

                  <ToolkitProvider
                    keyField="id"
                    data={clientEmployeeSkills || []}
                    columns={ClientFicheMissionDetailColumn(
                      toggleModalObjectif,
                      toggleModalSkillUpgrade
                    )}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row>
                          <Col>
                            <Label className="float-end">{" "}
                              <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                            </Label>
                          </Col>
                        </Row>

                        <div className="table-responsive mb-4">

                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={false}
                            hover={true}
                            classes={"table table-bordered border-primary datatable dt-responsive nowrap"}
                            {...toolkitProps.baseProps}
                          />
                        </div>

                      </React.Fragment>
                    )}
                  </ToolkitProvider>

                ) : (
                  <p>Aucune évaluation de compétence disponible</p>
                )}

              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mt-3 mb-4">
              <i className="mdi mdi-arrow-right text-secondary me-1"></i>
              Liste des habilitations ou certifications
            </h5>

            <Row className="mb-5 mt-4">
              <Col md="12 position-relative">

                {certificates.length !== 0 ? (

                  <ToolkitProvider
                    keyField="id"
                    data={certificates || []}
                    columns={ClientFicheMissionCertificateColumn()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <div className="table-responsive mb-4">

                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={false}
                            hover={true}
                            classes={"table table-bordered border-primary datatable dt-responsive nowrap"}
                            {...toolkitProps.baseProps}
                          />
                        </div>

                      </React.Fragment>
                    )}
                  </ToolkitProvider>

                ) : (
                  <p>Aucune habilitation ou certification disponible</p>
                )}

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default ClientFicheMissionDetail
