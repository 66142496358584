//import React from "react"

import {Link} from "react-router-dom";
import React from "react";
import {Badge} from "reactstrap";

const PrestataireDetailColumnFormation = toggleModalLink => [

  {
    dataField: "title",
    text: "Nom de la formation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    classes: 'py-4',
    sort: true,
  },

  {
    dataField: "cpfCode",
    text: "Code CPF",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    classes: 'py-4',
    sort: true,
  },

  {
    dataField: "cpfDesignation",
    text: "Designation CPF",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    classes: 'py-4',
    sort: true,
  },

  {
    dataField: "activeFormation",
    text: "Statut",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    classes: 'py-4',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.activeFormation) {
        return <Badge className="badge bg-pill bg-soft-success" color="success" pill>active</Badge>
      } else {
        return <Badge className="badge bg-pill bg-soft-warning" color="warning" pill>suspendu</Badge>
      }
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Voir détails",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <>
        <Link to={{ pathname: "/details-formation", state: row.id }} className="btn btn-link waves-effect px-3 text-success">
          <i className="uil uil-eye font-size-20"></i>
        </Link>

        <button
          type="button"
          className="btn btn-link waves-effect px-3 text-danger"
          onClick={() => toggleModalLink(row)}
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-link-broken font-size-18"></i>
        </button>
      </>
    ),
    csvExport: false
  }

]

export default PrestataireDetailColumnFormation
