import React, {useEffect, useState} from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Badge, Card, CardBody, Col, Container, Row} from "reactstrap"
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectPrestataire, PRESTATAIRE} from "store/prestataire/prestataireSlice";
import {DELETE_FORMATION_PRESTATAIRE_LINK, FORMATION} from "store/formation/formationSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";

import PrestataireDetailColumnIntervenant from "./PrestataireDetailColumnIntervenant";
import PrestataireDetailColumnFormation from "./PrestataireDetailColumnFormation";
import PrestataireModalRemoveFormationLink from "./PrestataireModalRemoveFormationLink";

const PrestataireDetail = ({isRole, location, history}) => {

  const dispatch = useDispatch()
  const { prestataire } = useSelector(selectPrestataire);

  // récupération de l'id passé dans la route
  const routeId = location.state

  const [showModal, setShowModal] = useState(false)

  const toggleModalLink = (formationData) => {
    if (!showModal) dispatch(FORMATION({formationId: formationData.id}))
    setShowModal(!showModal);
  };

  const deleteFormationLink = (formationId ) => {
    const data = {
      formationId: formationId,
      prestataire: null,
      history: history
    }
    dispatch(DELETE_FORMATION_PRESTATAIRE_LINK(data))
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(PRESTATAIRE({prestataireId: routeId}))
  }, [dispatch, routeId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <PrestataireModalRemoveFormationLink isOpen={showModal} toggle={toggleModalLink} deleteFormationLink={deleteFormationLink} prestataire={prestataire} />


        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-prestataires" title="Retour à la liste des prestataires" />

            <h5 className="font-size-18 color-secondary mb-3 mt-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Information sur le prestataire</h5>

            <Row className="mb-5">
              <Col md="4">

                <Card className="card h-100">
                  <CardBody className="mx-4">
                    <div className="text-center">
                      {
                        prestataire.hasOwnProperty('user') &&
                        prestataire.user.hasOwnProperty('image')
                        ? (
                          <img
                            className="avatar-title rounded-circle bg-white"
                            style={{ width: '80px', margin: '0 auto' }}
                            src={process.env.REACT_APP_URL + "/media/" + prestataire.user.image.filePath}
                            alt="Header Avatar"
                          />
                        ) : null
                      }

                      <h5 className="mt-2 mb-1">{prestataire.name}</h5>
                      <p className="text-muted">{prestataire.commercialCoverage}</p>

                      {prestataire.hasOwnProperty('locality') && (
                        <p className="text-muted">
                          {prestataire.locality.region.name} ({prestataire.locality.departmentName})
                        </p>
                      )}

                      {/*
                      <div className="mt-4">
                        <button type="button" className="btn btn-success btn-sm"><i className="uil uil-envelope-alt me-2"></i> Envoyer un message</button>
                      </div>
                      */}

                    </div>

                    <hr className="my-4"/>

                    <div className="text-muted">
                      <div className="table-responsive mt-4">

                        <div>
                          <p className="mb-1">Représentant :</p>
                          <h5 className="font-size-17">{prestataire.contactFirstname} {prestataire.contactLastname}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Fonction :</p>
                          <h5 className="font-size-17">{prestataire.contactFunction}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Téléphone :</p>
                          <h5 className="font-size-17">{prestataire.contactPhone}</h5>
                        </div>

                        <div className="mt-4">
                          <p className="mb-1">Email :</p>
                          <h5 className="font-size-17">{prestataire.contactEmail}</h5>
                        </div>

                        <div className="mt-4 d-flex align-items-center">
                          <p className="mb-1">Certification Qualiopi : </p>
                          <h5 className="mx-3 ">{
                            prestataire.qualiopiCertification
                              ? <Badge className="badge bg-pill bg-soft-success" color="success" pill>Oui</Badge>
                              : <Badge className="badge bg-pill bg-soft-danger" color="danger" pill>Non</Badge>
                          }</h5>
                        </div>

                        <div className="mt-4 d-flex align-items-center">
                          <p className="mb-1">Status : </p>
                          <h5 className="mx-3 ">{
                            prestataire.activeAccount
                              ? <Badge className="badge bg-pill bg-soft-success" color="success" pill>compte actif</Badge>
                              : <Badge className="badge bg-pill bg-soft-warning" color="warning" pill>compte suspendu</Badge>
                          }</h5>
                        </div>

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="8">

                <Card className="card">
                  <CardBody className="mx-4 mt-3">
                    <div className="mx-3">

                      <p className="mb-1">Description :</p>

                      { prestataire.presentation ? (
                        <ul style={{ paddingLeft: '0px' }}>
                          {prestataire.presentation && prestataire.presentation.split('\n').map((item, idx) => (
                            <li key={idx} className="font-size-17 color-text mb-4" style={{ listStyleType: 'none' }}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <h5 className="font-size-17 mt-2">Non renseigné</h5>
                      )}

                    </div>
                  </CardBody>
                </Card>

                <Card className="card mb-0">
                  <CardBody className="mx-4 mt-3">

                    <div className="mx-3">
                      <p className="mb-1">Adresse :</p>
                      <h5 className="font-size-17">{prestataire.address} {prestataire.zipCode} {prestataire.city}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Pays :</p>
                      <h5 className="font-size-17">{prestataire.country}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code NAF :</p>
                      <h5 className="font-size-17">{prestataire.codeNaf}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code SIRET :</p>
                      <h5 className="font-size-17">{prestataire.codeSiret}</h5>
                    </div>

                    <div className="mt-3 mx-3">
                      <p className="mb-1">Code TVA :</p>
                      <h5 className="font-size-17">{prestataire.codeTva}</h5>
                    </div>

                    <div className="mt-3 mx-3 d-flex align-items-center">
                      <p className="mb-1">Assujetti à la TVA ? </p>
                      <h5 className="mx-3">{
                        prestataire.subjectTva
                          ? <Badge className="badge bg-pill bg-soft-success" color="success" pill>Oui</Badge>
                          : <Badge className="badge bg-pill bg-soft-danger" color="danger" pill>Non</Badge>
                      }</h5>
                    </div>

                    <div className="mt-3 mx-3 mb-4">
                      <p className="mb-1">Numéro de déclaration d'activité (NDA) :</p>
                      <h5 className="font-size-17">{prestataire.codeNda}</h5>
                    </div>

                  </CardBody>
                </Card>

              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mb-2"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des intervenants du prestataire</h5>

            <Row>
              <Col sm={8}>

                <Link to={{ pathname: "/gestion-intervenants", state: prestataire.id }} className="btn btn-primary waves-effect waves-light mx-2 mt-3 mb-2">
                  <i className="uil uil-corner-up-right-alt me-1"></i>Gestion des intervenants
                </Link>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card className="mb-0 mt-4">
                  <CardBody className="mx-5 mt-3">

                    <ToolkitProvider
                      keyField="id"
                      data={ prestataire.prestataireIntervenants || []}
                      columns={PrestataireDetailColumnIntervenant()}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              remote
                              hover={true}
                              bordered={false}
                              striped={false}
                              classes={"table table-centered datatable dt-responsive nowrap table-card-list"}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mt-5 mb-2"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Formations proposées par le prestataire</h5>

            <Row>
              <Col lg={12}>
                <Card className="mb-0 mt-4">
                  <CardBody className="mx-5 mt-3">

                    <ToolkitProvider
                      keyField="id"
                      data={prestataire.formations || []}
                      columns={PrestataireDetailColumnFormation(toggleModalLink)}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              remote
                              bordered={false}
                              striped={false}
                              hover={true}
                              classes={
                                "table table-nowrap table-hover mb-0"
                              }
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(PrestataireDetail)
