import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  FORMATION_INTERNALS,
  FORMATION_INTERNALS_FILTER_BY_CLIENT,
  FORMATION_INTERNAL,
  GET_FORMATION_INTERNALS,
  GET_FORMATION_INTERNALS_FILTER_BY_CLIENT,
  GET_FORMATION_INTERNAL,
  ADD_FORMATION_INTERNAL,
  ADD_NEW_FORMATION_INTERNAL,
  UPDATE_FORMATION_INTERNAL,
  DELETE_FORMATION_INTERNAL,
  FORMATION_INTERNAL_SUCCESS,
  ADD_FORMATION_INTERNAL_SUCCESS,
  FORMATION_INTERNAL_ERROR,
  UPDATE_FORMATION_INTERNAL_INTERVENANT
} from "./formationInternalSlice"

import {
  getFormationInternals,
  getFormationInternal,
  postFormationInternal,
  putFormationInternal,
  deleteFormationInternal,
  getFormationInternalsFilterByClient
} from "helpers/request_helper"

function* viewFormationInternals() {
  try {
    const response = yield call(getFormationInternals)
    yield put(GET_FORMATION_INTERNALS(response['hydra:member']))
    yield put(FORMATION_INTERNAL_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error.message))
  }
}

function* viewFormationInternalsFilterByClient({ payload: { clientId } }) {
  try {
    const response = yield call(getFormationInternalsFilterByClient, clientId)
    yield put(GET_FORMATION_INTERNALS_FILTER_BY_CLIENT(response))
    yield put(FORMATION_INTERNAL_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error.message))
  }
}

function* viewFormationInternal({ payload: { formationInternalId } }) {
  try {
    const response = yield call(getFormationInternal, formationInternalId)
    yield put(GET_FORMATION_INTERNAL(response))

    yield put(FORMATION_INTERNAL_SUCCESS(""))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error.message))
  }
}

function* addNewFormationInternal({payload: {
  title, context, targetAudience, duration, durationUnit, description, objectifStrategic, objectifOperational, objectifEducational, prerequisites,
  programme, educationalTechnics, educationalMethods, modalities, necessaryMaterial, internal, referentFirstname, referentLastname,
  price, sanction, disabledAccessibility, formationQualification, skillDomain, client, role, history
}}) {

  try {

    const data = {
      title,
      context,
      targetAudience,
      duration,
      durationUnit,
      description,
      objectifStrategic,
      objectifOperational,
      objectifEducational,
      prerequisites,
      programme,
      educationalTechnics,
      educationalMethods,
      modalities,
      necessaryMaterial,
      internal,
      referentFirstname,
      referentLastname,
      formationQualification,
      price,
      sanction,
      disabledAccessibility,
      skillDomain,
      client
    }

    const response = yield call(postFormationInternal, data)
    yield put(ADD_NEW_FORMATION_INTERNAL(response))
    yield put(FORMATION_INTERNAL_SUCCESS("Ajout de la formation interne avec succès"))

    if (role === "client") {
      history.push({ pathname: "/ma-formation-interne-details", state: response.id })
    } else if (role === "admin") {
       //history.push({ pathname: "/gestion-formations-internes", state: response.id })
    }

  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error.message))
  }
}

function* editFormationInternal({ payload: {
  title, context, targetAudience, duration, durationUnit, description, objectifStrategic, objectifOperational, objectifEducational, prerequisites,
  programme, educationalTechnics, educationalMethods, modalities, necessaryMaterial, internal, referentFirstname, referentLastname,
  formationQualification, formationInternalId, price, sanction, disabledAccessibility, skillDomain
} }) {
  try {
    const response = yield call(putFormationInternal, {
      title,
      context,
      targetAudience,
      duration,
      durationUnit,
      description,
      objectifStrategic,
      objectifOperational,
      objectifEducational,
      prerequisites,
      programme,
      educationalTechnics,
      educationalMethods,
      modalities,
      necessaryMaterial,
      internal,
      referentFirstname,
      referentLastname,
      formationQualification,
      price,
      sanction,
      disabledAccessibility,
      formationInternalId,
      skillDomain
    })
    yield put(GET_FORMATION_INTERNAL(response))
    yield put(ADD_FORMATION_INTERNAL_SUCCESS("Les informations de la formation interne ont bien été modifiées"))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error))
  }
}

function* removeFormationInternal({ payload: { formationInternalId, role, clientId } }) {
  try {
    yield call(deleteFormationInternal, formationInternalId)

    if (role === "client") {
      const response = yield call(getFormationInternalsFilterByClient, clientId)
      yield put(GET_FORMATION_INTERNALS_FILTER_BY_CLIENT(response))
    } else if (role === "admin") {
      yield put(FORMATION_INTERNALS())
    }

    yield put(ADD_FORMATION_INTERNAL_SUCCESS(`Suppression de la formation interne avec succès !!`))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error.message))
  }
}

function* editFormationInternalIntervenant({ payload: { formationInternalId, intervenants, clientId, role } }) {
  try {
    yield call(putFormationInternal, {
      formationInternalId: formationInternalId,
      clientEmployees: intervenants,
    })

    if (role === "client") {
      const response = yield call(getFormationInternalsFilterByClient, clientId)
      yield put(GET_FORMATION_INTERNALS_FILTER_BY_CLIENT(response))
    } else if (role === "admin") {
      yield put(FORMATION_INTERNAL({formationInternalId: formationInternalId}))
    }

    yield put(ADD_FORMATION_INTERNAL_SUCCESS("Les informations de la formation interne ont bien été modifiées"))
  } catch (error) {
    yield put(FORMATION_INTERNAL_ERROR(error))
  }
}


export function* watchProfile() {
  yield takeEvery(FORMATION_INTERNALS.type, viewFormationInternals)
  yield takeEvery(FORMATION_INTERNALS_FILTER_BY_CLIENT.type, viewFormationInternalsFilterByClient)
  yield takeEvery(FORMATION_INTERNAL.type, viewFormationInternal)
  yield takeEvery(ADD_FORMATION_INTERNAL.type, addNewFormationInternal)
  yield takeEvery(UPDATE_FORMATION_INTERNAL.type, editFormationInternal)
  yield takeEvery(DELETE_FORMATION_INTERNAL.type, removeFormationInternal)
  yield takeEvery(UPDATE_FORMATION_INTERNAL_INTERVENANT.type, editFormationInternalIntervenant);
}

export default function* FormationSaga() {
  yield all([fork(watchProfile)])
}
