import React, {useEffect, useState} from "react"
import {Col, Container, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Redirect} from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next";
import {map} from "lodash";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

//REDUX
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_EMPLOYEE_SKILLS_BY_USER, ADD_CLIENT_EMPLOYEE_MODAL_DATA, UPDATE_CLIENT_EMPLOYEE_SKILL, CERTIFICATES, selectClientEmployee} from "store/clientEmployee/clientEmployeeSlice";
import {selectAdvert, ADVERTS} from "store/advert/advertSlice";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Components
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";
import UserFicheMissionDetailColumn from "./UserFicheMissionDetailColumn";
import UserFicheMissionSkillUpgradeModal from "./UserFicheMissionSkillUpgradeModal";
import AdvertCard from "components/Common/Advert/AdvertCard";

import placeholder from "assets/images/404-error.png"
import UserFicheMissionCertificateColumn from "./UserFicheMissionCertificateColumn";

const UserFicheMission = ({isRole, location}) => {

  const userId = localStorage.getItem('id')

  const { SearchBar } = Search;

  const dispatch = useDispatch()

  const { clientEmployee, clientEmployeeSkills, cartographiesNotArchived, certificates, error } = useSelector(selectClientEmployee);
  const [cartographyIdx, setCartographyIdx] = useState("")
  const { adverts } = useSelector(selectAdvert);
  const [showModalSkillUpgrade, setShowModalSkillUpgrade] = useState(false)

  const handleSelectCartographySubmit = () => {}

  /* ============== Demande de mise à niveau ===================== */

  const toggleModalSkillUpgrade = (messageUpgrade, colorRiskUpgrade, employeeSkillId, cartographyId) => {
    if (!showModalSkillUpgrade) dispatch(ADD_CLIENT_EMPLOYEE_MODAL_DATA({ messageUpgrade, colorRiskUpgrade, employeeSkillId, cartographyId }))
    setShowModalSkillUpgrade(!showModalSkillUpgrade);
  };

  const skillUpgrade = (event, data) => {
    dispatch(UPDATE_CLIENT_EMPLOYEE_SKILL({
      colorRiskUpgrade: data.colorRiskUpgrade,
      messageUpgrade: data.messageUpgrade,
      employeeSkillId: data.employeeSkillId,
      upgradeStatus: null,
      cartographyId: data.cartographyId,
      userId,
      clientEmployeeId: clientEmployee.id,
      cartographyIdx,
      clientId: clientEmployee.client.user.id
    }))

    setShowModalSkillUpgrade(!showModalSkillUpgrade);
  };

  /* =============================================================== */

  useEffect(() => {
    dispatch(CLIENT_EMPLOYEE_SKILLS_BY_USER({ userId, cartographyIdx }))
    dispatch(CERTIFICATES({ userId }))
    dispatch(ADVERTS())
  }, [dispatch, userId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <UserFicheMissionSkillUpgradeModal isOpen={showModalSkillUpgrade} toggle={toggleModalSkillUpgrade} skillUpgrade={skillUpgrade} />

        <div className="page-content my-5">
          <Container fluid>

            <SuccessErrorAlert error={error} />

            <Row className="mb-2">
              <Col md="12">

                {map(adverts, (advert, index) => {
                  if (index === 0 && advert.isActive) {
                    return <AdvertCard
                      key={index}
                      img={
                        advert.image.hasOwnProperty('filePath')
                          ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                          : placeholder}
                      message={advert.message}
                      link={advert.link}
                      isTransparent={true}
                    />
                  } else {
                    return null
                  }
                })}

              </Col>
            </Row>

            <h5 className="font-size-22 color-secondary text-center mb-5">Ma fiche de mission</h5>

            <Row>
              <Col md={6}>

                <h5 className="font-size-18 color-secondary mt-3 mb-4">
                  <i className="mdi mdi-arrow-right text-secondary me-1"></i>
                  Vos informations
                </h5>

                <Row>
                  <Col lg={12}>
                    <div className="mt-2 mb-5">
                      <p className="mb-1">Pôle : {clientEmployee.pole}</p>
                      <p className="mb-1">Service : {clientEmployee.service}</p>
                      <p className="mb-1">Poste : {clientEmployee.job}</p>
                      <p className="mb-1">Date d'arrivée dans l'entreprise : {moment(clientEmployee.arrivalDate).locale('fr', localization).format("DD MMMM yyyy")}</p>
                    </div>
                  </Col>
                </Row>

              </Col>

              <Col md={6}>

                {/* Menu déroulant pour choisir la fiche de mission correspondant à la cartographie */}

                { cartographiesNotArchived.length !== 0 && (
                  <AvForm onValidSubmit={(e, v) => handleSelectCartographySubmit(e, v)}>
                    <Row>
                      <Col className="col-8 mb-3">

                        <h5 className="font-size-17 mb-3">Choisir une cartographie pour voir la fiche de mission associée</h5>

                        <AvField
                          className="form-select"
                          type="select"
                          name="cartography"
                          onChange={event => {
                            setCartographyIdx(event.target.value)
                            dispatch(CLIENT_EMPLOYEE_SKILLS_BY_USER({ userId, cartographyIdx: event.target.value }))
                          }}
                          errorMessage="Choisir une cartographie"
                        >
                          {map(cartographiesNotArchived, (employee, index) => {
                            return (
                              <option key={`employee-${employee.cartography.id}`} value={index}>
                                {moment(employee.cartography.createdAt).locale('fr', localization).format("DD MMMM yyyy")}
                              </option>
                            )
                          })}
                        </AvField>
                      </Col>
                    </Row>
                  </AvForm>
                )}

              </Col>
            </Row>

            { cartographiesNotArchived.length !== 0 && (
              <Row>
                <Col lg={12} className="mt-5">
                  <span className="text-primary">Légende :</span>
                  <span className="badge bg-risk-very-good mx-2">Expert</span>
                  <span className="badge bg-risk-good mx-2">Confirmé</span>
                  <span className="badge bg-risk-medium mx-2">Débutant</span>
                  <span className="badge bg-risk-bad mx-2">Non maîtrisé</span>
                  <span className="badge bg-risk-not-concerned mx-2">Non concerné</span>
                </Col>
              </Row>
            )}

            <Row className="mb-5 mt-4">
              <Col md="12 position-relative">

                {clientEmployeeSkills.length !== 0 && cartographiesNotArchived.length !== 0 ? (

                  <ToolkitProvider
                    keyField="id"
                    data={clientEmployeeSkills || []}
                    columns={UserFicheMissionDetailColumn(
                      toggleModalSkillUpgrade
                    )}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row>
                          <Col>
                            <Label className="float-end">{" "}
                              <SearchBar placeholder="Rechercher" {...toolkitProps.searchProps} />
                            </Label>
                          </Col>
                        </Row>

                        <div className="table-responsive mb-4">

                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={false}
                            hover={true}
                            classes={"table table-bordered border-primary datatable dt-responsive nowrap"}
                            {...toolkitProps.baseProps}
                          />
                        </div>

                      </React.Fragment>
                    )}
                  </ToolkitProvider>

                ) : (
                  <p>Aucune évaluation de compétence disponible</p>
                )}

              </Col>
            </Row>

            <h5 className="font-size-18 color-secondary mt-3 mb-4">
              <i className="mdi mdi-arrow-right text-secondary me-1"></i>
              Liste des habilitations ou certifications
            </h5>

            <Row className="mb-5 mt-4">
              <Col md="12 position-relative">

                {certificates.length !== 0 ? (

                  <ToolkitProvider
                    keyField="id"
                    data={certificates || []}
                    columns={UserFicheMissionCertificateColumn()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <div className="table-responsive mb-4">

                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={false}
                            hover={true}
                            classes={"table table-bordered border-primary datatable dt-responsive nowrap"}
                            {...toolkitProps.baseProps}
                          />
                        </div>

                      </React.Fragment>
                    )}
                  </ToolkitProvider>

                ) : (
                  <p>Aucune habilitation ou certification disponible</p>
                )}

              </Col>
            </Row>



          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default UserFicheMission
