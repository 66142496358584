import React from "react"
import { Link } from "react-router-dom"

const FormationInternalColumn = toggleModal => [

  {
    dataField: "title",
    text: "Nom de la formation interne",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <Link to={{ pathname: "/ma-formation-interne-details", state: row.id }}>
          <p className="mb-0 color-primary">{cellContent}</p>
        </Link>
      )
    },
    csvFormatter: (cellContent, row) => `${row.title}`,

    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "client.entName",
    text: "Organisation",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <Link to={{ pathname: "/ma-formation-interne-details", state: row.id }}>
          <p className="mb-0 color-primary">{cellContent}</p>
        </Link>
      )
    },
    csvFormatter: (cellContent, row) => `${row.title}`,

    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    headerAlign: 'center',
    headerStyle: {width: '200px'},
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <div className="flex justify-content-center" style={{ minWidth: "130px"}}>
        <Link to={{ pathname: "/gestion-formation-interne-detail", state: row.id }} className="btn btn-link waves-effect px-2 text-success">
          <i className="uil uil-eye font-size-20"></i>
        </Link>

        <Link to={{ pathname: "edition-formation-interne", state: row.id }} className="btn btn-link waves-effect px-2 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>

        <button
          type="button"
          className="btn btn-link waves-effect text-danger"
          onClick={() => toggleModal(row)}
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-trash-alt font-size-18"></i>
        </button>
      </div>
    ),
    csvExport: false
  }

]

export default FormationInternalColumn
