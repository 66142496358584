import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

const FormationPlanDevalidateModal = ({ isOpen, toggle, devalidateTrainingPlan}) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Valider le plan de développement des compétences ?</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Confirmez-vous vouloir retirer la validation par l'organisation du plan de développement des compétences ?</p>
        <p>Après confirmation, le plan de développement des compétences sera à nouveau modifiable mais devra être soumis à une nouvelle validation.</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={() => devalidateTrainingPlan()}>Retirer la validation</button>
        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={toggle}>Annuler</button>
      </div>
    </Modal>
  )
}

export default FormationPlanDevalidateModal

FormationPlanDevalidateModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
