import React, {useEffect} from "react"

import { capitalizeFirstLetter } from 'helpers/text_helper'

// REDUX
import {selectAccount, ACCOUNT} from "store/account/account/accountSlice";
import {useDispatch, useSelector} from "react-redux";

const DashboardPrestataire = () => {

  const dispatch = useDispatch()
  const account = useSelector(selectAccount);

  useEffect(() => {
    dispatch(ACCOUNT({ userId: localStorage.getItem('id') }))
  }, [dispatch])

  return (
    <div className="mt-5">
      <h4>Bonjour {capitalizeFirstLetter(account.user.firstname)} {capitalizeFirstLetter(account.user.lastname)}</h4>
    </div>
  )
}

export default DashboardPrestataire
