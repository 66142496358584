import React, {useEffect, useState} from "react"
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {isEmpty, map} from "lodash";

import {capitalizeFirstLetter} from 'helpers/text_helper'

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectClientAccount, CLIENTS_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";
import {PRESTATAIRES, selectPrestataire} from "store/prestataire/prestataireSlice";
import {selectFormation, FORMATIONS,} from "store/formation/formationSlice";
import {selectAccount, ACCOUNT} from "store/account/account/accountSlice";
import {selectAdvert, ADVERTS, UPDATE_ADVERT} from "store/advert/advertSlice";

// Components
import MiniWidget from "./dashboardComponents/MiniWidget";
import AdvertCard from "components/Common/Advert/AdvertCard";
import AdvertEditModal from "./dashboardComponents/AdvertEditModal";
import AdvertImageEditModal from "./dashboardComponents/AdvertImageEditModal";

import placeholder from "assets/images/logo-firmain.png"


const DashboardAdmin = () => {

  const dispatch = useDispatch()
  const {clients} = useSelector(selectClientAccount);
  const {prestataires} = useSelector(selectPrestataire);
  const {formations} = useSelector(selectFormation);
  const account = useSelector(selectAccount);
  const {adverts, successImage} = useSelector(selectAdvert);

  function changeAdvertStatus() {

    const data = {
      advertId: adverts[0].id,
      isActive: !adverts[0].isActive
    }

    dispatch(UPDATE_ADVERT(data))
  }

  // ========  EDITION DU BANDEAU PUBLICITAIRE =============

  const [showModalAdvertEdit, setShowModalAdvertEdit] = useState(false)
  const [showModalAdvertImageEdit, setShowModalAdvertImageEdit] = useState(false)

  const toggleModalAdvertEdit = () => {
    setShowModalAdvertEdit(!showModalAdvertEdit);
  };

  const toggleModalAdvertImageEdit = () => {
    setShowModalAdvertImageEdit(!showModalAdvertImageEdit);
  };

  const editAdvert = (e, v) => {

    const data = {
      advertId: adverts[0].id,
      link: v.link,
      message: v.message,
    }
    dispatch(UPDATE_ADVERT(data))

    setShowModalAdvertImageEdit(!showModalAdvertImageEdit);
  };

  /* ============== DATA ================== */

  const series1 = [{data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]}];

  const options1 = {
    fill: {
      colors: ['#5b73e8']
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1
      },
    },
    tooltip: {
      fixed: {
        enabled: !1
      },
      x: {
        show: !1
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return '';
          }
        }
      },
      marker: {
        show: !1
      }
    }
  };

  const series4 = [{
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }];

  const options4 = {

    fill: {
      colors: ['#f1b44c']
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1
      },
    },
    tooltip: {
      fixed: {
        enabled: !1
      },
      x: {
        show: !1
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return '';
          }
        }
      },
      marker: {
        show: !1
      }
    }
  };

  const reports = [
    {
      id: 1,
      //icon: "mdi mdi-arrow-up-bold",
      title: "Nombre d'organisations utilisatrices (total)",
      value: clients.length,
      //prefix: "$",
      //suffix: "",
      //badgeValue: "2.65%",
      //decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      //color: "success",
      //desc: "since last week",
      series: series1,
      options: options1,

    },
    {
      id: 2,
      //icon: "mdi mdi-arrow-down-bold",
      title: "Nombre de prestataires (total)",
      value: prestataires.length,
      //decimal: 0,
      //prefix: "",
      //suffix: "",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      //badgeValue: "6.24%",
      //color: "danger",
      //desc: "since last week",
      series: series1,
      options: options1,
    },
    {
      id: 3,
      //icon: "mdi mdi-arrow-up-bold",
      title: "Nombre de formations (total)",
      value: formations.length,
      //decimal: 2,
      //prefix: "+",
      //suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      //badgeValue: "10.51%",
      //color: "success",
      //desc: "since last week",
      series: series4,
      options: options4,
    },
  ];

  /* =============================================== */

  useEffect(() => {
    dispatch(CLIENTS_ACCOUNT())
    dispatch(PRESTATAIRES())
    dispatch(FORMATIONS())
    dispatch(ACCOUNT({userId: localStorage.getItem('id')}))
    dispatch(ADVERTS())
  }, [dispatch])

  return (
    <div className="mt-5">
      <AdvertEditModal isOpen={showModalAdvertEdit} toggle={toggleModalAdvertEdit} editAdvert={editAdvert} adverts={adverts}/>

      <AdvertImageEditModal
        isOpen={showModalAdvertImageEdit}
        toggle={toggleModalAdvertImageEdit}
        adverts={adverts}
        success={successImage}
      />

      <Row>
        <h4 className="mb-4">Bonjour {capitalizeFirstLetter(account.user.firstname)} {capitalizeFirstLetter(account.user.lastname)}</h4>
        <MiniWidget reports={reports}/>
      </Row>

      {/* =============== PUBLICITE ================= */}

      <h5 className="font-size-18 color-secondary mt-5 mb-4"><i className="mdi mdi-arrow-right text-secondary me-1"></i>Gestion du bandeau publicitaire</h5>

      <Row className="mb-0">
        <Col md="12">

          <p className="mt-1">Aperçu du bandeau publicitaire :</p>

          {map(adverts, (advert, index) => {
            if (index === 0) {
              return <AdvertCard
                key={index}
                img={
                  adverts[0].hasOwnProperty('image') && advert.image.hasOwnProperty('filePath')
                    ? process.env.REACT_APP_URL + "/media/" + advert.image.filePath
                    : placeholder}
                message={advert.message}
                link={advert.link}
                isTransparent={false}
              />
            } else {
              return null
            }
          })}

        </Col>
      </Row>

      <Row className="mb-1">
        <Col md="12">

          <FormGroup>
            <div className="form-check mt-4 mb-1">
              <Input type="checkbox"
                     className="form-check-input"
                     id="status-input"
                     onChange={changeAdvertStatus}
                     checked={!isEmpty(adverts) ? adverts[0].isActive : false}
              />
              <Label className="form-check-label" htmlFor="status-input">Bandeau publicitaire visible ?
                <span className="font-size-15 color-grey mx-2">(si décoché, le bandeau publicitaire ne sera pas affiché sur les tableaux de bord administrateur et user)</span>
              </Label>
            </div>
          </FormGroup>

        </Col>
      </Row>

      <Row className="mb-5">
        <Col md="12">

          <button
            type="button"
            className="btn btn-primary waves-effect waves-light mt-4 mx-2"
            onClick={() => toggleModalAdvertImageEdit()}
            data-toggle="modal"
            data-target="modal-edit"
          >
            <i className="uil uil-pen font-size-18"></i> <span className="font-size-16">Modifier l'image</span>
          </button>

          <button
            type="button"
            className="btn btn-primary waves-effect waves-light mt-4 mx-3"
            onClick={() => toggleModalAdvertEdit()}
            data-toggle="modal"
            data-target="modal-edit"
          >
            <i className="uil uil-pen font-size-18"></i> <span className="font-size-16">Modifier le contenu</span>
          </button>

        </Col>
      </Row>


      <p className="mt-5 mb-5">Fichier de recettage :
        <a
          href="https://insaniam.notion.site/Fichier-de-recettage-Super-Administrateur-f111d444df5c4a80910695245ebaec77"
          className="mx-2 color-success"
          target="_blank"
          rel="noreferrer"
        >Lien</a>
      </p>
    </div>
  )
}

export default DashboardAdmin
