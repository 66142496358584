import React from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"

// Redux
import {useSelector} from "react-redux";
import {selectCartography } from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {AvField, AvForm} from "availity-reactstrap-validation";

const CartographyModal = ({ isOpen, toggle, updateCartography}) => {

  const { modal } = useSelector(selectCartography);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <ModalHeader toggle={toggle}>
          <p className="modal-title mt-0">Modifier la compétence individuelle de {modal.employeeFirstname} {modal.employeeLastname}</p>
          <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
      </ModalHeader>

      <ModalBody>

        <AvForm onValidSubmit={updateCartography}>

          <Row form>

            <Col className="col-12 mb-3">
              <AvField
                name="message"
                label="Commentaire (facultatif)"
                type="text"
                value={modal.message ? modal.message : ""}
              />
            </Col>

            <Col className="col-6 mb-3">
              <AvField
                className="form-select"
                type="select"
                name="colorRisk"
                label="Niveau de maîtrise"
                validate={{ required: { value: true } }}
                value={modal.colorRisk ? modal.colorRisk : "default"}
              >
                <option value="default">Aucun</option>
                <option value="not-concerned">Non concerné</option>
                <option value="bad">Non maîtrisé</option>
                <option value="medium">Débutant</option>
                <option value="good">Confirmé</option>
                <option value="very-good">Expert</option>
              </AvField>
            </Col>

            <AvField type="hidden" name="employeeActionId" value={modal.employeeActionId ? modal.employeeActionId : null}></AvField>

          </Row>

          <Row>
            <Col>
              <div className="text-end">

                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>

                <button type="submit" className="btn btn-success save-event">Modifier la compétence</button>

              </div>
            </Col>
          </Row>
        </AvForm>

      </ModalBody>
    </Modal>
  )
}

export default CartographyModal

CartographyModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
