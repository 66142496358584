import React from "react"
import { Link } from "react-router-dom"
import {Badge} from "reactstrap";

const PrestataireColumn = toggleModal => [

  {
    dataField: "name",
    text: "Prestataire",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <Link to={{ pathname: "/details-prestataire", state: row.id }}>
          <p className="mb-0 color-primary">{cellContent}</p>
        </Link>
      )
    },
    csvFormatter: (cellContent, row) => `${row.name}`,

    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "locality.region.name",
    text: "Région",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "commercialCoverage",
    text: "Domaine d'activité",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "contactLastname",
    text: "Contact",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <p className="mb-0">{row.contactFirstname} {row.contactLastname}</p>
      )
    },
    csvFormatter: (cellContent, row) => `${row.contactFirstname} ${row.contactLastname}`,
    sortValue: (cell, row) => row.contactLastname,
    sortCaret: (order) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "contactEmail",
    text: "Contact (email)",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      return (
          <p className="mb-0">{row.contactEmail}</p>
      )
    },
    csvFormatter: (cellContent, row) => `${row.contactEmail}`,
    sortValue: (cell, row) => row.contactEmail,
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "activeAccount",
    text: "Statut",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.activeAccount) {
        return <Badge className="badge bg-pill bg-soft-success" color="success" pill>Actif</Badge>
      } else {
        return <Badge className="badge bg-pill bg-soft-warning" color="warning" pill>Suspendu</Badge>
      }
    },
    csvFormatter: (cellContent, row) => {
      return (row.activeAccount) ? "Actif" : "Suspendu"
    },
    sortCaret: (order, column) => {
      if (!order) return (<i className="uil uil-sort mx-1 color-sort"></i>);
      else if (order === 'asc') return (<i className="uil uil-angle-double-down font-size-20 mx-1 color-sort"></i>);
      else if (order === 'desc') return (<i className="uil uil-angle-double-up font-size-20 mx-1 color-sort"></i>);
      return null;
    }
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    headerAlign: 'center',
    headerClasses: 'header-column-title',
    align: 'center',
    formatter: (cellContent, row) => (
      <div className="flex justify-content-center" style={{ minWidth: "130px"}}>
        <Link to={{ pathname: "details-prestataire", state: row.id }} className="btn btn-link waves-effect px-2 text-success">
          <i className="uil uil-eye font-size-20"></i>
        </Link>

        <Link to={{ pathname: "modifier-prestataire", state: row.id }} className="btn btn-link waves-effect px-2 text-info">
          <i className="uil uil-pen font-size-18"></i>
        </Link>

        <button
          type="button"
          className="btn btn-link waves-effect text-danger"
          onClick={ () => toggleModal(row) }
          data-toggle="modal"
          data-target="modal-delete"
        >
          <i className="uil uil-trash-alt font-size-18"></i>
        </button>
      </div>
    ),
    csvExport: false
  }

]

export default PrestataireColumn
