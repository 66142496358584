import React from "react"
import {Col, Container, Row} from "reactstrap"
//import {Card, CardBody, Media, Table} from "reactstrap"
import {Redirect} from "react-router-dom"
//import {isEmpty, map} from "lodash";


// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// import images
//import logo from "assets/images/logo-dark.png"
//import logolight from "assets/images/logo-light.png"

const TransactionDetail = ({isRole, location}) => {
/*
  const [invoiceDetail, setInvoiceDetail] = ([{
    id: 1,
    invoiceID: "EDC0131",
    date: "10 Jul, 2020",
    Amount: "141",
    color: "success",
    status: "Payé",
    billingName: "Ma jolie licorne",
    billingAddress: "443 rue de la prairie, 35000 RENNES",
    billingEmail: "majolielicorne@test.com",
    billingPhoneno: "02 99 34 54 45",
    orderId: "1123456",
    orderSummary: {
      items: [
        {
          id: 1,
          item: "Réussir son SEO",
          price: "260.00",
          margin: "26.00",
          total: "286.00",
          quantity: 1,
          trainingEntreprise: "Insaniam",
          place: "Rennes",
          dates: "Du 23 décembre 2021 à 08h00 au 23 décembre 2021 à 18h00"
        },
        {
          id: 2,
          item: "Prise de parole",
          price: "320.00",
          margin: "32.00",
          total: "352.00",
          quantity: 1,
          trainingEntreprise: "Lila Formation",
          place: "Brest",
          dates: "Du 05 janvier 2021 à 08h00 au 06 janvier 2021 à 18h00"
        },
      ],
      subTotal: "580.00",
      marginTotal: "58.00",
      tva: "127.60",
      total: "707.60",
    },
  }])
*/
  //Print the Invoice
  //const printInvoice = () => window.print();

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <div className="page-content my-5">
          <Container fluid>

            <Row className="mb-5">
              <Col md="12">
                <p>Cette fonctionnalité est en cours de développement. Merci pour votre patience.</p>
              </Col>
            </Row>

            {/*
            <Row>
              <Col lg="12">

                <Card className="mt-3 mb-5">
                  <CardBody className="bg-gradient-primary">
                    <Media className="d-flex">

                      <Media body className="flex-1 align-self-center">
                        <div className="text-muted">
                          <h5 className="mb-3 text-white">Détail d'une transaction</h5>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mt-1 mb-3">

                  <Link to="/gestion-transactions" className="btn btn-primary waves-effect waves-light mx-2 mb-3">
                    <i className="uil uil-corner-up-right-alt me-1"></i>Retour liste des transactions
                  </Link>

                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        Facture #{invoiceDetail.invoiceID}
                        <span className={`badge bg-${invoiceDetail.color} font-size-12 ms-2`}>{invoiceDetail.status}</span>
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo" height="100"/>
                      </div>
                      <div className="text-muted">
                        <p className="mb-1">BOIT'ACTION</p>
                        <p className="mb-1">5 Rue du manoir de Servigné</p>
                        <p className="mb-1">35000 RENNES</p>
                        <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i> contact@firmain.fr</p>
                        <p><i className="uil uil-phone me-1"></i> 06 22 63 43 04</p>
                      </div>
                    </div>
                    <hr className="my-4"/>
                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          <h5 className="font-size-16 mb-3">Organisation :</h5>
                          <h5 className="font-size-15 mb-2">Ma jolie licorne</h5>
                          <p className="mb-1">443 rue de la prairie, 35000 Rennes</p>
                          <p className="mb-1">majolielicorne@test.com</p>
                          <p>02 99 34 54 45</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">

                          <div>
                            <h5 className="font-size-16 mb-1">Facture no:</h5>
                            <p>EDC0131</p>
                          </div>

                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">Date de facturation:</h5>
                            <p>10 Juillet 2021</p>
                          </div>

                        </div>
                      </Col>
                    </Row>

                    <div className="py-2 mt-5">

                      <h5 className="font-size-15">Récapitulatif des formations choisies</h5>

                      <div className="table-responsive">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                          <tr>
                            <th style={{width: "70px"}}>Nr</th>
                            <th>Description</th>
                            <th>Prix HT</th>
                            <th>Marge EDC</th>
                            <th>Quantité</th>
                            <th className="text-end" style={{width: "120px"}}>Total</th>
                          </tr>
                          </thead>
                          <tbody>
                          {map(
                            invoiceDetail.orderSummary.items,
                            (item, key) => (
                              <tr key={key}>

                                <td>{item.id}</td>

                                <td>
                                  <h5 className="font-size-17 text-primary mb-1">{item.item}</h5>
                                  <p className="mb-0">Prestataire : <span className="fw-medium">{item.trainingEntreprise}</span></p>
                                  <p className="mb-0">Lieu : <span className="fw-medium">{item.place}</span></p>
                                    <p className=""><span className="fw-medium">{item.dates}</span></p>
                                </td>

                                <td>{item.price} €</td>
                                <td>{item.margin} €</td>
                                <td>{item.quantity}</td>
                                <td className="text-end">{item.total} €</td>
                              </tr>
                            )
                          )}

                          </tbody>
                        </Table>
                      </div>

                    </div>

                    <div className="py-2 mt-5">

                      <div className="table-responsive">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                          <tr>
                            <th style={{width: "70px"}}></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className="text-end" style={{width: "120px"}}></th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr>
                            <th colSpan="4" className="text-end">Sous-total</th>
                            <td className="text-end">{invoiceDetail.orderSummary.subTotal} €</td>
                          </tr>

                          <tr>
                            <th colSpan="4" className="border-0 text-end">Marge EDC :</th>
                            <td className="border-0 text-end">{invoiceDetail.orderSummary.marginTotal} €</td>
                          </tr>

                          <tr>
                            <th colSpan="4" className="border-0 text-end">TVA</th>
                            <td className="border-0 text-end">{invoiceDetail.orderSummary.tva} €</td>
                          </tr>

                          <tr>
                            <th colSpan="4" className="border-0 text-end">Total</th>
                            <td className="border-0 text-end"><h4 className="m-0">{invoiceDetail.orderSummary.total} €</h4></td>
                          </tr>

                          </tbody>
                        </Table>

                      </div>
                      <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" className="btn btn-primary waves-effect waves-light me-1">Pdf <i className="uil uil-download-alt ms-2"></i></Link>{" "}
                          <Link to="#" onClick={printInvoice} className="btn btn-primary waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-success w-md waves-effect waves-light">Envoyer</Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            */}

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }

}

export default TransactionDetail
