import React from "react";

const FormationInternalDetailColumn = toggleModalIntervenantRemove => [

  {
    dataField: "user.image.filePath",
    text: "",
    sort: true,
    formatter: (column, row) => {
      return <>
        {
          row.hasOwnProperty('image')
            ? (
              <img
                className="avatar-title rounded-circle bg-white"
                style={{ width: 35, height: 35 }}
                src={process.env.REACT_APP_URL + "/media/" + row.user.image.filePath}
                alt="Header Avatar"
              />
            ) : (
              <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-13`} style={{ width: 35, height: 35 }}>
                {row.user.firstname.charAt(0).toUpperCase() + row.user.lastname.charAt(0).toUpperCase()}
              </span>
            )
        }
      </>
    },
  },

  {
    dataField: "user.lastname",
    text: "Intervenant",
    sort: true,
    formatter: (column, row) => (
      <>
        <p className="mb-0">{row.user.firstname} {row.user.lastname}</p>
      </>
    ),
  },

  {
    dataField: "job",
    text: "Qualification",
    sort: true
  },

  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    headerStyle: {width: '50px'},
    formatter: (cellContent, row) => (

      <button
        type="button"
        className="btn btn-link waves-effect px-3 text-danger"
        onClick={() => toggleModalIntervenantRemove(row.id)}
        data-toggle="modal"
        data-target="modal-delete"
      >
        <i className="uil uil-times font-size-17"></i>
      </button>

    ),
  },

];

export default FormationInternalDetailColumn;
