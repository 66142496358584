import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {map} from "lodash";

// Login Redux States

import {
  CLIENT_EMPLOYEES,
  CLIENT_EMPLOYEE,
  CLIENT_EMPLOYEE_SKILLS,
  CERTIFICATES,
  CERTIFICATES_BY_ADMIN,
  GET_CLIENT_EMPLOYEES,
  GET_CLIENT_EMPLOYEE,
  GET_CLIENT_EMPLOYEE_SKILLS,
  GET_CLIENT_EMPLOYEE_CARTO_NOT_ARCHIVED,
  GET_CERTIFICATES,
  CLIENT_EMPLOYEE_SKILLS_BY_USER,
  CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER,
  ADD_CLIENT_EMPLOYEE,
  UPDATE_CLIENT_EMPLOYEE,
  UPDATE_CLIENT_EMPLOYEE_SKILL,
  UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF,
  DELETE_CLIENT_EMPLOYEE,
  CLIENT_EMPLOYEE_SUCCESS,
  CLIENT_EMPLOYEE_ERROR,
  GET_CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER,
  GET_LAST_FORMATION,
  GET_NEXT_FORMATION,
  GET_NBR_FORMATION_FINISHED,
  GET_NBR_HOUR_FORMATION
} from "./clientEmployeeSlice"

import {
  getClientEmployees,
  getClientEmployee,
  putClientEmployee,
  deleteClientEmployee,
  postClientEmployee,
  putEmployeeSkill,
  putEmployeeSkillObjectif,
  putCartographyUpdatedAt,
  getAccount
} from "helpers/request_helper"

import {CLIENT_ACCOUNT} from "store/account/clientAccount/clientAccountSlice";

import { ADD_NOTIFICATION } from "store/notification/notificationSlice";

function* viewClientEmployees() {
  try {
    const response = yield call(getClientEmployees)
    yield put(GET_CLIENT_EMPLOYEES(response['hydra:member']))
    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* viewClientEmployee({ payload: { clientEmployeeId } }) {
  try {
    const response = yield call(getClientEmployee, clientEmployeeId)
    yield put(GET_CLIENT_EMPLOYEE(response))
    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* viewClientEmployeeSkills({ payload: { clientEmployeeId, cartographyIdx } }) {
  try {
    const response = yield call(getClientEmployee, clientEmployeeId)
    yield put(GET_CLIENT_EMPLOYEE(response))

    const cartographiesFiltering = response.employees.filter( employee => !employee.cartography.intervention.isArchived).reverse()
    yield put(GET_CLIENT_EMPLOYEE_CARTO_NOT_ARCHIVED(cartographiesFiltering))

    const newArray = []

    const pole = response.pole
    const service = response.service
    const cartography = cartographiesFiltering[cartographyIdx !== '' ? cartographyIdx : cartographiesFiltering.length - 1]

    if (response.hasOwnProperty('employees') && response.employees.length >= 1) {

     map(cartographiesFiltering[cartographyIdx !== '' ? cartographyIdx : 0].employeeSkills, employeeSkill => {
       newArray.push(
         {
           id: employeeSkill.skill.id,
           pole,
           service,
           cartography,
           skillDomain: employeeSkill.skill.skillDomain.name,
           skillTask: employeeSkill.skill.task,
           risk: employeeSkill.colorRisk,
           message: employeeSkill.message,
           objectif: employeeSkill.objectif,
           colorRiskObjectif: employeeSkill.colorRiskObjectif,
           colorRiskUpgrade: employeeSkill.colorRiskUpgrade,
           messageUpgrade: employeeSkill.messageUpgrade,
           isUpgrade: employeeSkill.isUpgrade,
           upgradeDate: employeeSkill.upgradeDate,
           upgradeStatus: employeeSkill.upgradeStatus,
           employeeSkillId: employeeSkill.id
         }
       )
     })
    }

    yield put(GET_CLIENT_EMPLOYEE_SKILLS(newArray))

    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* viewClientEmployeeSkillsByUser({ payload: { userId, cartographyIdx  } }) {
  try {

    const user = yield call(getAccount, userId)

    const response = yield call(getClientEmployee, user.clientEmployee.id)
    yield put(GET_CLIENT_EMPLOYEE(response))

    const cartographiesFiltering = response.employees.filter( employee => !employee.cartography.intervention.isArchived).reverse()
    yield put(GET_CLIENT_EMPLOYEE_CARTO_NOT_ARCHIVED(cartographiesFiltering))

    const newArray = []

    const pole = response.pole
    const service = response.service
    const cartography = cartographiesFiltering[cartographyIdx !== '' ? cartographyIdx : cartographiesFiltering.length - 1]

    if (response.hasOwnProperty('employees') && response.employees.length >= 1) {

      map(cartographiesFiltering[cartographyIdx !== '' ? cartographyIdx : 0].employeeSkills, employeeSkill => {
        newArray.push(
          {
            id: employeeSkill.skill.id,
            pole,
            service,
            cartography,
            skillDomain: employeeSkill.skill.skillDomain.name,
            skillTask: employeeSkill.skill.task,
            risk: employeeSkill.colorRisk,
            message: employeeSkill.message,
            objectif: employeeSkill.objectif,
            colorRiskObjectif: employeeSkill.colorRiskObjectif,
            colorRiskUpgrade: employeeSkill.colorRiskUpgrade,
            messageUpgrade: employeeSkill.messageUpgrade,
            isUpgrade: employeeSkill.isUpgrade,
            upgradeDate: employeeSkill.upgradeDate,
            upgradeStatus: employeeSkill.upgradeStatus,
            employeeSkillId: employeeSkill.id
          }
        )
      })
    }

    yield put(GET_CLIENT_EMPLOYEE_SKILLS(newArray))

    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* viewClientEmployeeAnalyticsFormationByUser({ payload: { userId } }) {
  try {

    const user = yield call(getAccount, userId)

    const response = yield call(getClientEmployee, user.clientEmployee.id)
    yield put(GET_CLIENT_EMPLOYEE(response))

    yield put(GET_CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER(response.employees[0].trainingPlanActions))

    // ======== get the last formation ================

    const sortClientEmployeeAnalyticsByFinished = (allTrainingFormationData) => {
      const clientEmployeeAnalyticsWithFinished = allTrainingFormationData.filter( training =>  training.hasOwnProperty('trainingEnd') && training.finished === true)
      return clientEmployeeAnalyticsWithFinished.sort((a, b) => new Date(b.trainingEnd) - new Date(a.trainingEnd))
    }

    // ajout d'un item avec la date du jour dans le tableau
    let newArrayEnd = [...response.employees[0].trainingPlanActions, {title: "dateNow", trainingEnd: new Date(), finished: true}]

    // filtre du tableau avec les formations terminées et trie du nouveau tableau en date croissante
    let newArrayEndSorted = sortClientEmployeeAnalyticsByFinished(newArrayEnd)

    let lastFormation = {}

    newArrayEndSorted.forEach( (item, index) => {
      if (item.title === 'dateNow') {
        lastFormation = newArrayEndSorted[index + 1]
      }
    })

    yield put(GET_LAST_FORMATION(lastFormation))

    // =======================================================

    // ======== get the next formation =======================

      const sortClientEmployeeAnalyticsByDateStart = (allTrainingFormationData) => {
        const clientEmployeeAnalyticsWithTrainingStart = allTrainingFormationData.filter( training => training.hasOwnProperty('trainingStart'))
        return clientEmployeeAnalyticsWithTrainingStart.sort((a, b) => new Date(b.trainingStart) - new Date(a.trainingStart))
      }

    // ajout d'un item avec la date du jour dans le tableau
    let newArrayStart = [...response.employees[0].trainingPlanActions, {title: "dateNow", trainingStart: new Date()}]

    // filtre du tableau avec les formations terminées et trie du nouveau tableau en date croissante
    let newArrayStartSorted = sortClientEmployeeAnalyticsByDateStart(newArrayStart)

    let nextFormation = {}

    newArrayStartSorted.map( (item, index) => {
      if (item.title === 'dateNow') {
        nextFormation = newArrayStartSorted[index - 1]
      }
    })

    yield put(GET_NEXT_FORMATION(nextFormation))

    // =======================================================

    // ====== get nbr of formation finished (bar graph) ======

    let newGraphDatas = { yAxis: [], xAxis: ['2021', '2022', '2023', '2024']}

    let formationsFinished = response.employees[0].trainingPlanActions.filter( item => item.finished === true)

      newGraphDatas.yAxis.push([...formationsFinished].filter( item => new Date(item.trainingEnd).getFullYear() == '2021').length)
      newGraphDatas.yAxis.push([...formationsFinished].filter( item => new Date(item.trainingEnd).getFullYear() == '2022').length)
      newGraphDatas.yAxis.push([...formationsFinished].filter( item => new Date(item.trainingEnd).getFullYear() == '2023').length)
      newGraphDatas.yAxis.push([...formationsFinished].filter( item => new Date(item.trainingEnd).getFullYear() == '2024').length)

    yield put(GET_NBR_FORMATION_FINISHED(newGraphDatas))

    // =======================================================

    // ====== get nbr of formation finished (bar graph) ======

    let nbrHoursFormation = formationsFinished.reduce( (acc, next) => acc + next.formation.durationUnit === 'heures' ? next.formation.duration : next.formation.duration * 7, 0)

    yield put(GET_NBR_HOUR_FORMATION(Number(nbrHoursFormation)))

    // =======================================================

    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* addClientEmployee({payload: { firstname, lastname, job, service, pole, email, arrivalDate, client, clientId }}) {

  try {
    const data = {
      firstname,
      lastname,
      job,
      service,
      pole,
      email,
      arrivalDate,
      client
    }
    yield call(postClientEmployee, data)

    yield put(CLIENT_ACCOUNT({ clientId: clientId }))
    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* editClientEmployee({ payload: { firstname, lastname, job, service, pole, email, arrivalDate, employeeId, clientId } }) {
  try {
    yield call(putClientEmployee, {
      firstname,
      lastname,
      job,
      service,
      pole,
      email,
      arrivalDate,
      clientEmployeeId: employeeId
    })
    yield put(CLIENT_ACCOUNT({ clientId: clientId }))
    yield put(CLIENT_EMPLOYEE_SUCCESS("Les informations du collaborateur ont bien été modifiées"))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error))
  }
}

function* editClientEmployeeSkill({ payload: { employeeSkillId, clientEmployeeId, cartographyIdx, upgradeStatus, colorRiskUpgrade, messageUpgrade, cartographyId, userId, clientId } }) {

  try {
    if (upgradeStatus === 'accord') {
      yield call(putEmployeeSkill, {
        colorRisk:colorRiskUpgrade,
        colorRiskUpgrade: null,
        message: messageUpgrade,
        messageUpgrade: null,
        isUpgrade: true,
        upgradeDate: new Date(),
        employeeSkillId: employeeSkillId,
        upgradeStatus: 'accord',
        clientEmployeeId,
        cartographyIdx
      })

      yield call(putCartographyUpdatedAt, { cartographyId })

      yield put(ADD_NOTIFICATION({
        userId,
        title: "Mise à niveau d'une évaluation de compétence",
        message: `Une mise à niveau a été acceptée.`,
        link: "/ma-fiche-de-mission",
        linkState: null,
        allAdmin: false,
        notificationRead: false
      }))

    } else if (upgradeStatus === 'refus') {
      yield call(putEmployeeSkill, {
        colorRiskUpgrade: null,
        messageUpgrade: null,
        employeeSkillId: employeeSkillId,
        upgradeStatus: 'refus',
        clientEmployeeId,
        cartographyIdx
      })

      yield call(putCartographyUpdatedAt, { cartographyId })

      yield put(ADD_NOTIFICATION({
        userId,
        title: "Mise à niveau d'une évaluation de compétence",
        message: `Une mise à niveau a été refusée.`,
        link: "/ma-fiche-de-mission",
        linkState: null,
        allAdmin: false,
        notificationRead: false
      }))

    } else {
      yield call(putEmployeeSkill, {
        colorRiskUpgrade,
        messageUpgrade,
        employeeSkillId,
        upgradeStatus,
        clientEmployeeId,
        cartographyIdx
      })
    }

    yield put(ADD_NOTIFICATION({
      userId: clientId,
      title: "Mise à niveau d'une évaluation de compétence",
      message: `Une mise à niveau a été proposée.`,
      link: "/fiches-mission-detail",
      linkState: clientEmployeeId,
      allAdmin: false,
      notificationRead: false
    }))

    yield put(CLIENT_EMPLOYEE_SKILLS({ clientEmployeeId, cartographyIdx }))
    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error))
  }
}

function* editClientEmployeeSkillObjectif({ payload: { colorRiskObjectif, objectif, employeeSkillId, clientEmployeeId, cartographyIdx } }) {

  try {
    yield call(putEmployeeSkillObjectif, {
      colorRiskObjectif,
      objectif,
      employeeSkillId
    })

    yield put(CLIENT_EMPLOYEE_SKILLS({ clientEmployeeId, cartographyIdx }))
    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error))
  }
}

function* removeClientEmployee({ payload: { clientEmployeeId, clientId } }) {
  try {
    yield call(deleteClientEmployee, clientEmployeeId)
    yield put(CLIENT_ACCOUNT({ clientId: clientId }))
    yield put(CLIENT_EMPLOYEE_SUCCESS(`Suppression du collaborateur avec succès !!`))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR("Une erreur s'est produite !"))
  }
}

function* viewCertificates({ payload: { userId } }) {
  try {
    const user = yield call(getAccount, userId)
    const response = yield call(getClientEmployee, user.clientEmployee.id)

    let certificates = []

    map(response.employees, employee => {
      employee.trainingPlanActions.forEach( formation => {
        if (formation.certificateReceived) certificates.push(formation)
      })
    })

    yield put(GET_CERTIFICATES(certificates))

    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

function* viewCertificatesByAdmin({ payload: { clientEmployeeId } }) {
  try {
    const response = yield call(getClientEmployee, clientEmployeeId)

    let certificates = []

    map(response.employees, employee => {
      employee.trainingPlanActions.forEach( formation => {
        if (formation.certificateReceived) certificates.push(formation)
      })
    })

    yield put(GET_CERTIFICATES(certificates))

    yield put(CLIENT_EMPLOYEE_SUCCESS(""))
  } catch (error) {
    yield put(CLIENT_EMPLOYEE_ERROR(error.message))
  }
}

export function* watchProfile() {
  yield takeEvery(CLIENT_EMPLOYEES.type, viewClientEmployees)
  yield takeEvery(CLIENT_EMPLOYEE.type, viewClientEmployee)
  yield takeEvery(CLIENT_EMPLOYEE_SKILLS.type, viewClientEmployeeSkills)
  yield takeEvery(CLIENT_EMPLOYEE_SKILLS_BY_USER.type, viewClientEmployeeSkillsByUser)
  yield takeEvery(CLIENT_EMPLOYEE_ANALYTICS_FORMATION_BY_USER.type, viewClientEmployeeAnalyticsFormationByUser)
  yield takeEvery(ADD_CLIENT_EMPLOYEE.type, addClientEmployee)
  yield takeEvery(UPDATE_CLIENT_EMPLOYEE.type, editClientEmployee)
  yield takeEvery(UPDATE_CLIENT_EMPLOYEE_SKILL.type, editClientEmployeeSkill)
  yield takeEvery(UPDATE_CLIENT_EMPLOYEE_SKILL_OBJECTIF.type, editClientEmployeeSkillObjectif)
  yield takeEvery(DELETE_CLIENT_EMPLOYEE.type, removeClientEmployee)
  yield takeEvery(CERTIFICATES.type, viewCertificates)
  yield takeEvery(CERTIFICATES_BY_ADMIN.type, viewCertificatesByAdmin)
}

export default function* ClientEmployeeSaga() {
  yield all([fork(watchProfile)])
}
