import { call, put, takeEvery } from "redux-saga/effects"
import jwt_decode from "jwt-decode";

// expiration time to value in localStorage
import { setLocalStorageWithExpiry } from "helpers/storage_helper"

// Login Redux States
import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOCK_SCREEN_USER, API_ERROR, EDIT_USER } from "./loginSlice"
import { RESET_NOTIFICATIONS } from "store/notification/notificationSlice"

//Include Both Helper File with needed methods
import { postLogin, getProfile } from "helpers/request_helper"

function* loginUser({ payload: { email, password, rememberme, history } }) {
    try {
        const response = yield call(postLogin, {
            username: email,
            password: password,
        })
        if (jwt_decode(response.token).status) {
            localStorage.setItem("accessJWT", response.token)
            localStorage.setItem("refreshToken", response.refresh_token)
            setLocalStorageWithExpiry("rememberme", rememberme, 604800) // 1 week in seconds
            localStorage.setItem("username", email)
            localStorage.setItem("id", jwt_decode(response.token).id)

            yield put(EDIT_USER(jwt_decode(response.token)))

            // stockage du nom / prénom dans storage pour avatar du header
            const responseUser = yield call(getProfile, localStorage.getItem('id'))
            localStorage.setItem("firstname", responseUser.firstname)
            localStorage.setItem("lastname", responseUser.lastname)

            if (responseUser.hasOwnProperty('client')) {
                localStorage.setItem('clientId', responseUser.client.id)
            }

            if (responseUser.hasOwnProperty('prestataire')) {
                localStorage.setItem('prestataireId', responseUser.prestataire.id)
            }

            yield put(LOGIN_SUCCESS(response))
            history.push("/dashboard")
        } else {
            yield put(API_ERROR("Votre compte est suspendu. Merci de contacter l'assistance technique"))
            history.push("/login")
        }

    } catch (error) {
        yield put(API_ERROR("Identifiant / mot de passe incorrect !!"))
    }
}

function* lockScreenUser({ payload: { email, password, history } }) {
    try {
        const response = yield call(postLogin, {
            username: email,
            password: password,
        })
        localStorage.setItem("accessJWT", JSON.stringify(response))
        localStorage.setItem("username", email)
        yield put(LOGIN_SUCCESS(response))
        history.push("/dashboard")
    } catch (error) {
        if (error.response.status === 401) {
            yield put(API_ERROR("Le mot de passe n'est pas correct"))
        } else {
            yield put(API_ERROR(error.message))
        }
    }
}

function* logoutUser({ payload: { rememberme, history } }) {
    try {
        yield put(RESET_NOTIFICATIONS())

        if(!rememberme) {
            localStorage.removeItem("accessJWT")
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("username")
            localStorage.removeItem("firstname")
            localStorage.removeItem("lastname")
            localStorage.removeItem("id")
            localStorage.removeItem("clientId")
            localStorage.removeItem("prestataireId")
            localStorage.removeItem("authUser")
        }
        history.push("/login")
    } catch (error) {
        yield put(API_ERROR(error))
    }
}

export default function* LoginSaga() {
    yield takeEvery(LOGIN_USER.type, loginUser)
    yield takeEvery(LOCK_SCREEN_USER.type, lockScreenUser)
    yield takeEvery(LOGOUT_USER.type, logoutUser)
}
