import React from "react"
import PropTypes from "prop-types"
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"

// REDUX
import {useSelector} from "react-redux";
import {selectCartography} from "store/skillsDevelopmentPlan/cartography/cartographySlice";
import {AvField, AvForm} from "availity-reactstrap-validation";

const CartographyRequiredLevelModal = ({ isOpen, toggle, updateRequiredLevel}) => {

  const { modal } = useSelector(selectCartography);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >

      <ModalHeader toggle={toggle}>
        <p className="modal-title mt-0">Modifier le niveau requis</p>
        <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>

      <ModalBody>

        <AvForm onValidSubmit={updateRequiredLevel}>

          <Row form>

            <Col className="col-12 mb-3">

              <p>Choississez le niveau requis pour la compétence {modal.task} ({modal.hasOwnProperty('skillActivity') && modal.skillActivity.name}) : </p>

              <AvField
                name="requiredLevel"
                label="Niveau requis"
                type="number"
                min={2}
                max={10}
                value={modal.requiredLevel ? modal.requiredLevel : ""}
                errorMessage="Veuillez saisir un chiffre entre 2 et 10"
                required
              />
            </Col>

            <AvField type="hidden" name="skillId" value={modal.id ? modal.id : null}></AvField>

          </Row>

          <Row>
            <Col>
              <div className="text-end">

                <button type="button" className="btn btn-primary me-2" onClick={toggle}>Annuler</button>

                <button type="submit" className="btn btn-success save-event">Modifier le niveau</button>

              </div>
            </Col>
          </Row>
        </AvForm>

      </ModalBody>
    </Modal>
  )
}

export default CartographyRequiredLevelModal

CartographyRequiredLevelModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}
