import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap"
import {Redirect} from "react-router-dom"
import {map} from "lodash";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

import ClientFormationInternalIntervenantGestionCard from "./ClientFormationInternalIntervenantGestionCard";
import ClientFormationInternalIntervenantGestionModalAdd from "./ClientFormationInternalIntervenantGestionModalAdd";
import ClientFormationInternalIntervenantGestionModalRemove from "./ClientFormationInternalIntervenantGestionModalRemove";

// REDUX
import {useDispatch, useSelector} from "react-redux";
import {selectFormationInternal, FORMATION_INTERNALS_FILTER_BY_CLIENT, ADD_FORMATION_INTERNAL_INTERVENANT, UPDATE_FORMATION_INTERNAL_INTERVENANT} from "store/formationInternal/formationInternalSlice";

const ClientFormationInternalIntervenantGestion = ({ isRole, location }) => {

  const clientId = Number(localStorage.getItem('clientId'))

  const dispatch = useDispatch()

  const { formationInternals, intervenantFormationInternal, error } = useSelector(selectFormationInternal);

  const [showModalIntervenantRemove, setShowModalIntervenantRemove] = useState(false)
  const [showModalIntervenantAdd, setShowModalIntervenantAdd] = useState(false)

  const toggleModalIntervenantRemove = (intervenantId, formationInternalId, formationInternal) => {
    if (!showModalIntervenantRemove) dispatch(ADD_FORMATION_INTERNAL_INTERVENANT({
      intervenantId: intervenantId,
      formationInternalId: formationInternalId,
      formationInternal: formationInternal
    }))
    setShowModalIntervenantRemove(!showModalIntervenantRemove);
  };

  const toggleModalIntervenantAdd = (formationInternal) => {
    if (!showModalIntervenantAdd) dispatch(ADD_FORMATION_INTERNAL_INTERVENANT({
      formationInternal: formationInternal
    }))
    setShowModalIntervenantAdd(!showModalIntervenantAdd);
  };

  const deleteFormationInternalIntervenant = () => {

    const formationInternalIntervenants = []

    intervenantFormationInternal.formationInternal.clientEmployees.forEach(intervenant => {
      if (intervenant.id !== intervenantFormationInternal.intervenantId) {
        formationInternalIntervenants.push(`/api/client_employees/${intervenant.id}`)
      }
    })

    const data = {
      formationInternalId: intervenantFormationInternal.formationInternal.id,
      intervenants: formationInternalIntervenants,
      clientId: clientId,
      role: 'client'
    }

    dispatch(UPDATE_FORMATION_INTERNAL_INTERVENANT(data))

    setShowModalIntervenantRemove(!showModalIntervenantRemove);
  }

  const addFormationInternalIntervenant = employeeId => {

    const formationInternalIntervenants = []

    intervenantFormationInternal.formationInternal.clientEmployees.forEach(intervenant => {
      formationInternalIntervenants.push(`/api/client_employees/${intervenant.id}`)
    })
    formationInternalIntervenants.push(`/api/client_employees/${employeeId}`)

    const data = {
      formationInternalId: intervenantFormationInternal.formationInternal.id,
      intervenants: formationInternalIntervenants,
      clientId: clientId,
      role: 'client'
    }
    dispatch(UPDATE_FORMATION_INTERNAL_INTERVENANT(data))

    setShowModalIntervenantAdd(!showModalIntervenantAdd);
  }

  useEffect(() => {
    dispatch(FORMATION_INTERNALS_FILTER_BY_CLIENT({clientId: clientId}))
  }, [dispatch, clientId])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>

        <ClientFormationInternalIntervenantGestionModalAdd isOpen={showModalIntervenantAdd} toggle={toggleModalIntervenantAdd} addFormationInternalIntervenant={addFormationInternalIntervenant} />
        <ClientFormationInternalIntervenantGestionModalRemove isOpen={showModalIntervenantRemove} toggle={toggleModalIntervenantRemove} deleteFormationInternalIntervenant={deleteFormationInternalIntervenant} />

        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/mes-formations-internes" title="Retour à la liste des formations internes" />

            <h5 className="font-size-22 color-secondary text-center mb-5">Gestion des intervenants</h5>

            <SuccessErrorAlert error={error} />

            <Row>
              <h5 className="font-size-18 color-secondary mt-3 mb-3">
                <i className="mdi mdi-arrow-right text-secondary me-1"></i>Liste des formations internes proposées
              </h5>
            </Row>

            <Row>
              {map(formationInternals, (formationInternal, key) => (
                <Col lg={4} key={"_formation_" + key}>
                  <ClientFormationInternalIntervenantGestionCard
                    color={"primary"}
                    toggleModalIntervenantRemove={toggleModalIntervenantRemove}
                    toggleModalIntervenantAdd={toggleModalIntervenantAdd}
                    formationInternal={formationInternal}
                  />
                </Col>
              ))}
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{ pathname: "/page-404", state: { from: location } }}/>
    )
  }

}

export default ClientFormationInternalIntervenantGestion
