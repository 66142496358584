import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

// Role Verification
import { verifRoleToken } from "helpers/role_helper"

const SidebarContent = props => {

  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);

  useEffect(() => {

    const pathName = props.location.pathname
    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">

            <li>
              <Link to="/dashboard" className="waves-effect mt-4 mb-4">
                <i className="uil-home-alt"></i>
                <span>Tableau de bord</span>
              </Link>
            </li>

            { verifRoleToken('ROLE_ADMIN') && (
                <>
                  <li>
                    <Link to="/gestion-comptes" className="has-arrow waves-effect">
                      <i className="uil-users-alt"></i>
                      <span>Comptes</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-comptes">Liste des utilisateurs</Link></li>
                      <li><Link to="/ajout-super-administrateur">Ajouter un super administrateur</Link></li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gestion-organisations" className="has-arrow waves-effect">
                      <i className="uil-house-user"></i>
                      <span>Organisations</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-organisations">Liste des organisations</Link></li>
                      <li><Link to="/ajout-organisation">Ajouter une organisation</Link></li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gestion-transactions" className="has-arrow waves-effect">
                      <i className="uil-shopping-cart"></i>
                      <span>Transactions</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-transactions">Liste des transactions</Link></li>
                      <li><Link to="/ajout-transaction">Ajouter une transaction</Link></li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gestion-prestataires" className="has-arrow waves-effect">
                      <i className="uil-channel"></i>
                      <span>Prestataires</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-prestataires">Liste des prestataires</Link></li>
                      <li><Link to="/ajout-prestataire">Ajouter un prestataire</Link></li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gestion-formations" className="has-arrow waves-effect">
                      <i className="uil-graduation-cap"></i>
                      <span>Catalogue de formation</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-formations">Liste des formations</Link></li>
                      <li><Link to="/ajout-formation">Ajouter une formation</Link></li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gestion-formations-internes" className="has-arrow waves-effect">
                      <i className="uil-graduation-cap"></i>
                      <span>Formations internes</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/gestion-formations-internes">Catalogue de formations internes</Link></li>
                      <li><Link to="/ajout-formation-interne">Ajouter une formation interne</Link></li>
                    </ul>
                  </li>
                </>
            )}


            { verifRoleToken('ROLE_CLIENT') && (
                <>
                  <li>
                    <Link to="/mon-espace" className="waves-effect">
                      <i className="uil-document-info"></i>
                      <span>Mon espace</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/liste-collaborateurs" className="has-arrow waves-effect">
                      <i className="uil-users-alt"></i>
                      <span>Mon équipe</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/fiches-mission">Fiche de mission</Link></li>
                      <li><Link to="/liste-collaborateurs">Liste des collaborateurs</Link></li>
                      <li><Link to="/liste-poles">Liste des pôles</Link></li>
                      {/*<li><Link to="/organigramme">Organigramme</Link></li>*/}
                    </ul>
                  </li>

                  <li>
                    <Link to="/ma-cartographie" className="waves-effect">
                      <i className="uil-map"></i>
                      <span>Cartographie</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/mon-plan-developpement-competences" className="waves-effect">
                      <i className="uil-briefcase"></i>
                      <span>PDC</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/mon-catalogue-de-formation" className="waves-effect">
                      <i className="uil-books"></i>
                      <span>Catalogue de formation</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/mes-formations-internes" className="has-arrow waves-effect">
                      <i className="uil-graduation-cap"></i>
                      <span>Formations internes</span>
                    </Link>

                    <ul className="sub-menu">
                      <li><Link to="/mes-formations-internes">Catalogue de formations internes</Link></li>
                      <li><Link to="/mes-formations-internes-ajout">Ajouter une formation interne</Link></li>
                    </ul>
                  </li>

                  <li>
                    {/*<Link to="/formation-autrement" className="waves-effect">*/}
                    {/*  <i className="uil-graduation-cap"></i>*/}
                    {/*  <span>Se former autrement</span>*/}
                    {/*</Link>*/}
                    <a href="https://seformerautrement.firmain.fr/" target="_blank" rel="noreferrer" className="waves-effect">
                      <i className="uil-graduation-cap"></i>
                      <span>Se former autrement</span>
                    </a>
                  </li>

                </>
            )}

            { verifRoleToken('ROLE_USER') && (
              <>
                <li>
                  <Link to="/ma-fiche-de-mission" className="has-arrow waves-effect">
                    <i className="uil-briefcase"></i>
                    <span>Mes compétences</span>
                  </Link>

                  <ul className="sub-menu">
                    <li><Link to="/ma-fiche-de-mission">Fiche de mission</Link></li>
                    <li><Link to="/mon-plan-de-charge">Plan de charge</Link></li>
                  </ul>
                </li>

                <li>
                  <Link to="/catalogue-de-formation" className="waves-effect">
                    <i className="uil-books"></i>
                    <span>Catalogue de formation</span>
                  </Link>
                </li>

                <li>
                  {/*<Link to="/formation-autrement" className="waves-effect">*/}
                  {/*  <i className="uil-graduation-cap"></i>*/}
                  {/*  <span>Se former autrement</span>*/}
                  {/*</Link>*/}
                  <a href="https://seformerautrement.firmain.fr/" target="_blank" rel="noreferrer" className="waves-effect">
                    <i className="uil-graduation-cap"></i>
                    <span>Se former autrement</span>
                  </a>
                </li>

                <li>
                  <Link to="/mon-espace-personnel" className="waves-effect">
                    <i className="uil-user-square"></i>
                    <span>Mon espace personnel</span>
                  </Link>
                </li>

              </>
            )}

            { verifRoleToken('ROLE_PRESTATAIRE') && (
              <>
                <li>
                  <Link to="/prestataire-informations" className="waves-effect">
                    <i className="uil-user-square"></i>
                    <span>Mes informations</span>
                  </Link>
                </li>

                <li>
                  <Link to="/prestataire-intervenants" className="waves-effect">
                    <i className="uil-users-alt"></i>
                    <span>Gestion des intervenants</span>
                  </Link>
                </li>

                <li>
                  <Link to="/prestataire-formations" className="waves-effect">
                    <i className="uil-graduation-cap"></i>
                    <span>Mes formations</span>
                  </Link>
                </li>
              </>
            )}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default withRouter(SidebarContent)

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
