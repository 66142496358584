import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  SKILL,
  GET_SKILL,
  ADD_SKILL,
  UPDATE_SKILL,
  UPDATE_SKILL_REQUIRED_LEVEL,
  UPDATE_SKILL_RISK,
  DELETE_SKILL,
  SKILL_SUCCESS,
  SKILL_ERROR,
  SKILL_DOMAIN_ACTIVITY,
  ADD_SKILL_ACTIVITIES,
  ADD_SKILL_DOMAINS,
  POLE_EMPLOI_SKILLS,
  GET_POLE_EMPLOI_SKILLS
} from "./skillSlice"

import {CARTOGRAPHY} from "store/skillsDevelopmentPlan/cartography/cartographySlice";

import {
  getSkill,
  postSkill,
  putSkill,
  putSkillRequiredLevel,
  deleteSkill,
  postEmployeeSkill,
  deleteEmployeeSkill,
  getSkillActivities,
  getSkillDomains,
  getSkillsPoleEmploi
} from "helpers/request_helper"

function* viewSkill({ payload: { skillId } }) {
  try {
    const response = yield call(getSkill, skillId)
    yield put(GET_SKILL(response))
    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error.message))
  }
}


function* addSkill({payload: { pole, task, totalVeryGood, totalGood, totalMedium, totalBad, risk, skillDomain, skillActivity, requiredLevel, cartography, cartographyId, employeesId }}) {

  try {
    const data = {
      pole: pole,
      task: task,
      totalVeryGood: totalVeryGood,
      totalGood: totalGood,
      totalMedium: totalMedium,
      totalBad: totalBad,
      risk: risk,
      skillDomain: skillDomain,
      skillActivity: skillActivity,
      requiredLevel: requiredLevel,
      cartography: cartography
    }

    const response = yield call(postSkill, data)

    for (let i = 0; i < employeesId.length; i++) {
      yield call(postEmployeeSkill, {
        employee: `/api/employees/${employeesId[i]}`,
        skill: `/api/skills/${response.id}`,
        message: '',
        notation: 0,
        colorRisk: "default",
        isUpgrade: false
      })
    }

    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error.message))
  }
}

function* editSkill({ payload: { pole, task, totalVeryGood, totalGood, totalMedium, totalBad, risk, skillDomain, skillActivity, requiredLevel, skillId, cartographyId } }) {
  try {
    yield call(putSkill, {
      pole: pole,
      task: task,
      totalVeryGood: totalVeryGood,
      totalGood: totalGood,
      totalMedium: totalMedium,
      totalBad: totalBad,
      risk: risk,
      skillDomain: skillDomain,
      skillActivity: skillActivity,
      requiredLevel: requiredLevel,
      skillId: skillId
    })
    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(SKILL_SUCCESS("La compétence a bien été modifiée"))
  } catch (error) {
    yield put(SKILL_ERROR(error))
  }
}

function* editSkillRequiredLevel({ payload: { requiredLevel, skillId, cartographyId } }) {
  try {
    yield call(putSkillRequiredLevel, {
      requiredLevel: requiredLevel,
      skillId: skillId
    })
    yield put(UPDATE_SKILL_RISK({ skillId: skillId, cartographyId: cartographyId }))
    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error))
  }
}

function* editSkillRisk({ payload: { skillId, cartographyId } }) {

  try {
    // mise à jour des totaux de couleurs (skills)
    const skill = yield call(getSkill, skillId)

    let totalBad = 0
    let totalMedium = 0
    let totalGood = 0
    let totalVeryGood = 0
    let totalRisk
    let requiredLevel = skill.requiredLevel

    skill.employeeSkills.forEach( employeeSkill => {
      if (employeeSkill.colorRisk === "medium") {
        totalMedium++
      } else if (employeeSkill.colorRisk === "bad") {
        totalBad++
      } else if (employeeSkill.colorRisk === "good") {
        totalGood++
      } else if (employeeSkill.colorRisk === "very-good") {
        totalVeryGood++
      }
    })

    // en ordre de priorité de haut en bas (dès qu'une condition est bonne, le code s'arrête et prend le résultat)

    if (totalGood + totalVeryGood > requiredLevel) {
      // SI > X personnes ont un niveau confirmé (good) ou expert (very good) : Fond vert, « OK »
      totalRisk = 3
    } else {
      if (totalGood + totalVeryGood === requiredLevel) {
        // SI X personne ont un niveau confirmé (good) ou expert (very good) : Fond jaune, « Action de formation dans l’année préconisée »
        totalRisk = 2
      } else {
        // SI < X ont un niveau confirmé ou expert : Fond rouge, « Action de formation immédiate préconisée »
        totalRisk = 1
      }
    }

    // gestion du cas où le nombre de collaborateur est inférieur au niveau de risque
    if (totalBad + totalMedium + totalGood + totalVeryGood < requiredLevel) {
      totalRisk = 4
    }

    yield call(putSkill, {
      totalVeryGood: totalVeryGood,
      totalGood: totalGood,
      totalMedium: totalMedium,
      totalBad: totalBad,
      risk: totalRisk,
      skillId: skillId
    })
    yield put(CARTOGRAPHY({ cartographyId: cartographyId }))
    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error))
  }
}

function* removeSkill({ payload: { skillId, cartographyId } }) {
  try {

    const response = yield call(getSkill, skillId)

    for (let i = 0; i < response.employeeSkills.length; i++) {
      yield call(deleteEmployeeSkill, response.employeeSkills[i].id)
    }

    yield call(deleteSkill, skillId)
    yield put(CARTOGRAPHY({cartographyId: cartographyId}))
    yield put(SKILL_SUCCESS(`Suppression de la compétence avec succès !!`))
  } catch (error) {
    yield put(SKILL_ERROR(error.message))
  }
}

function* viewSkillDomainsActivities() {
  try {
    const activitiesResponse = yield call(getSkillActivities)
    yield put(ADD_SKILL_ACTIVITIES(activitiesResponse['hydra:member']))

    const domainsResponse = yield call(getSkillDomains)
    yield put(ADD_SKILL_DOMAINS(domainsResponse['hydra:member']))

    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error.message))
  }
}

// Récupération des compétences depuis Pôle Emploi
function* viewSkillsPoleEmploi({ payload: { query } }) {
  try {
    const response = yield call(getSkillsPoleEmploi, { query: query })
    yield put(GET_POLE_EMPLOI_SKILLS(response))
    yield put(SKILL_SUCCESS(""))
  } catch (error) {
    yield put(SKILL_ERROR(error.message))
  }
}


export function* watchProfile() {
  yield takeEvery(SKILL.type, viewSkill)
  yield takeEvery(ADD_SKILL.type, addSkill)
  yield takeEvery(UPDATE_SKILL.type, editSkill)
  yield takeEvery(UPDATE_SKILL_REQUIRED_LEVEL.type, editSkillRequiredLevel)
  yield takeEvery(UPDATE_SKILL_RISK.type, editSkillRisk)
  yield takeEvery(DELETE_SKILL.type, removeSkill)
  yield takeEvery(SKILL_DOMAIN_ACTIVITY.type, viewSkillDomainsActivities)
  yield takeEvery(POLE_EMPLOI_SKILLS.type, viewSkillsPoleEmploi)
}

export default function* SkillSaga() {
  yield all([fork(watchProfile)])
}
