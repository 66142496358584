import React from "react";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import {map} from "lodash";

// formatage date
import moment from "moment";
import localization from 'moment/locale/fr';

const ClientFormationCard = ({color, trainingPlanAction, formation, employee}) => {

  return (
    <Card outline color={color} className="border">

      <CardHeader className="bg-transparent">
        <h5 className={`my-0 text-${color}`}><i className="uil uil-graduation-cap mx-2"></i>{formation.title}</h5>
      </CardHeader>

      <CardBody className="py-2">

        <CardTitle>
          <div className="d-flex align-items-center mb-3">
            <h6 className="mb-0">code CPF :</h6>
            <p className="font-size-15 text-muted mx-2 mb-0">{formation.cpfCode}</p>
          </div>

          <h6 className="mb-1">Désignation CPF :</h6>
          <p className="font-size-15 text-muted mb-3">{formation.cpfDesignation}</p>

          <div className={"mb-4"}>
            <h6 className="mb-1 text-primary">intervenant(s) :</h6>

            { map(formation.intervenants, (intervenant, key) => (
              <div className="avatar-sm me-4 d-flex align-items-center w-100 mt-2" key={"_intervenant_" + key}>
                {intervenant.hasOwnProperty('image') ? (
                  <>
                    <img
                      className="avatar-title rounded-circle bg-white" style={{width: '50px'}}
                      src={process.env.REACT_APP_URL + "/media/" + intervenant.image.filePath}
                      alt="Header Avatar"
                    />
                    <span className="font-size-15 text-muted mx-3">{intervenant.intFirstname} {intervenant.intLastname}</span>
                  </>
                ) : (
                  <>
                    <span className={`avatar-title rounded-circle bg-primary primary text-white font-size-15`} style={{width: '50px'}}>
                      {intervenant.intFirstname.charAt(0).toUpperCase() + intervenant.intLastname.charAt(0).toUpperCase()}
                    </span>
                    <p className="font-size-15 text-muted mb-0 mx-3">{intervenant.intFirstname} {intervenant.intLastname}</p>
                  </>
                )}
              </div>
            ))}
          </div>

          <h6 className="mb-1 text-primary">Collaborateur participant :</h6>
          <p className="font-size-15 text-muted mb-3">{employee.clientEmployee.user.firstname} {employee.clientEmployee.user.lastname}</p>

          {trainingPlanAction.trainingPlace && (
            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0">Lieu de la formation :</h6>
              <p className="font-size-15 text-muted mx-2 mb-0">{trainingPlanAction.trainingPlace}</p>
            </div>
          )}

          {trainingPlanAction.trainingStart ? (
            <p className="font-size-15 text-muted mb-3">
              Du <span className="color-table-secondary mx-1">{moment.utc(trainingPlanAction.trainingStart).locale('fr', localization).format("DD MMMM yyyy [à] HH[h]mm")}</span>
              {" "}au{" "}
              <span className="color-table-secondary mx-1">{moment.utc(trainingPlanAction.trainingEnd).locale('fr', localization).format("DD MMMM yyyy [à] HH[h]mm")}</span>.
            </p>
          ) : (
            <p  className="font-size-15 text-danger mb-0 mb-3">Date de formation non renseignée</p>
          )}

          <Link to={{ pathname: "/ma-formation-details", state: {formationId: trainingPlanAction.formation.id, url: 'formationDetails' }}} className="btn btn-primary waves-effect waves-light mx-2 mb-3">
            <i className="uil uil-graduation-cap mx-2 font-size-20"></i>Détails de la formation
          </Link>

        </CardTitle>


      </CardBody>

    </Card>
  );
};

export default ClientFormationCard;

