import React, {useEffect, useState} from "react"
import {Redirect, withRouter} from "react-router-dom"
import {Input, Label, Button, Card, CardBody, FormGroup, Col, Container, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

// Authentification
import {verifRoleRoute} from "helpers/role_helper";
import { generatePassword } from "helpers/text_helper"

// Redux
import {useDispatch, useSelector} from "react-redux";
import {selectAccount, UPDATE_ACCOUNT, UPDATE_PASSWORD_ACCOUNT, ACCOUNT, ACCOUNT_FORM_DATA_ERROR, ACCOUNT_FORM_PASSWORD_ERROR} from "store/account/account/accountSlice";

// Components
import GoBackBtn from "components/Common/GoBackBtn";
import SuccessErrorAlert from "components/Common/SuccessErrorAlert";

const AccountEdit = ({isRole, location}) => {

  const dispatch = useDispatch()
  const account = useSelector(selectAccount);

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [status, setStatus] = useState(true);

  // récupération de l'id passé dans la route
  const routeId = location.state

  const GeneratePasswordSecure = () => {
    const passwordSecure = generatePassword()
    setPassword(passwordSecure)
    setConfirmPassword(passwordSecure)
  }

  function handleValidSubmitUser(e, value) {

    const data = {
      userId: routeId,
      email: value.email,
      firstname: value.firstname,
      lastname: value.lastname,
      status: status
    }
    dispatch(UPDATE_ACCOUNT(data))
  }

  function handleValidSubmitPassword(e, value) {
    if (value.password !== value.confirmPassword) {
      dispatch(ACCOUNT_FORM_PASSWORD_ERROR("Les 2 mots de passe ne sont pas identiques !!"))
    } else {
      const data = { userId: routeId, password: value.password }
      dispatch(UPDATE_PASSWORD_ACCOUNT(data))
    }
  }

  function handleInvalidSubmitUser() {
    dispatch(ACCOUNT_FORM_DATA_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  function handleInvalidSubmitPassword() {
    dispatch(ACCOUNT_FORM_PASSWORD_ERROR("Veuillez remplir tous les champs obligatoires marqués d'un *"))
  }

  useEffect(() => {
    dispatch(ACCOUNT({ userId: routeId }))
    setStatus(account.user.status)

    // génération d'un mot de passe sécurisé
    GeneratePasswordSecure()

  }, [dispatch, routeId, account.user.status])

  if (verifRoleRoute(isRole)) {
    return (
      <React.Fragment>
        <div className="page-content my-5">
          <Container fluid>

            <GoBackBtn link="/gestion-comptes" title="Revenir à la liste des comptes" />
            <SuccessErrorAlert error={account.error} />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitUser(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitUser(e, v)}
                    >

                      <Row>
                        <Col lg={8}>
                          <div className="mt-4">

                            <h5 className="mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier un compte</h5>

                            <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>

                            <Row>

                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Prénom <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="firstname"
                                    value={account.user.firstname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    errorMessage="Veuillez renseigner un prénom"
                                    required
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <h5 className="font-size-17 mt-5 mb-3">Nom <span className="text-danger">*</span></h5>
                                  <AvField
                                    name="lastname"
                                    value={account.user.lastname}
                                    className="form-control"
                                    placeholder=""
                                    type="text"
                                    errorMessage="Veuillez renseigner un nom"
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="form-group mb-4">
                              <h5 className="font-size-17 mt-5 mb-4">Email <span className="text-danger">*</span></h5>
                              <AvField
                                name="email"
                                value={account.user.email}
                                className="form-control"
                                placeholder=""
                                type="email"
                                errorMessage="Veuillez renseigner un email"
                                required
                              />
                            </div>

                            <FormGroup>
                              <div className="form-check mt-4">
                                <Input type="checkbox"
                                       className="form-check-input"
                                       id="status-input"
                                       onChange={() => status ? setStatus(false) : setStatus(true)}
                                       checked={status}
                                />
                                <Label className="form-check-label" htmlFor="status-input">Compte actif ?
                                  <span className="font-size-15 color-grey mx-2">(si décoché, l'accès de l'utilisateur à l'application sera bloqué)</span>
                                </Label>
                              </div>
                            </FormGroup>
                          </div>
                        </Col>

                      </Row>

                      <div className="mt-4 mb-3">

                        <SuccessErrorAlert error={account.errorDataForm} success={account.successDataForm} />

                        <Button type="submit" color="success" className="w-md">Modifier le compte</Button>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="mx-5">
                    <AvForm
                      onValidSubmit={(e, v) => handleValidSubmitPassword(e, v)}
                      onInvalidSubmit={(e, v) => handleInvalidSubmitPassword(e, v)}
                    >

                      <Row>
                        <Col lg={6}>
                          <div className="mt-4">

                            <h5 className="mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Modifier le mot de passe</h5>

                            <p className="text-danger">Les champs marqués d'un * sont obligatoires</p>

                            <p><i className="uil uil-question-circle font-size-16 text-info me-2"></i> Un nouveau mot de passe sécurisé est suggéré automatiquement par défaut</p>

                            <Button
                              color="dark"
                              outline
                              className="waves-effect waves-light mb-3"
                              onClick={ () => GeneratePasswordSecure()}
                            >
                              Générer un nouveau mot de passe ?
                            </Button>

                            <Row>

                            <div className="form-group">
                              <h5 className="font-size-17 mt-4 mb-4">Mot de passe <span className="text-danger">*</span></h5>
                              <AvField
                                name="password"
                                value={password}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setPassword(event.target.value)}
                                errorMessage="Veuillez renseigner un mot de passe"
                                required
                              />
                            </div>

                            <div className="form-group mt-4">
                              <h5 className="font-size-17 mt-5 mb-4">Confirmer le mot de passe <span className="text-danger">*</span></h5>
                              <AvField
                                name="confirmPassword"
                                value={confirmPassword}
                                className="form-control"
                                placeholder=""
                                type="text"
                                onChange={event => setConfirmPassword(event.target.value)}
                                errorMessage="Veuillez confirmer le mot de passe"
                                required
                              />
                            </div>

                            </Row>
                          </div>
                        </Col>

                      </Row>


                      <div className="mt-5 mb-3">

                        <SuccessErrorAlert error={account.errorPasswordForm} success={account.successPasswordForm} />

                        <Button type="submit" color="success" className="w-md">Modifier le mot de passe</Button>

                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )

  } else {
    return (
      <Redirect to={{pathname: "/page-404", state: {from: location}}}/>
    )
  }
}

export default withRouter(AccountEdit)
